import { template } from "@ember/template-compiler";
import BsButtonGroup from 'ember-bootstrap/components/bs-button-group';
import { eq, or } from 'ember-truth-helpers';
import { fn } from '@ember/helper';
import { subtract } from 'ramda';
import { t } from 'ember-intl';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
export default template(`
<BsButtonGroup
  @size={{or @size 'sm'}}
as |bg|>
  <bg.button
    @type="secondary"
    @onClick={{fn @increment @item}}
    disabled={{or (eq @index 0) @disabled}}
    title={{t "moveUp"}}
    class="app-move-up"
  >
    <FaIcon @icon="arrow-up" @prefix="fas" class="fa-fw"/>
  </bg.button>
  <bg.button
    @type="secondary"
    @onClick={{fn @decrement @item}}
    disabled={{or (eq @index (subtract @items.length 1)) @disabled}}
    title={{t "moveDown"}}
    class="app-move-down"
  >
    <FaIcon @icon="arrow-down" @prefix="fas" class="fa-fw"/>
  </bg.button>
</BsButtonGroup>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
