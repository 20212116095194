import { template } from "@ember/template-compiler";
import SidePanel from 'eflex/components/side-panel';
import { t } from 'ember-intl';
import BsForm from 'eflex/components/bs-form';
import AdamIo from 'eflex/components/hardware/adam-io';
import CognexCamera from 'eflex/components/hardware/cognex-camera';
import Datalogic from 'eflex/components/hardware/datalogic';
import Edhr from 'eflex/components/hardware/edhr';
import Keyence from 'eflex/components/hardware/keyence';
import LightGuide from 'eflex/components/hardware/light-guide';
import NodeRed from 'eflex/components/hardware/node-red';
import OpcUa from 'eflex/components/hardware/opc-ua';
import OpenProtocolTool from 'eflex/components/hardware/open-protocol-tool';
import PlcHardware from 'eflex/components/hardware/plc-hardware';
import TurckIo from 'eflex/components/hardware/turck-io';
import UniversalRobot from 'eflex/components/hardware/universal-robot';
import WebCam from 'eflex/components/hardware/web-cam';
import ZebraPrinter from 'eflex/components/hardware/zebra-printer';
const getHardwareConfigComponent = (hardware1)=>{
    switch(hardware1.constructor.modelName){
        case 'adam-io':
            {
                return AdamIo;
            }
        case 'cognex-camera':
            {
                return CognexCamera;
            }
        case 'datalogic':
            {
                return Datalogic;
            }
        case 'edhr':
            {
                return Edhr;
            }
        case 'keyence':
            {
                return Keyence;
            }
        case 'light-guide':
            {
                return LightGuide;
            }
        case 'node-red':
            {
                return NodeRed;
            }
        case 'opc-ua':
            {
                return OpcUa;
            }
        case 'open-protocol-tool':
            {
                return OpenProtocolTool;
            }
        case 'plc-hardware':
            {
                return PlcHardware;
            }
        case 'turck-io':
            {
                return TurckIo;
            }
        case 'universal-robot':
            {
                return UniversalRobot;
            }
        case 'web-cam':
            {
                return WebCam;
            }
        case 'zebra-printer':
            {
                return ZebraPrinter;
            }
        default:
            {
                return null;
            }
    }
};
export default template(`
<SidePanel
  @header={{@hardware.displayType}}
  @subheader={{@hardware.name}}
  @onClose={{@onClose}}
  class="hardware-panel component-hardware-panel"
  ...attributes
>
  <div class="p-3">
    <BsForm
      @model={{@hardware}}
      @showValidations={{@showValidations}}
      @disabled={{@disabled}}
    as |form|>
      <form.element
        @controlType="text"
        @property="name"
        @label={{t "name"}}
        class="is-required hardware-name"
      />

      {{#let (getHardwareConfigComponent @hardware) as |HardwareConfigComponent|}}
        {{#if HardwareConfigComponent}}
          <HardwareConfigComponent
            @form={{form}}
            @hardware={{@hardware}}
          />
        {{/if}}
      {{/let}}
    </BsForm>
  </div>
</SidePanel>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
