import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import { selectVal } from 'eflex/helpers';
import { not, or } from 'ember-truth-helpers';
import { removeEdhrMappingsBelowMaxBoltCount } from 'eflex/services/task-repo';
import { removeInvalidBoltAnimations } from 'eflex/services/task-config-repo';
const limitSources = [
    {
        label: 'useDeviceLimits',
        value: 'false'
    },
    {
        label: 'useEflexLimits',
        value: 'true'
    }
];
const getCurrentLimitSource = (task1)=>{
    return limitSources.find((item1)=>item1.value === String(task1?.torqueUseEflexLimits));
};
export default class TaskContextConfigTorque extends Component {
    setUseEflexLimits = (value1)=>{
        this.args.task.torqueUseEflexLimits = JSON.parse(value1);
    };
    setBoltCount = (val1)=>{
        const taskConfig1 = this.args.taskConfig;
        taskConfig1.boltCount = val1;
        removeEdhrMappingsBelowMaxBoltCount(this.args.task);
        removeInvalidBoltAnimations(taskConfig1);
    };
    static{
        template(`
    <div class="row form-row model-specific">
      <div class="col-3">
        <@form.element
          @controlType="number"
          @label={{t "programNumber"}}
          @property="programNumber"
        />
      </div>
      <div class="col">
        <@form.element
          class="bolt-count"
          @controlType="number"
          @label={{t "plant.station.bolt"}}
          @property="boltCount"
          @onChange={{this.setBoltCount}}
        />
      </div>
    </div>
    <div class="row form-row">
      <div class="col-3">
        <@form.element
          @controlType="radio-group"
          @options={{limitSources}}
          @value={{getCurrentLimitSource @task}}
          @optionLabelPath="label"
          @onChange={{selectVal this.setUseEflexLimits}}
        as |el|>
          <el.control as |opt|>
            {{t opt}}
          </el.control>
        </@form.element>
      </div>
      {{#let (or @disabled (not @task.torqueUseEflexLimits)) as |disabled|}}
        {{#let (not @taskConfig.finalTorqueLimitsEnabled) as |finalTorqueDisabled|}}
          <div class="col model-specific">
            <@form.element
              class="mb-1 test-final-torque-limits-enable"
              @controlType="checkbox"
              @property="finalTorqueLimitsEnabled"
              @label={{t "plant.task.torque.finalTorque"}}
            as |el|>
              <el.control @disabled={{disabled}} />
            </@form.element>
            <div class="row form-row text-uppercase">
              <div class="col">
                <@form.element
                  class="small test-final-torque-lower-limit"
                  @controlType="number"
                  @label={{t "lowerLimit"}}
                  @property="finalTorqueLowerLimit"
                as |el|>
                  <el.control disabled={{or disabled finalTorqueDisabled}} />
                </@form.element>
              </div>
              <div class="col">
                <@form.element
                  class="small test-final-torque-upper-limit"
                  @controlType="number"
                  @label={{t "upperLimit"}}
                  @property="finalTorqueUpperLimit"
                as |el|>
                  <el.control disabled={{or disabled finalTorqueDisabled}} />
                </@form.element>
              </div>
            </div>
          </div>
        {{/let}}
        {{#let (not @taskConfig.finalAngleLimitsEnabled) as |finalAngleDisabled|}}
          <div class="col model-specific">
            <@form.element
              class="mb-1 test-final-angle-limits-enable"
              @controlType="checkbox"
              @property="finalAngleLimitsEnabled"
              @label={{t "plant.task.torque.finalAngle"}}
            as |el|>
              <el.control @disabled={{disabled}} />
            </@form.element>
            <div class="row form-row text-uppercase">
              <div class="col">
                <@form.element
                  class="small test-final-angle-lower-limit"
                  @controlType="number"
                  @label={{t "lowerLimit"}}
                  @property="finalAngleLowerLimit"
                as |el|>
                  <el.control
                    disabled={{or disabled finalAngleDisabled}}
                  />
                </@form.element>
              </div>
              <div class="col">
                <@form.element
                  class="small test-final-angle-upper-limit"
                  @controlType="number"
                  @label={{t "upperLimit"}}
                  @property="finalAngleUpperLimit"
                as |el|>
                  <el.control
                    disabled={{or disabled finalAngleDisabled}}
                  />
                </@form.element>
              </div>
            </div>
          </div>
        {{/let}}
      {{/let}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
