import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { all, task, timeout } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import OEE_STATES from 'eflex/constants/oee-states';
import STATION_FILTERS from 'eflex/constants/bi-andon-station-filters';
import moment from 'moment-timezone';
import { formatStateColumnData } from 'eflex/util/bi-format-oee-state-cell';
import { tracked } from '@glimmer/tracking';
import TaskStatuses from 'eflex/constants/task-statuses';
import echartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import TabulatorTable from 'eflex/components/tabulator-table';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import Spinner from 'eflex/components/spinner';
import { isTesting, macroCondition } from '@embroider/macros';
const REFRESH_INTERVAL = 15_000;
export default class AndonStationSummary extends Component {
    @service
    queryRunner;
    @service
    intl;
    @service
    store;
    @service
    stationRepo;
    @service
    licensing;
    @tracked
    timerToken;
    startAutoRefresh = task({
        drop: true
    }, waitFor(async ()=>{
        if (macroCondition(isTesting())) {
            return;
        }
        if (globalThis.isIntegrationTest) {
            return;
        }
        while(true){
            await timeout(REFRESH_INTERVAL);
            this.args.refresher = true;
        }
    }));
    get params() {
        return {
            stationIds: this.args.stationIds,
            stationFilter: this.args.stationFilter
        };
    }
    get tableConfiguration() {
        const columns1 = [
            {
                title: this.intl.t('station'),
                field: 'station',
                tooltip: false,
                headerSort: false,
                formatter: 'textarea'
            },
            {
                title: this.intl.t('bi.chartLabel.operator'),
                field: 'operator',
                tooltip: false,
                headerSort: false,
                formatter: 'textarea'
            },
            {
                title: this.intl.t('model'),
                field: 'model',
                tooltip: false,
                headerSort: false,
                formatter: 'textarea'
            }
        ];
        if (this.params.stationFilter === STATION_FILTERS.oee || this.params.stationFilter === STATION_FILTERS.all) {
            columns1.push({
                title: this.intl.t('bi.chartLabel.oeeState'),
                field: 'state',
                tooltip: false,
                headerSort: false,
                formatter: (cell1)=>formatStateColumnData(cell1),
                width: 100
            }, {
                title: this.intl.t('bi.chartLabel.oeeStateDuration'),
                field: 'oeeStateDuration',
                tooltip: false,
                headerSort: false,
                formatter: (cell1)=>this.formatStateDuration(cell1),
                maxWidth: 185
            }, {
                title: this.intl.t('bi.chartLabel.faults'),
                field: 'faults',
                tooltip: false,
                headerSort: false,
                formatter: 'textarea'
            });
        }
        if (this.params.stationFilter === STATION_FILTERS.jem || this.params.stationFilter === STATION_FILTERS.all) {
            columns1.push({
                title: this.intl.t('bi.chartLabel.currentStationState'),
                field: 'stationState',
                tooltip: false,
                headerSort: false,
                minWidth: 100
            }, {
                title: this.intl.t('bi.chartLabel.stationCycleTime'),
                field: 'stationCycleTime',
                tooltip: false,
                headerSort: false,
                formatter: (cell1)=>this.formatCycleTime(cell1),
                minWidth: 125
            });
        }
        return {
            columns: columns1
        };
    }
    getOeeStateData = task({
        restartable: true
    }, waitFor(async ()=>{
        const oeeStatesQuery1 = [
            {
                $match: {
                    endDate: null,
                    state: {
                        $in: Object.values(OEE_STATES.STATES)
                    }
                }
            },
            {
                $group: {
                    _id: '$station._id',
                    station: {
                        $first: '$station'
                    },
                    state: {
                        $first: '$state'
                    },
                    customButtonColor: {
                        $first: '$customButtonColor'
                    },
                    faultCodes: {
                        $first: '$faultCodes'
                    },
                    totalTimeInState: {
                        $sum: {
                            $dateDiff: {
                                startDate: '$startDate',
                                endDate: '$$NOW',
                                unit: 'millisecond'
                            }
                        }
                    }
                }
            }
        ];
        const [oeeStates1, liveBuildStatuses1] = await all([
            this.queryRunner.runQuery.perform('OeeStates', oeeStatesQuery1),
            this.queryRunner.runQuery.perform('LiveBuildStatuses', [])
        ]);
        const stations1 = this.params.stationIds.length > 0 ? this.params.stationIds.map((stationId1)=>this.store.peekRecord('station', stationId1)) : this.stationRepo.stations;
        let filteredBuildStatuses1 = stations1.map((station1)=>{
            const liveBuildStatus1 = liveBuildStatuses1.filter((item1)=>item1.status !== TaskStatuses.STOPPED).find((item1)=>item1.location?._id === station1.id);
            const oeeState1 = this.licensing.license.oeeEnabled ? oeeStates1?.find((item1)=>item1.station?._id === station1.id) : null;
            const stationCycleTime1 = moment.duration(moment().diff(moment(liveBuildStatus1?.timestamp), 'milliseconds'));
            return {
                liveBuildStatus: liveBuildStatus1,
                stationName: station1.name,
                oeeState: oeeState1,
                stationCycleTime: stationCycleTime1,
                stationStateTranslationKey: this.setStationStateTranslationKey(liveBuildStatus1)
            };
        });
        if (this.params.stationFilter === STATION_FILTERS.oee) {
            filteredBuildStatuses1 = filteredBuildStatuses1.filter((buildStatus1)=>buildStatus1.oeeState != null);
        } else if (this.params.stationFilter === STATION_FILTERS.jem) {
            filteredBuildStatuses1 = filteredBuildStatuses1.filter((buildStatus1)=>buildStatus1.stationStateTranslationKey != 'idle');
        }
        return filteredBuildStatuses1.map((status1)=>{
            return {
                station: status1.stationName,
                operator: status1.liveBuildStatus?.userName,
                model: status1.liveBuildStatus?.model?.code,
                state: status1.oeeState,
                oeeStateDuration: moment.duration(status1.oeeState?.totalTimeInState),
                faults: status1.oeeState?.faultCodes?.codes?.map((faultCode1)=>`${faultCode1.code} - ${faultCode1.description}`).join(', '),
                stationState: this.intl.t(status1.stationStateTranslationKey),
                stationCycleTime: status1.stationCycleTime,
                targetCycleTime: status1.liveBuildStatus?.totalTargetCycleTime * 1000
            };
        }).toSorted((a1, b1)=>a1.station.localeCompare(b1.station));
    }));
    oeeStateAndLiveBuildStatusData = trackedTask(this, this.getOeeStateData, ()=>[
            this.params,
            this.args.refresher
        ]);
    formatStateDuration(cell1) {
        const { state: state1, oeeStateDuration: oeeStateDuration1 } = cell1.getData();
        Object.assign(cell1.getElement().style, {
            whiteSpace: 'pre-wrap'
        });
        // render nothing instead of 0.0s
        return state1 ? oeeStateDuration1.format('d[d] h[h] m[m] s[s]', {
            precision: 1,
            trim: 'both mid'
        }) : null;
    }
    formatCycleTime(cell1) {
        const cellData1 = cell1.getData();
        const { stationCycleTime: stationCycleTime1, targetCycleTime: targetCycleTime1 } = cellData1;
        // render nothing instead of 0.0s
        if (!stationCycleTime1._milliseconds) {
            return null;
        }
        if (targetCycleTime1 && stationCycleTime1._milliseconds > targetCycleTime1) {
            Object.assign(cell1.getElement().style, {
                color: echartsTheme.colorPalette.danger,
                fontWeight: 'bolder',
                whiteSpace: 'pre-wrap'
            });
        }
        return stationCycleTime1.format('d[d] h[h] m[m] s[s]', {
            precision: 1,
            trim: 'both mid'
        });
    }
    setStationStateTranslationKey(liveBuildStatus1) {
        if (liveBuildStatus1 && liveBuildStatus1.status !== TaskStatuses.STOPPED) {
            return `status.${liveBuildStatus1.status}`;
        } else {
            return 'idle';
        }
    }
    static{
        template(`
    {{#if this.oeeStateAndLiveBuildStatusData.value}}
      <TabulatorTable
        class="bi-tabulator component-bi-andon-station-summary d-flex flex-column h-100"
        @tableConfiguration={{this.tableConfiguration}}
        @updateColumns={{true}}
        @data={{this.oeeStateAndLiveBuildStatusData.value}}
        {{didInsert this.startAutoRefresh.perform}}
        ...attributes
      />
    {{else if this.oeeStateAndLiveBuildStatusData.isRunning}}
      <Spinner />
    {{else}}
      <BiNoDataPlaceholder />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
