import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import BsButton from 'eflex/components/bs-button';
import { gte, lte } from 'ember-truth-helpers';
import { divide, multiply, min, add, subtract } from 'ramda';
import { ceil } from 'ramda-adjunct';
import { fn } from '@ember/helper';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
export default template(`
<div class="pagination-row sticky-bottom mt-auto text-gray-400">
  <div class="d-flex justify-content-end">
    {{#let (multiply @page @take) as |upperCount|}}
      <div class="align-self-center pagination-count">
        {{t "pagination.count"
          firstRecord=(subtract upperCount (subtract @take 1))
          lastRecord=(min upperCount @recordCount)
          totalRecords=@recordCount
        }}
      </div>
    {{/let}}
    {{#let (ceil (divide @recordCount @take)) as |pageMax|}}
      <div class="mx-3 my-1">
        <BsButton
          class="step-start"
          @disabled={{lte @page 1}}
          @onClick={{fn @updatePage 1}}
        >
          <FaIcon @icon="backward-step" class="icon-link text-dark" />
        </BsButton>
        <BsButton
          class="step-previous"
          @disabled={{lte @page 1}}
          @onClick={{fn @updatePage (subtract @page 1)}}
        >
          <FaIcon @icon="chevron-left" class="icon-link text-dark" />
        </BsButton>
        <BsButton
          class="step-next"
          @disabled={{gte @page pageMax}}
          @onClick={{fn @updatePage (add @page 1)}}
        >
          <FaIcon @icon="chevron-right" class="icon-link text-dark" />
        </BsButton>
        <BsButton
          class="step-end"
          @disabled={{gte @page pageMax}}
          @onClick={{fn @updatePage pageMax}}
        >
          <FaIcon @icon="forward-step" class="icon-link text-dark" />
        </BsButton>
      </div>
    {{/let}}
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
