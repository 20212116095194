import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import DecisionTypes from 'eflex/constants/tasks/decision-types';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import DecisionConfig from 'eflex/components/decision-config';
import AddButton from 'eflex/components/add-button';
import { not, or } from 'ember-truth-helpers';
const decisionTypes = Object.values(DecisionTypes);
export default class TaskContextConfigDecision extends Component {
    @service
    decisionDefRepo;
    @service
    intl;
    get showOptions() {
        return this.args.task.decisionType !== DecisionTypes.passFail;
    }
    addDecisionDef = ()=>{
        this.decisionDefRepo.createDecisionDefForTask(this.args.task);
    };
    selectDecisionType = (decisionType1)=>{
        const task1 = this.args.task;
        if (decisionType1 === DecisionTypes.passFail) {
            const decisionDefs1 = task1.decisionDefs;
            decisionDefs1.flatMap((decisionDef1)=>decisionDef1.decisionDefConfigs).forEach((decisionDefConfig1)=>{
                decisionDefConfig1.deleteRecord();
            });
            decisionDefs1.forEach((decisionDef1)=>{
                decisionDef1.deleteRecord();
            });
            this.decisionDefRepo.createDecisionDefForTask(task1, {
                name: this.intl.t('pass')
            });
            this.decisionDefRepo.createDecisionDefForTask(task1, {
                name: this.intl.t('fail')
            });
            task1.decisionRequired = false;
        }
        task1.decisionType = decisionType1;
    };
    static{
        template(`
    <div class="row form-row">
      <div class="col">
        <@form.element
          @controlType="checkbox"
          @model={{@task}}
          @property="decisionRequired"
          @label={{t "requireDecision"}}
          class="require-decision"
        as |el|>
          <el.control @disabled={{or @disabled (not this.showOptions)}} />
        </@form.element>
      </div>
    </div>
    <div class="row form-row">
      <div class="col">
        <@form.element
          @controlType="power-select"
          @label={{t "decisionType"}}
          @options={{decisionTypes}}
          @value={{@task.decisionType}}
          @onChange={{this.selectDecisionType}}
          as |el|
        >
          <el.control @triggerClass="form-control" as |option|>
            {{t option}}
          </el.control>
        </@form.element>
      </div>
    </div>
    <div class="row form-row">
      <div class="col">
        <@form.element
          @controlType="text"
          @label={{t "decisionLabel"}}
          @model={{@task}}
          @property="decisionLabel"
        />
      </div>
    </div>

    <DecisionConfig
      @location={{@task}}
      @taskConfig={{@taskConfig}}
      @disabled={{@disabled}}
    />

    {{#if this.showOptions}}
      <AddButton
        @onClick={{this.addDecisionDef}}
        class="my-2 add-decision"
      >
        {{t "addDecision"}}
      </AddButton>
    {{else}}
      <div class="mb-2"></div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
