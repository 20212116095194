import { template } from "@ember/template-compiler";
import ButtonPowerSelect from 'eflex/components/button-power-select';
import { get } from '@ember/helper';
export default template(`
<ButtonPowerSelect
  @options={{@options}}
  @triggerClass={{@triggerClass}}
  @placeholder={{@placeholder}}
  @disabled={{@disabled}}
  @extra={{@extra}}
  @searchEnabled={{@searchEnabled}}
  @searchField={{@searchField}}
  @ariaDescribedBy={{@ariaDescribedBy}}
  @onChange={{@onChange}}
  ...attributes
as |option select|>
  {{#if (has-block)}}
    {{yield option select}}
  {{else}}
    {{#if @optionLabelPath}}
      {{get option @optionLabelPath}}
    {{else}}
      {{option}}
    {{/if}}
  {{/if}}
</ButtonPowerSelect>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
