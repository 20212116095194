import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import { all, task, timeout } from 'ember-concurrency';
import getDelayTime from 'eflex/util/get-delay-time';
import { AnimationTypes } from 'eflex/constants/jem/instruction-animation-types';
import { TextTypes, EflexObjTypes } from 'eflex/constants/work-instructions/tool-props';
import { fabric } from 'fabric';
import { clone } from 'ramda';
import { dequal } from 'dequal';
import { isBlank, isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { getCanvasObjects, getCanvasObjectByPoint, getCanvasObjectById, getCanvasCoordsForClick, updateObjectProperty, getArrowsByLine, scaleCanvas as origScaleCanvas, cleanupCanvas, initializeCanvas } from 'eflex/util/fabric-helpers';
import { waitFor } from '@ember/test-waiters';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import { onResize } from 'eflex/modifiers';
import Spinner from 'eflex/components/spinner';
import { on } from '@ember/modifier';
const ANIMATION_DURATION = 200;
const renderAllSafe = (canvas1)=>{
    try {
        canvas1.renderAll();
    } catch (e1) {
        // Don't care if animation is interrupted midway.
        if (!(e1 instanceof TypeError)) {
            throw e1;
        }
    }
};
const setLineColor = (lineGroup1, strokeColor1)=>{
    if (strokeColor1 == null) {
        return;
    }
    lineGroup1.getObjects()?.forEach(function(obj1) {
        switch(obj1.eflex?.type){
            case EflexObjTypes.STRAIGHT_LINE:
                {
                    obj1.set('stroke', strokeColor1);
                    break;
                }
            case EflexObjTypes.ARROWHEAD:
                {
                    obj1.set({
                        fill: strokeColor1,
                        stroke: strokeColor1
                    });
                    break;
                }
        }
    });
};
const setFill = (obj1, fillColor1)=>{
    if (obj1.fill) {
        obj1.set('fill', fillColor1);
    }
    if (obj1.stroke) {
        obj1.set('stroke', fillColor1);
    }
};
const setIconColor = (icon1, fillColor1)=>{
    if (fillColor1 == null) {
        return;
    }
    if (icon1.type === 'group') {
        icon1.getObjects().forEach((obj1)=>{
            setFill(obj1, fillColor1);
        });
    } else {
        setFill(icon1, fillColor1);
    }
};
const setTextboxColor = (obj1, fillColor1, strokeColor1)=>{
    if (fillColor1 != null) {
        obj1.setSelectionStyles({
            fill: fillColor1
        }, 0, obj1.text.length);
    }
    if (strokeColor1 != null) {
        obj1.set('stroke', strokeColor1);
        obj1.dirty = true;
    }
};
const setColor = (obj1, fillColor1, strokeColor1, allowNull1 = false)=>{
    const type1 = obj1.eflex?.type ?? obj1.type;
    switch(type1){
        case EflexObjTypes.LINE_GROUP:
            {
                setLineColor(obj1, strokeColor1);
                break;
            }
        case EflexObjTypes.ICON:
            {
                setIconColor(obj1, fillColor1);
                break;
            }
        case TextTypes.TEXTBOX:
            {
                setTextboxColor(obj1, fillColor1, strokeColor1);
                break;
            }
        default:
            {
                if (fillColor1 != null || allowNull1) {
                    obj1.set('fill', fillColor1);
                }
                if (strokeColor1 != null || allowNull1) {
                    obj1.set('stroke', strokeColor1);
                }
            }
    }
};
const notifyModified = (canvas1, target1)=>{
    if (!target1) {
        return;
    }
    canvas1.fire('object:modified', {
        target: target1
    });
    canvas1.renderAll();
};
const prepareLineObjects = (canvas1, previewMode1)=>{
    const lines1 = getCanvasObjects(canvas1, EflexObjTypes.STRAIGHT_LINE);
    lines1.forEach((line1)=>{
        const arrowheads1 = getArrowsByLine(canvas1, line1);
        const layer1 = getCanvasObjects(canvas1).indexOf(line1);
        const id1 = line1.id;
        line1.id = undefined;
        const objects1 = [
            line1
        ];
        objects1.push(...arrowheads1);
        objects1.forEach((obj1)=>{
            obj1.opacity = 1;
        });
        const active1 = new fabric.ActiveSelection(objects1, {
            canvas: canvas1
        });
        const group1 = active1.toGroup();
        group1.set({
            id: id1,
            selectable: false,
            editable: false,
            eflex: {
                type: EflexObjTypes.LINE_GROUP,
                startHidden: line1.eflex.startHidden,
                originalStrokeColor: line1.eflex.originalStrokeColor
            }
        });
        group1.moveTo(layer1);
        if (line1.eflex.startHidden && !previewMode1) {
            group1.opacity = 0;
        }
        canvas1.discardActiveObject();
    });
};
const scaleCanvas = (element1, canvas1, workInstruction1)=>{
    const display1 = element1.querySelector('.work-instruction-display');
    if (display1 == null) {
        return;
    }
    const { width: width1, height: height1 } = display1.getBoundingClientRect();
    origScaleCanvas(canvas1, width1, height1, workInstruction1?.width, workInstruction1?.height);
};
const setStartHiddenObjectOpacity = (canvas1, opacity1)=>{
    getCanvasObjects(canvas1)?.forEach((obj1)=>{
        if (obj1.eflex?.startHidden) {
            obj1.opacity = opacity1;
        }
    });
    renderAllSafe(canvas1);
};
export default class JemDynamicInstruction extends Component {
    @service
    store;
    previousAnimations = this.args.animations;
    previousWorkInstruction = this.args.workInstruction;
    canvas;
    onResize = task({
        drop: true
    }, waitFor(async (element1)=>{
        await timeout(getDelayTime(100));
        scaleCanvas(element1, this.canvas, this.args.workInstruction);
    }));
    loadCanvas = task({
        enqueue: true
    }, waitFor(async (element1)=>{
        cleanupCanvas(this.canvas);
        await this.store.findRecord('workInstruction', this.args.workInstruction.id, {
            reload: true
        });
        this.canvas = initializeCanvas(element1.querySelector('.work-instruction-canvas'), {
            width: this.args.workInstruction.width,
            height: this.args.workInstruction.height,
            hoverCursor: 'default'
        });
        const canvasJson1 = this.args.workInstruction.deployedCanvas;
        canvasJson1.selection = false;
        canvasJson1.objects.forEach((obj1)=>{
            obj1.objectCaching = false;
            obj1.selectable = false;
            obj1.editable = false;
            if (obj1.eflex != null) {
                obj1.eflex.originalFillColor = obj1.fill;
                obj1.eflex.originalStrokeColor = obj1.stroke;
                if (obj1.type === TextTypes.TEXTBOX) {
                    obj1.eflex.originalStyles = clone(obj1.styles);
                }
                if (obj1.eflex.link?.length > 0) {
                    obj1.hoverCursor = 'pointer';
                }
                if (obj1.eflex.startHidden && !this.args.previewMode) {
                    obj1.opacity = 0;
                }
            }
        });
        await new Promise((resolve1)=>{
            this.canvas.loadFromJSON(canvasJson1, resolve1);
        });
        scaleCanvas(element1, this.canvas, this.args.workInstruction);
        prepareLineObjects(this.canvas, this.args.previewMode);
        this.animate.perform(); // not yielded since it can use a while true for flash
    }));
    compareAttrs = task({
        onState: null
    }, waitFor(async (element1, [workInstruction1, animations1, previewMode1])=>{
        const workInstructionChanged1 = this.previousWorkInstruction !== workInstruction1;
        const animationsChanged1 = !dequal(this.previousAnimations, animations1);
        this.previousWorkInstruction = workInstruction1;
        this.previousAnimations = animations1;
        if (!workInstruction1 || workInstructionChanged1 || (previewMode1 && animations1 != null)) {
            await this.animate.cancelAll({
                resetState: true
            });
        }
        if (workInstructionChanged1) {
            await this.loadCanvas.perform(element1);
        } else if (animationsChanged1 || (previewMode1 && animations1 != null)) {
            await this.animate.cancelAll({
                resetState: true
            });
            this.animate.perform();
            if (previewMode1) {
                await this.resetCanvas.perform();
            }
        }
    }));
    resetCanvas = task({
        restartable: true
    }, waitFor(async ()=>{
        setStartHiddenObjectOpacity(this.canvas, 0);
        await timeout(this.#getDelayTime(this.args.resetTimeout));
        const tasks1 = [
            this.animate.cancelAll({
                resetState: true
            })
        ];
        tasks1.push(getCanvasObjects(this.canvas)?.map((obj1)=>this.resetObject.perform(obj1)));
        await all(tasks1);
        setStartHiddenObjectOpacity(this.canvas, 1);
    }));
    resetObject = task(waitFor(async (obj1)=>{
        let fillColor1, strokeColor1;
        if (obj1.eflex != null) {
            await this.resetObjectOpacity.perform(obj1);
            fillColor1 = obj1.eflex.originalFillColor;
            strokeColor1 = obj1.eflex.originalStrokeColor;
        }
        if (obj1.type === TextTypes.TEXTBOX) {
            setTextboxColor(obj1, null, strokeColor1);
            if (obj1.eflex != null) {
                obj1.styles = clone(obj1.eflex.originalStyles);
            }
        } else {
            setColor(obj1, fillColor1, strokeColor1, true);
        }
        renderAllSafe(this.canvas);
    }));
    resetObjectOpacity = task(waitFor(async (obj1)=>{
        if (obj1.eflex == null || !obj1.eflex.isFlashing) {
            return;
        }
        const opacity1 = obj1.eflex.startHidden ? 0 : 1;
        updateObjectProperty(obj1.eflex, 'isFlashing', false);
        notifyModified(this.canvas, obj1);
        await this._animateOpacity.perform(obj1, opacity1, {
            duration: 10
        });
    }));
    showObject = task(waitFor(async (obj1)=>{
        await this.resetObjectOpacity.perform(obj1);
        await this._animateOpacity.perform(obj1, 1, {
            duration: ANIMATION_DURATION,
            easing: fabric.util.ease.easeInQuad
        });
    }));
    hideObject = task(waitFor(async (obj1)=>{
        await this.resetObjectOpacity.perform(obj1);
        await this._animateOpacity.perform(obj1, 0, {
            duration: ANIMATION_DURATION,
            easing: fabric.util.ease.easeOutQuad
        });
    }));
    flashObject = task(waitFor(async (obj1)=>{
        obj1.eflex ??= {};
        updateObjectProperty(obj1.eflex, 'isFlashing', true);
        notifyModified(this.canvas, obj1);
        while(true){
            await all([
                this._animateEaseOutAndIn.perform(obj1),
                timeout(this.#getDelayTime(ANIMATION_DURATION * 4))
            ]);
        }
    }));
    _animateEaseOutAndIn = task(waitFor(async (obj1)=>{
        await this._animateOpacity.perform(obj1, 0, {
            duration: ANIMATION_DURATION,
            easing: fabric.util.ease.easeOutQuad
        });
        await this._animateOpacity.perform(obj1, 1, {
            duration: ANIMATION_DURATION,
            easing: fabric.util.ease.easeInQuad
        });
    }));
    animate = task({
        enqueue: true
    }, waitFor(async ()=>{
        if (isEmpty(this.args.animations) || !this.canvas) {
            return;
        }
        const animateTasks1 = this.args.animations.map((animation1)=>{
            const obj1 = getCanvasObjectById(this.canvas, animation1.object);
            if (obj1 == null) {
                return null;
            }
            switch(animation1.animation){
                case AnimationTypes.SHOW:
                    {
                        setColor(obj1, animation1.fillColor, animation1.strokeColor);
                        return this.showObject.perform(obj1);
                    }
                case AnimationTypes.HIDE:
                    {
                        setColor(obj1, animation1.fillColor, animation1.strokeColor);
                        return this.hideObject.perform(obj1);
                    }
                case AnimationTypes.FLASH:
                    {
                        setColor(obj1, animation1.fillColor, animation1.strokeColor);
                        return this.flashObject.perform(obj1);
                    }
                case AnimationTypes.RESET:
                    {
                        return this.resetObject.perform(obj1);
                    }
                default:
                    {
                        return null;
                    }
            }
        });
        await all(animateTasks1);
    }));
    _animateOpacity = task(waitFor(async (obj1, value1, options1)=>{
        await new Promise((resolve1)=>{
            options1.onComplete = resolve1;
            options1.onChange = ()=>{
                renderAllSafe(this.canvas);
            };
            obj1.animate('opacity', value1, options1);
        });
    }));
    openLink = task({
        drop: true
    }, waitFor(async (event1)=>{
        if (event1.target.tagName !== 'CANVAS') {
            return;
        }
        event1.preventDefault();
        const getCanvasCoordsForClickFunc1 = this.args.getCanvasCoordsForClick ?? getCanvasCoordsForClick;
        const { x: x1, y: y1 } = getCanvasCoordsForClickFunc1(this.canvas, event1);
        const obj1 = getCanvasObjectByPoint(this.canvas, x1, y1, true);
        const link1 = obj1?.eflex?.link;
        if (isBlank(link1)) {
            return;
        }
        if (link1.startsWith('red:')) {
            await fetch(link1.replace('red:', ''));
        } else {
            window.open(link1);
        }
    }));
    willDestroy() {
        super.willDestroy(...arguments);
        cleanupCanvas(this.canvas);
        this.canvas = null;
    }
    #getDelayTime(time1) {
        if (this.args.bypassDelayTime) {
            return time1;
        } else {
            return getDelayTime(time1);
        }
    }
    static{
        template(`
    <div
      class="component-jem-dynamic-instruction"
      {{didInsert this.loadCanvas.perform}}
      {{didUpdate this.compareAttrs.perform @workInstruction @animations @previewMode}}
      {{onResize this.onResize.perform}}
      ...attributes
    >
      {{#if this.loadCanvas.isRunning}}
        <Spinner />
      {{/if}}
      <div
        class="work-instruction-display"
        title={{@workInstruction.deployedName}}
        {{on "pointerup" this.openLink.perform}}
      >
        <canvas class="work-instruction-canvas"></canvas>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
