import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { trackedFunction } from 'reactiveweb/function';
import { waitForPromise } from '@ember/test-waiters';
import { t } from 'ember-intl';
import StandardModal from 'eflex/components/standard-modal';
export default class JsonModal extends Component {
    formattedJson = trackedFunction(this, async ()=>{
        const displayed1 = this.args.displayed;
        const { default: JSONFormatter1 } = await waitForPromise(import('json-formatter-js'));
        return new JSONFormatter1(displayed1).render();
    });
    static{
        template(`
    <StandardModal
      class="component-modals-json"
      @onCancel={{@onCancel}}
      @size="xl"
      ...attributes
    as |modal|>
      <modal.header />
      <modal.body>
        {{!-- template-lint-disable no-triple-curlies --}}
        {{{this.formattedJson.value}}}
        {{!-- template-lint-enable no-triple-curlies --}}
      </modal.body>
      <modal.footer as |footer|>
        <footer.close>
          {{t "close"}}
        </footer.close>
      </modal.footer>
    </StandardModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
