import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import BsForm from 'eflex/components/bs-form';
import { findBy } from '@eflexsystems/ember-composable-helpers';
const OPTIONS = [
    {
        label: 'useUniqueSerialNumbers',
        value: false
    },
    {
        label: 'useTotalCycles',
        value: true
    }
];
export default template(`
<div class="use-unique-serials-radio-button d-flex justify-content-center" ...attributes>
  <BsForm as |form|>
    <form.element
      @controlType="radio-group"
      @options={{OPTIONS}}
      @value={{findBy "value" @useTotalCycles OPTIONS}}
      @optionLabelPath="label"
      @onChange={{@onChange}}
    as |el|>
      <el.control @inline={{true}} as |opt|>
        {{t opt}}
      </el.control>
    </form.element>
  </BsForm>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
