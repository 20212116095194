import { template } from "@ember/template-compiler";
import { hash } from '@ember/helper';
import { t } from 'ember-intl';
const JemTaskPrintDetails = template(`
<div ...attributes>
  <div class="mt-2">
    <div class="fs-4 fw-semibold text-gray-800 printer-name">
      {{@hardware.cupsName}}
    </div>
    <div class="d-flex align-items-baseline">
      <div class="detail-text-value fw-bold text-gray-900 status success print-copies me-2">
        {{@task.printCopies}}
      </div>
      <div class="text-gray-800 fw-semibold">
        {{t "plant.task.printCopies"}}
      </div>
    </div>
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template(`
  {{yield
    (hash
      taskDetails=(component
        JemTaskPrintDetails
        task=@task
        hardware=@hardware
      )
    )
  }}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
