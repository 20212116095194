import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import TaskStatuses from 'eflex/constants/task-statuses';
import { waitFor } from '@ember/test-waiters';
import TabulatorTable from 'eflex/components/tabulator-table';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import { t } from 'ember-intl';
import BiTotalCyclesLabel from 'eflex/components/bi/total-cycles-label';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import Spinner from 'eflex/components/spinner';
import { isTesting, macroCondition } from '@embroider/macros';
const REFRESH_INTERVAL = 60_000;
export default class AndonProductionStation extends Component {
    @service
    queryRunner;
    @service
    intl;
    startAutoRefresh = task({
        drop: true
    }, waitFor(async ()=>{
        if (macroCondition(isTesting())) {
            return;
        }
        if (globalThis.isIntegrationTest) {
            return;
        }
        while(true){
            await timeout(REFRESH_INTERVAL);
            this.args.refresher = true;
        }
    }));
    get params() {
        return {
            stationIds: this.args.stationIds,
            modelIds: this.args.modelIds,
            userIds: this.args.userIds,
            tags: this.args.tags,
            startDate: this.args.startDate,
            endDate: this.args.endDate,
            shiftNames: this.args.shiftNames,
            useTotalCycles: this.args.useTotalCycles
        };
    }
    get tableConfiguration() {
        return {
            columns: [
                {
                    title: this.intl.t('station'),
                    field: 'station',
                    tooltip: false,
                    headerSort: false,
                    formatter: 'textarea'
                },
                {
                    title: this.intl.t('bi.chartLabel.partCounts'),
                    field: 'partCounts',
                    tooltip: false,
                    headerSort: false,
                    formatter: (cell1)=>this.formatPartsColumnData(cell1),
                    hozAlign: 'right'
                }
            ]
        };
    }
    getData = task({
        restartable: true
    }, waitFor(async (params1)=>{
        const totalCyclesTableQuery1 = await this.queryRunner.queryWithParams.perform(params1, [
            {
                $group: {
                    _id: '$location._id',
                    station: {
                        $first: '$location.captions'
                    },
                    totalParts: {
                        $sum: 1
                    },
                    goodParts: {
                        $sum: {
                            $cond: {
                                if: {
                                    $and: [
                                        {
                                            $gte: [
                                                '$status',
                                                TaskStatuses.GOOD_RANGE_START
                                            ]
                                        },
                                        {
                                            $lte: [
                                                '$status',
                                                TaskStatuses.GOOD_RANGE_END
                                            ]
                                        }
                                    ]
                                },
                                then: 1,
                                else: 0
                            }
                        }
                    }
                }
            },
            {
                $project: {
                    _id: false,
                    station: {
                        $arrayElemAt: [
                            '$station',
                            0
                        ]
                    },
                    totalParts: true,
                    goodParts: true
                }
            },
            {
                $sort: {
                    totalParts: -1
                }
            }
        ]);
        const totalPartsTableQuery1 = await this.queryRunner.queryWithParams.perform(params1, [
            {
                $sort: {
                    timestamp: -1
                }
            },
            {
                $group: {
                    _id: {
                        serialNumber: '$serialNumber',
                        stationId: '$location._id'
                    },
                    buildStatus: {
                        $first: {
                            station: '$location',
                            timestamp: '$timestamp',
                            status: '$status'
                        }
                    }
                }
            },
            {
                $group: {
                    _id: '$_id.stationId',
                    station: {
                        $first: '$buildStatus.station.captions'
                    },
                    totalParts: {
                        $sum: 1
                    },
                    goodParts: {
                        $sum: {
                            $cond: {
                                if: {
                                    $and: [
                                        {
                                            $gte: [
                                                '$buildStatus.status',
                                                TaskStatuses.GOOD_RANGE_START
                                            ]
                                        },
                                        {
                                            $lte: [
                                                '$buildStatus.status',
                                                TaskStatuses.GOOD_RANGE_END
                                            ]
                                        }
                                    ]
                                },
                                then: 1,
                                else: 0
                            }
                        }
                    }
                }
            },
            {
                $project: {
                    _id: 0,
                    station: {
                        $arrayElemAt: [
                            '$station',
                            0
                        ]
                    },
                    totalParts: true,
                    goodParts: true
                }
            },
            {
                $sort: {
                    totalParts: -1
                }
            }
        ]);
        const andonProductionQuery1 = params1.useTotalCycles ? totalCyclesTableQuery1 : totalPartsTableQuery1;
        const partCountTableData1 = await this.queryRunner.runQuery.perform('BuildStatuses', andonProductionQuery1);
        let goodParts1 = 0;
        let badParts1 = 0;
        const rows1 = partCountTableData1.map((row1)=>{
            goodParts1 += row1.goodParts;
            badParts1 += row1.totalParts - row1.goodParts;
            return {
                station: row1.station?.text.toUpperCase(),
                goodParts: row1.goodParts,
                badParts: row1.totalParts - row1.goodParts
            };
        });
        return {
            rows: rows1,
            goodParts: goodParts1,
            badParts: badParts1,
            exportData: ()=>rows1.map(({ partCounts: partCounts1, ...row1 })=>row1)
        };
    }));
    partCountData = trackedTask(this, this.getData, ()=>[
            this.params,
            this.args.refresher
        ]);
    formatPartsColumnData(cell1) {
        const { goodParts: goodParts1, badParts: badParts1 } = cell1.getData();
        return `<div>
      <span class="part-count-good">${goodParts1} </span>
      <span class="part-count-bad">${badParts1}</span>
    </div>`;
    }
    static{
        template(`
    {{#if this.partCountData.value.rows.length}}
      <div
        class="
          station-count-{{this.partCountData.value.rows.length}}
          component-bi-andon-production-station
          bi-tabulator
          d-flex
          flex-column
          h-100
        "
        ...attributes
      >
        <TabulatorTable
          class="production-tabulator"
          @tableConfiguration={{this.tableConfiguration}}
          @data={{this.partCountData.value.rows}}
          @exportDataFunction={{this.partCountData.value.exportData}}
          @exportFilename={{t 'bi.chartLabel.partCounts'}}
          {{didInsert this.startAutoRefresh.perform}}
        />
        <div class="d-flex justify-content-center p-2">
          <BiTotalCyclesLabel
            @total={{this.partCountData.value.goodParts}}
            @rejectCount={{this.partCountData.value.badParts}}
          />
        </div>
      </div>
    {{else if this.partCountData.isRunning}}
      <Spinner />
    {{else}}
      <BiNoDataPlaceholder />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
