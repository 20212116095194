import { template } from "@ember/template-compiler";
import { isEmpty, isPresent } from '@ember/utils';
import { pipe, chain, map, difference, head, filter, prop } from 'ramda';
import { dequal } from 'dequal';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { TrackedArray } from 'tracked-built-ins';
import { intoArray } from '@eflexsystems/ramda-helpers';
import { compact } from 'ramda-adjunct';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import { t } from 'ember-intl';
import style from 'ember-style-modifier';
import { on } from '@ember/modifier';
import { inputVal } from 'eflex/helpers';
import BasicDropdown from 'ember-basic-dropdown/components/basic-dropdown';
import { isEmpty as isEmptyHelper, or, and, not } from 'ember-truth-helpers';
import { fn } from '@ember/helper';
import { isPresent as isPresentHelper, withDefault, inc } from '@eflexsystems/ember-composable-helpers';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { onResize } from 'eflex/modifiers';
import CheckboxInput from 'eflex/components/checkbox-input';
let TreeSelectItem = class TreeSelectItem {
    @tracked
    id;
    @tracked
    name;
    @tracked
    treeIconClass;
    @tracked
    children = [];
    @tracked
    path;
    @tracked
    modelName;
    @tracked
    position;
    @tracked
    checked = false;
    @tracked
    visible = true;
    @tracked
    expanded = false;
    constructor(props1){
        Object.assign(this, props1);
    }
};
const getLeafIndentWidth = (indent1)=>{
    return `${indent1 * 27}px`;
};
const getLeafIsClickable = (canExpand1, checkable1)=>{
    return canExpand1 || (!canExpand1 && !checkable1);
};
const onClick = (isClickable1, onClickFn1)=>{
    if (!isClickable1) {
        return;
    }
    event.stopPropagation();
    onClickFn1();
};
const Leaf = template(`
<div data-location-name={{@text}} ...attributes>
  <div class="label">
    {{#let (getLeafIsClickable @canExpand @checkable) as |isClickable|}}
      <div
        class="
          icon
          expand-icon
          pointer
          {{if (withDefault @expanded true) "dropdown-close-icon" "dropdown-open-icon"}}
          {{if @canExpand "can-expand"}}
        "
        {{on 'click' (fn onClick isClickable @onClick)}}
      ></div>
      {{#if @checkable}}
        <CheckboxInput
          class="d-inline-block align-text-bottom"
          @checked={{@checked}}
          @onChange={{fn @onCheckedChanged (not @checked)}}
        />
      {{/if}}
      <div
        class="indent d-inline-block"
        {{style width=(getLeafIndentWidth @indent)}}
      ></div>
      {{#if @iconClass}}
        <div
          class="icon data-icon me-2 {{@iconClass}} {{if isClickable "pointer"}}"
          {{on 'click' (fn onClick isClickable @onClick)}}
        ></div>
      {{/if}}
      <div
        class="label-text d-inline-block {{if isClickable "pointer"}}"
        {{on 'click' (fn onClick isClickable @onClick)}}
      >
        {{@text}}
      </div>
    {{/let}}
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const getCanExpandBranch = (branchData1, depth1, maxDepth1)=>{
    return isPresent(branchData1.children) && (maxDepth1 == null || depth1 < maxDepth1);
};
const updateChecked = (data1, checked1)=>{
    data1.forEach((child1)=>{
        child1.checked = checked1;
        if (isPresent(child1.children)) {
            updateChecked(child1.children, checked1);
        }
    });
};
let Branch = class Branch extends Component {
    onClick = (canExpand1, branchData1)=>{
        if (canExpand1) {
            branchData1.expanded = !branchData1.expanded;
        } else {
            this.args.onClick(branchData1);
        }
    };
    updateChecked = (branchData1)=>{
        updateChecked([
            branchData1
        ], !branchData1.checked);
        this.args.onCheckedChanged();
    };
    onChildCheckedChanged = (branchData1)=>{
        branchData1.checked = branchData1.children.every((item1)=>item1.checked);
        this.args.onCheckedChanged();
    };
    static{
        template(`
    {{#let (getCanExpandBranch @branchData @depth @maxDepth) as |canExpand|}}
      <div
        class="
          branch
          {{if this.expanded "expanded"}}
          {{if canExpand "can-expand" "no-expand"}}
        "
        ...attributes
      >
        {{#if @branchData.visible}}
          <Leaf
            @checked={{@branchData.checked}}
            @checkable={{@checkable}}
            @indent={{@depth}}
            @expanded={{@branchData.expanded}}
            @canExpand={{canExpand}}
            @iconClass={{@branchData.treeIconClass}}
            @text={{@branchData.name}}
            @onClick={{fn this.onClick canExpand @branchData}}
            @onCheckedChanged={{fn this.updateChecked @branchData}}
          />
          {{#if (and @branchData.expanded canExpand)}}
            <div class="branches">
              {{#each @branchData.children as |child|}}
                <Branch
                  @branchData={{child}}
                  @depth={{inc @depth}}
                  @maxDepth={{@maxDepth}}
                  @checkable={{@checkable}}
                  @onCheckedChanged={{fn this.onChildCheckedChanged @branchData}}
                  @onClick={{@onClick}}
                />
              {{/each}}
            </div>
          {{/if}}
        {{/if}}
      </div>
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
const Trigger = template(`
<div class="tree-select-trigger" ...attributes>
  <div class="tree-select-trigger-target">
    {{#if (and (not @allChecked) (isEmptyHelper @selected))}}
      <span class="input-placeholder">
        {{@placeholder}}
      </span>
    {{else if (and @allChecked @checkable)}}
      <span class="item">
        {{@allCheckedText}}
      </span>
    {{else}}
      {{#each @selected as |item|}}
        <span class="item ember-power-select-multiple-option">
          {{item.name}}
          {{#unless @disabled}}
            <span
              class="ember-power-select-multiple-remove-btn {{if @checkable "show" "hide"}}"
              {{on "click" (fn @onRemove item)}}
            >
              ×
            </span>
          {{/unless}}
        </span>
      {{/each}}
    {{/if}}
  </div>
  {{#if (and (not @disabled) (or (and (not @checkable) (isPresentHelper @selected)) @allChecked))}}
    <div class="tree-select-trigger-clear">
      <FaIcon @icon="xmark" {{on "click" @onClear}} />
    </div>
  {{/if}}
  <span class="ember-power-select-status-icon"></span>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const findTreeItemByPathArray = (treeData1, pathArray1)=>{
    return pipe(intoArray(chain((item1)=>{
        if (item1.id !== pathArray1[0]) {
            return null;
        }
        if (pathArray1.length === 1) {
            return item1;
        } else if (item1.children.length > 0) {
            pathArray1.shift();
            return findTreeItemByPathArray(item1.children, pathArray1);
        } else {
            return null;
        }
    }), compact), head)(treeData1);
};
const setTreeItemChecked = (id1, treePaths1, localTree1, checked1)=>{
    const path1 = treePaths1.find((p1)=>p1.match(new RegExp(`${id1}$`)));
    const item1 = findTreeItemByPathArray(localTree1, path1.split('#'));
    item1.checked = checked1;
};
const checkByChildren = (item1)=>{
    if (isPresent(item1.children)) {
        const checked1 = item1.children.every((_item1)=>_item1.checked);
        if (item1.checked !== checked1) {
            item1.checked = checked1;
        }
    }
};
const updateCheckByChildren = (treeData1)=>{
    treeData1.forEach((item1)=>{
        if (isEmpty(item1.children)) {
            return;
        }
        checkByChildren(item1);
        updateCheckByChildren(item1.children);
        //need to check children again if the child check status was affected by its own children
        checkByChildren(item1);
    });
};
const cleanEmptyChildren = (parent1, minDepth1, depth1 = 0)=>{
    if (depth1 === minDepth1) {
        return parent1;
    }
    const children1 = intoArray(map((child1)=>cleanEmptyChildren(child1, minDepth1, depth1 + 1)), compact)(parent1.children);
    if (children1.length > 0) {
        parent1.children = children1;
        return parent1;
    }
};
export default class TreeSelect extends Component {
    @tracked
    localTree = [];
    @tracked
    treePaths = new TrackedArray();
    @tracked
    selected;
    @tracked
    searchTerm = '';
    @tracked
    allChecked = false;
    @tracked
    verticalPosition = 'auto';
    @tracked
    contentMaxHeight;
    previousCheckedIds = this.args.checkedIds;
    constructor(){
        super(...arguments);
        let treeData1 = this.args.sourceTree;
        // Specifying a minimum depth will make sure only those paths are shown
        // rather than having an incomplete path selection. Example: if we want to
        // select a station but the group has no stations, we dont show that group
        if (this.args.minDepth != null && this.args.minDepth > 0) {
            treeData1 = intoArray(map((branch1)=>cleanEmptyChildren(branch1, this.args.minDepth, 0)), compact)(treeData1);
        }
        treeData1 = this._copy(treeData1);
        Object.assign(this, {
            localTree: treeData1,
            selected: this._getSelection(treeData1)
        });
        updateCheckByChildren(this.localTree);
        this._updateAllChecked();
    }
    onDidUpdate = (element1, [checkedIds1])=>{
        const checkedIdsChanged1 = !dequal(this.previousCheckedIds, checkedIds1);
        if (!checkedIdsChanged1) {
            return;
        }
        const toUncheck1 = difference(this.previousCheckedIds, checkedIds1);
        const toCheck1 = difference(checkedIds1, this.previousCheckedIds);
        this.previousCheckedIds = checkedIds1;
        toUncheck1.forEach((id1)=>{
            setTreeItemChecked(id1, this.treePaths, this.localTree, false);
        });
        toCheck1.forEach((id1)=>{
            setTreeItemChecked(id1, this.treePaths, this.localTree, true);
        });
        this.selected = this._getSelection(this.localTree);
        updateCheckByChildren(this.localTree);
        this._updateAllChecked();
    };
    minWidth = (element1)=>{
        if (this.args.fullWidth) {
            this.contentWidth = `${element1.clientWidth}px`;
        }
    };
    search = (searchTerm1)=>{
        this.searchTerm = searchTerm1;
        this._updateVisible(this.localTree, searchTerm1);
        this._updateAllChecked();
        this._updateSelected();
    };
    checkAllChanged = (checked1)=>{
        this.allChecked = checked1;
        this._updateProperty('checked', checked1, this.localTree);
        this._updateSelected();
    };
    dataCheckedChanged = ()=>{
        this._updateAllChecked();
        this._updateSelected();
    };
    remove = (item1)=>{
        this._uncheckTree(this.localTree, item1.position.slice());
        this._updateAllChecked();
        this._updateSelected();
    };
    clear = ()=>{
        this.allChecked = false;
        this._updateProperty('checked', false, this.localTree);
        this._updateSelected();
        this.args.onClick?.(null);
    };
    onClick = (branchData1)=>{
        if (!this.clickable) {
            this._updateProperty('checked', false, this.localTree);
            if (branchData1?.length) {
                this._updateProperty('checked', true, branchData1);
            } else {
                this._updateProperty('checked', true, [
                    branchData1
                ]);
            }
            this._updateSelected();
        }
        this.args.onClick?.(branchData1);
    };
    dropdownClosed = ()=>{
        this._updateProperty('expanded', false, this.localTree);
    };
    dropdownOpen = (component1, { target: target1 })=>{
        const trigger1 = target1.closest('.tree-select-trigger')?.children[0] ?? target1.closest('.tree-select-trigger-target');
        if (!trigger1) {
            return true;
        }
        const pageHeight1 = document.documentElement.clientHeight;
        const offset1 = trigger1.getBoundingClientRect();
        const triggerBottom1 = offset1.top + trigger1.clientHeight;
        let height1 = pageHeight1 - triggerBottom1 - 35; // Leave a bit of a margin
        let verticalPosition1 = 'below';
        if (height1 < pageHeight1 / 3) {
            height1 = offset1.top - 35;
            verticalPosition1 = 'above';
        }
        Object.assign(this, {
            contentMaxHeight: `${height1}px`,
            verticalPosition: verticalPosition1
        });
        // prevent opening when a remove button is pressed
        if (target1.classList?.value?.includes('times')) {
            return false;
        }
        return true;
    };
    onBranchClick = (close1, branchData1)=>{
        this.onClick(branchData1);
        close1();
    };
    _getSelection(treeData1, depth1 = 0) {
        return intoArray(chain((child1)=>{
            if (isPresent(child1.children) && (this.args.maxDepth == null || depth1 < this.args.maxDepth)) {
                return this._getSelection(child1.children, depth1 + 1);
            } else {
                return child1;
            }
        }), compact, filter(prop('checked')))(treeData1);
    }
    _copy(sourceTree1, indexPath1 = []) {
        if (sourceTree1 == null) {
            return [];
        }
        return sourceTree1.map((sourceItem1, index1)=>{
            const position1 = [
                ...indexPath1,
                index1
            ];
            const item1 = {
                id: sourceItem1.id,
                name: sourceItem1.name,
                treeIconClass: sourceItem1.treeIconClass,
                path: sourceItem1.path,
                modelName: sourceItem1.modelName,
                expanded: sourceItem1.expanded,
                position: position1,
                checked: this.args.checkedIds?.includes(sourceItem1.id) ?? false,
                children: this._copy(sourceItem1.children, position1)
            };
            checkByChildren(item1);
            this.treePaths.push(item1.path);
            return new TreeSelectItem(item1);
        });
    }
    _updateProperty(property1, value1, treeData1) {
        treeData1?.forEach((child1)=>{
            child1[property1] = value1;
            if (isPresent(child1.children)) {
                this._updateProperty(property1, value1, child1.children);
            }
        });
    }
    _updateVisible(treeData1, searchTerm1 = '') {
        if (treeData1 == null) {
            treeData1 = [];
        }
        treeData1.forEach((child1)=>{
            const match1 = child1.name?.toLowerCase().includes(searchTerm1.toLowerCase().trim());
            let childMatch1 = false;
            if (match1) {
                child1.visible = true;
                this._updateProperty('visible', true, child1.children);
            } else {
                if (isPresent(child1.children)) {
                    childMatch1 = this._updateVisible(child1.children, searchTerm1);
                }
                child1.visible = childMatch1;
            }
        });
        return treeData1.some((item1)=>item1.visible);
    }
    _updateAllChecked() {
        this.allChecked = this.localTree.every((item1)=>item1.checked);
    }
    _updateSelected() {
        const selection1 = this._getSelection(this.localTree);
        this.selected = selection1;
        this.args.onCheckedChanged?.(selection1);
    }
    _uncheckTree(tree1, position1) {
        if (position1.length === 0) {
            return;
        }
        const index1 = position1.shift();
        const item1 = tree1[index1];
        item1.checked = false;
        this._uncheckTree(item1.children, position1);
    }
    static{
        template(`
    <div
      class="tree-select"
      {{didUpdate this.onDidUpdate @checkedIds}}
      ...attributes
    >
      <BasicDropdown
        @disabled={{@disabled}}
        @verticalPosition={{this.verticalPosition}}
        @onClose={{this.dropdownClosed}}
        @onOpen={{this.dropdownOpen}}
        class="tree-select-dropdown"
      as |dropdown|>
        <dropdown.Trigger>
          {{#if (has-block)}}
            {{yield}}
          {{else}}
            <Trigger
              class={{@triggerClass}}
              @checkable={{@checkable}}
              @placeholder={{@placeholder}}
              @allChecked={{this.allChecked}}
              @allCheckedText={{@checkAllText}}
              @selected={{this.selected}}
              @onRemove={{this.remove}}
              @onClear={{this.clear}}
              @disabled={{@disabled}}
              {{didInsert this.minWidth}}
              {{onResize this.minWidth}}
            />
          {{/if}}
        </dropdown.Trigger>
        <dropdown.Content
          class="{{@contentClass}} tree-select-dropdown-content tree-select-position-{{this.verticalPosition}}"
          @width={{this.contentWidth}}
        >
          <div class="tree-select-content" {{style maxHeight=this.contentMaxHeight}}>
            <div class="search input-group">
              <input
                placeholder={{t "searchFor"}}
                value={{this.searchTerm}}
                class="form-control"
                {{on "input" (inputVal this.search)}}
              />
              <div class="input-group-append input-group-text">
                <div class="icon icon-search"></div>
              </div>
            </div>
            {{#if @checkAllText}}
              <div class="check-all no-expand">
                <Leaf
                  @checked={{this.allChecked}}
                  @checkable={{@checkable}}
                  @text={{@checkAllText}}
                  @onCheckedChanged={{this.checkAllChanged}}
                  @onClick={{fn this.onClick this.localTree}}
                />
                <div class="divider"></div>
              </div>
            {{/if}}
            <div class="branches">
              {{#each this.localTree as |branchData|}}
                <Branch
                  @branchData={{branchData}}
                  @maxDepth={{@maxDepth}}
                  @depth={{0}}
                  @checkable={{@checkable}}
                  @onCheckedChanged={{this.dataCheckedChanged}}
                  @onClick={{fn this.onBranchClick dropdown.actions.close}}
                />
              {{/each}}
            </div>
          </div>
        </dropdown.Content>
      </BasicDropdown>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
