import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import { TaskToVariablesConfig } from 'eflex/components/task-variables-config';
export default template(`
<div class="row form-row model-specific">
  <div class="col">
    <@form.element
      class="program-name is-required"
      @label={{t "program"}}
      @property="programName"
    />
  </div>
  <div class="col">
    <@form.element
      class="light-guide-watch-variable is-required"
      @label={{t "watchVariable"}}
      @property="lightGuideWatchVariable"
    />
  </div>
  <div class="col">
    <@form.element
      class="light-guide-watch-variable-expected is-required"
      @label={{t "watchVariableExpected"}}
      @property="lightGuideWatchVariableExpected"
    />
  </div>
</div>

<TaskToVariablesConfig
  @form={{@form}}
  @disabled={{@disabled}}
  @task={{@task}}
  @taskConfig={{@taskConfig}}
/>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
