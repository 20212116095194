import { template } from "@ember/template-compiler";
import { isEmpty } from '@ember/utils';
import { t } from 'ember-intl';
import PowerSelect from 'eflex/components/power-select';
import { sortBy, withDefault } from '@eflexsystems/ember-composable-helpers';
import { or } from 'ember-truth-helpers';
import KeyboardWedgeEvent from 'eflex/components/keyboard-wedge-event';
import { fn } from '@ember/helper';
import { serviceHelper as service } from 'eflex/helpers';
const filterOptions = (data1, station1, intl1)=>{
    const sortedOptions1 = {
        allowed: [],
        stationNotAllowed: [],
        missingConfiguration: []
    };
    data1.forEach((datum1)=>{
        if (!datum1.isReleased) {
            return;
        }
        if (datum1.missingConfiguration) {
            sortedOptions1.missingConfiguration.push(datum1);
            return;
        }
        if (!datum1.allowedStations.includes(station1)) {
            sortedOptions1.stationNotAllowed.push(datum1);
            return;
        }
        sortedOptions1.allowed.push(datum1);
    });
    const options1 = sortedOptions1.allowed;
    if (!isEmpty(sortedOptions1.missingConfiguration)) {
        options1.push({
            groupName: intl1.t('kinetic.operations.missingConfiguration'),
            disabled: true,
            options: sortedOptions1.missingConfiguration
        });
    }
    if (!isEmpty(sortedOptions1.stationNotAllowed)) {
        options1.push({
            groupName: intl1.t('jem.stationNotAllowed'),
            disabled: true,
            options: sortedOptions1.stationNotAllowed
        });
    }
    return options1;
};
const getFilteredAssemblies = (kineticJobAssemblies1, selectedJob1, station1, intl1)=>{
    const assemblies1 = kineticJobAssemblies1.filter((assembly1)=>{
        return assembly1.job === selectedJob1;
    }) ?? [];
    return filterOptions(assemblies1, station1, intl1);
};
const getFilteredOperations = (kineticAssemblyOperations1, selectedAssembly1, station1, intl1)=>{
    const operations1 = kineticAssemblyOperations1?.filter((assemblyOp1)=>{
        return assemblyOp1.assembly === selectedAssembly1;
    }) ?? [];
    return filterOptions(operations1, station1, intl1);
};
const onOperationScanned = (kineticAssemblyOperations1, onOperationSelected1, operationName1)=>{
    //todo: this would be some sort of JOB-assembly-op scan? need to parse and load from that
    onOperationSelected1(kineticAssemblyOperations1.find((operation1)=>{
        return operation1.name === operationName1;
    }));
};
export default template(`
{{#let
  (service 'kineticRepo')
  (service 'intl')
as |kineticRepo intl|}}
  <div class="col-auto align-self-end d-flex flex-column mb-0">
    <label class="form-label">
      {{t "quantity"}}
    </label>
    <div
      class="
        jem-operation-quantity
        form-control
        form-control-lg
        border-0
        d-flex
        align-items-center
        fw-semibold
        px-3
        bg-gray-800
      "
    >
      {{withDefault @assemblyOperation.quantityCount "-"}}
    </div>
  </div>

  <div class="col">
    <label class="form-label">
      {{t "job"}}
    </label>
    <PowerSelect
      class="jem-job-select no-chrome-power-select"
      @triggerClass="jem-job-select-trigger form-control form-control-lg bg-gray-800 border-0"
      @searchEnabled={{true}}
      @searchField="jobNumber"
      @options={{sortBy "jobNumber" (filterOptions kineticRepo.kineticJobs @station intl)}}
      @selected={{@selectedJob}}
      @allowClear={{true}}
      @placeholder={{t "kinetic.selectJob"}}
      @disabled={{@disabled}}
      @onChange={{@onJobSelected}}
    as |job|>
      {{job.jobNumber}}
    </PowerSelect>
  </div>

  <div class="col">
    <label class="form-label">
      {{t "kinetic.assembly"}}
    </label>
    <PowerSelect
      class="jem-assembly-select no-chrome-power-select"
      @triggerClass="jem-assembly-select-trigger form-control form-control-lg bg-gray-800 border-0"
      @options={{
        sortBy
        "assemblySequence"
        (getFilteredAssemblies kineticRepo.kineticJobAssemblies @selectedJob @station intl)
      }}
      @selected={{@selectedAssembly}}
      @allowClear={{true}}
      @placeholder={{t "kinetic.selectAssembly"}}
      @disabled={{or @disabled (isEmpty @selectedJob)}}
      @onChange={{@onAssemblySelected}}
      @searchField="assemblySequence"
    as |assembly|>
      {{assembly.assemblySequence}}
    </PowerSelect>
  </div>

  <div class="col">
    <label class="form-label">
      {{t "operation"}}
    </label>
    <PowerSelect
      class="jem-operation-select no-chrome-power-select"
      @triggerClass="jem-operation-select-trigger form-control form-control-lg bg-gray-800 border-0"
      @searchEnabled={{true}}
      @searchField="name"
      @options={{
        sortBy
        "name"
        (getFilteredOperations kineticRepo.kineticAssemblyOperations @selectedAssembly @station intl)
      }}
      @selected={{@assemblyOperation.operation}}
      @disabled={{or @disabled (isEmpty @selectedAssembly)}}
      @allowClear={{true}}
      @placeholder={{t "kinetic.selectOperation"}}
      @onChange={{@onOperationSelected}}
    as |operation|>
      {{operation.name}}
    </PowerSelect>
  </div>

  {{#if (isEmpty @assemblyOperation.operation)}}
    <KeyboardWedgeEvent
      @onScan={{fn onOperationScanned kineticRepo.kineticAssemblyOperations @onOperationSelected}}
    />
  {{/if}}
{{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
