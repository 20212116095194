import { template } from "@ember/template-compiler";
import TaskStatuses from 'eflex/constants/task-statuses';
import JemTaskOkButton from 'eflex/components/jem/task-ok-button';
import { RETRY_STRATEGY_VALUES, REJECT_ALLOWED_RETRY_STRATEGIES } from 'eflex/constants/retry-strategy-values';
import { withDefault } from '@eflexsystems/ember-composable-helpers';
import BsTooltip from 'eflex/components/bs-tooltip';
import BsButton from 'eflex/components/bs-button';
import { t } from 'ember-intl';
import { fn } from '@ember/helper';
const RetryButton = template(`
<BsButton
  class='reject-jem-task retry-button px-3 py-2'
  @type={{@type}}
  @size={{@size}}
  @disabled={{@disabled}}
  @onClick={{fn @onTaskComplete TaskStatuses.RESTART}}
  ...attributes
>
  {{yield}}
  {{t 'retry'}}
</BsButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const RestartButton = template(`
<BsButton
  class='restart-jem-task restart-button px-3 py-2'
  @type={{@type}}
  @size={{@size}}
  @disabled={{@disabled}}
  @onClick={{fn @onTaskComplete TaskStatuses.RESTART}}
  ...attributes
>
  {{yield}}
  {{t 'status.restart'}}
</BsButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const getButtonComponent = (task1, childStatus1, retryButtonOverride1, okButtonOverride1)=>{
    if (!childStatus1.isRunning && !childStatus1.isRestart) {
        if (childStatus1.isGood && task1.retryStrategy === RETRY_STRATEGY_VALUES.always) {
            return RestartButton;
        }
        if (childStatus1.isRejected && REJECT_ALLOWED_RETRY_STRATEGIES.has(task1.retryStrategy)) {
            return retryButtonOverride1 ?? RetryButton;
        }
    }
    return okButtonOverride1 ?? JemTaskOkButton;
};
export default template(`
{{#let (getButtonComponent @task @childStatus @retryButtonOverride @okButtonOverride) as |ButtonComponent|}}
  <ButtonComponent
    class="jem-status-button text-capitalize h-100 mb-0"
    @type={{withDefault @type "secondary"}}
    @size={{withDefault @size "md"}}
    @disabled={{@disabled}}
    @onTaskComplete={{@onTaskComplete}}
    ...attributes
  >
    {{#if @notAuthorized}}
      <BsTooltip>
        {{t "notAuthorized"}}
      </BsTooltip>
    {{/if}}
  </ButtonComponent>
{{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
