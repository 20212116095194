import { template } from "@ember/template-compiler";
import BsInputControl from 'ember-bootstrap/components/bs-form/element/control/input';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
export default template(`
<div class="position-relative">
  <BsInputControl
    @type={{@type}}
    @id={{@id}}
    @disabled={{@disabled}}
    @readonly={{@readonly}}
    @ariaDescribedBy={{@ariaDescribedBy}}
    @value={{@value}}
    @size={{@size}}
    @validationType={{@validationType}}
    @onChange={{@onChange}}
    ...attributes
  />
  {{#if @readonly}}
    <div class="position-absolute end-0 top-0 d-flex align-items-center p-3 h-100">
      <FaIcon @icon="lock" @prefix="fas" @size='sm' class="text-gray-400 input-icon"/>
    </div>
  {{/if}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
