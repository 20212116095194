import { template } from "@ember/template-compiler";
import { add, flow, map, sum } from 'ramda';
import NoRouteTabs from 'eflex/components/no-route-tabs';
import { concat, fn } from '@ember/helper';
import { t } from 'ember-intl';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { findBy } from '@eflexsystems/ember-composable-helpers';
import TrashCan from 'eflex/components/trash-can';
import QuantitySelect from 'eflex/components/quantity-select';
import { eq } from 'ember-truth-helpers';
import { PLC_FLOAT_BYTES, MAX_PLC_BYTES, getBytesConsumed } from 'eflex/util/plc-variable-def-helpers';
import track from 'ember-simple-track-helper/helpers/track';
import { serviceHelper as service } from 'eflex/helpers';
import { VARIABLE_VARIABLE, VARIABLE_TEXT, VARIABLE_NUMBER } from 'eflex/constants/variable-types';
const FROM_TYPES = [
    VARIABLE_TEXT,
    VARIABLE_NUMBER
];
const TO_TYPES = [
    ...FROM_TYPES,
    VARIABLE_VARIABLE
];
export const getDisplayVariables = (taskConfig1, isFromDevice1)=>{
    return taskConfig1?.variableDefConfigs.filter((item1)=>!item1.isDeleted).filter((item1)=>item1.variableDef?.isFromDevice === isFromDevice1) ?? [];
};
export const getAvailableByteLengths = (displayVariables1)=>{
    const totalConsumed1 = flow(displayVariables1, [
        map((variableDefConfig1)=>getBytesConsumed(variableDefConfig1.variableDef)),
        sum
    ]);
    const availableBytes1 = MAX_PLC_BYTES - totalConsumed1;
    const result1 = [];
    for(let i1 = PLC_FLOAT_BYTES; i1 <= availableBytes1; i1 += PLC_FLOAT_BYTES){
        result1.push(i1);
    }
    return result1;
};
const getPreviousVariableDefs = (task1, taskRepo1)=>{
    return taskRepo1.getAllPreviousTasks(task1)?.flatMap((_task1)=>_task1.variableDefs).filter((def1)=>!def1.isDeleted).filter((def1)=>(def1.task?.isPlc && def1.isFromDevice) || def1.type === 'number') ?? [];
};
const setVariableValue = (variableDefConfig1, value1)=>{
    const variableDef1 = variableDefConfig1.variableDef;
    if (value1.constructor?.modelName === 'variable-def') {
        variableDefConfig1.value = null;
        variableDef1.variableDefForValue = value1;
    } else {
        variableDefConfig1.value = value1;
        variableDef1.variableDefForValue = null;
    }
};
const onSelectedVariableType = (variableDefConfig1, selected1)=>{
    variableDefConfig1.value = null;
    Object.assign(variableDefConfig1.variableDef, {
        length: null,
        variableDefForValue: null,
        type: selected1.value
    });
};
const addMultipleVariables = (task1, isFromDevice1, variableDefRepo1, variableQuantity1)=>{
    for(let i1 = 0; i1 < variableQuantity1; i1++){
        variableDefRepo1.createVariableDef({
            task: task1,
            isFromDevice: isFromDevice1
        });
    }
};
export default template(`
{{#let
  (track (concat 'deviceToVariables-' @taskConfig.id))
  (concat 'deviceFromVariables-' @taskConfig.id)
  (concat 'deviceToVariables-' @taskConfig.id)
  (service 'variableDefRepo')
  (service 'taskRepo')
as |currentVariableTypeTab deviceFromId deviceToId variableDefRepo taskRepo|}}
  <NoRouteTabs
    @activeTab={{currentVariableTypeTab.value}}
    @tabBecameActive={{currentVariableTypeTab.update}}
  as |tabs|>
    <tabs.navigation class="mb-3" as |nav|>
      <nav.tab @href={{deviceToId}} class="device-to-variables">
        {{t "deviceToVariables"}}
      </nav.tab>
      <nav.tab @href={{deviceFromId}} class="device-from-variables">
        {{t "deviceFromVariables"}}
      </nav.tab>
    </tabs.navigation>
    <tabs.panes as |panes|>
      <panes.pane @id={{deviceToId}}>
        <div class="bootstrap-table">
          <div class="row form-row header-row">
            <div class="col-icon border-0" />
            <div class="col-icon border-0">
              <FaIcon @icon="hashtag" @prefix="fas" />
            </div>
            <div class="col">
              {{t "variableName"}}
            </div>
            <div class="col-auto">
              <div class="width-rem-6">
                {{t "dataType"}}
              </div>
            </div>
            <div class="col-auto">
              <div class="width-rem-5">
                {{t "length"}}
              </div>
            </div>
            <div class="col">
              {{t "variableValue"}}
            </div>
            <div class="col-icon border-0" />
          </div>
          {{#let (getDisplayVariables @taskConfig false) as |displayVariables|}}
            {{#let
              (getAvailableByteLengths displayVariables)
              (getPreviousVariableDefs @task taskRepo)
            as |availableByteLengths previousVariableDefs|}}
              {{#each displayVariables as |variableDefConfig index|}}
                {{#let variableDefConfig.variableDef as |variableDef|}}
                  <div class="variable-row row form-row">
                    <div class="col-icon model-specific">
                      <@form.element
                        @controlType="checkbox"
                        @model={{variableDefConfig}}
                        @property="enabled"
                      />
                    </div>
                    <div class="col-icon">
                      <span>
                        {{add index 1}}
                      </span>
                    </div>
                    <div class="col">
                      <@form.element
                        @controlType="text"
                        @model={{variableDef}}
                        @property="name"
                        class="variable-name"
                      />
                    </div>
                    <div class="col-auto">
                      <div class="width-rem-6">
                        <@form.element
                          class="variable-type"
                          @controlType="power-select"
                          @options={{TO_TYPES}}
                          @value={{findBy "value" variableDef.type TO_TYPES}}
                          @onChange={{fn onSelectedVariableType variableDefConfig}}
                        as |el|>
                          <el.control as |type|>
                            {{t type.label}}
                          </el.control>
                        </@form.element>
                      </div>
                    </div>
                    <div class="col-auto">
                      <div class="width-rem-5">
                        {{#if (eq variableDef.type "string")}}
                          <@form.element
                            @controlType="power-select"
                            @options={{availableByteLengths}}
                            @model={{variableDef}}
                            @property="length"
                            class="variable-length"
                          />
                        {{/if}}
                      </div>
                    </div>
                    <div class="col model-specific">
                      {{#if (eq variableDef.type "variable")}}
                        <@form.element
                          @controlType="power-select"
                          @options={{previousVariableDefs}}
                          @optionLabelPath="name"
                          @model={{variableDef}}
                          @property="selectedValue"
                          @onChange={{fn setVariableValue variableDefConfig}}
                          class="variable-value"
                        as |el|>
                          <el.control
                            @searchEnabled={{true}}
                            @searchField="name"
                          />
                        </@form.element>
                      {{else}}
                        <@form.element
                          @controlType={{if (eq variableDef.type 'number') 'number' 'text'}}
                          @model={{variableDefConfig}}
                          @property="value"
                          class="variable-value"
                        />
                      {{/if}}
                    </div>
                    <div class="col-icon">
                      <TrashCan
                        disabled={{@disabled}}
                        @onDelete={{fn variableDefRepo.deleteVariable variableDef}}
                      />
                    </div>
                  </div>
                {{/let}}
              {{/each}}
            {{/let}}
          {{/let}}
          <div class="row form-row no-hover-row">
            <div class="col-auto">
              <QuantitySelect
                class="px-2 text-uppercase"
                @disabled={{@disabled}}
                @onSubmit={{fn addMultipleVariables @task false variableDefRepo}}
              >
                {{t "components.addVariable"}}
              </QuantitySelect>
            </div>
          </div>
        </div>
      </panes.pane>
      <panes.pane @id={{deviceFromId}}>
        <div class="bootstrap-table">
          <div class="row form-row header-row">
            <div class="col-icon border-0" />
            <div class="col-icon border-0">
              <FaIcon @icon="hashtag" @prefix="fas" />
            </div>
            <div class="col">
              {{t "variableName"}}
            </div>
            <div class="col-auto">
              <div class="width-rem-6">
                {{t "dataType"}}
              </div>
            </div>
            <div class="col-auto">
              <div class="width-rem-5">
                {{t "length"}}
              </div>
            </div>
            <div class="col-icon border-0" />
          </div>

          {{#let (getDisplayVariables @taskConfig true) as |displayVariables|}}
            {{#let (getAvailableByteLengths displayVariables) as |availableByteLengths|}}
              {{#each displayVariables as |variableDefConfig index|}}
                {{#let variableDefConfig.variableDef as |variableDef|}}
                  <div class="row form-row variable-row">
                    <div class="col-icon models-specific">
                      <@form.element
                        @controlType="checkbox"
                        @model={{variableDefConfig}}
                        @property="enabled"
                      />
                    </div>
                    <div class="col-icon">
                      <span>
                        {{add index 1}}
                      </span>
                    </div>
                    <div class="col">
                      <@form.element
                        @controlType="text"
                        @model={{variableDef}}
                        @property="name"
                        class="variable-name"
                      />
                    </div>
                    <div class="col-auto">
                      <div class="width-rem-6">
                        <@form.element
                          class="variable-type"
                          @controlType="power-select"
                          @options={{FROM_TYPES}}
                          @value={{findBy "value" variableDef.type FROM_TYPES}}
                          @onChange={{fn onSelectedVariableType variableDefConfig}}
                        as |el|>
                          <el.control as |type|>
                            {{t type.label}}
                          </el.control>
                        </@form.element>
                      </div>
                    </div>
                    <div class="col-auto">
                      <div class="width-rem-5">
                        {{#if (eq variableDef.type "string")}}
                          <@form.element
                            @controlType="power-select"
                            @options={{availableByteLengths}}
                            @model={{variableDef}}
                            @property="length"
                            class="variable-length"
                          />
                        {{/if}}
                      </div>
                    </div>
                    <div class="col-icon">
                      <TrashCan
                        disabled={{@disabled}}
                        @onDelete={{fn variableDefRepo.deleteVariable variableDef}}
                      />
                    </div>
                  </div>
                {{/let}}
              {{/each}}
            {{/let}}
          {{/let}}
          <div class="row form-row no-hover-row">
            <div class="col-auto">
              <QuantitySelect
                class="px-2 text-uppercase"
                @disabled={{@disabled}}
                @onSubmit={{fn addMultipleVariables @task true variableDefRepo}}
              >
                {{t "components.addVariable"}}
              </QuantitySelect>
            </div>
          </div>
        </div>
      </panes.pane>
    </tabs.panes>
  </NoRouteTabs>

  <div class="row form-row model-specific">
    <div class="col-3">
      <@form.element
        @controlType="number"
        @label={{t "programNumber"}}
        @property="programNumber"
        class="program-number"
      as |el|>
        <el.control disabled={{@disabled}} />
      </@form.element>
    </div>
  </div>
{{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
