import { template } from "@ember/template-compiler";
import PowerSelect from 'eflex/components/power-select';
import { get } from '@ember/helper';
export default template(`
<PowerSelect
  @afterOptionsComponent={{@afterOptionsComponent}}
  @allowClear={{@allowClear}}
  @animationEnabled={{@animationEnabled}}
  @ariaDescribedBy={{@ariaDescribedBy}}
  @ariaInvalid={{@ariaInvalid}}
  @ariaLabel={{@ariaLabel}}
  @ariaLabelledBy={{@ariaLabelledBy}}
  @beforeOptionsComponent={{@beforeOptionsComponent}}
  @buildSelection={{@buildSelection}}
  @calculatePosition={{@calculatePosition}}
  @class={{@class}}
  @closeOnSelect={{@closeOnSelect}}
  @defaultHighlighted={{@defaultHighlighted}}
  @destination={{@destination}}
  @disabled={{@disabled}}
  @dropdownClass={{@dropdownClass}}
  @eventType={{@eventType}}
  @extra={{@extra}}
  @groupComponent={{@groupComponent}}
  @highlightOnHover={{@highlightOnHover}}
  @horizontalPosition={{@horizontalPosition}}
  @initiallyOpened={{@initiallyOpened}}
  @loadingMessage={{@loadingMessage}}
  @matcher={{@matcher}}
  @matchTriggerWidth={{@matchTriggerWidth}}
  @noMatchesMessage={{@noMatchesMessage}}
  @onBlur={{@onBlur}}
  @onChange={{@onChange}}
  @onClose={{@onClose}}
  @onFocus={{@onFocus}}
  @onInput={{@onInput}}
  @onKeydown={{@onKeydown}}
  @onOpen={{@onOpen}}
  @options={{@options}}
  @optionsComponent={{@optionsComponent}}
  @placeholder={{@placeholder}}
  @placeholderComponent={{@placeholderComponent}}
  @preventScroll={{@preventScroll}}
  @registerAPI={{@registerAPI}}
  @renderInPlace={{@renderInPlace}}
  @scrollTo={{@scrollTo}}
  @search={{@search}}
  @searchEnabled={{@searchEnabled}}
  @searchField={{if (has-block) @searchField (if @searchField @searchField @optionLabelPath)}}
  @searchMessage={{@searchMessage}}
  @searchPlaceholder={{@searchPlaceholder}}
  @selected={{@value}}
  @selectedItemComponent={{@selectedItemComponent}}
  @tabindex={{@tabindex}}
  @triggerClass={{@triggerClass}}
  @triggerComponent={{@triggerComponent}}
  @triggerId={{@triggerId}}
  @triggerRole={{@triggerRole}}
  @typeAheadMatcher={{@typeAheadMatcher}}
  @verticalPosition={{@verticalPosition}}
  @title={{@title}}
  ...attributes
as |item select|>
  {{#if (has-block)}}
    {{yield item select}}
  {{else}}
    {{#if @optionLabelPath}}
      {{get item @optionLabelPath}}
    {{else}}
      {{item}}
    {{/if}}
  {{/if}}
</PowerSelect>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
