import { template } from "@ember/template-compiler";
import { serviceHelper as service } from 'eflex/helpers';
import BsForm from 'eflex/components/bs-form';
import { t } from 'ember-intl';
const getDisplayedSerialNumber = (buildStatus1, intl1)=>{
    const serialNumber1 = buildStatus1?.serialNumber;
    if (serialNumber1 != null) {
        return serialNumber1;
    } else if (buildStatus1 != null) {
        return intl1.t('controls.ajaxindicator.loading');
    } else {
        return intl1.t('plant.station.jem.unknown');
    }
};
export default template(`
<BsForm @model={{this}} @readonly={{true}} class="w-100" as |form|>
  {{#let (service 'intl') as |intl|}}
    <form.element
      class="mb-0"
      @label={{t "serialNumberAbbr"}}
      @controlType="text"
      @size="lg"
      @value={{getDisplayedSerialNumber @buildStatus intl}}
    as |el|>
      <el.control class="jem-serial-display preserve-spaces fw-semibold" ...attributes />
    </form.element>
  {{/let}}
</BsForm>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
