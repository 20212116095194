import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import { inputVal } from 'eflex/helpers';
import { on } from '@ember/modifier';
import CheckboxInput from 'eflex/components/checkbox-input';
export default template(`
<div class="dynamic-object-config-wrapper" ...attributes>
  <div class="property-row mb-2">
    <label class="form-label">
      {{t "workInstructions.dynamicObjectName"}}
    </label>
    <input
      type="text"
      value={{@dynamicObjectName}}
      disabled={{@disabled}}
      class="dynamic-object-name-input form-control"
      {{on "input" (inputVal @updateObjectName)}}
    />
  </div>
  <div class="property-row">
    <CheckboxInput
      class="dynamic-object-show"
      @disabled={{false}}
      @checked={{@startHidden}}
      @label={{t "workInstructions.startHidden"}}
      @onChange={{@updateStartHidden}}
    />
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
