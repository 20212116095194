import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import { task, all } from 'ember-concurrency';
import { NotificationLogLevels, NotificationLogTypes } from 'eflex/constants/notification-log';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import StandardModal from 'eflex/components/standard-modal';
import { t } from 'ember-intl';
import { isEmpty, or } from 'ember-truth-helpers';
import BsForm from 'eflex/components/bs-form';
import BsButton from 'eflex/components/bs-button';
import { fn } from '@ember/helper';
import { autofocus } from 'eflex/modifiers';
export default class NotificationLogCreateModal extends Component {
    @service
    store;
    @service
    currentUser;
    @tracked
    notes = [];
    _createLog(treeItem1, source1) {
        return this.store.createRecord('log', {
            level: this.args.logLevel ?? NotificationLogLevels.INFO,
            logType: this.args.logType,
            pendingDeploy: this.args.pendingDeploy,
            plainMessage: this.notes,
            location: treeItem1?.asHistory() ?? null,
            source: source1,
            createdBy: this.currentUser.user.toFragment()
        });
    }
    _createAndSaveLog = task(waitFor(async ()=>{
        const log1 = this._createLog(this.args.location, this.args.source);
        await log1.save();
    }));
    _createAndSaveWiLogs = task(waitFor(async ()=>{
        const logs1 = this.args.source.allUniqueAssignedStations?.map((station1)=>this._createLog(station1, this.args.source.id));
        await all(logs1.map((log1)=>log1.save()));
    }));
    onConfirm = task(waitFor(async ()=>{
        if (this.args.logType === NotificationLogTypes.WIE) {
            await this._createAndSaveWiLogs.perform();
        } else {
            await this._createAndSaveLog.perform();
        }
        await this.args.onConfirm?.();
    }));
    static{
        template(`
    <StandardModal
      class="notification-log-create-modal"
      @onCancel={{@onConfirm}}
      @onConfirm={{this.onConfirm.perform}}
      ...attributes
    as |modal|>
      <modal.header @text={{t "jem.createChangeNotification"}} />
      <modal.body>
        <BsForm as |form|>
          <form.element
            @controlType="textarea"
            @label={{t "notes"}}
            @onChange={{fn (mut this.notes)}}
          as |el|>
            <el.control
              class="notes"
              rows={{9}}
              {{autofocus}}
            />
          </form.element>
        </BsForm>
      </modal.body>
      <modal.footer as |footer|>
        <footer.confirm
          @disabled={{
            or
              (isEmpty this.notes)
              this.onConfirm.isRunning
            }}
        >
          {{t "save"}}
        </footer.confirm>
        <BsButton
          @type="secondary"
          class="none-notes fs-3"
          @onClick={{@onConfirm}}
        >
          {{t "none"}}
        </BsButton>
      </modal.footer>
    </StandardModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
