import { template } from "@ember/template-compiler";
import { isPresent } from '@ember/utils';
const getOptionsWithDefault = (options1, displayedField1, defaultOption1)=>{
    if (isPresent(defaultOption1)) {
        return [
            {
                isDefaultOption: true,
                [displayedField1]: defaultOption1
            },
            ...options1
        ];
    }
    return options1;
};
const getSelectedOption = (selected1, optionsWithDefault1)=>{
    if (selected1 == null) {
        return optionsWithDefault1[0];
    } else {
        return selected1;
    }
};
export default template(`
{{#let (getOptionsWithDefault @options @displayedField @defaultOption) as |optionsWithDefault|}}
  {{yield
    optionsWithDefault
    (getSelectedOption @selected optionsWithDefault)
  }}
{{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
