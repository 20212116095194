import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import getDelayTime from 'eflex/util/get-delay-time';
// eslint-disable-next-line no-restricted-imports
import $ from 'jquery';
import { all, task, timeout } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { t } from 'ember-intl';
import { modulo } from 'ramda';
import BsButton from 'eflex/components/bs-button';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import DraggableObjectTarget from '@eflexsystems/ember-drag-drop/components/draggable-object-target';
import { includes } from '@eflexsystems/ember-composable-helpers';
import WorkInstructionEditorLibraryCard from 'eflex/components/work-instruction-editor/library-card';
import { eq, not } from 'ember-truth-helpers';
export default class WorkInstructionEditorLibraryThumbView extends Component {
    @service
    workInstructionRepo;
    @tracked
    isDragging = false;
    scrollingActive = false;
    #scrollContainerSelector = '.library-scroll-container';
    scrollTime = getDelayTime(600);
    _dragScroll = task(waitFor(async (scrollAmount1)=>{
        while(this.scrollingActive){
            // eslint-disable-next-line ember/no-jquery
            $(this.#scrollContainerSelector).animate({
                scrollTop: scrollAmount1
            }, this.scrollTime);
            await timeout(this.scrollTime);
        }
    }));
    scrollDown = task({
        drop: true
    }, waitFor(async ()=>{
        this.scrollingActive = true;
        await this._dragScroll.perform('+=300px');
    }));
    scrollUp = task({
        drop: true
    }, waitFor(async ()=>{
        this.scrollingActive = true;
        await this._dragScroll.perform('-=300px');
    }));
    stopScroll = task(waitFor(async ()=>{
        this.scrollingActive = false;
        await all([
            this._dragScroll.cancelAll({
                resetState: true
            }),
            this.scrollDown.cancelAll({
                resetState: true
            }),
            this.scrollUp.cancelAll({
                resetState: true
            })
        ]);
    }));
    willDestroy() {
        super.willDestroy(...arguments);
        // eslint-disable-next-line ember/no-jquery
        $(this.#scrollContainerSelector).clearQueue();
    }
    libraryCardDraggable = (workInstruction1)=>{
        return this.workInstructionRepo.userCanEdit(workInstruction1);
    };
    dropOnFolder = (folder1, workInstruction1)=>{
        this.isDragging = false;
        this.args.dropOnFolder(folder1, workInstruction1);
    };
    static{
        template(`
    <DraggableObjectTarget
      @onDragOver={{this.scrollUp.perform}}
      @onDragOut={{this.stopScroll.perform}}
      @action={{this.stopScroll.perform}}
    >
      <div
        class="
          drag-scroll-target
          at-top
          {{if this.isDragging "is-active"}}
          component-drag-scroll-container
        "
      ></div>
    </DraggableObjectTarget>

    <div class="eflex-scroll library-scroll-container">
      <div class="library-contents-inner d-flex flex-wrap thumb-view">
        {{#if @selectedFolder}}
          <WorkInstructionEditorLibraryCard
            @workInstruction={{null}}
            @largeThumb={{not @smallThumb}}
            @isReadOnly={{@isReadOnly}}
            @dropOnFolder={{fn this.dropOnFolder null}}
            {{on "click" (fn @loadFolder null)}}
          />
        {{/if}}
        {{#each @selectableFolders as |folder index|}}
          <WorkInstructionEditorLibraryCard
            @workInstruction={{folder}}
            @largeThumb={{not @smallThumb}}
            @isReadOnly={{@isReadOnly}}
            @dropOnFolder={{fn this.dropOnFolder folder}}
            @saveFolder={{fn @saveFolder folder}}
            @isSelected={{includes folder @selectedCards}}
            {{on "click" (fn @cardSelected folder)}}
            {{on "dblclick" (fn @loadFolder folder)}}
          />
          <span>
            {{#if (modulo index 2)}}
              <div class="clearfix"></div>
            {{/if}}
          </span>
        {{/each}}
        {{#each @selectableWorkInstructions as |workInstruction index|}}
          <WorkInstructionEditorLibraryCard
            @workInstruction={{workInstruction}}
            @largeThumb={{not @smallThumb}}
            @isReadOnly={{@isReadOnly}}
            @isSelected={{includes workInstruction @selectedCards}}
            @isActive={{eq workInstruction.id @currentWorkInstruction.id}}
            @startDrag={{fn (mut this.isDragging) true}}
            @endDrag={{fn (mut this.isDragging) false}}
            @isDraggable={{fn this.libraryCardDraggable workInstruction}}
            {{on "click" (fn @cardSelected workInstruction)}}
            {{on "dblclick" (fn @cardDoubleClick workInstruction)}}
          />
          <span>
            {{#if (modulo index 2)}}
              <div class="clearfix"></div>
            {{/if}}
          </span>
        {{/each}}
      </div>
      {{#if @showLoadButton}}
        <BsButton
          class="
            load-btn
            position-relative
            {{if @isReadOnly "rounded-0 load-btn-modal" "load-btn-normal"}}
            text-uppercase
          "
          @type="primary"
          @onClick={{@loadMore}}
        >
          {{t "grid.loadMore"}}
        </BsButton>
      {{/if}}
    </div>

    <DraggableObjectTarget
      @onDragOver={{this.scrollDown.perform}}
      @onDragOut={{this.stopScroll.perform}}
      @action={{this.stopScroll.perform}}
    >
      <div
        class="
          drag-scroll-target
          at-bottom
          {{if this.isDragging "is-active"}}
          component-drag-scroll-container
        "
      ></div>
    </DraggableObjectTarget>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
