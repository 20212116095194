import { template } from "@ember/template-compiler";
import { isEmpty, isPresent } from '@ember/utils';
import PortalTarget from 'ember-stargate/components/portal-target';
import SplitView from 'eflex/components/split-view';
import { t } from 'ember-intl';
import JemTaskGridRow from 'eflex/components/jem/task-grid-row';
import { serviceHelper as service, getHardware } from 'eflex/helpers';
import { eq } from 'ember-truth-helpers';
const getChildStatus = (treeTask1, buildStatus1)=>{
    return buildStatus1?.getChildStatusForTask(treeTask1);
};
const getTaskIsDisplayed = (taskConfig1, treeTask1, buildStatus1, user1)=>{
    if (taskConfig1?.disabled) {
        return false;
    }
    const decisionTags1 = treeTask1.decisionTags;
    if (buildStatus1 && !isEmpty(decisionTags1)) {
        const allowedDecisions1 = new Set(buildStatus1.getAllowedDecisions(treeTask1));
        if (!decisionTags1.some((tag1)=>allowedDecisions1.has(tag1))) {
            return false;
        }
    }
    const jemVisibilityTags1 = treeTask1.jemVisibilityTags;
    if (isPresent(jemVisibilityTags1)) {
        const userVisibilityTags1 = new Set(user1.visibilityTags);
        if (!jemVisibilityTags1.some((tag1)=>userVisibilityTags1.has(tag1))) {
            return false;
        }
    }
    return true;
};
export default template(`
<div
  class="
    jem-task-grid-container
    h-100
    bg-gray-900
    overflow-overlay
    eflex-scroll
  "
  ...attributes
>
  <PortalTarget @name="jem-task-spinner-portal" />
  <div
    id="jem-task-table"
    class="jem-task-grid"
  >
    <SplitView
      class="text-gray-200 position-relative"
      @name="eflex.tasks-rows"
      @columns="1fr 16px 7fr 16px 5fr 16px 3fr"
    >
      <div class="jem-step position-sticky top-0 bg-gray-900 jem-pane-header d-flex align-items-center" />

      <div class="gutter-col gutter-1 position-sticky top-0 bg-gray-900 z-1 py-2" />

      <div class="jem-description position-sticky top-0 bg-gray-900 jem-pane-header d-flex align-items-center z-1 ps-1">
        {{t "plant.station.jem.taskDescription"}}
      </div>

      <div class="gutter-col gutter-2 position-sticky top-0 bg-gray-900 z-1 py-2" />

      <div class="jem-status position-sticky top-0 bg-gray-900 jem-pane-header d-flex align-items-center z-1 ps-1">
        {{t "plant.station.jem.taskStatus"}}
      </div>

      <div class="gutter-col gutter-3 position-sticky top-0 bg-gray-900 z-1 py-2" />

      <div
        class="
          task-actual-time
          app-jem-task-cycle-column
          position-sticky
          top-0
          bg-gray-900
          jem-pane-header
          d-flex
          align-items-center
          z-1
          ps-1
        "
      >
        {{#if @station.jemShowTaskCycle}}
          {{t "time"}}
        {{/if}}
      </div>

      <div class="jem-task-rows full-width-grid">
        {{#let
        (service 'taskRepo')
        (service 'currentUser')
      as |taskRepo currentUser|}}
          {{#each @taskRows as |task|}}
            {{#let (taskRepo.getConfigForModelOrBuildDatum task @model @buildStatus.buildDatum) as |taskConfig|}}
              {{#if (getTaskIsDisplayed taskConfig task @buildStatus currentUser.user)}}
                <JemTaskGridRow
                class="full-width-grid"
                @station={{@station}}
                @task={{task}}
                @taskConfig={{taskConfig}}
                @hardware={{getHardware @station task}}
                @childStatus={{getChildStatus task @buildStatus}}
                @notAuthorized={{@notAuthorized}}
                @isSelected={{eq task @currentTask}}
                @onSelectTask={{@onSelectTask}}
                @onTaskComplete={{@onTaskComplete}}
                @onRepair={{@onRepair}}
              />
              {{/if}}
            {{/let}}
          {{/each}}
        {{/let}}
      </div>
    </SplitView>
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
