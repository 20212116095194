import { template } from "@ember/template-compiler";
import { isBlank } from '@ember/utils';
import { inputNum } from 'eflex/helpers';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
const getLabelClass = (opacity1)=>{
    opacity1 = Number.parseInt(opacity1);
    if (opacity1 < 10) {
        return 'ones';
    } else if (opacity1 < 100) {
        return 'tens';
    } else {
        return 'hundreds';
    }
};
const updateOpacity = (onUpdate1, opacity1)=>{
    if (isBlank(opacity1) || opacity1 < 0 || isNaN(opacity1)) {
        opacity1 = 0;
    } else if (opacity1 > 100) {
        opacity1 = 100;
    }
    onUpdate1(opacity1);
};
export default template(`
<div class="component-inputs-opacity opacity-input-wrapper" ...attributes>
  <input
    class="opacity-input form-control"
    type="number"
    value={{@opacity}}
    min={{0}}
    max={{100}}
    disabled={{@disabled}}
    {{on "input" (inputNum (fn updateOpacity @update))}}
  />
  <label class="{{getLabelClass @opacity}} {{if @disabled "disabled"}}">
    %
  </label>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
