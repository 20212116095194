import { template } from "@ember/template-compiler";
import TaskVariablesConfig from 'eflex/components/task-variables-config';
import { eq } from 'ember-truth-helpers';
import { t } from 'ember-intl';
export default template(`
{{#let (eq @task.opcUaType 'variable') as |usesVariable|}}
  <TaskVariablesConfig
    @form={{@form}}
    @disabled={{@disabled}}
    @task={{@task}}
    @taskConfig={{@taskConfig}}
  >
    <:toVariableCols>
      {{#if usesVariable}}
        <div class="col">
          {{t "nodeId"}}
        </div>
      {{/if}}
    </:toVariableCols>

    <:toVariableRows as |variableDef|>
      {{#if usesVariable}}
        <div class="col">
          <@form.element
            class='test-variable-opc-ua-node-id'
            @controlType="text"
            @model={{variableDef}}
            @property="opcUaNodeId"
          />
        </div>
      {{/if}}
    </:toVariableRows>

    <:fromVariableCols>
      {{#if usesVariable}}
        <div class="col">
          {{t "nodeId"}}
        </div>
      {{/if}}
    </:fromVariableCols>

    <:fromVariableRows as |variableDef|>
      {{#if usesVariable}}
        <div class="col">
          <@form.element
            class='test-variable-opc-ua-node-id'
            @controlType="text"
            @model={{variableDef}}
            @property="opcUaNodeId"
          />
        </div>
      {{/if}}
    </:fromVariableRows>
  </TaskVariablesConfig>
{{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
