import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import { task, all, waitForProperty } from 'ember-concurrency';
import { anyInvalid } from 'eflex/util/getter-helpers';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { PERMISSION_NAMES } from 'eflex/constants/user-permissions';
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import Breadcrumbs from 'eflex/components/breadcrumbs';
import SaveButtons from 'eflex/components/save-buttons';
import ButtonPowerSelect from 'eflex/components/button-power-select';
import ConfigTable from 'eflex/components/config-table';
import ConfigButton from 'eflex/components/config-button';
import LinkPopover from 'eflex/components/link-popover';
import TrashCan from 'eflex/components/trash-can';
import StandardModal from 'eflex/components/standard-modal';
import ConfigurationHistoryNotesModal from 'eflex/components/modals/configuration-history-notes';
import HardwarePanel from 'eflex/components/panels/hardware';
import InputsLiveSearch from 'eflex/components/inputs/search-live';
import { or, eq, not } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import { fn } from '@ember/helper';
import { isBlank, isPresent } from '@ember/utils';
import { filterBy, rejectBy } from '@eflexsystems/ember-composable-helpers';
import { chain, prop } from 'ramda';
import { intoArray } from '@eflexsystems/ramda-helpers';
import { compact } from 'ramda-adjunct';
const getDisplayedHardware = (nonDeletedHardwares1, searchFilter1)=>{
    if (isBlank(searchFilter1)) {
        return nonDeletedHardwares1;
    }
    searchFilter1 = searchFilter1.toLowerCase();
    return nonDeletedHardwares1.filter((hardware1)=>{
        return hardware1.displayName.toLowerCase().includes(searchFilter1);
    });
};
let HardwareTemplate = class HardwareTemplate extends Component {
    @service
    validationErrorNotifier;
    @service
    hardwareRepo;
    @service
    currentUser;
    @service
    systemConfig;
    @service
    taskRepo;
    @service
    stationRepo;
    @service
    hardwareInputDefRepo;
    @tracked
    hardwareToDelete;
    @tracked
    searchFilter;
    @tracked
    selectedHardware;
    @tracked
    locationsToUpdateVersion = [];
    @tracked
    hideTransitionModal = false;
    @tracked
    showConfigurationHistoryNotesModal = false;
    @tracked
    showHardwarePanel = false;
    getAssignedStations = (hardware1)=>{
        const hardwareIos1 = hardware1.hardwareIos;
        const { taskRepo: taskRepo1, stationRepo: stationRepo1, hardwareInputDefRepo: hardwareInputDefRepo1 } = this;
        const tasks1 = taskRepo1.tasks;
        return new Set([
            ...hardware1.tasks.map((item1)=>item1.parent),
            ...stationRepo1.stations.filter((item1)=>item1.webCam === hardware1),
            ...intoArray(chain((hardwareIo1)=>[
                    tasks1.find((treeTask1)=>treeTask1.hardwareOutputs.includes(hardwareIo1))?.parent,
                    tasks1.find((item1)=>item1.hardwareInput === hardwareIo1)?.parent,
                    hardwareInputDefRepo1.hardwareInputDefs.find((item1)=>item1.input === hardwareIo1)?.task?.parent
                ]), compact)(hardwareIos1)
        ]);
    };
    save = task({
        drop: true
    }, waitFor(async (isInvalid1, nonDeletedHardwares1, hardwareIos1, dirtyHardwares1, dirtyHardwareIos1, jemConfig1)=>{
        if (isInvalid1) {
            this.validationErrorNotifier.sendErrors([
                ...nonDeletedHardwares1,
                ...hardwareIos1.filter((item1)=>!item1.isDeleted)
            ]);
            return;
        }
        const updateLocations1 = new Set();
        await all(dirtyHardwareIos1.map((hardwareIo1)=>{
            this.getAssignedStations(hardwareIo1.hardware).forEach((station1)=>{
                updateLocations1.add(station1);
            });
            return hardwareIo1.save();
        }));
        await all(dirtyHardwares1.map((hardware1)=>{
            this.getAssignedStations(hardware1).forEach((station1)=>{
                updateLocations1.add(station1);
            });
            return hardware1.save();
        }));
        if (!jemConfig1?.configurationHistory || updateLocations1.size === 0) {
            return;
        }
        Object.assign(this, {
            showConfigurationHistoryNotesModal: true,
            hideTransitionModal: true,
            locationsToUpdateVersion: Array.from(updateLocations1)
        });
        await waitForProperty(this, 'showConfigurationHistoryNotesModal', false);
        Object.assign(this, {
            showConfigurationHistoryNotesModal: false,
            hideTransitionModal: false,
            locationsToUpdateVersion: []
        });
    }));
    deleteHardware = (hardware1)=>{
        this.hardwareToDelete = null;
        hardware1.hardwareIos.forEach((hardwareIo1)=>{
            hardwareIo1.deleteRecord();
        });
        hardware1.deleteRecord();
    };
    toggleDeleteWarning = (hardware1, attachedStations1, event1)=>{
        if (isPresent(attachedStations1)) {
            event1.preventDefault();
            event1.stopPropagation();
            this.hardwareToDelete = hardware1;
        } else {
            this.deleteHardware(hardware1);
        }
    };
    openHardwarePanel = (hardware1)=>{
        Object.assign(this, {
            showHardwarePanel: true,
            selectedHardware: hardware1
        });
    };
    closeHardwarePanel = ()=>{
        Object.assign(this, {
            showHardwarePanel: false,
            selectedHardware: null
        });
    };
    rollback = (selectedHardware1, hardwares1, hardwareIos1)=>{
        if (selectedHardware1?.isDeleted || selectedHardware1?.isNew) {
            this.closeHardwarePanel();
        }
        hardwares1.forEach((hardware1)=>{
            hardware1.rollbackAttributes();
        });
        hardwareIos1.forEach((hardwareIo1)=>{
            hardwareIo1.rollbackAttributes();
        });
    };
    addHardware = (hardwareType1)=>{
        const hardware1 = this.hardwareRepo.createHardware(hardwareType1);
        this.openHardwarePanel(hardware1);
    };
    static{
        template(`
    {{#let (this.hardwareRepo.getAllAssignableHardware) as |hardwares|}}
      {{#let (chain (prop 'hardwareIos') hardwares) as |hardwareIos|}}
        {{#let
          (rejectBy 'isDeleted' hardwares)
          (this.currentUser.hasPermission PERMISSION_NAMES.hardware)
        as |nonDeletedHardwares hasPermission|}}
          <Breadcrumbs
            @navKey="plant"
            @page={{t "hardware"}}
            @userNotPermitted={{not hasPermission}}
          />

          {{#let
            (or (anyInvalid hardwares) (anyInvalid hardwareIos))
            (filterBy 'isDirty' hardwares)
            (filterBy 'isDirty' hardwareIos)
          as |isInvalid dirtyHardwares dirtyHardwareIos|}}
            <SaveButtons
              @isDirty={{or
                (isPresent dirtyHardwares)
                (isPresent dirtyHardwareIos)
              }}
              @isInvalid={{isInvalid}}
              @save={{fn
                this.save.perform
                isInvalid
                nonDeletedHardwares
                hardwareIos
                dirtyHardwares
                dirtyHardwareIos
                this.systemConfig.jem
              }}
              @rollback={{fn this.rollback this.selectedHardware hardwares hardwareIos}}
              @allowUserSave={{hasPermission}}
              @hideTransitionModal={{this.hideTransitionModal}}
            as |showValidations|>
              {{#if this.showHardwarePanel}}
                <HardwarePanel
                  @hardware={{this.selectedHardware}}
                  @showValidations={{showValidations}}
                  @onClose={{this.closeHardwarePanel}}
                  @disabled={{not hasPermission}}
                />
              {{/if}}
            </SaveButtons>
          {{/let}}

          <div class="plant-hardware d-flex flex-column h-100">
            <div class="sticky-top d-flex flex-row align-items-center mb-4">
              <div class="hardware-filter w-25">
                <InputsLiveSearch
                  @placeholder="{{t 'search'}}..."
                  @searchValue={{this.searchFilter}}
                  @onInput={{fn (mut this.searchFilter)}}
                />
              </div>
              <div class="ms-auto">
                <ButtonPowerSelect
                  @dropdownClass="add-hardware"
                  @options={{(this.hardwareRepo.getAllAssignableHardwareTypes)}}
                  @placeholder={{t "hardware"}}
                  @searchEnabled={{true}}
                  @onChange={{this.addHardware}}
                  @disabled={{not hasPermission}}
                  class="add-hardware ms-auto"
                as |hardwareType|>
                  {{t hardwareType}}
                </ButtonPowerSelect>
              </div>
            </div>

            <ConfigTable class="hardware-list d-flex flex-column overflow-x-hidden">
              <div class="row form-row header-row sticky-top bg-white">
                <div class="col-icon" />
                <div class="col-auto col-hardware-status">{{t "status"}}</div>
                <div class="col">{{t "name"}}</div>
                <div class="col">{{t "type"}}</div>
                <div class="col col-hardware-stations">{{t "stations"}}</div>
                <div class="col-icon border-0"/>
              </div>

              {{#each (getDisplayedHardware nonDeletedHardwares this.searchFilter) as |hardware|}}
                {{#let (this.getAssignedStations hardware) as |attachedStations|}}
                  <div
                    class="
                      hardware-row
                      form-row
                      row
                      align-items-center
                      {{if (eq this.selectedHardware hardware) "selected"}}
                    "
                  >
                    <div class="col-auto">
                      <ConfigButton @onClick={{fn this.openHardwarePanel hardware}} />
                    </div>
                    <div class="col-auto col-hardware-status">
                      {{#if (not hardware.isConfigured)}}
                        <div class="connection-indicator badge rounded-pill text-bg-secondary">
                          {{t 'noStatus'}}
                        </div>
                      {{else if hardware.hasError}}
                        <div class="connection-indicator badge rounded-pill text-bg-danger text-white">
                          {{t 'disconnected'}}
                        </div>
                      {{else if hardware.isConnected}}
                        <div class="connection-indicator badge rounded-pill text-bg-success">
                          {{t 'connected'}}
                        </div>
                      {{else}}
                        <div class="connection-indicator badge rounded-pill text-bg-danger text-white">
                          {{t 'disconnected'}}
                        </div>
                      {{/if}}
                    </div>
                    <div class="col col-hardware-name">
                      {{hardware.name}}
                    </div>
                    <div class="col col-hardware-type">
                      {{hardware.displayType}}
                    </div>
                    <div class="col col-hardware-stations">
                      <LinkPopover
                        @label={{attachedStations.size}}
                        @options={{attachedStations}}
                        @route='plant.stations'
                      as |station|>
                        {{station.name}}
                      </LinkPopover>
                    </div>
                    <div class="col-icon">
                      <TrashCan
                        disabled={{not hasPermission}}
                        @onDelete={{fn this.toggleDeleteWarning hardware attachedStations}}
                      />
                    </div>
                  </div>
                {{/let}}
              {{/each}}
            </ConfigTable>
          </div>

          {{#if this.hardwareToDelete}}
            <StandardModal
              class="delete-hardware-warning"
              @header={{t "plant.stations.hardware.hardwareAssigned"}}
              @text={{t "plant.stations.hardware.areYouSure" name=this.hardwareToDelete.name}}
              @onConfirm={{fn this.deleteHardware this.hardwareToDelete}}
              @onCancel={{fn (mut this.hardwareToDelete) null}}
              @size="sm"
            />
          {{/if}}

          {{#if this.showConfigurationHistoryNotesModal}}
            <ConfigurationHistoryNotesModal
              @locations={{this.locationsToUpdateVersion}}
              @onConfirm={{fn (mut this.showConfigurationHistoryNotesModal) false}}
            />
          {{/if}}
        {{/let}}
      {{/let}}
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(HardwareTemplate);
