import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import LocaleMap from 'eflex/util/locale-map';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { capitalize } from '@ember/string';
import { toLower } from 'ramda';
import BsDropdown from 'ember-bootstrap/components/bs-dropdown';
import BsButton from 'eflex/components/bs-button';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { eq } from 'ember-truth-helpers';
import { fn, concat } from '@ember/helper';
import { t } from 'ember-intl';
import { transitionTo } from 'eflex/helpers';
import { on } from '@ember/modifier';
import StandardModal from 'eflex/components/standard-modal';
export default class UserDropdown extends Component {
    @service
    currentUser;
    @service
    session;
    languages = LocaleMap;
    @tracked
    showLogoutModal = false;
    get user() {
        return this.currentUser.user;
    }
    get userRole() {
        return this.user?.role;
    }
    get userAbbreviation() {
        if (!this.user) {
            return null;
        }
        return capitalize((this.user.userName.slice(0, 2)));
    }
    noHide = ()=>{
        return false;
    };
    languageChanged = (language1)=>{
        return this.currentUser.changeLanguage.perform(language1.lang);
    };
    logOut = ()=>{
        this.session.invalidate();
    };
    static{
        template(`
    <BsDropdown
      class="user-dropdown-menu"
      @direction={{if @noChrome "bottom" "right"}}
      @closeOnMenuClick={{false}}
      ...attributes
    as |dd|>
      <dd.toggle class="user-dropdown">
        {{#if @noChrome}}
          <BsButton
            class="rounded-circle text-uppercase"
            @size="md"
            @type="{{if (eq this.userRole "Admin") "orange"}}
              {{if (eq this.userRole "User") "primary"}}
              {{if (eq this.userRole "ViewOnly") "secondary"}}"
          >
            {{this.userAbbreviation}}
          </BsButton>
        {{else}}
          <BsButton
            @type="epicor-nav"
            class="text-uppercase p-0"
          >
            <img
              src='/images/icons/account-settings.svg'
            />
          </BsButton>
        {{/if}}
      </dd.toggle>

      <dd.menu class="
        dropdown-menu-epicor
        shadow
        overflow-visible
        fs-3
        p-0
        {{unless @noChrome "ms-1" "dropdown-menu-dark mt-1"}}"
      as |menu|>
        <menu.item>
          <div class="
            dropdown-item-text
            user-option
            pb-3
            border-bottom
            {{unless @noChrome "border-gray-200 p-2" "border-gray-600 p-3"}}"
          >
            <div class="
              fs-5
              text-uppercase
              fw-bold
              mb-2
              {{unless @noChrome "text-gray-500" "text-gray-300"}}"
            >
              {{t "user.menu.account"}}
            </div>
            <div class="row align-items-center g-2">
              <div class="col-auto">
                <div class="
                  profile-image
                  {{if (eq this.userRole "Admin") "bg-orange" "bg-primary"}}
                  {{if (eq this.userRole "User") "bg-primary"}}
                  {{if (eq this.userRole "ViewOnly") "bg-secondary"}}
                  {{if @noChrome "text-gray-900"}}
                  d-flex
                  align-items-center
                  justify-content-center
                  rounded-circle
                  user-select-none
                  fs-3
                  text-uppercase
                  fw-bold
                  m-0">
                  {{this.userAbbreviation}}
                </div>
              </div>
              <div class="col-auto">
                <div class="fw-semibold {{unless @noChrome "text-body" "text-white"}}">
                  {{this.user.userName}}
                </div>
                {{#if this.user}}
                  <div class="fs-5 fw-semibold {{unless @noChrome "text-gray-500" "text-gray-300"}}">
                    {{t (concat "user.menu." (toLower this.userRole))}}
                  </div>
                {{/if}}
              </div>
            </div>
          </div>
        </menu.item>

        <div class="py-1 border-bottom {{unless @noChrome "border-gray-200" "border-gray-600"}}">
          {{#if @showHeaderButtons}}
            <menu.item>
              <div
                class="user-option user-profile dropdown-item {{unless @noChrome "fs-4" "p-3"}}"
                {{on "click" (transitionTo 'settings')}}
              >
                {{t "user.menu.userSettings"}}
              </div>
            </menu.item>
          {{/if}}

          <menu.item>
            <div class="user-option dropdown-item p-0">
              <BsDropdown
                class="z-top-menu"
                @direction="right"
              as |languageDropdown|>
                <languageDropdown.toggle
                  class="
                    language-dropdown
                    d-flex
                    align-items-center
                    {{unless @noChrome "fs-4 p-2" "p-3" }}"
                  >
                  {{t "settings.language"}}
                  <FaIcon @icon="caret-right" @prefix="fas" class="ms-auto" />
                </languageDropdown.toggle>

                <languageDropdown.menu
                  class="
                    dropdown-menu
                    eflex-scroll
                    shadow
                    {{unless @noChrome "ms-1" "dropdown-menu-dark me-1"}}"
                  as |subMenu|>
                  {{#each this.languages as |language|}}
                    <subMenu.item>
                      <div
                        class="language-option dropdown-item {{unless @noChrome "fs-4" "p-3"}}"
                        {{on "click" (fn this.languageChanged language)}}
                      >
                        {{language.label}}
                      </div>
                    </subMenu.item>
                  {{/each}}
                </languageDropdown.menu>
              </BsDropdown>
            </div>
          </menu.item>
        </div>

        {{#if @showHeaderButtons}}
          <div class="py-1">
            <menu.item>
              <div class="user-option dropdown-item fs-4 p-0">
                {{#if this.session.isAuthenticated}}
                  <a
                    class="log-out {{unless @noChrome "p-2" "p-3" }}"
                    {{on "click" (fn (mut this.showLogoutModal) true)}}
                  >
                    {{t "controls.login.logout"}}
                  </a>
                {{/if}}

                {{#if this.showLogoutModal}}
                  <StandardModal
                    class="confirm-logout"
                    @onCancel={{fn (mut this.showLogoutModal) false}}
                    @onConfirm={{this.logOut}}
                    @size="sm"
                  as |Modal|>
                    <Modal.header>
                      <h5>{{t "controls.login.logout"}}</h5>
                    </Modal.header>
                    <Modal.body>
                      <p class="fs-2">{{t "areyousure.logout"}}</p>
                    </Modal.body>
                    <Modal.footer />
                  </StandardModal>
                {{/if}}
              </div>
            </menu.item>
          </div>
        {{/if}}
      </dd.menu>
    </BsDropdown>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
