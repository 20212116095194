import { template } from "@ember/template-compiler";
import TaskVariablesConfig from 'eflex/components/task-variables-config';
export default template(`
<TaskVariablesConfig
  @form={{@form}}
  @disabled={{@disabled}}
  @task={{@task}}
  @taskConfig={{@taskConfig}}
/>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
