import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import TaskStatuses from 'eflex/constants/task-statuses';
import EchartsTheme from 'eflex/echarts-theme';
import Component from '@glimmer/component';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { clone, omit } from 'ramda';
import { fn, get } from '@ember/helper';
import EChart from 'eflex/components/e-chart';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import Spinner from 'eflex/components/spinner';
export default class BiStationQualityChart extends Component {
    @service
    queryRunner;
    @service
    intl;
    @service
    router;
    getData = task({
        restartable: true
    }, waitFor(async (params1)=>{
        const query1 = await this.queryRunner.queryWithParams.perform(params1, [
            {
                $group: {
                    _id: '$location._id',
                    station: {
                        $first: {
                            $first: '$location.captions.text'
                        }
                    },
                    totalParts: {
                        $sum: 1
                    },
                    rejectCount: {
                        $sum: {
                            $cond: {
                                if: {
                                    $and: [
                                        {
                                            $gte: [
                                                '$status',
                                                TaskStatuses.REJECT_RANGE_START
                                            ]
                                        },
                                        {
                                            $lte: [
                                                '$status',
                                                TaskStatuses.REJECT_RANGE_END
                                            ]
                                        }
                                    ]
                                },
                                then: 1,
                                else: 0
                            }
                        }
                    },
                    passCount: {
                        $sum: {
                            $cond: {
                                if: {
                                    $and: [
                                        {
                                            $gte: [
                                                '$status',
                                                TaskStatuses.GOOD_RANGE_START
                                            ]
                                        },
                                        {
                                            $lte: [
                                                '$status',
                                                TaskStatuses.GOOD_RANGE_END
                                            ]
                                        }
                                    ]
                                },
                                then: 1,
                                else: 0
                            }
                        }
                    }
                }
            },
            {
                $addFields: {
                    quality: {
                        $round: [
                            {
                                $multiply: [
                                    {
                                        $divide: [
                                            '$passCount',
                                            '$totalParts'
                                        ]
                                    },
                                    100
                                ]
                            },
                            1
                        ]
                    }
                }
            },
            {
                $sort: {
                    quality: -1
                }
            }
        ]);
        const qualityData1 = await this.queryRunner.runQuery.perform('BuildStatuses', query1);
        return {
            chartData: this.getStationQualityChartOptions(qualityData1),
            exportData: ()=>clone(qualityData1).map(omit([
                    '_id'
                ]), qualityData1)
        };
    }));
    stationQualityData = trackedTask(this, this.getData, ()=>[
            {
                stationIds: this.args.stationIds,
                modelIds: this.args.modelIds,
                userIds: this.args.userIds,
                tags: this.args.tags,
                startDate: this.args.startDate,
                endDate: this.args.endDate,
                shiftNames: this.args.shiftNames
            },
            this.args.refresher
        ]);
    getStationQualityChartOptions(qualityData1) {
        return {
            title: {
                text: this.intl.t('bi.chartName.qualitySummaryStation')
            },
            legend: {
                show: true,
                bottom: 5
            },
            tooltip: {
                trigger: 'axis'
            },
            yAxis: {
                type: 'category',
                boundaryGap: true,
                data: qualityData1.map((item1)=>item1.station)
            },
            xAxis: [
                {
                    type: 'value',
                    max: 100,
                    interval: 10,
                    position: 'bottom',
                    axisLabel: {
                        formatter: '{value}%'
                    }
                },
                {
                    type: 'value',
                    show: false
                }
            ],
            series: [
                {
                    name: this.intl.t('bi.chartLegend.timePeriodTotalParts'),
                    data: qualityData1.map((item1)=>item1.totalParts),
                    type: 'line',
                    xAxisIndex: 1,
                    color: EchartsTheme.colorPalette.biBlue
                },
                {
                    name: this.intl.t('bi.chartLegend.timePeriodRejects'),
                    data: qualityData1.map((item1)=>item1.rejectCount),
                    type: 'line',
                    xAxisIndex: 1,
                    color: EchartsTheme.colorPalette.danger
                },
                {
                    name: this.intl.t('bi.chartLegend.timePeriodQuality'),
                    data: qualityData1.map((item1)=>item1.quality),
                    type: 'bar',
                    xAxisIndex: 0,
                    color: EchartsTheme.colorPalette.success
                }
            ],
            stationIds: qualityData1.map((item1)=>item1._id)
        };
    }
    stationDrillDown = (stationQualityData1, dataIndex1)=>{
        this.router.transitionTo('bi.quality.stations', {
            queryParams: {
                stationIds: [
                    stationQualityData1.stationIds[dataIndex1]
                ],
                modelIds: this.args.modelIds,
                userIds: this.args.userIds,
                tags: this.args.tags,
                beginDate: this.args.startDate,
                endDate: this.args.endDate,
                shiftNames: this.args.shiftNames
            }
        });
    };
    static{
        template(`
    {{! template-lint-disable no-passed-in-event-handlers }}
    {{#if this.stationQualityData.isRunning}}
      <Spinner />
    {{else if (get this.stationQualityData.value.chartData.series '0.data.length')}}
      <div class="d-flex flex-row h-100 chart-row">
        <div class="col d-flex justify-content-around">
          <EChart
            @option={{this.stationQualityData.value.chartData}}
            @exportData={{this.stationQualityData.value.exportData}}
            class="h-100 w-100 station-quality-chart"
            @onClick={{fn this.stationDrillDown this.stationQualityData.value.chartData}}
          />
        </div>
      </div>
    {{else}}
      <BiNoDataPlaceholder />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
