import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { sortBy } from '@eflexsystems/ember-composable-helpers';
import { t } from 'ember-intl';
import StandardModal from 'eflex/components/standard-modal';
import EmberGrid from 'eflex/components/ember-grid';
let KineticMaterialsModal = class KineticMaterialsModal extends Component {
    @service
    kineticRepo;
    @service
    intl;
    static{
        template(`
    <StandardModal
      class="test-kinetic-materials-modal text-white"
      @size="lg"
      @onCancel={{@onCancel}}
    as |modal|>
      <modal.header @text={{t 'kinetic.materials'}} class="pb-2 bg-gray-900" />
      <modal.body class="main-overflow bg-gray-900">
        <div class="row mb-4 mx-1 text-white">
          <div class="col-2">
            <label>
              {{t "job"}}
            </label>
            <div class="test-material-job">
              {{@assemblyOperation.job.jobNumber}}
            </div>
          </div>
          <div class="col-2">
            <label>
              {{t "kinetic.assembly"}}
            </label>
            <div class="test-material-assembly">
              {{@assemblyOperation.assembly.assemblySequence}}
            </div>
          </div>
          <div class="col-2">
            <label>
              {{t "operation"}}
            </label>
            <div class="test-material-operation">
              {{@assemblyOperation.name}}
            </div>
          </div>
        </div>

        <EmberGrid class="w-100 ember-grid-dark">
          <thead>
            <tr>
              <th>
                {{t 'kinetic.sequence'}}
              </th>
              <th>
                {{t 'partNumber'}}
              </th>
              <th>
                {{t 'kinetic.quantityIssued'}}
              </th>
              <th>
                {{t 'kinetic.issuedComplete'}}
              </th>
            </tr>
          </thead>
          <tbody>
            {{#if (isEmpty @assemblyOperation.materials)}}
              <tr>
                <td colspan="4" class="center">
                  {{t 'kinetic.noMaterialsAssigned'}}
                </td>
              </tr>
            {{else}}
              {{#each (sortBy "sequence" @assemblyOperation.materials) as |material|}}
                <tr class="test-material-row">
                  <td class="test-material-sequence">{{material.sequence}}</td>
                  <td class="test-material-part-number">{{material.partNumber}}</td>
                  <td class="test-material-quantity-issued">{{material.quantityIssued}}</td>
                  <td class="test-material-issued-complete">{{material.issuedComplete}}</td>
                </tr>
              {{/each}}
            {{/if}}
          </tbody>
        </EmberGrid>
      </modal.body>
      <modal.footer class="bg-gray-900" as |footer|>
        <footer.close class="btn-secondary-dark">
          {{t "close"}}
        </footer.close>
      </modal.footer>
    </StandardModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default KineticMaterialsModal;
