import { template } from "@ember/template-compiler";
import { assert } from '@ember/debug';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { filterOptions, defaultMatcher } from 'ember-power-select/utils/group-utils';
import { ensureSafeComponent } from '@embroider/util';
import PowerSelect from 'eflex/components/power-select';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
const SuggestedOptionComponent = template(`
  {{@option.text}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default class PowerSelectWithCreateComponent extends Component {
    matcher = defaultMatcher;
    @tracked
    searchEnabled = true;
    constructor(){
        super(...arguments);
        assert('<PowerSelectWithCreate> requires an `onCreate` function', this.args.onCreate && typeof this.args.onCreate === 'function');
    }
    get powerSelectComponent() {
        return ensureSafeComponent(this.args.powerSelectComponent ?? PowerSelect, this);
    }
    get suggestedOptionComponent() {
        return ensureSafeComponent(this.args.suggestedOptionComponent ?? SuggestedOptionComponent, this);
    }
    shouldShowCreateOption(term1, options1) {
        return this.args.showCreateWhen ? this.args.showCreateWhen(term1, options1) : true;
    }
    addCreateOption(term1, results1) {
        if (this.shouldShowCreateOption(term1, results1)) {
            if (this.args.showCreatePosition === 'bottom') {
                results1.push(this.buildSuggestionForTerm(term1));
            } else {
                results1.unshift(this.buildSuggestionForTerm(term1));
            }
        }
    }
    searchAndSuggest = task(waitFor(async (term1, select1)=>{
        let newOptions1 = await this.args.options;
        if (term1.length === 0) {
            return newOptions1;
        }
        const searchAction1 = this.args.search;
        if (searchAction1) {
            const results1 = await searchAction1(term1, select1);
            this.addCreateOption(term1, results1);
            return results1;
        }
        newOptions1 = this.filter(newOptions1, term1);
        this.addCreateOption(term1, newOptions1);
        return newOptions1;
    }));
    selectOrCreate = (selection1, select1, e1)=>{
        if (selection1 && selection1.__isSuggestion__) {
            this.args.onCreate(selection1.__value__, select1, e1);
        } else {
            this.args.onChange(selection1, select1, e1);
        }
    };
    // Methods
    filter(options1, searchText1) {
        let matcher1;
        if (this.args.searchField) {
            matcher1 = (option1, text1)=>this.matcher(option1[this.args.searchField], text1);
        } else {
            matcher1 = (option1, text1)=>this.matcher(option1, text1);
        }
        return filterOptions(options1 || [], searchText1, matcher1);
    }
    buildSuggestionForTerm(term1) {
        return {
            __isSuggestion__: true,
            __value__: term1,
            text: this.buildSuggestionLabel(term1)
        };
    }
    buildSuggestionLabel(term1) {
        if (this.args.buildSuggestion) {
            return this.args.buildSuggestion(term1);
        }
        return `Add "${term1}"...`;
    }
    static{
        template(`
    <this.powerSelectComponent
      @afterOptionsComponent={{@afterOptionsComponent}}
      @allowClear={{@allowClear}}
      @ariaDescribedBy={{@ariaDescribedBy}}
      @ariaInvalid={{@ariaInvalid}}
      @ariaLabel={{@ariaLabel}}
      @ariaLabelledBy={{@ariaLabelledBy}}
      @beforeOptionsComponent={{this.beforeOptionsComponent}}
      @buildSelection={{@buildSelection}}
      @calculatePosition={{@calculatePosition}}
      @class={{@class}}
      @closeOnSelect={{@closeOnSelect}}
      @defaultHighlighted={{@defaultHighlighted}}
      @destination={{@destination}}
      @dir={{@dir}}
      @disabled={{@disabled}}
      @dropdownClass={{@dropdownClass}}
      @extra={{@extra}}
      @groupComponent={{@groupComponent}}
      @highlightOnHover={{@highlightOnHover}}
      @horizontalPosition={{@horizontalPosition}}
      @initiallyOpened={{@initiallyOpened}}
      @loadingMessage={{@loadingMessage}}
      @matchTriggerWidth={{@matchTriggerWidth}}
      @matcher={{this.matcher}}
      @noMatchesMessage={{@noMatchesMessage}}
      @onBlur={{@onBlur}}
      @onChange={{this.selectOrCreate}}
      @onClose={{@onClose}}
      @onFocus={{@onFocus}}
      @onInput={{@onInput}}
      @onKeydown={{@onKeydown}}
      @onOpen={{@onOpen}}
      @options={{@options}}
      @optionsComponent={{@optionsComponent}}
      @placeholder={{@placeholder}}
      @placeholderComponent={{@placeholderComponent}}
      @preventScroll={{@preventScroll}}
      @registerAPI={{@registerAPI}}
      @renderInPlace={{@renderInPlace}}
      @scrollTo={{@scrollTo}}
      @search={{this.searchAndSuggest.perform}}
      @searchEnabled={{this.searchEnabled}}
      @searchField={{@searchField}}
      @searchMessage={{@searchMessage}}
      @searchMessageComponent={{@searchMessageComponent}}
      @searchPlaceholder={{@searchPlaceholder}}
      @selected={{@selected}}
      @selectedItemComponent={{@selectedItemComponent}}
      @tabindex={{@tabindex}}
      @triggerClass={{@triggerClass}}
      @triggerComponent={{@triggerComponent}}
      @triggerId={{@triggerId}}
      @triggerRole={{@triggerRole}}
      @typeAheadMatcher={{@typeAheadMatcher}}
      @verticalPosition={{@verticalPosition}}
      ...attributes
    as |option term|>
      {{#if option.__isSuggestion__}}
        <this.suggestedOptionComponent @option={{option}} @term={{term}} />
      {{else}}
        {{yield option term}}
      {{/if}}
    </this.powerSelectComponent>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
