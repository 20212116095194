import { template } from "@ember/template-compiler";
import { sortByProp } from 'ramda-adjunct';
import PowerSelect from 'eflex/components/power-select';
import { t } from 'ember-intl';
import { isEmpty } from 'ember-truth-helpers';
import KeyboardWedgeEvent from 'eflex/components/keyboard-wedge-event';
// don't use composable helpers sort since it uses a different sorting algorithm that could be different than the model context selector
const getSortedModels = (models1)=>{
    return sortByProp('code', models1 ?? []);
};
export default template(`
<PowerSelect
  class="jem-model-select"
  @dropdownClass="jem-model-select-dropdown"
  @triggerClass="jem-model-select-trigger form-control form-control-lg bg-gray-900"
  @searchEnabled={{true}}
  @searchField="displayName"
  @options={{getSortedModels @models}}
  @selected={{@model}}
  @disabled={{@disabled}}
  @allowClear={{true}}
  @placeholder={{t (if @productionScheduleEnabled "jem.noModelScheduled" "jem.selectModel")}}
  @onChange={{@onChange}}
as |selection|>
  {{selection.displayName}}
</PowerSelect>

{{#if (isEmpty @model)}}
  <KeyboardWedgeEvent @onScan={{@onScan}} />
{{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
