import { template } from "@ember/template-compiler";
import { isTesting as isTestingMacro, macroCondition } from '@embroider/macros';
// eslint-disable-next-line no-unneeded-ternary
const isTesting = macroCondition(isTestingMacro()) ? true : false;
export default template(`
{{#unless isTesting}}
  <div
    class="component-spinner spinner text-primary"
    ...attributes
  >
    <div class="spinner-border" role="status"></div>
  </div>
{{/unless}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
