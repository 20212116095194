import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import onKey from 'ember-keyboard/modifiers/on-key';
import { on } from '@ember/modifier';
import { inputVal } from 'eflex/helpers';
import BsButton from 'eflex/components/bs-button';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
const ClearButton = template(`
<BsButton
  @type="peripheral"
  class="clear-icon position-absolute end-0 px-3 py-2 me-4"
  {{on "click" @onClick}}
  ...attributes
>
  <FaIcon @icon="xmark" @prefix="fas" @size="xs" />
</BsButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default class SearchInput extends Component {
    onInput = (val1)=>{
        if (this.args.disabled) {
            return;
        }
        this.args.onInput?.(val1);
    };
    onSubmit = ()=>{
        if (this.args.disabled) {
            return;
        }
        this.args.onSubmit?.(this.args.value);
    };
    onClear = ()=>{
        if (this.args.disabled) {
            return;
        }
        this.onInput('');
        this.onSubmit();
    };
    static{
        template(`
    <div class="component-inputs-search"
      {{onKey 'Enter' this.onSubmit}}
      ...attributes
    >
      <div class="d-flex position-relative align-items-center">
        <input
          placeholder={{@placeholder}}
          value={{@searchTerm}}
          disabled={{@disabled}}
          class="{{@inputClass}} search-term border-gray-300 fs-4 form-control form-control-sm"
          {{on "input" (inputVal this.onInput)}}
          {{on "search" this.onSubmit}}
        />
        {{#if @searchTerm}}
          <ClearButton @onClick={{this.onClear}} />
        {{/if}}
        <BsButton
          @type="secondary"
          class="search d-flex align-items-center position-absolute end-0 h-100 px-2 z-2"
          @size="sm"
          @disabled={{@disabled}}
          @onClick={{this.onSubmit}}
        >
          <FaIcon @icon="magnifying-glass" @prefix="fas" class="fs-5" />
        </BsButton>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
