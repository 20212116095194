import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import moment from 'moment-timezone';
import echartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';
import { clone } from 'ramda';
import { t } from 'ember-intl';
import { get } from '@ember/helper';
import EChart from 'eflex/components/e-chart';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import Spinner from 'eflex/components/spinner';
export default class FaultCodeSummary extends Component {
    @service
    queryRunner;
    @service
    intl;
    get params() {
        return {
            stationIds: this.args.stationIds,
            startDate: this.args.startDate,
            endDate: this.args.endDate,
            shiftNames: this.args.shiftNames
        };
    }
    getData = task({
        drop: true
    }, waitFor(async (params1)=>{
        const oeeFaultCodeQuery1 = await this.queryRunner.queryWithParams.perform(params1, [
            {
                $match: {
                    state: 'faulted',
                    faultCodes: {
                        $exists: true
                    }
                }
            },
            {
                $unwind: {
                    path: '$faultCodes.codes'
                }
            },
            {
                $group: {
                    _id: '$faultCodes.codes.code',
                    totalTimeInState: {
                        $sum: {
                            $dateDiff: {
                                startDate: '$startDate',
                                endDate: '$endDate',
                                unit: 'millisecond'
                            }
                        }
                    },
                    doc: {
                        $first: '$$ROOT'
                    }
                }
            },
            {
                $replaceRoot: {
                    newRoot: {
                        $mergeObjects: [
                            {
                                totalTimeInState: '$totalTimeInState'
                            },
                            '$doc'
                        ]
                    }
                }
            },
            {
                $sort: {
                    totalTimeInState: 1
                }
            }
        ], true);
        const oeeFaultCodes1 = await this.queryRunner.runQuery.perform('OeeStates', oeeFaultCodeQuery1);
        return {
            chartData: this.getEchartOptions(oeeFaultCodes1),
            exportData: ()=>this.formatExportData(clone(oeeFaultCodes1))
        };
    }));
    oeeFaultCodeData = trackedTask(this, this.getData, ()=>[
            this.params,
            this.args.refresher
        ]);
    getEchartOptions(faultCodeData1) {
        return {
            title: {
                text: this.intl.t('bi.chartLabel.faultCodeSummary')
            },
            legend: {
                show: true,
                bottom: 5
            },
            tooltip: {
                trigger: 'axis',
                formatter (params1) {
                    const tooltipItems1 = params1.map(({ marker: marker1, seriesName: seriesName1, value: value1 })=>`${marker1} ${seriesName1} <span style="float:right;font-weight:bold;">${moment.duration(value1, 'milliseconds').format('d[d] h[h] m[m] s[s]', {
                            precision: 1,
                            trim: 'both mid'
                        })}</span>`).join('<br>');
                    return `<div style="min-width:200px;">${tooltipItems1}</div>`;
                }
            },
            yAxis: {
                type: 'category',
                boundaryGap: true,
                data: faultCodeData1.map(({ faultCodes: faultCodes1 })=>{
                    return `${faultCodes1.codes.code}: ${faultCodes1.codes.description}`;
                })
            },
            xAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        formatter: (value1)=>{
                            return moment.duration(value1, 'milliseconds').format('d[d] h[h] m[m] s[s]');
                        }
                    }
                }
            ],
            series: [
                {
                    name: this.intl.t('bi.chartLegend.timeFaulted'),
                    data: faultCodeData1.map((item1)=>item1.totalTimeInState),
                    label: {
                        show: true,
                        position: 'right',
                        color: echartsTheme.colorPalette.biGrey,
                        formatter: (params1)=>{
                            return moment.duration(params1.value, 'milliseconds').format('d[d] h[h] m[m] s[s]', {
                                precision: 1,
                                trim: 'both mid'
                            });
                        }
                    },
                    color: echartsTheme.colorPalette.danger,
                    type: 'bar',
                    xAxisIndex: 0
                }
            ]
        };
    }
    formatExportData(data1) {
        data1.forEach((faultCode1)=>{
            const { totalTimeInState: totalTimeInState1 } = faultCode1;
            faultCode1.totalTimeInState = moment.duration(totalTimeInState1, 'milliseconds').format('d[d] h[h] m[m] s[s]', {
                precision: 1,
                trim: 'both mid'
            });
            delete faultCode1.station;
            delete faultCode1.faultCodes;
            delete faultCode1._id;
            delete faultCode1.startDate;
            delete faultCode1.endDate;
            delete faultCode1.customButtonColor;
            delete faultCode1.button;
            delete faultCode1.scheduleHistory;
        });
        return data1;
    }
    static{
        template(`
    <div ...attributes>
      {{#if this.oeeFaultCodeData.isRunning}}
        <Spinner />
      {{else if (get this.oeeFaultCodeData.value.chartData.series '0.data.length')}}
        <div class="row h-100">
          <div class="col d-flex justify-content-between">
            <EChart
              @option={{this.oeeFaultCodeData.value.chartData}}
              @exportData={{this.oeeFaultCodeData.value.exportData}}
              @exportFilename={{t 'bi.chartLabel.faultCodeSummary'}}
              class="h-100 w-100 fault-code-summary-bar-chart"
            />
          </div>
        </div>
      {{else}}
        <BiNoDataPlaceholder />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
