import { template } from "@ember/template-compiler";
import { valuesAndLabels } from 'eflex/constants/tasks/task-types';
import { toLower, flow, map } from 'ramda';
import { sortByProp } from 'ramda-adjunct';
import PowerSelect from 'eflex/components/power-select';
import { findBy } from '@eflexsystems/ember-composable-helpers';
import { serviceHelper as service, selectVal } from 'eflex/helpers';
import { t } from 'ember-intl';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
const AddTaskButton = template(`
<div class="text-capitalize d-flex align-items-center">
  <FaIcon @icon="plus" @prefix="fas" @size="sm" class="me-2"/>
  {{t "plant.addtask"}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const getContent = (license1, intl1)=>{
    return flow(valuesAndLabels, [
        map((type1)=>({
                value: type1.value,
                disabled: license1?.[`${type1.value}Disabled`] ?? false,
                name: intl1.t(type1.label)
            })),
        sortByProp('name')
    ]);
};
export default template(`
<div class="component-task-type-select">
  {{#let
    (service 'licensing')
    (service 'intl')
  as |licensing intl|}}
    {{#let (getContent licensing.license intl) as |content|}}
      <PowerSelect
        class="task-type-select"
        @searchEnabled={{true}}
        @searchField='name'
        @placeholder={{t "selecttype"}}
        @options={{content}}
        @selected={{findBy "value" @selected content}}
        @disabled={{@disabled}}
        @onChange={{selectVal @onChange}}
        @dropdownClass="task-dropdown"
        @triggerClass={{if @buttonTrigger 'add-task btn btn-primary'}}
        @triggerComponent={{if @buttonTrigger AddTaskButton}}
        @renderInPlace={{true}}
        ...attributes
      as |type|>
        <div class="task-type-menu {{type.value}} {{if type.disabled 'disabled'}}">
          <i class="tree-icon me-2 {{toLower type.value}}-icon"></i>
          <span>
            {{type.name}}
          </span>
        </div>
      </PowerSelect>
    {{/let}}
  {{/let}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
