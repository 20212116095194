import { template } from "@ember/template-compiler";
import { sortByProp } from 'ramda-adjunct';
import { t } from 'ember-intl';
import { isPresent } from '@eflexsystems/ember-composable-helpers';
import TrashCan from 'eflex/components/trash-can';
import QuantitySelect from 'eflex/components/quantity-select';
import { fn } from '@ember/helper';
import { serviceHelper as service } from 'eflex/helpers';
import { VariableValue, VariableType } from 'eflex/components/task-variables-config';
const getVariableDefs = (trigger1)=>{
    return trigger1.variableDefs.filter((item1)=>!item1.isDeleted);
};
const getPreviousVariableDefs = (trigger1, isStationEvent1, taskRepo1)=>{
    let task1;
    if (trigger1.task != null) {
        task1 = trigger1.task;
    } else {
        task1 = sortByProp('row', trigger1.station.tasks ?? []).at(-1);
    }
    const previousTasks1 = taskRepo1.getAllPreviousTasks(task1, isStationEvent1);
    return previousTasks1?.flatMap((_task1)=>_task1.variableDefs).filter((def1)=>!def1.isDeleted && def1.isFromDevice) ?? [];
};
const addMultipleVariableDefs = (trigger1, store1, quantity1)=>{
    for(let i1 = 1, end1 = quantity1; i1 <= end1; i1++){
        trigger1.variableDefs.push(store1.createRecord('variableDef', {}));
    }
};
export default template(`
{{#let
  @triggerConfig.parentTrigger
  (getVariableDefs @triggerConfig.parentTrigger)
as |trigger variableDefs|}}
  <div class="row form-row align-nested-header pt-0">
    <div class="col">
      <div class="nested-header">
        {{t "mqttRequestTopic"}}
      </div>
      <@form.element
        class="trigger-mqtt-topic"
        @model={{trigger}}
        @controlType="text"
        @property="mqttTopic"
        @size="sm"
      />
    </div>
  </div>
  {{#if (isPresent variableDefs)}}
    <div class="row form-row align-nested-header py-0">
      <div class="col">
        <div class="nested-header">
          {{t "variableName"}}
        </div>
      </div>
      <div class="col">
        <div class="nested-header">
          {{t "dataType"}}
        </div>
      </div>
      <div class="col">
        <div class="nested-header">
          {{t "variableValue"}}
        </div>
      </div>
    </div>
    {{#let
      (getPreviousVariableDefs trigger @isStationEvent (service 'taskRepo'))
      (service 'variableDefRepo')
    as |previousVariableDefs variableDefRepo|}}
      {{#each variableDefs as |variableDef|}}
        <div class="variable-row position-relative pt-0 pb-1 row form-row">
          <div class="col">
            <@form.element
              class="variable-name"
              @controlType="text"
              @model={{variableDef}}
              @property="name"
              @size="sm"
              @disabled={{@disabled}}
            as |el|>
              <el.control disabled={{@disabled}} />
            </@form.element>
          </div>
          <div class="col">
            <VariableType
              @form={{@form}}
              @size='sm'
              @variableDef={{variableDef}}
              @variableDefConfig={{variableDef}}
            />
          </div>
          <div class="col">
            <VariableValue
              @variableDef={{variableDef}}
              @variableDefConfig={{variableDef}}
              @previousVariableDefs={{previousVariableDefs}}
              @form={{@form}}
              @size='sm'
            />
          </div>
          <div class="col-auto absolute-trash">
            <div class="col-icon">
              <TrashCan
                disabled={{@disabled}}
                @onDelete={{fn variableDefRepo.deleteVariable variableDef}}
              />
            </div>
          </div>
        </div>
      {{/each}}
    {{/let}}
  {{/if}}
  <div class="row form-row pt-0">
    <div class="col">
      <QuantitySelect
        class="text-uppercase"
        @size="sm"
        @disabled={{@disabled}}
        @onSubmit={{fn addMultipleVariableDefs trigger (service 'store')}}
      >
        {{t "components.addVariable"}}
      </QuantitySelect>
    </div>
  </div>
{{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
