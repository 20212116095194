import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { includes } from 'ramda';
import { t } from 'ember-intl';
import BsForm from 'eflex/components/bs-form';
export default class BiPanelShifts extends Component {
    @service
    scheduleRepo;
    get shifts() {
        const set1 = new Set();
        this.scheduleRepo.schedules.forEach((schedule1)=>{
            if (this.checkShiftAvailability(schedule1)) {
                set1.add(schedule1.text);
            }
        });
        return [
            ...set1
        ];
    }
    checkShiftAvailability(shift1) {
        if (new Date(shift1.startDate) > new Date(this.args.endDate) || new Date(shift1.endDate) < new Date(this.args.startDate)) {
            return false;
        }
        shift1.stations?.forEach((station1)=>{
            if (!includes(station1, this.args.stationIds)) {
                return false;
            }
        });
        return true;
    }
    static{
        template(`
    <div class="shift-select" ...attributes>
      <BsForm as |form|>
        <form.element
          class="shift-select"
          @controlType="power-select-multiple"
          @label={{t "shifts"}}
          @value={{@shiftNames}}
          @options={{this.shifts}}
          @onChange={{@onChange}}
        as |el|>
          <el.control
            @searchEnabled={{true}}
            @searchField="text"
            @placeholder={{t "allShifts"}}
          />
        </form.element>
      </BsForm>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
