import { template } from "@ember/template-compiler";
import { isEmpty } from '@ember/utils';
import RouteTemplate from 'ember-route-template';
import Breadcrumbs from 'eflex/components/breadcrumbs';
import BsForm from 'eflex/components/bs-form';
import BsButton from 'eflex/components/bs-button';
import { t } from 'ember-intl';
import { hash, fn } from '@ember/helper';
import SpinnerOverlay from 'eflex/components/spinner-overlay';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';
import { timeout, all, task } from 'ember-concurrency';
import { flow, filter, propEq, values } from 'ramda';
import { sortByProp } from 'ramda-adjunct';
import JobStatuses from 'eflex/constants/kinetic-job-statuses';
import getDelayTime from 'eflex/util/get-delay-time';
import { OUTBOX_EVENT_MAPPINGS, OUTBOX_EVENTS, INBOX_EVENT_MAPPINGS, INBOX_EVENTS } from 'eflex/constants/integration-sync-events';
import Component from '@glimmer/component';
import { isTesting, isDevelopingApp, macroCondition } from '@embroider/macros';
const EVENT_NAMES = [
    {
        groupName: 'Inbox',
        options: INBOX_EVENTS
    },
    {
        groupName: 'Outbox',
        options: OUTBOX_EVENTS
    }
];
const getFilteredPartRevs = (kineticPartRevs1, selectedPart1)=>{
    return flow(kineticPartRevs1 ?? [], [
        filter(propEq('parent', selectedPart1)),
        sortByProp('name')
    ]);
};
const getFilteredOperations = (kineticOperations1, selectedPartRev1)=>{
    return flow(kineticOperations1 ?? [], [
        filter(propEq('parent', selectedPartRev1)),
        sortByProp('sequence')
    ]);
};
const getEventData = (eventName1, kineticRepo1)=>{
    const employeeId1 = '1';
    const shift1 = 1;
    const resourceGroupId1 = 'group1';
    const resourceId1 = 'station1';
    let data1;
    switch(eventName1){
        case INBOX_EVENT_MAPPINGS.Site:
            {
                data1 = {
                    name: 'Main Plant',
                    timeZone: 'America/Detroit'
                };
                break;
            }
        case INBOX_EVENT_MAPPINGS.ReasonCode:
            {
                data1 = {
                    reasonCode: 'SCMBD',
                    description: 'Machine Breakdown',
                    reasonType: 'Scrap',
                    deleted: false
                };
                break;
            }
        case INBOX_EVENT_MAPPINGS.Station:
            {
                data1 = {
                    departmentId: 'area1',
                    departmentDescription: 'Area 1',
                    resourceGroupId: resourceGroupId1,
                    resourceGroupDescription: 'Group 1',
                    resourceId: resourceId1,
                    resourceDescription: 'Station 1',
                    deleted: false
                };
                break;
            }
        case INBOX_EVENT_MAPPINGS.Employee:
            {
                data1 = {
                    employeeId: employeeId1,
                    supervisorEmployeeId: employeeId1,
                    name: 'Charles Johnson',
                    email: 'charles@somewhere.com',
                    mobile: '0773585415',
                    status: 'Active',
                    defaultShift: shift1,
                    defaultResourceGroupId: resourceGroupId1,
                    deleted: false
                };
                break;
            }
        case INBOX_EVENT_MAPPINGS.Shift:
            {
                data1 = {
                    shift: shift1,
                    name: 'Shift A',
                    startDate: new Date(),
                    endDate: null,
                    deleted: false
                };
                break;
            }
        case INBOX_EVENT_MAPPINGS.BOM:
            {
                data1 = {
                    materials: [],
                    partNumber: 'ECPC101',
                    partDescription: 'ECPC Test 1',
                    revisionNumber: 'B',
                    revisionDescription: 'Big',
                    deleted: false,
                    operations: [
                        {
                            sequence: 0,
                            operationCode: 'AssyUnit',
                            operationCodeDescription: 'Assembly',
                            resources: [
                                {
                                    resourceGroupId: resourceGroupId1,
                                    resourceId: resourceId1
                                },
                                {
                                    resourceGroupId: resourceGroupId1,
                                    resourceId: resourceId1
                                }
                            ]
                        },
                        {
                            sequence: 1,
                            operationCode: 'Hammer',
                            operationCodeDescription: 'Hammer Time',
                            resources: [
                                {
                                    resourceGroupId: resourceGroupId1,
                                    resourceId: resourceId1
                                },
                                {
                                    resourceGroupId: resourceGroupId1,
                                    resourceId: resourceId1
                                }
                            ]
                        }
                    ]
                };
                break;
            }
        case INBOX_EVENT_MAPPINGS.IssueMaterial:
            {
                data1 = {
                    jobNumber: 'CPCTEST1',
                    partNumber: 'FLR',
                    assemblySequence: 0,
                    materialSequence: 10,
                    relatedOperation: 10,
                    requiredQuantity: 10,
                    issuedQuantity: 8,
                    issuedComplete: false,
                    quantityPer: 1,
                    issuedQuantities: [
                        {
                            partNumber: 'FLR',
                            fromWarehouseCode: 'CHI',
                            toWarehouseCode: 'PRD',
                            fromBinNum: '00-00-00',
                            toBinNum: 'ASM',
                            transactionQuantity: 8
                        }
                    ],
                    company: kineticRepo1.kinetic.companyId,
                    siteId: kineticRepo1.kinetic.siteId
                };
                break;
            }
        case OUTBOX_EVENT_MAPPINGS.ClockInOut:
            {
                data1 = {
                    employeeId: employeeId1,
                    shift: shift1,
                    clockInDateTime: new Date(),
                    clockOutDateTime: null
                };
                break;
            }
        case OUTBOX_EVENT_MAPPINGS.JobActivity:
            {
                data1 = {
                    jobNumber: 'JOB-1',
                    assemblySequence: 0,
                    operationSequence: 0,
                    resourceId: resourceId1,
                    employeeId: employeeId1,
                    activityType: 'Production'
                };
                break;
            }
        case INBOX_EVENT_MAPPINGS.Job:
            {
                data1 = {
                    jobNumber: 'CPCTEST1',
                    assemblySequence: 0,
                    parentAssemblySequence: 0,
                    assemblyPartNumber: 'COOKIE',
                    assemblyPartDescription: 'Tasty Cookie',
                    assemblyRevisionNumber: 'B',
                    serialTracked: false,
                    lotTracked: false,
                    requiredQuantity: 12,
                    jobStatus: JobStatuses.released,
                    deleted: false,
                    materials: [
                        {
                            sequence: 10,
                            partNumber: 'FLR',
                            partDescription: 'Flour',
                            relatedOperation: 10,
                            quantityPer: 0,
                            fixedQuantity: false,
                            serialTracked: false,
                            lotTracked: false
                        }
                    ],
                    operations: [
                        {
                            sequence: 10,
                            operationCode: 'Bake',
                            operationCodeDescription: 'Bake',
                            productionStandard: 0,
                            productionFormat: 'PH',
                            productionResourceGroupID: 'Ovens',
                            setupResourceGroupID: 'Ovens',
                            runQuantity: 12,
                            completedQuantity: 0,
                            resources: [
                                {
                                    resourceGroupId: 'Ovens',
                                    resourceId: 'ConvOven'
                                }
                            ]
                        }
                    ],
                    company: kineticRepo1.kinetic.companyId,
                    siteId: kineticRepo1.kinetic.siteId
                };
                break;
            }
        default:
            {
                data1 = {
                    company: kineticRepo1.kinetic.companyId,
                    siteId: kineticRepo1.kinetic.siteId
                };
                break;
            }
    }
    return JSON.stringify(data1, null, 2);
};
let IntegrationTesterTemplate;
if (macroCondition(isDevelopingApp() && !isTesting())) {
    IntegrationTesterTemplate = class extends Component {
        @service
        eflexAjax;
        @service
        notifier;
        @service
        store;
        @service
        kineticRepo;
        @service
        stationRepo;
        @tracked
        eventName;
        @tracked
        jobCount = 1;
        @tracked
        jobStatus = JobStatuses.released;
        @tracked
        assembliesPerJob = 1;
        @tracked
        requiredQuantity = 10;
        @tracked
        _selectedPart;
        @tracked
        _selectedPartRev;
        @tracked
        selectedOperations;
        @tracked
        data;
        kineticParts = this.store.peekAll('kineticPart');
        kineticPartRevs = this.store.peekAll('kineticPartRev');
        kineticOperations = this.store.peekAll('kineticOperation');
        get selectedPart() {
            return this._selectedPart;
        }
        set selectedPart(val1) {
            this._selectedPart = val1;
        }
        get selectedPartRev() {
            return this._selectedPartRev;
        }
        set selectedPartRev(val1) {
            this._selectedPartRev = val1;
        }
        _waitForLength = task(waitFor(async (checked1, expected1)=>{
            while(checked1.length !== expected1){
                await timeout(getDelayTime(50));
            }
        }));
        sendIntegrationJson = task({
            drop: true
        }, waitFor(async (type1, data1, eventName1)=>{
            let json1;
            try {
                json1 = JSON.parse(data1);
            } catch  {
                this.notifier.sendError('Invalid JSON');
                return;
            }
            await this.eflexAjax.post.perform(type1, {
                eventName: eventName1,
                data: json1
            });
        }));
        quickCreate = task({
            drop: true
        }, waitFor(async (kineticParts1, kineticPartRevs1, kineticOperations1, stations1)=>{
            const events1 = [
                INBOX_EVENT_MAPPINGS.Site,
                INBOX_EVENT_MAPPINGS.Shift,
                INBOX_EVENT_MAPPINGS.Employee,
                INBOX_EVENT_MAPPINGS.Station,
                INBOX_EVENT_MAPPINGS.BOM,
                INBOX_EVENT_MAPPINGS.JOB
            ];
            const previousPartCount1 = kineticParts1.length;
            const previousPartRevCount1 = kineticPartRevs1.length;
            const previousOperationsCount1 = kineticOperations1.length;
            for (const eventName1 of events1){
                const data1 = getEventData(eventName1, this.kineticRepo);
                await this.sendIntegrationJson.perform('integrationInboxes', data1, eventName1);
            }
            await all([
                this._waitForLength.perform(kineticParts1, previousPartCount1 + 1),
                this._waitForLength.perform(kineticPartRevs1, previousPartRevCount1 + 1),
                this._waitForLength.perform(kineticOperations1, previousOperationsCount1 + 2)
            ]);
            const part1 = kineticParts1.find((kineticPart1)=>kineticPart1.name === 'ECPC101');
            const partRev1 = part1.partRevs.find((rev1)=>rev1.name === 'B');
            const operation1 = partRev1.operations.find((op1)=>op1.name === 'AssyUnit');
            await this.createJobs.perform({
                jobCount: 1,
                jobStatus: JobStatuses.released,
                requiredQuantity: 10,
                assembliesPerJob: 1,
                partRev: partRev1,
                operations: [
                    operation1
                ],
                stations: stations1
            });
        }));
        createJobs = task({
            drop: true
        }, waitFor(async ({ jobCount: jobCount1, jobStatus: jobStatus1, requiredQuantity: requiredQuantity1, assembliesPerJob: assembliesPerJob1, partRev: partRev1, operations: operations1, stations: stations1 })=>{
            const store1 = this.store;
            const jobs1 = [];
            const assemblies1 = [];
            for(let i1 = 0; i1 < jobCount1; i1++){
                const job1 = store1.createRecord('kineticJob', {
                    jobNumber: `JOB-${i1}`,
                    jobStatus: jobStatus1
                });
                for(let j1 = 0; j1 < assembliesPerJob1; j1++){
                    const assembly1 = store1.createRecord('kineticJobAssembly', {
                        assemblySequence: j1,
                        requiredQuantity: requiredQuantity1,
                        job: job1,
                        partRev: partRev1
                    });
                    operations1.forEach((operation1, index1)=>{
                        store1.createRecord('kineticAssemblyOperation', {
                            sequence: index1,
                            runQuantity: requiredQuantity1,
                            operation: operation1,
                            assembly: assembly1,
                            stations: stations1
                        });
                    });
                    assemblies1.push(assembly1);
                }
                jobs1.push(job1);
            }
            await all(jobs1.map((job1)=>job1.save()));
            await all(assemblies1.map((assembly1)=>assembly1.save()));
        }));
        loadPart = (part1)=>{
            this.selectedPart = part1;
            this.selectedPartRev = null;
            this.selectedOperations = null;
        };
        loadPartRev = (partRev1)=>{
            this.selectedPartRev = partRev1;
            this.selectedOperations = null;
        };
        selectOperations = (operations1)=>{
            this.selectedOperations = operations1;
        };
        setEventName = (eventName1)=>{
            this.eventName = eventName1;
            this.data = getEventData(eventName1, this.kineticRepo);
        };
        static{
            template(`
      <Breadcrumbs @page="Integration Tester">
        <BsButton @onClick={{
          fn
          this.quickCreate.perform
          this.kineticParts
          this.kineticPartRevs
          this.kineticOperations
          this.stationRepo.stations
        }}>
          Quick Create
        </BsButton>
      </Breadcrumbs>

      <div class="row">
        <div class="col-sm-6">
          <BsForm @model={{this}} as |form|>
            <form.element
              @controlType="power-select"
              @label="Event Name"
              @options={{EVENT_NAMES}}
              @value={{this.eventName}}
              @onChange={{this.setEventName}}
            />
            <form.element
              @controlType="text"
              @label="Manual Event Name"
              @value={{this.eventName}}
              @onChange={{this.setEventName}}
            />
            <form.element
              @controlType="textarea"
              @label="Data"
              @property="data"
            as |elem|>
              <elem.control rows='18' />
            </form.element>
            <BsButton
              @onClick={{
                fn
                this.sendIntegrationJson.perform
                'integrationOutboxes'
                this.data
                this.eventName
              }}
            >
              Add To Outbox
            </BsButton>
            <BsButton
              @onClick={{
                fn
                this.sendIntegrationJson.perform
                'integrationInboxes'
                this.data
                this.eventName
              }}
            >
              Add To Inbox
            </BsButton>
          </BsForm>
        </div>
        <div class="col-sm-6">
          <BsForm
            @model={{this}}
            @onSubmit={{
              fn
              this.createJobs.perform
              (hash
                jobCount=this.jobCount
                jobStatus=this.jobStatus
                requiredQuantity=this.requiredQuantity
                assembliesPerJob=this.assembliesPerJob
                partRev=this.selectedPartRev
                operations=this.selectedOperations
                stations=this.stationRepo.stations
              )
            }}
          as |form|>
            <form.element
              @controlType="power-select"
              @label={{t "kinetic.part"}}
              class="kinetic-part-select"
              @options={{sortByProp 'name' this.kineticParts}}
              @optionLabelPath="name"
              @value={{this.selectedPart}}
              @onChange={{this.loadPart}}
            as |el|>
              <el.control
                @noMatchesMessage={{t "noMatch"}}
                @searchEnabled={{true}}
                @searchField="name"
                @allowClear={{true}}
                @placeholder={{t "kinetic.selectPart"}}
              />
            </form.element>
            <form.element
              @controlType="power-select"
              @label={{t "kinetic.revision"}}
              class="kinetic-part-rev-select"
              @options={{getFilteredPartRevs this.kineticPartRevs this.selectedPart}}
              @optionLabelPath="name"
              @value={{this.selectedPartRev}}
              @onChange={{this.loadPartRev}}
            as |el|>
              <el.control
                @disabled={{isEmpty this.selectedPart}}
                @noMatchesMessage={{t "noMatch"}}
                @searchEnabled={{true}}
                @searchField="name"
                @allowClear={{true}}
                @placeholder={{t "kinetic.selectRevision"}}
              />
            </form.element>
            <form.element
              @controlType="power-select-multiple"
              @label={{t "kinetic.operation"}}
              class="kinetic-operation-select"
              @options={{getFilteredOperations this.kineticOperations this.selectedPartRev}}
              @value={{this.selectedOperations}}
              @onChange={{this.selectOperations}}
            as |el|>
              <el.control
                @disabled={{isEmpty this.selectedPartRev}}
                @noMatchesMessage={{t "noMatch"}}
                @searchEnabled={{true}}
                @searchField="name"
                @allowClear={{true}}
                @placeholder={{t "kinetic.selectOperation"}}
              as |operation|>
                {{operation.sequence}} - {{operation.name}}
              </el.control>
            </form.element>
            <form.element
              @controlType="number"
              @label="Job Count"
              @property="jobCount"
            />
            <form.element
              @controlType="power-select"
              @label="Job Status"
              @options={{values JobStatuses}}
              @value={{this.jobStatus}}
              @onChange={{fn (mut this.jobStatus)}}
            />
            <form.element
              @controlType="number"
              @label="Assemblies Per Job"
              @property="assembliesPerJob"
            />
            <form.element
              @controlType="number"
              @label="Required Quantity"
              @property="requiredQuantity"
            />
            <form.submitButton @disabled={{isEmpty this.selectedOperations}}>
              Create Jobs
            </form.submitButton>
          </BsForm>
        </div>
      </div>

      {{#if this.generate.isRunning}}
        <SpinnerOverlay @message={{t "loading.wait"}} />
      {{/if}}
    `, {
                component: this,
                eval () {
                    return eval(arguments[0]);
                }
            });
        }
    };
} else {
    IntegrationTesterTemplate = template(``, {
        eval () {
            return eval(arguments[0]);
        }
    });
}
export default RouteTemplate(IntegrationTesterTemplate);
