import Controller from '@ember/controller';
import { action } from '@ember/object';
import { isBlank, isEmpty, isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';
import { JEM_VIEW_MODES } from 'eflex/constants/jem';
import TaskStatuses from 'eflex/constants/task-statuses';
import { taskTypes } from 'eflex/constants/tasks/task-types';
import { NotificationLogTypes } from 'eflex/constants/notification-log';
import { service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { sortByProps } from 'ramda-adjunct';
import { pipe, find, prop } from 'ramda';
import { dedupeTracked } from 'tracked-toolbox';
import { usesBomSourceLookup } from 'eflex/constants/station-options';
import { canRetryStopReject } from 'eflex/util/jem-task-state';
import { updateFromWebSocket, deleteFromWebSocket } from 'eflex/util/websocket-helpers';

const POPOVER_ERROR_KEYS = new Set([
  'badModel',
  'noBuildDatumFound',
  'buildDatumMissingConfig',
]);

const MAX_SIZE_OF_CONFIG_TABS = 4;

const CHANGELOG_DISPLAYED_TYPES = new Set([
  NotificationLogTypes.JEM,
  NotificationLogTypes.WIE,
]);

const RESET_INSTRUCTION_TYPES_ON_FINISH = new Set([
  taskTypes.imageCapture,
  taskTypes.vision,
  'station',
]);

const IMAGE_TASK_TYPES = new Set([taskTypes.imageCapture, taskTypes.vision]);

const validateSerialNumber = (serialNumber, station) =>
  !station.jemLoadRegexIsAllowed ||
  isBlank(station.jemLoadRegex) ||
  new RegExp(station.jemLoadRegex).test(serialNumber);

const anyCustomIdentifiersEmpty = (buildStatus) => {
  return buildStatus?.customIdentifierData.some((datum) => isEmpty(datum.value)) ?? false;
};

export default class JemStationsController extends Controller {
  queryParams = ['instructions'];

  @service currentUser;
  @service eflexAjax;
  @service intl;
  @service jemRepo;
  @service notifier;
  @service stationRepo;
  @service store;
  @service taskRepo;
  @service audioPlayer;
  @service logRepo;
  @service productionScheduleRepo;
  @service kineticEmployeeRepo;

  @tracked station;
  @dedupeTracked instructions = 'task';
  @tracked viewMode = JEM_VIEW_MODES.taskList;
  @tracked tempSerialNumber = '';
  @tracked showTabConfigModal = false;
  @tracked showRejectTaskModal = false;
  @tracked showRepairTaskModal = false;
  @tracked showNotesModal = false;
  @tracked showEmployeeLoginModal = false;
  @tracked showEmployeeClockOutModal = false;
  @tracked showWorkQueueModal = false;
  @tracked showMaterialsModal = false;
  @tracked waitingForData = false;
  @tracked bomSourceLookupValue;
  @tracked configJemTabModalTitle;
  @tracked currentTab;
  @tracked currentEmployee;
  @tracked rejectArgs;
  @tracked repairArgs;
  @tracked totalLogs;
  @tracked showInvalidPopover = false;
  @tracked _selectedJob;
  @tracked _selectedAssembly;
  @tracked _kineticAssemblyOperation;
  @tracked _model;
  @tracked _buildStatus;
  @tracked _currentTask;

  @cached
  get currentRunningTimeInSeconds() {
    const buildStatus = this.buildStatus;

    if (buildStatus == null) {
      return 0;
    }

    const currentRunningTime = buildStatus.isStarted ? buildStatus.elapsedTime : buildStatus.cycleTime;

    return currentRunningTime / 1000;
  }

  get currentJemConfig() {
    if (this.buildStatus == null) {
      return null;
    }

    const station = this.station;
    const usesModels = this.station.usesModels;
    const model = this.model;

    if (usesModels && !model) {
      return null;
    }

    if (usesModels) {
      return station.stationJemConfigurations.find(config => config.model === model);
    } else {
      return station.stationJemConfigurations[0];
    }
  }

  get selectedJob() {
    return this._selectedJob ?? this.kineticAssemblyOperation?.job;
  }

  set selectedJob(val) {
    this._selectedJob = val;
  }

  get selectedAssembly() {
    return this._selectedAssembly ?? this.kineticAssemblyOperation?.assembly;
  }

  set selectedAssembly(val) {
    this._selectedAssembly = val;
  }

  get kineticOperation() {
    return this.kineticAssemblyOperation?.operation;
  }

  @cached
  get kineticAssemblyOperation() {
    if (!this.isOperation) {
      return null;
    }

    return this.buildStatus?.kineticAssemblyOperationRecord ?? this._kineticAssemblyOperation;
  }

  set kineticAssemblyOperation(val) {
    if (this._kineticAssemblyOperation !== val) {
      this._kineticAssemblyOperation = val;
    }
  }

  get isOperation() {
    return this.station.usesOperations;
  }

  get buildStatus() {
    return this._buildStatus;
  }

  set buildStatus(val) {
    const previousBuildStatus = this._buildStatus;

    if (previousBuildStatus === val) {
      return;
    }

    this._buildStatus = val;
    if (previousBuildStatus && !previousBuildStatus.isDestroyed) {
      previousBuildStatus?.unloadRecord();
    }
  }

  @cached
  get model() {
    if (!this.station.usesModels) {
      return null;
    }

    if (this.station.productionScheduleEnabled) {
      return this.productionSchedule?.model;
    }

    return this.buildStatus?.modelRecord ?? this._model;
  }

  set model(val) {
    if (this._model !== val) {
      this._model = val;
    }
  }

  @cached
  get firstActiveTask() {
    const children = this.buildStatus?.children;

    if (isEmpty(children)) {
      return null;
    }

    return pipe(
      sortByProps(['row', 'column']),
      find(item => item.isStarted || canRetryStopReject(item.task, item)),
      prop('task'),
    )(children);
  }

  @cached
  get currentTaskIsRunning() {
    const currentTask = this.currentTask;

    if (!currentTask) {
      return false;
    }

    return currentTask === this.firstActiveTask;
  }

  @cached
  get currentTask() {
    const currentTask = this._currentTask;

    if (this.stationIsRunning && !currentTask) {
      return this.firstActiveTask;
    }

    return currentTask;
  }

  set currentTask(val) {
    if (this._currentTask !== val) {
      this._currentTask = val;
    }
  }

  get isListView() {
    return this.viewMode === JEM_VIEW_MODES.taskList;
  }

  get isSingleView() {
    return this.viewMode === JEM_VIEW_MODES.singleTask;
  }

  get authorized() {
    if (this.isOperation && !this.currentEmployee) {
      return false;
    }

    return this.currentUser.user.hasAuthorization(this.station.jemAuthorizedTags);
  }

  get notAuthorized() {
    return !this.authorized;
  }

  get area() {
    return this.station.area;
  }

  get taskRows() {
    let tasks;
    if (this.isOperation) {
      tasks = this.kineticOperation?.tasks ?? [];
    } else {
      tasks = this.station.tasks;
    }

    return sortByProps(['row', 'column'], tasks);
  }

  @cached
  get stationIsRunning() {
    const isStarted = this.buildStatus?.isStarted ?? false;
    return isStarted && !this.waitingForData;
  }

  get logsToAcknowledge() {
    return this.logRepo.logs.filter(log =>
      CHANGELOG_DISPLAYED_TYPES.has(log.logType) &&
      this.currentUser.user.createdAt <= log.timestamp &&
      !log.acknowledgedBy.some(item => item.userName === this.currentUser.user.userName),
    );
  }

  @cached
  get productionSchedule() {
    return this.jemRepo.getProductionScheduleForStation(this.station);
  }

  @cached
  get currentChildStatus() {
    return this.buildStatus?.getChildStatusForTask(this.currentTask);
  }

  get buildStatusClass() {
    if (!this.buildStatus) {
      return null;
    }

    const { status, isStarted } = this.buildStatus;

    if (this.buildStatus.status === TaskStatuses.UNKNOWN || isStarted) {
      return 'part-value-active';
    } else if (TaskStatuses.isRejected(status)) {
      return 'part-value-failed';
    } else {
      return 'part-value-passed';
    }
  }

  @cached
  get currentTaskConfig() {
    const currentTask = this.currentTask;

    if (!currentTask) {
      return null;
    }

    return this.taskRepo.getConfigForModelOrBuildDatum(
      currentTask,
      this.model,
      this.buildStatus?.buildDatum,
    );
  }

  onSubmitSerialNumber = task({ drop: true }, waitFor(async serialNumber => {
    if (!this.authorized) {
      return;
    }

    let liveBuildStatus;
    try {
      liveBuildStatus = await this.jemRepo.createLiveBuildStatus.perform(
        serialNumber,
        this.station,
        this.bomSourceLookupValue,
        this.model,
        this.kineticAssemblyOperation,
        this.currentEmployee,
      );
    } catch (e) {
      if (this.station.usesOperations) {
        this.notifier.sendError(`${this.intl.t('kinetic.startActivityErrorMessage')}:\n${e}`);
      } else {
        this.notifier.sendError(e);
      }
    }

    if (!liveBuildStatus) {
      return;
    }

    this.bomSourceLookupValue = null;

    if (isPresent(this.tempSerialNumber)) {
      this.tempSerialNumber = '';
    }

    if (!this.waitingForData && anyCustomIdentifiersEmpty(liveBuildStatus)) {
      this.buildStatus = liveBuildStatus;
      this.waitingForData = true;
      return;
    }

    liveBuildStatus = await this.onSaveNewBuildStatus.perform(liveBuildStatus);
    if (liveBuildStatus) {
      this.buildStatus = liveBuildStatus;
    }
  }));

  setStatusAndSave = task({ drop: true }, waitFor(async (status, codes) => {
    if (this.waitingForData) {
      this.clearBuildStatus();
      this.waitingForData = false;
      return;
    }

    if (status === TaskStatuses.REJECTED && codes) {
      this.buildStatus.rejectCodes = codes;
    }

    if (status === TaskStatuses.SCRAPPED && codes) {
      this.buildStatus.scrapCodes = codes;
    }

    this.buildStatus.status = status;
    await this.jemRepo.saveLiveStatus.perform(this.buildStatus);
  }));

  onTaskComplete = task({ drop: true }, waitFor(async (taskStatus, childStatus) => {
    if (!childStatus || childStatus.isHolding) {
      return;
    }

    if (taskStatus === TaskStatuses.REJECTED && this.station.confirmRejects) {
      Object.assign(this, {
        showRejectTaskModal: true,
        rejectArgs: [taskStatus, childStatus],
      });

      return;
    }

    if (taskStatus === TaskStatuses.GOOD && childStatus.task.confirmRepair) {
      this.onRepair(taskStatus, childStatus);
      return;
    }

    await this.jemRepo.completeTask.perform(taskStatus, childStatus);
  }));

  onHold = task(waitFor(async (buildStatus, taskConfig) => {
    await this.jemRepo.toggleHold.perform(buildStatus, taskConfig);
  }));

  onSerialNumberScanned = task(waitFor(async serialNumber => {
    if (usesBomSourceLookup(this.station) && isEmpty(this.bomSourceLookupValue)) {
      this.bomSourceLookupValue = serialNumber;
      return;
    }

    this.tempSerialNumber = this.tempSerialNumber + serialNumber;

    if (validateSerialNumber(this.tempSerialNumber, this.station)) {
      await this.onSubmitSerialNumber.perform(this.tempSerialNumber);
    }
  }));

  @action
  onSerialNumberInput(val) {
    this.tempSerialNumber = val;
  }

  onSaveNewBuildStatus = task({ drop: true }, waitFor(async (liveBuildStatus) => {
    if (anyCustomIdentifiersEmpty(liveBuildStatus)) {
      return liveBuildStatus;
    }

    if (this.waitingForData) {
      this.waitingForData = false;
    }

    try {
      await this.jemRepo.saveLiveStatus.perform(liveBuildStatus);
      return liveBuildStatus;
    } catch (error) {
      if (error.isValidationError && error.message != null) {
        if (POPOVER_ERROR_KEYS.has(error.message)) {
          this.showInvalidPopover = true;
        } else {
          this.notifier.sendError(error);
        }
      } else {
        console.error(error);
      }

      this.buildStatus = null;
      return null;
    }
  }));

  onDeleteTab = task({ drop: true }, waitFor(async jemConfig => {
    jemConfig?.deleteRecord();
    await this.station.save();
    this.instructions = 'task';
  }));

  onSetSingleTaskView = task(waitFor(async () => {
    await this._changeViewMode.perform(JEM_VIEW_MODES.singleTask);
  }));

  onSetTaskListView = task(waitFor(async () => {
    await this._changeViewMode.perform(JEM_VIEW_MODES.taskList);
  }));

  _changeViewMode = task(waitFor(async viewMode => {
    this.viewMode = viewMode;

    // Make sure we have updated timer information
    const buildStatuses = await this.store.query('liveBuildStatus', {
      station: this.station.id,
      limit: 1,
    });

    await this.onBuildStatusReceived.perform(buildStatuses[0]);
  }));

  onConfirmTaskRejectModal = task(waitFor(async codesFragment => {
    const currentChildStatus = this.currentChildStatus;

    if (!currentChildStatus) {
      this.onCloseRejectModal();
      return;
    }

    const args = this.rejectArgs;
    currentChildStatus.rejectCodes = codesFragment;
    await this.jemRepo.completeTask.perform(...args);
    this.onCloseRejectModal();
  }));

  onConfirmTaskRepairModal = task(waitFor(async codesFragment => {
    const currentChildStatus = this.currentChildStatus;

    if (!currentChildStatus) {
      this.onCloseRepairmodal();
      return;
    }

    const args = this.repairArgs;
    currentChildStatus.repair = codesFragment;
    await this.jemRepo.completeTask.perform(...args, this.currentTaskConfig);
    this.onCloseRepairModal();
  }));

  onAcknowledgeChangeNotifications = task(waitFor(async () => {
    await this.eflexAjax.post.perform('logs/acknowledgeMany', {
      acknowledgedBy: this.currentUser.user.toFragment(),
      afterDate: this.currentUser.user.createdAt,
      logTypes: [NotificationLogTypes.JEM, NotificationLogTypes.WIE],
      locationId: this.station.id,
    });

    this.logsToAcknowledge.forEach(logsToAcknowledge => { logsToAcknowledge.unloadRecord(); });
  }));

  onSaveSelectedVariable = task(waitFor(async variable => {
    this.area.jemDisplayVariable = variable;
    await this.area.save();
  }));

  onAudioTrigger = task(waitFor(async ({ locationType, audioType, toPlay }) => {
    if (locationType !== 'task' && locationType !== 'station') {
      return;
    }

    await this.audioPlayer.play.perform(audioType, toPlay);
  }));

  @action
  onJemNotificationLog({ logs }) {
    this.store.pushPayload({ logs });
  }

  onBuildStatusReceived = task(waitFor(async buildStatus => {
    if (
      this.station.productionScheduleEnabled &&
      (this.productionSchedule == null || this.productionSchedule.isCompleted)
    ) {
      await this.productionScheduleRepo.getCurrent(this.station);
    }

    if (buildStatus?.status === TaskStatuses.STOPPED) {
      this.clearBuildStatus();
      return;
    }

    this.kineticAssemblyOperation = buildStatus?.kineticAssemblyOperationRecord;

    await this.jemRepo.loadTaskConfigs.perform(this.station, this.kineticOperation ?? buildStatus?.modelRecord);
    this.buildStatus = buildStatus;

    if (buildStatus?.isStarted) {
      this.currentTask = null;
    }

    this.setInstructionTab();
  }));

  onBuildStatusWebsocket = task(waitFor(async status => {
    const buildStatus = await this.jemRepo.pushFromWebSocket.perform(status);
    await this.onBuildStatusReceived.perform(buildStatus);
  }));

  fetchLogs = task(waitFor(async (pagination = {}) => {
    const logs = await this.logRepo.fetchLogsForJem.perform({
      stationId: this.station.id,
      ...pagination,
    });
    this.totalLogs = logs.meta?.count ?? 0;
  }));

  setInstructionTab() {
    if (!this.stationIsRunning) {
      if (this.station.isWebCamScan) {
        this.onSetInstructionType('imageCapture');
      } else if (RESET_INSTRUCTION_TYPES_ON_FINISH.has(this.instructions)) {
        this.onSetInstructionType('task');
      }
      return;
    }

    const childStatus = this.currentChildStatus;
    if (childStatus?.needsAuth) {
      this.onSetInstructionType('authorize');
    } else if (childStatus?.task?.usesWebCam) {
      this.onSetInstructionType('imageCapture');
    } else if (IMAGE_TASK_TYPES.has(childStatus?.taskType)) {
      this.onSetInstructionType(childStatus.taskType);
    } else {
      this.onSetInstructionType('task');
    }
  }

  clearBuildStatus() {
    this.buildStatus = null;
    this.kineticAssemblyOperation = null;
    this.setInstructionTab();
  }

  #onShowTabConfigModal({ title, currentTab }) {
    Object.assign(this, {
      currentTab,
      showTabConfigModal: true,
      configJemTabModalTitle: title,
    });
  }

  onModelSelected = task(waitFor(async model => {
    this.clearBuildStatus();
    await this.jemRepo.loadTaskConfigs.perform(this.station, model);
    this.model = model;
  }));

  onOperationSelected = task(waitFor(async (kineticAssemblyOperation = null) => {
    this.showWorkQueueModal = false;
    this.clearBuildStatus();
    this.kineticAssemblyOperation = kineticAssemblyOperation;
    if (kineticAssemblyOperation) {
      await this.jemRepo.loadTaskConfigs.perform(this.station, kineticAssemblyOperation.operation);
    }
  }));

  employeeClockIn = task(waitFor(async (employee, shift) => {
    try {
      await this.kineticEmployeeRepo.clockIn.perform(employee, shift);

      Object.assign(this, {
        showEmployeeLoginModal: false,
        currentEmployee: employee,
      });
    } catch (error) {
      this.notifier.sendError(`${this.intl.t('kinetic.clockInErrorMessage')}:\n ${error}`);
    }
  }));

  employeeClockOut = task(waitFor(async () => {
    try {
      await this.kineticEmployeeRepo.clockOut.perform(this.currentEmployee);

      Object.assign(this, {
        showEmployeeClockOutModal: false,
        currentEmployee: null,
      });
    } catch (error) {
      this.notifier.sendError(`${this.intl.t('kinetic.clockOutErrorMessage')}:\n ${error}`);
    }
  }));

  onEndActivity = task({ drop: true }, waitFor(async (notes, complete) => {
    try {
      await this.eflexAjax.post.perform('kinetic/endJobActivity', {
        employeeId: this.currentEmployee.employeeId,
        jobNumber: this.kineticAssemblyOperation.assembly.job.jobNumber,
        assemblySequence: this.kineticAssemblyOperation.assembly.assemblySequence,
        operationSequence: this.kineticAssemblyOperation.sequence,
        jobActivityData: {
          assemblyOperationId: this.kineticAssemblyOperation.id,
          employeeId: this.currentEmployee.employeeId,
          resourceId: this.station.resourceId,
          notes,
          complete,
          isEnd: true,
        },
      });

      this.selectedJob = null;
      this.selectedAssembly = null;
      await this.onOperationSelected.perform();
    } catch (error) {
      this.notifier.sendError(`${this.intl.t('kinetic.endActivityErrorMessage')}\n ${error}`);
    }
  }));

  @action
  onRepair(taskStatus, childStatus, scannedValue) {
    Object.assign(this, {
      showRepairTaskModal: true,
      repairArgs: [taskStatus, childStatus, scannedValue],
    });
  }

  @action
  onSetInstructionType(instructionType) {
    if (this.currentChildStatus?.needsAuth && instructionType !== 'authorize') {
      return;
    }

    this.instructions = instructionType;
  }

  @action
  onAddTab() {
    if (this.station.configuredJemTabs.length >= MAX_SIZE_OF_CONFIG_TABS) {
      this.notifier.sendWarning('jem.maxConfigTabs');
      return;
    }

    this.#onShowTabConfigModal({
      title: this.intl.t('title.addATab'),
      currentTab: this.stationRepo.createJemConfigTab(this.station),
    });
  }

  @action
  onConfigureTab(jemConfig) {
    this.#onShowTabConfigModal({
      title: this.intl.t('jem.tabConfiguration'),
      currentTab: jemConfig,
    });
  }

  @action
  closeTabConfigModal(tab) {
    Object.assign(this, {
      currentTab: null,
      showTabConfigModal: false,
    });

    if (tab != null) {
      this.instructions = tab.id;
    }
  }

  @action
  onCloseRejectModal() {
    Object.assign(this, {
      showRejectTaskModal: false,
      rejectArgs: null,
    });
  }

  @action
  onCloseRepairModal() {
    Object.assign(this, {
      showRepairTaskModal: false,
      repairArgs: null,
    });
  }

  onModelScanned = task(waitFor(async modelCode => {
    const matchingModel = this.area.advancedModelCodeEnabled
      ? this.area.models.find((model) => new RegExp(model.code).test(modelCode))
      : this.area.models.find(item => item.code === modelCode);

    if (matchingModel != null) {
      await this.onModelSelected.perform(matchingModel);
      this.showInvalidPopover = false;
    } else {
      this.showInvalidPopover = true;
    }
  }));

  @action
  onSelectTask(treeTask) {
    if (this.station.jemNotViewFutureTasks && this.currentUser.isNotAdmin) {
      return;
    }

    this.currentTask = treeTask;
    this.setInstructionTab();
  }

  @action
  onTriggerError(msg) {
    this.notifier.jemTriggerError(msg.error);
  }

  @action
  onPrereqsNotMet() {
    this.notifier.sendError('stationPrereqsNotMet');
  }

  @action
  onEmployeeLogin(employee) {
    Object.assign(this, {
      currentEmployee: employee,
      showEmployeeLoginModal: false,
    });
  }

  @action
  onJobSelected(job) {
    this.selectedJob = job;
    this.selectedAssembly = null;
    this.onOperationSelected.perform();
  }

  @action
  onAssemblySelected(assembly) {
    this.selectedAssembly = assembly;
    this.onOperationSelected.perform();
  }

  @action
  onUpdatedKineticJob(job) {
    updateFromWebSocket('kineticJob', this.store, job);
  }

  onDeletedKineticJob = task({ drop: true }, waitFor(async (job) => {
    this.notifier.sendError('kinetic.job.jobDeleted');
    await this.setStatusAndSave.perform(TaskStatuses.REJECTED);
    Object.assign(this, {
      buildStatus: null,
      selectedJob: null,
      selectedAssembly: null,
      kineticAssemblyOperation: null,
    });

    deleteFromWebSocket('kineticJob', this.store, job.id);
  }));

  @action
  onUpdatedKineticJobAssembly(jobAssembly) {
    updateFromWebSocket('kineticJobAssembly', this.store, jobAssembly);
  }

  @action
  onUpdatedKineticIssuedMaterial(issuedMaterial) {
    updateFromWebSocket('kineticIssuedMaterial', this.store, issuedMaterial);
  }
}
