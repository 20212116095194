import { template } from "@ember/template-compiler";
import { hash } from '@ember/helper';
const BlankTemplate = template(`
<div ...attributes>
  {{yield}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template(`
  {{yield
    (hash
      taskDetails=BlankTemplate
    )
  }}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
