import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import SearchTag from 'eflex/components/criteria-visor/search-tag';
import FilterWrapper from 'eflex/components/criteria-visor/filter-wrapper';
const IMAGE_TYPE_OPTIONS = [
    {
        label: 'JPG',
        value: 'jpg'
    },
    {
        label: 'BMP',
        value: 'bmp'
    },
    {
        label: 'PDF',
        value: 'pdf'
    },
    {
        label: 'SVG',
        value: 'svg'
    },
    {
        label: 'PNG',
        value: 'png'
    }
];
export default class CriteriaVisorImageType extends Component {
    @tracked
    isDisplayed = Boolean(this.args.imageType);
    get breadcrumb() {
        return IMAGE_TYPE_OPTIONS.find((opt1)=>opt1.value === this.args.imageType)?.label;
    }
    constructor(){
        super(...arguments);
        this.args.initParam('imageType', this.args.imageType);
    }
    onDisplayedChanged = (isDisplayed1)=>{
        this.args.updateParam('imageType', null);
        this.isDisplayed = isDisplayed1;
    };
    onImageTypeSelection = (imageType1)=>{
        this.args.updateParam('imageType', imageType1?.value);
    };
    static{
        template(`
    <SearchTag class='imageType' @isDisplayed={{this.isDisplayed}} @text={{this.breadcrumb}} />

    <FilterWrapper
      class="eflex-image-criteria"
      @form={{@form}}
      @label="controls.criteria.imageType"
      @isDisplayed={{this.isDisplayed}}
      @onChecked={{this.onDisplayedChanged}}
      ...attributes
    >
      <@form.element
        @controlType="power-select"
        @options={{IMAGE_TYPE_OPTIONS}}
        @optionLabelPath="label"
        @value={{@imageType}}
        @onChange={{this.onImageTypeSelection}}
      as |el|>
        <el.control
          class="image-type-dropdown"
          @searchEnabled={{true}}
          @searchField="label"
        />
      </@form.element>
    </FilterWrapper>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
