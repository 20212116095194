import { template } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { inputVal } from 'eflex/helpers';
export default template(`
<div class="link-input-wrapper" ...attributes>
  <input
    class="form-control link-input"
    type="url"
    value={{@link}}
    disabled={{@disabled}}
    placeholder="https://"
    {{on "input" (inputVal @updateLink)}}
  />
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
