import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import { sortBy } from '@eflexsystems/ember-composable-helpers';
import { add } from 'ramda';
export default template(`
<div class="row form-row">
  <div class="col-6">
    <@form.element
      @controlType="text"
      @label={{t "macAddress"}}
      @property="macAddress"
      class="hardware-mac is-required"
    />
  </div>
</div>
<div class="bootstrap-table border-0">
  <div class="row form-row no-hover-row fs-4 text-muted pt-2 pb-0">
    <div class="col-auto">
      <div class="width-rem-1"></div>
    </div>
    <div class="col">
      {{t "input"}}
    </div>
    <div class="col">
      {{t "output"}}
    </div>
  </div>
  {{#each (sortBy "index" @hardware.hardwareIos) as |io|}}
    <div class="row form-row io-row">
      <div class="col-auto">
        <label class="width-rem-1 fs-4 text-muted">
          {{add 1 io.index}}
        </label>
      </div>
      <div class="col">
        <@form.element
          @controlType="text"
          @model={{io}}
          @property="inputName"
          class="io-input-name"
        />
      </div>
      <div class="col">
        <@form.element
          @controlType="text"
          @model={{io}}
          @property="outputName"
          class="io-output-name"
        />
      </div>
    </div>
  {{/each}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
