import { template } from "@ember/template-compiler";
import { default as OrigPowerSelect } from 'ember-power-select/components/power-select';
import { withDefault } from '@eflexsystems/ember-composable-helpers';
export default class PowerSelect extends OrigPowerSelect {
    get title() {
        if (this.args.title) {
            return this.args.title;
        }
        if (!this.args.selected) {
            return null;
        }
        if (this.args.searchField) {
            return this.args.selected[this.args.searchField];
        } else if (this.args.selected.name) {
            return this.args.selected.name;
        } else {
            return this.args.selected;
        }
    }
    static{
        template(`
    <div class="ember-power-select" ...attributes>
      <OrigPowerSelect
        @afterOptionsComponent={{@afterOptionsComponent}}
        @allowClear={{@allowClear}}
        @animationEnabled={{@animationEnabled}}
        @ariaDescribedBy={{@ariaDescribedBy}}
        @ariaInvalid={{@ariaInvalid}}
        @ariaLabel={{@ariaLabel}}
        @ariaLabelledBy={{@ariaLabelledBy}}
        @beforeOptionsComponent={{@beforeOptionsComponent}}
        @buildSelection={{@buildSelection}}
        @calculatePosition={{@calculatePosition}}
        @closeOnSelect={{@closeOnSelect}}
        @contentComponent={{@ebdContentComponent}}
        @defaultHighlighted={{@defaultHighlighted}}
        @destination={{@destination}}
        @disabled={{@disabled}}
        @dropdownClass={{@dropdownClass}}
        @eventType={{@eventType}}
        @extra={{@extra}}
        @groupComponent={{@groupComponent}}
        @highlightOnHover={{@highlightOnHover}}
        @horizontalPosition={{@horizontalPosition}}
        @initiallyOpened={{@initiallyOpened}}
        @loadingMessage={{@loadingMessage}}
        @matchTriggerWidth={{withDefault @matchTriggerWidth false}}
        @matcher={{@matcher}}
        @noMatchesComponent={{@noMatchesComponent}}
        @noMatchesMessage={{@noMatchesMessage}}
        @onBlur={{@onBlur}}
        @onChange={{@onChange}}
        @onClose={{@onClose}}
        @onFocus={{@onFocus}}
        @onInput={{@onInput}}
        @onKeydown={{@onKeydown}}
        @onOpen={{@onOpen}}
        @options={{@options}}
        @optionsComponent={{@optionsComponent}}
        @placeholder={{@placeholder}}
        @placeholderComponent={{@placeholderComponent}}
        @preventScroll={{@preventScroll}}
        @registerAPI={{@registerAPI}}
        @renderInPlace={{@renderInPlace}}
        @required={{@required}}
        @resultCountMessage={{@resultCountMessage}}
        @scrollTo={{@scrollTo}}
        @search={{@search}}
        @searchEnabled={{@searchEnabled}}
        @searchField={{@searchField}}
        @searchMessage={{@searchMessage}}
        @searchMessageComponent={{@searchMessageComponent}}
        @searchPlaceholder={{@searchPlaceholder}}
        @selected={{@selected}}
        @selectedItemComponent={{@selectedItemComponent}}
        @tabIndex={{@tabIndex}}
        @title={{this.title}}
        @triggerClass={{@triggerClass}}
        @triggerComponent={{@triggerComponent}}
        @ebdTriggerComponent={{@ebdTriggerComponent}}
        @triggerId={{@triggerId}}
        @triggerRole={{@triggerRole}}
        @typeAheadOptionMatcher={{@typeAheadOptionMatcher}}
        @verticalPosition={{@verticalPosition}}
      as |option select|>
        {{yield option select}}
      </OrigPowerSelect>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
