import { template } from "@ember/template-compiler";
import StandardModal from 'eflex/components/standard-modal';
import BsForm from 'eflex/components/bs-form';
import { t } from 'ember-intl';
import { conditionalAutofocus } from 'eflex/modifiers';
import JemInstructionsConfiguration from 'eflex/components/jem/instructions/configuration';
import { fn } from '@ember/helper';
const cancel = (station1, closeModal1)=>{
    station1.rollbackAttributes();
    closeModal1();
};
const save = async (tab1, station1, closeModal1)=>{
    if (tab1.isInvalid) {
        return;
    }
    await station1.save();
    closeModal1(tab1);
};
export default template(`
<StandardModal
  class="jem-tab-config-modal jem"
  @size="lg"
  @onConfirm={{fn save @tab @station @closeModal}}
  @onCancel={{fn cancel @station @closeModal}}
  ...attributes
as |modal|>
  <modal.header @text={{@title}} />
  <modal.body>
    <BsForm @model={{@tab}} as |form|>
      <form.element
        @label={{t "name"}}
        @property="title"
        {{conditionalAutofocus @tab.isNew}}
      as |el|>
        <el.control class="config-tab-name" />
      </form.element>
    </BsForm>
    <div class="eflex-scroll row w-auto mt-3">
      <div class="col">
        <JemInstructionsConfiguration
          @jemConfiguration={{@tab}}
          @useEvent={{false}}
          @showCopyToAll={{false}}
        />
      </div>
    </div>
  </modal.body>

  <modal.footer/>
</StandardModal>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
