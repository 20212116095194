import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import { didCancel, task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import SpinnerOverlay from 'eflex/components/spinner-overlay';
import StandardModal from 'eflex/components/standard-modal';
import { t } from 'ember-intl';
import { withDefault } from '@eflexsystems/ember-composable-helpers';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import { beforeUnload, set } from 'eflex/helpers';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import BsButton from 'eflex/components/bs-button';
import Portal from 'ember-stargate/components/portal';
import { or } from 'ember-truth-helpers';
import { dedupeTracked } from 'tracked-toolbox';
export default class SaveButtons extends Component {
    @service
    notifier;
    @service
    currentUser;
    @service
    intl;
    @service
    router;
    #transition;
    @tracked
    showTransitionModal = false;
    @dedupeTracked
    showValidations = null;
    get exitmsg() {
        return this.intl.t('application.unsavedChanges');
    }
    get showDiscard() {
        return this.saveTask.isIdle && this.args.isDirty;
    }
    saveTask = task({
        drop: true
    }, waitFor(async ()=>{
        try {
            await this.args.save();
            if (this.args.isInvalid) {
                this.showValidations = true;
            } else {
                this.showValidations = null;
            }
        } catch (error1) {
            if (didCancel(error1)) {
                return;
            }
            console.error(error1);
            this.notifier.sendError('errors.saving');
        }
    }));
    transitionModalSave = task({
        drop: true
    }, waitFor(async ()=>{
        await this.saveTask.perform();
        if (this.#transition == null) {
            this.showTransitionModal = false;
            return;
        }
        if (this.args.isInvalid) {
            try {
                await this.#transition.abort();
            } catch (error1) {
                if (!didCancel(error1) && error1.name !== 'TransitionAborted') {
                    throw error1;
                }
            } finally{
                this.showTransitionModal = false;
            }
            return;
        }
        this.showTransitionModal = false;
        this.#transition.retry();
    }));
    constructor(){
        super(...arguments);
        this.router.on('routeWillChange', this.#willChangeRoute);
    }
    willDestroy() {
        super.willDestroy(...arguments);
        this.#transition = null;
        this.router.off('routeWillChange', this.#willChangeRoute);
    }
    transitionModalDiscard = ()=>{
        this.showTransitionModal = false;
        this.args.rollback(true);
        this.#transition?.retry();
    };
    onBeforeUnload = (event1)=>{
        event1.preventDefault();
        event1.returnValue = this.exitmsg;
    };
    onRollback = ()=>{
        this.showValidations = null;
        this.args.rollback();
    };
    onHideTransitionModal = (element1, [value1])=>{
        if (value1 !== true) {
            return;
        }
        this.showTransitionModal = false;
    };
    #willChangeRoute = (transition1)=>{
        if (transition1.isAborted || transition1.queryParamsOnly || transition1.from == null) {
            return;
        }
        if (this.args?.isDirty) {
            this.#transition = transition1;
            this.showTransitionModal = true;
            transition1.abort();
        }
    };
    static{
        template(`
    {{beforeUnload
      isDirty=@isDirty
      onBeforeUnload=this.onBeforeUnload
    }}

    {{#if (has-block 'buttons')}}
      {{yield this.currentUser.isAdmin this.showDiscard this.saveTask this.showValidations to='buttons'}}
    {{else}}
      {{#if (or this.currentUser.isAdmin @allowUserSave)}}
        <Portal @target='save-buttons'>
          {{#if this.showDiscard}}
            <span class="text-gray-600 small fw-semibold me-2">
              {{t "save.unsavedChanges"}}
            </span>
            <BsButton
              @disabled={{or this.saveTask.isRunning @disabled}}
              @type="secondary"
              @size="sm"
              class="btn-screen-discard me-1"
              @onClick={{this.onRollback}}
            >
              <FaIcon @icon="eraser" @prefix="fas" @size="sm" class="me-1" /> {{t "discard"}}
            </BsButton>
          {{/if}}

          <BsButton
            @disabled={{@disabled}}
            @type={{if @isDirty "success" "secondary"}}
            @size="sm"
            class="btn-screen-save {{if @isDirty "is-dirty" "is-clean"}}"
            @onClick={{this.saveTask.perform}}
          >
            <FaIcon @icon="floppy-disk" @prefix="fas" @size="sm" class="fa-floppy-disk-o me-1" /> {{t "save"}}
          </BsButton>
        </Portal>
      {{/if}}

      {{yield this.showValidations}}
    {{/if}}

    {{#if (withDefault @showSpinner this.saveTask.isRunning)}}
      <SpinnerOverlay @message={{t "saving.scary"}} />
    {{/if}}

    {{#if this.showTransitionModal}}
      <StandardModal
        class="transition-modal {{if this.onNoChromePage "jem"}}"
        @size="sm"
        @onCancel={{set this 'showTransitionModal' false}}
        @onConfirm={{this.transitionModalSave.perform}}
        {{didUpdate this.onHideTransitionModal @hideTransitionModal}}
      as |modal|>
        <modal.header @text={{t "unsavedChanges"}} />

        <modal.body class="route-transition-modal">
          <p class="fs-2">
            {{t "modals.callToAction"}}
          </p>
        </modal.body>

        <modal.footer as |footer|>
          {{#if this.currentUser.user.isAdmin}}
            <footer.confirm>
              {{t "save"}}
            </footer.confirm>
          {{/if}}

          <BsButton
            @type="secondary"
            class="transition-modal-discard fs-3"
            @onClick={{this.transitionModalDiscard}}
          >
            {{t "discard"}}
          </BsButton>

          <footer.close />
        </modal.footer>
      </StandardModal>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
