import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import SidePanel from 'eflex/components/side-panel';
import BsForm from 'eflex/components/bs-form';
import { t } from 'ember-intl';
import { autofocus } from 'eflex/modifiers';
import SpinnerOverlay from 'eflex/components/spinner-overlay';
import SquareButton from 'eflex/components/square-button';
export default class BomCopyPanel extends Component {
    // eslint-disable-next-line ember/no-tracked-properties-from-args
    @tracked
    serialNumber = this.args.datumToCopy.serialNumber;
    @tracked
    target = 1;
    @tracked
    start = 1;
    @service
    buildDataRepo;
    @service
    notifier;
    get startDisabled() {
        return Number.parseInt(this.target) === 1;
    }
    copyDatum = task({
        drop: true
    }, waitFor(async ()=>{
        const target1 = Number.parseInt(this.target);
        const start1 = Number.parseInt(this.start);
        if (target1 < 1 || target1 > 1000 || start1 < 0) {
            this.notifier.sendError('bomBuilder.invalidCopyParameters');
            return;
        }
        const copies1 = await this.buildDataRepo.copyTargetAmount.perform(this.args.datumToCopy, this.serialNumber, target1, start1);
        this.args.updateModel(copies1);
        this.notifier.sendSuccess('bomBuilder.serialsGenerated');
        this.args.onClose();
    }));
    static{
        template(`
    <SidePanel
      class="bom-copy-panel"
      @header="{{t "bomBuilder.bomCopy"}} - {{@datumToCopy.serialNumber}}"
      @onClose={{@onClose}}
      ...attributes
    >
      <:default>
        <div class="p-3">
          <BsForm
            @model={{this}}
            @disabled={{@disabled}}
            class="h-100 row"
          as |form|>
            <form.element
              @controlType="text"
              class="serial-number col-6"
              @property="serialNumber"
              @label={{t "bomBuilder.serialNumber"}}
              {{autofocus}}
            />

            <form.element
              @controlType="number"
              class="target-amount col-3"
              @property="target"
              @label={{t "targetAmount"}}
            as |el|>
              <el.control
                min={{1}}
                max={{1000}}
              />
            </form.element>

            <form.element
              @controlType="number"
              class="start-index col-3"
              @property="start"
              @label={{t "start"}}
            as |el|>
              <el.control
                disabled={{this.startDisabled}}
                min={{0}}
              />
            </form.element>
          </BsForm>

          {{#if this.copyDatum.isRunning}}
            <SpinnerOverlay @message={{t 'bomBuilder.generatingCopies'}} />
          {{/if}}
        </div>
      </:default>

      <:footer>
        <SquareButton @onClick={{@onClose}}>
          {{t 'cancel'}}
        </SquareButton>
        <SquareButton
          class="generate-serial-numbers"
          @onClick={{this.copyDatum.perform}}
        >
          {{t 'bomBuilder.generate'}}
        </SquareButton>
      </:footer>

    </SidePanel>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
