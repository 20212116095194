import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { trackedFunction } from 'reactiveweb/function';
import { waitForPromise } from '@ember/test-waiters';
import { t } from 'ember-intl';
import StandardModal from 'eflex/components/standard-modal';
export default class DiffModal extends Component {
    formattedDiff = trackedFunction(this, async ()=>{
        const left1 = this.args.left;
        const right1 = this.args.right;
        const [jsondiffpatch1, htmlFormatter1] = await waitForPromise(Promise.all([
            import('jsondiffpatch'),
            import('jsondiffpatch/formatters/html')
        ]));
        const delta1 = jsondiffpatch1.create({
            objectHash (obj1, index1) {
                return obj1.id ?? obj1._id ?? obj1?.eflex?.id ?? obj1?.eflex?._id ?? `$$index:${index1}`;
            }
        }).diff(left1, right1);
        htmlFormatter1.hideUnchanged();
        return htmlFormatter1.format(delta1, left1);
    });
    static{
        template(`
    <StandardModal
      class="component-modals-diff"
      @onCancel={{@onCancel}}
      @size="xl"
      ...attributes
    as |modal|>
      <modal.header />
      <modal.body>
        {{!-- template-lint-disable no-triple-curlies --}}
        {{{this.formattedDiff.value}}}
        {{!-- template-lint-enable no-triple-curlies --}}
      </modal.body>
      <modal.footer as |footer|>
        <footer.close>
          {{t "close"}}
        </footer.close>
      </modal.footer>
    </StandardModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
