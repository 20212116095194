import { template } from "@ember/template-compiler";
import { hash } from '@ember/helper';
import Component from '@glimmer/component';
import { setInterval, clearInterval } from 'worker-timers';
import { tracked } from '@glimmer/tracking';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import { t } from 'ember-intl';
export class JemTaskTimerDetails extends Component {
    @tracked
    countdown = 0;
    get remainingTime() {
        const countdownTime1 = this.args.taskConfig?.interlockedTime;
        const cycleTimeInSeconds1 = this.args.childStatus?.cycleTime / 1000;
        const remaining1 = Math.max(0, countdownTime1 - cycleTimeInSeconds1);
        if (Number.isNaN(remaining1)) {
            return 0;
        }
        return remaining1.toFixed(0);
    }
    constructor(){
        super(...arguments);
        this.updateCountdown(null, [
            this.args.taskConfig?.interlockedTime,
            this.args.childStatus?.elapsedTime,
            this.args.childStatus?.isStarted
        ]);
    }
    willDestroy() {
        super.willDestroy(...arguments);
        this._clearTimer();
    }
    updateCountdown = (element1, [interlockedTime1, elapsedTime1, isStarted1])=>{
        let countdown1 = interlockedTime1 - Math.floor(elapsedTime1 / 1000);
        if (countdown1 < 0 || Number.isNaN(countdown1)) {
            countdown1 = 0;
        }
        this.countdown = countdown1;
        if (isStarted1 && this.timer == null) {
            this._handleCountdown();
        }
    };
    _clearTimer() {
        if (this.timer == null) {
            return;
        }
        clearInterval(this.timer);
        this.timer = null;
    }
    _handleCountdown() {
        this._clearTimer();
        if (this.countdown > 0) {
            this.timer = setInterval(()=>{
                if (this.countdown > 0) {
                    if (this.args.childStatus?.isHolding) {
                        return;
                    }
                    this.countdown = this.countdown - 1;
                } else {
                    this._clearTimer();
                }
            }, 1000);
        }
    }
    static{
        template(`
    <div ...attributes>
      <div
        {{didUpdate
          this.updateCountdown
          @taskConfig.interlockedTime
          @childStatus.elapsedTime
          @childStatus.isStarted
        }}
        class="mt-2"
      >
        <div class="countdown-text fs-4 fw-semibold text-gray-800">
          {{t "countdown"}}
        </div>
        {{#if @childStatus.isFinished}}
          <div class="remaining status detail-text-value fw-bold text-gray-900 {{@childStatus.taskStatusClass}}">
            {{this.remainingTime}}
          </div>
        {{else}}
          <div class="detail-text-value fw-bold text-gray-900 fw-bold">
            <div class="{{@childStatus.taskStatusClass}} timed-countdown status d-flex align-items-center">
              <h6 class="mb-0">{{this.countdown}}</h6>
            </div>
          </div>
        {{/if}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default template(`
  {{yield
    (hash
      taskDetails=(component
        JemTaskTimerDetails
        childStatus=@childStatus
        taskConfig=@taskConfig
      )
    )
  }}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
