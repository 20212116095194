import { template } from "@ember/template-compiler";
import Tokens from 'eflex/constants/date-picker-tokens';
import Component from '@glimmer/component';
import BsForm from 'eflex/components/bs-form';
import { t } from 'ember-intl';
import { isPresent } from '@eflexsystems/ember-composable-helpers';
import { and } from 'ember-truth-helpers';
export default class DatePickerWithTokens extends Component {
    tokens = Tokens;
    selectToken = (token1)=>{
        this.args.onChangeStartDate(token1.startDate());
        this.args.onChangeEndDate(token1.endDate());
        this.args.onChangeSelectedToken(token1);
    };
    setStartDate = (date1)=>{
        this.args.onChangeStartDate(date1);
        this.args.onChangeSelectedToken(null);
    };
    setEndDate = (date1)=>{
        this.args.onChangeEndDate(date1);
        this.args.onChangeSelectedToken(null);
    };
    static{
        template(`
    <div
      class="date-picker-with-tokens component-date-picker-with-tokens"
      ...attributes
    >
      <BsForm as |form|>
        <form.element
          class="range-select"
          @controlType="power-select"
          @label={{t "controls.daterange.range"}}
          @value={{@selectedToken}}
          @onChange={{this.selectToken}}
          @options={{this.tokens}}
        as |el|>
          <el.control as |token|>
            {{t token.translationKey}}
          </el.control>
        </form.element>
        {{#if (and (isPresent @startDate) (isPresent @endDate))}}
          <div class="row form-row">
            <div class="col">
              <form.element
                class="start-date"
                @controlType="date-picker"
                @label={{t "datetime.begin"}}
                @value={{@startDate}}
                @onChange={{this.setStartDate}}
              as |el|>
                <el.control
                  @altInput={{true}}
                  @enableTime={{true}}
                  @allowInput={{true}}
                  @dateFormat="Z"
                  @altFormat="M j, Y h:iK"
                />
              </form.element>
            </div>
          </div>
          <div class="row form-row">
            <div class="col">
              <form.element
                class="end-date"
                @controlType="date-picker"
                @label={{t "datetime.end"}}
                @value={{@endDate}}
                @onChange={{this.setEndDate}}
              as |el|>
                <el.control
                  @altInput={{true}}
                  @enableTime={{true}}
                  @allowInput={{true}}
                  @dateFormat="Z"
                  @altFormat="M j, Y h:iK"
                />
              </form.element>
            </div>
          </div>
        {{/if}}
      </BsForm>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
