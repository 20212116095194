import { template } from "@ember/template-compiler";
import TaskConfigBarcode from 'eflex/components/task-context-config/barcode';
import TaskConfigButton from 'eflex/components/task-context-config/button';
import TaskConfigCobot from 'eflex/components/task-context-config/cobot';
import TaskConfigDecision from 'eflex/components/task-context-config/decision';
import TaskConfigErrorProofing from 'eflex/components/task-context-config/errorproofing';
import TaskConfigLightGuide from 'eflex/components/task-context-config/lightguide';
import TaskConfigMultispindle from 'eflex/components/task-context-config/multispindle';
import TaskConfigNodeRed from 'eflex/components/task-context-config/nodered';
import TaskConfigPick from 'eflex/components/task-context-config/pick';
import TaskConfigPlc from 'eflex/components/task-context-config/plc';
import TaskConfigPushToSchedule from 'eflex/components/task-context-config/pushtoschedule';
import TaskConfigSerialNumberTransfer from 'eflex/components/task-context-config/serialnumbertransfer';
import TaskConfigOpcUa from 'eflex/components/task-context-config/opc-ua';
import TaskConfigTimer from 'eflex/components/task-context-config/timer';
import TaskConfigTorque from 'eflex/components/task-context-config/torque';
import TaskConfigVision from 'eflex/components/task-context-config/vision';
import BsForm from 'eflex/components/bs-form';
import { not, and, or } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import BsButton from 'eflex/components/bs-button';
import { fn } from '@ember/helper';
import { copyToAllContexts } from 'eflex/services/task-config-repo';
const getTaskContextComponent = (taskType1)=>{
    switch(taskType1){
        case 'barcode':
            {
                return TaskConfigBarcode;
            }
        case 'button':
            {
                return TaskConfigButton;
            }
        case 'cobot':
            {
                return TaskConfigCobot;
            }
        case 'decision':
            {
                return TaskConfigDecision;
            }
        case 'errorProofing':
            {
                return TaskConfigErrorProofing;
            }
        case 'lightGuide':
            {
                return TaskConfigLightGuide;
            }
        case 'multispindle':
            {
                return TaskConfigMultispindle;
            }
        case 'nodeRed':
            {
                return TaskConfigNodeRed;
            }
        case 'opcUa':
            {
                return TaskConfigOpcUa;
            }
        case 'pick':
            {
                return TaskConfigPick;
            }
        case 'plc':
            {
                return TaskConfigPlc;
            }
        case 'pushToSchedule':
            {
                return TaskConfigPushToSchedule;
            }
        case 'serialNumberTransfer':
            {
                return TaskConfigSerialNumberTransfer;
            }
        case 'timer':
            {
                return TaskConfigTimer;
            }
        case 'torque':
            {
                return TaskConfigTorque;
            }
        case 'vision':
            {
                return TaskConfigVision;
            }
        default:
            {
                return null;
            }
    }
};
const hasComplexOption = (componentOptions1)=>{
    return componentOptions1?.some((item1)=>item1.isSimple === false) ?? false;
};
export default template(`
<BsForm
  @showValidations={{@showValidations}}
  @disabled={{or @disabled @taskConfig.isIgnored}}
  @model={{@taskConfig}}
  ...attributes
as |form|>
  <div class="row form-row">
    <form.element
      @controlType="checkbox"
      @property="enabled"
      @label='{{t "enabled"}} - {{@taskConfig.configName}}'
      class="enable-checkbox model-specific w-50 form-switch ps-4"
    as |el|>
      <el.control @disabled={{@disabled}} />
    </form.element>
    {{#if
      (and
        @task.usesComponents
        (not @task.component.isAlwaysRun)
        (hasComplexOption @task.component.options)
      )
    }}
      <form.element
        @controlType="checkbox"
        @model={{@taskConfig}}
        @property="ignored"
        @label={{ t "plant.task.ignoreComplexOption" }}
        class="ignore-option model-specific"
      as |el|>
        <el.control @disabled={{ or @disabled @taskConfig.configOption.isSimple }} />
      </form.element>
    {{/if}}
  </div>

  <div class="model-specific row form-row">
    <div class="col-4">
      <form.element
        @label={{t "release.targetLabel"}}
        @property="targetCycleTime"
        @controlType="number"
      as |el|>
        <el.control disabled={{@disabled}} class="target-cycle-time" />
      </form.element>
    </div>
  </div>

  {{#let (getTaskContextComponent @task.taskType) as |TaskContextComponent|}}
    <TaskContextComponent
      @taskConfig={{@taskConfig}}
      @task={{@task}}
      @disabled={{@disabled}}
      @form={{form}}
    />
  {{/let}}
</BsForm>

{{#unless @task.usesOperations}}
  <div class="d-flex justify-content-end mt-2">
    <BsButton
      @type="secondary"
      @onClick={{fn copyToAllContexts @taskConfig}}
      @disabled={{@task.component.isAlwaysRun}}
      class="copy-tab"
    >
      {{#if @task.usesComponents}}
        {{t "copyOptionSpecific"}}
      {{else}}
        {{t "copyModelSpecific"}}
      {{/if}}
    </BsButton>
  </div>
{{/unless}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
