import { template } from "@ember/template-compiler";
import { uniqueId } from '@ember/helper';
import Component from '@glimmer/component';
import { on } from '@ember/modifier';
export default class FileInput extends Component {
    onChange = ({ target: target1 })=>{
        this.args.onChange(target1.files, ()=>{
            if (target1) {
                target1.value = '';
            }
        });
    };
    static{
        template(`
    <span
      class="
        component-file-input
        x-file-input
        {{if @disabled "x-file-input--disabled"}}
      "
    >
      {{#let (uniqueId) as |uid|}}
        <input
          type="file"
          id={{uid}}
          class="x-file--input"
          disabled={{@disabled}}
          {{on "change" this.onChange}}
          ...attributes
        >
        <label for={{uid}}>
          {{yield}}
        </label>
      {{/let}}
    </span>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
