/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { service } from '@ember/service';
import { task, waitForProperty } from 'ember-concurrency';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { uniqBy, pluck, flatten, pipe, prop } from 'ramda';

export default class WorkInstructionEditorLibraryApprovalView extends Component {
  @service currentUser;
  @service workInstructionRepo;
  @service systemConfig;

  @tracked enlargedImageSrc;
  @tracked approveRejectMode;
  @tracked showApproveRejectModal = false;
  @tracked showDeploymentModal = false;
  @tracked showConfigurationHistoryNotesModal = false;

  get isEditorOnly() {
    return this.workInstructionRepo.userIsEditorOnly();
  }

  get approvableWorkInstructions() {
    const { user } = this.currentUser;

    if (user.isAdmin) {
      return this.args.selectableWorkInstructions;
    } else if (!isEmpty(user.wieApproverTags) || !isEmpty(user.wieDeployerTags)) {
      return this.args.selectableWorkInstructions.filter((instruction) => {
        return this.workInstructionRepo.userCanApprove(instruction) ||
          this.workInstructionRepo.userCanDeploy(instruction);
      });
    } else if (this.isEditorOnly) {
      return this.args.selectableWorkInstructions.filter((instruction) => {
        return this.workInstructionRepo.userCanEdit(instruction);
      });
    }

    return null;
  }

  get approveRejectModalText() {
    const key = `wieList.approveModal.${this.approveRejectMode}`;

    if (this.args.selectedCards.length === 1) {
      return `${key}.single`;
    } else {
      return `${key}.multiple`;
    }
  }

  get approvalsModified() {
    return this.args.selectedCards.some(item => item.isDirty);
  }

  get selectedAreDeployable() {
    return this.args.oneOrMoreSelected && this.args.selectedCards?.every(item => item.isApproved);
  }

  get canApprove() {
    return this.workInstructionRepo.userCanApproveSelected(this.args.selectedCards);
  }

  get canDeploy() {
    return this.workInstructionRepo.userCanDeploySelected(this.args.selectedCards);
  }

  get uniqueAssignedLocations() {
    return pipe(
      pluck('allUniqueAssignedStations'),
      flatten,
      uniqBy(prop('id')),
    )(this.args.selectedCards ?? []);
  }

  saveApprovals = task(waitFor(async () => {
    await this.workInstructionRepo.saveWithApprovals.linked().perform(this.args.selectedCards);
    this._closeReviewModal();
  }));

  approveSelected = task(waitFor(async () => {
    await this.workInstructionRepo.approveAndSave.linked().perform(this.args.selectedCards);
    this._closeReviewModal();
  }));

  disapproveSelected = task(waitFor(async () => {
    await this.workInstructionRepo.rejectAndSave.linked().perform(this.args.selectedCards);
    this._closeReviewModal();
  }));

  deploy = task(waitFor(async () => {
    if (this.uniqueAssignedLocations.length > 0 && this.systemConfig.jem?.configurationHistory) {
      this.showConfigurationHistoryNotesModal = true;
      await waitForProperty(this, 'showConfigurationHistoryNotesModal', false);
    }

    await this.workInstructionRepo.deployMany.perform(this.args.selectedCards);
    await this.args.delistInstructions(this.args.selectedCards);
    this.showDeploymentModal = false;
  }));

  openReviewModal = task(waitFor(async mode => {
    await this.workInstructionRepo.setPendingApprovalAndSave.perform(
      this.args.selectedCards,
      this.currentUser.user.userName,
    );

    Object.assign(this, {
      approveRejectMode: mode,
      showApproveRejectModal: true,
    });
  }));

  @action
  rollbackApprovals() {
    this.args.selectedCards.forEach(selectedCard => { selectedCard.rollbackAttributes(); });
    this._closeReviewModal();
  }

  @action
  enlargeImage(src) {
    if (src != null) {
      this.enlargedImageSrc = src;
    }
  }

  @action
  setApproved(workInstruction) {
    workInstruction.currentApproveReject.approve();
  }

  @action
  setRejected(workInstruction) {
    workInstruction.currentApproveReject.reject();
  }

  @action
  instructionClicked(instruction) {
    if (this.isEditorOnly) {
      return;
    }
    this.args.cardSelected(instruction);
  }

  @action
  instructionDoubleClicked(instruction) {
    if (!this.workInstructionRepo.userCanEdit(instruction)) {
      return;
    }
    this.args.cardDoubleClick(instruction);
  }

  _closeReviewModal() {
    Object.assign(this, {
      approveRejectMode: null,
      showApproveRejectModal: false,
    });

    this.args.clearSelections();
  }
}
