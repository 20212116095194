import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { PAGE_SIZE } from 'eflex/constants/pagination';
import { isPresent } from '@ember/utils';
import { eventBus } from 'eflex/helpers';
import BsButton from 'eflex/components/bs-button';
import { t } from 'ember-intl';
import PaginationFooter from 'eflex/components/pagination-footer';
import { or } from 'ember-truth-helpers';
export default class EmberGrid extends Component {
    @tracked
    _previousDataLength = 0;
    @tracked
    _hideLoad = false;
    @tracked
    _skip = 0;
    take = PAGE_SIZE;
    get usePages() {
        return isPresent(this.args.page);
    }
    get showLoadMore() {
        if (this.args.fetch == null || this._hideLoad || this.usePages) {
            return false;
        }
        return this.args.data?.length - this._previousDataLength >= PAGE_SIZE;
    }
    onResetPagination = ()=>{
        this.args.updatePage?.(1);
        Object.assign(this, {
            _skip: 0,
            _hideLoad: false,
            _previousDataLength: 0
        });
    };
    sendFetch = ()=>{
        const dataLength1 = this.args.data?.length;
        if (this._previousDataLength === dataLength1) {
            this._hideLoad = true;
        }
        this._previousDataLength = dataLength1;
        this._skip = this._skip + PAGE_SIZE;
        return this.args.fetch?.({
            skip: this._skip,
            take: PAGE_SIZE
        });
    };
    updatePage = (page1)=>{
        this.args.updatePage(page1);
        return this.args.fetch?.({
            skip: (page1 - 1) * PAGE_SIZE,
            take: PAGE_SIZE
        });
    };
    static{
        template(`
    {{eventBus resetPagination=this.onResetPagination}}

    {{#if (has-block "buttons")}}
      <div class="d-flex flex-row-reverse pb-1">
        {{yield to="buttons"}}
      </div>
    {{/if}}

    <div
      class="component-ember-grid"
      ...attributes
    >
      <div class="ember-grid-component table-responsive">
        <table class="ember-grid table">
          {{yield}}
        </table>
        {{#if this.showLoadMore}}
          <div class="load-wrapper">
            <BsButton
              class="app-load-more"
              @type=''
              @onClick={{this.sendFetch}}
            >
              {{t "grid.loadMore"}}
            </BsButton>
          </div>
        {{/if}}
      </div>
    </div>

    {{#if this.usePages}}
      <PaginationFooter
        @page={{@page}}
        @take={{this.take}}
        @recordCount={{or @data.meta.count 0}}
        @updatePage={{this.updatePage}}
      />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
