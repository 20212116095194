import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import echartsTheme from 'eflex/echarts-theme';
import moment from 'moment-timezone';
import { waitFor } from '@ember/test-waiters';
import FormatDurationAxisBi from 'eflex/util/bi-duration-axis-formatter';
import { clone } from 'ramda';
import { get } from '@ember/helper';
import EChart from 'eflex/components/e-chart';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import Spinner from 'eflex/components/spinner';
import { and, eq } from 'ember-truth-helpers';
export default class StationCycleTimeControlChart extends Component {
    @service
    queryRunner;
    @service
    intl;
    get params() {
        return {
            stationIds: this.args.stationIds,
            modelIds: this.args.modelIds,
            userIds: this.args.userIds,
            tags: this.args.tags,
            startDate: this.args.startDate,
            endDate: this.args.endDate,
            shiftNames: this.args.shiftNames,
            processData: this.args.processData
        };
    }
    getCycleTimeData = task({
        drop: true
    }, waitFor(async (params1)=>{
        const cycleTimeControlChartQuery1 = await this.queryRunner.queryWithParams.perform(params1, [
            {
                $sort: {
                    timestamp: 1
                }
            },
            {
                $group: {
                    _id: null,
                    cycleTimes: {
                        $push: {
                            cycleTime: '$cycleTime',
                            timestamp: '$timestamp',
                            serialNumber: '$serialNumber',
                            station: '$location'
                        }
                    },
                    avg: {
                        $avg: '$cycleTime'
                    },
                    standardDeviation: {
                        $stdDevPop: '$cycleTime'
                    }
                }
            },
            {
                $project: {
                    _id: 0,
                    cycleTimes: 1,
                    avg: 1,
                    ucl: {
                        $sum: [
                            {
                                $multiply: [
                                    3,
                                    '$standardDeviation'
                                ]
                            },
                            '$avg'
                        ]
                    },
                    lcl: {
                        $subtract: [
                            '$avg',
                            {
                                $multiply: [
                                    3,
                                    '$standardDeviation'
                                ]
                            }
                        ]
                    }
                }
            }
        ]);
        const data1 = await this.queryRunner.runQuery.perform('BuildStatuses', cycleTimeControlChartQuery1);
        const formattedData1 = data1?.[0]?.cycleTimes.map((datum1)=>{
            return {
                cycleTime: datum1.cycleTime,
                timestamp: moment(datum1.timestamp).format('MM/DD/YYYY hh:mm:ss A'),
                serialNumber: datum1.serialNumber,
                station: datum1.station.captions[0].text,
                ucl: data1[0].ucl,
                lcl: Math.max(data1[0].lcl, 0)
            };
        });
        return {
            chartData: this.getEchartOptions(formattedData1),
            exportData: ()=>clone(formattedData1)?.map((datum1)=>{
                    return {
                        cycleTime: moment.duration(datum1.cycleTime, 'milliseconds').format('d[d] h[h] m[m] s[s]', {
                            precision: 1,
                            trim: 'both mid'
                        }),
                        timestamp: datum1.timestamp,
                        serialNumber: datum1.serialNumber,
                        station: datum1.station
                    };
                })
        };
    }));
    cycleTimeData = trackedTask(this, this.getCycleTimeData, ()=>[
            this.params,
            this.args.refresher
        ]);
    getEchartOptions(controlChartData1) {
        return {
            title: {
                text: this.intl.t('bi.chartLabel.cycleTimeStations')
            },
            legend: {
                show: true,
                bottom: 5
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params1)=>{
                    const tooltipData1 = params1[0];
                    return `<div style="min-width:285px;">${tooltipData1.marker} ${params1[1].axisValueLabel}<span style="float:right;font-weight:bold;">: ${moment.duration(tooltipData1.value, 'milliseconds').format('d[d] h[h] m[m] s[s]', {
                        precision: 1,
                        trim: 'both mid'
                    })}</span></div>`;
                }
            },
            xAxis: {
                type: 'category',
                data: controlChartData1?.map((item1)=>item1.timestamp),
                axisLabel: {
                    show: true,
                    rotate: 75,
                    interval: 'auto'
                }
            },
            yAxis: {
                type: 'value',
                scale: true,
                axisLabel: {
                    formatter: (value1)=>FormatDurationAxisBi(value1)
                }
            },
            series: [
                {
                    name: 'cycle time',
                    data: controlChartData1?.map((item1)=>item1.cycleTime),
                    type: 'line',
                    color: echartsTheme.colorPalette.biPurple,
                    markLine: {
                        data: [
                            {
                                type: 'average',
                                name: 'Avg'
                            }
                        ],
                        label: {
                            show: true,
                            position: 'insideEndTop',
                            formatter: (params1)=>moment.duration(params1.value).format('d[d] h[h] m[m] s[s]', {
                                    precision: 1,
                                    trim: 'both mid'
                                })
                        }
                    },
                    symbol: 'emptyCircle',
                    symbolSize: 7
                },
                {
                    name: this.intl.t('bi.chartLegend.lcl'),
                    data: controlChartData1?.map((item1)=>item1.lcl),
                    type: 'line',
                    symbol: 'none',
                    color: echartsTheme.colorPalette.taskRowRed
                },
                {
                    name: this.intl.t('bi.chartLegend.ucl'),
                    data: controlChartData1?.map((item1)=>item1.ucl),
                    type: 'line',
                    symbol: 'none',
                    color: echartsTheme.colorPalette.taskRowRed
                }
            ]
        };
    }
    static{
        template(`
    <div ...attributes>
      {{#if this.cycleTimeData.isRunning}}
        <Spinner />
      {{else if (and (eq @stationIds.length 1) (get this.cycleTimeData.value.chartData.series '0.data.length'))}}
        <div class="row h-100">
          <div class="col d-flex justify-content-between">
            <EChart
            @option={{this.cycleTimeData.value.chartData}}
            @exportData={{this.cycleTimeData.value.exportData}}
            class="h-100 w-100 individual-station-cycle-time-control-chart" />
          </div>
        </div>
      {{else}}
        <BiNoDataPlaceholder />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
