import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import TaskStatuses from 'eflex/constants/task-statuses';
import EchartsTheme from 'eflex/echarts-theme';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import Levels from 'eflex/constants/level-select-levels';
import generateDataForBiTimeChart from 'eflex/util/generate-data-for-bi-time-chart';
import FormatxAxisTimestamps from 'eflex/util/bi-format-xAxis-Timestamps';
import { waitFor } from '@ember/test-waiters';
import { fn, get } from '@ember/helper';
import EChart from 'eflex/components/e-chart';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import BiLevelSelector from 'eflex/components/bi/level-selector';
import Spinner from 'eflex/components/spinner';
export default class ProductionTimeBarChart extends Component {
    @service
    queryRunner;
    @service
    intl;
    @tracked
    selectedLevel = 'Day';
    get params() {
        return {
            stationIds: this.args.stationIds,
            modelIds: this.args.modelIds,
            userIds: this.args.userIds,
            tags: this.args.tags,
            startDate: this.args.startDate,
            endDate: this.args.endDate,
            shiftNames: this.args.shiftNames,
            useTotalCycles: this.args.useTotalCycles
        };
    }
    getData = task({
        drop: true
    }, waitFor(async (params1)=>{
        const totalCyclesQuery1 = await this.queryRunner.queryWithParams.perform(params1, [
            {
                $group: {
                    _id: {
                        $dateToString: {
                            format: Levels[this.selectedLevel],
                            date: '$timestamp'
                        }
                    },
                    rejectCount: {
                        $sum: {
                            $cond: {
                                if: {
                                    $and: [
                                        {
                                            $gte: [
                                                '$status',
                                                TaskStatuses.REJECT_RANGE_START
                                            ]
                                        },
                                        {
                                            $lte: [
                                                '$status',
                                                TaskStatuses.REJECT_RANGE_END
                                            ]
                                        }
                                    ]
                                },
                                then: 1,
                                else: 0
                            }
                        }
                    },
                    passCount: {
                        $sum: {
                            $cond: {
                                if: {
                                    $and: [
                                        {
                                            $gte: [
                                                '$status',
                                                TaskStatuses.GOOD_RANGE_START
                                            ]
                                        },
                                        {
                                            $lte: [
                                                '$status',
                                                TaskStatuses.GOOD_RANGE_END
                                            ]
                                        }
                                    ]
                                },
                                then: 1,
                                else: 0
                            }
                        }
                    }
                }
            },
            {
                $addFields: {
                    date: '$_id'
                }
            },
            {
                $sort: {
                    _id: 1
                }
            },
            {
                $project: {
                    _id: 0
                }
            }
        ]);
        const totalPartsQuery1 = await this.queryRunner.queryWithParams.perform(params1, [
            {
                $sort: {
                    timestamp: -1
                }
            },
            {
                $group: {
                    _id: {
                        serialNumber: '$serialNumber'
                    },
                    buildStatus: {
                        $first: {
                            timestamp: '$timestamp',
                            status: '$status'
                        }
                    }
                }
            },
            {
                $group: {
                    _id: {
                        $dateToString: {
                            format: Levels[this.selectedLevel],
                            date: '$buildStatus.timestamp'
                        }
                    },
                    rejectCount: {
                        $sum: {
                            $cond: {
                                if: {
                                    $and: [
                                        {
                                            $gte: [
                                                '$buildStatus.status',
                                                TaskStatuses.REJECT_RANGE_START
                                            ]
                                        },
                                        {
                                            $lte: [
                                                '$buildStatus.status',
                                                TaskStatuses.REJECT_RANGE_END
                                            ]
                                        }
                                    ]
                                },
                                then: 1,
                                else: 0
                            }
                        }
                    },
                    passCount: {
                        $sum: {
                            $cond: {
                                if: {
                                    $and: [
                                        {
                                            $gte: [
                                                '$buildStatus.status',
                                                TaskStatuses.GOOD_RANGE_START
                                            ]
                                        },
                                        {
                                            $lte: [
                                                '$buildStatus.status',
                                                TaskStatuses.GOOD_RANGE_END
                                            ]
                                        }
                                    ]
                                },
                                then: 1,
                                else: 0
                            }
                        }
                    }
                }
            },
            {
                $addFields: {
                    date: '$_id'
                }
            },
            {
                $sort: {
                    _id: 1
                }
            },
            {
                $project: {
                    _id: 0
                }
            }
        ]);
        const productionChartQuery1 = params1.useTotalCycles ? totalCyclesQuery1 : totalPartsQuery1;
        const productionChartData1 = await this.queryRunner.runQuery.perform('BuildStatuses', productionChartQuery1);
        const chartData1 = generateDataForBiTimeChart(productionChartData1, this.selectedLevel);
        return {
            chartData: this.getEchartOptions(chartData1),
            exportData: ()=>chartData1
        };
    }));
    productionData = trackedTask(this, this.getData, ()=>[
            this.params,
            this.selectedLevel,
            this.args.refresher
        ]);
    getEchartOptions(productionChartData1) {
        return {
            title: {
                text: this.intl.t('bi.chartLabel.productionTime')
            },
            legend: {
                show: true,
                bottom: 5
            },
            tooltip: {
                trigger: 'axis'
            },
            xAxis: FormatxAxisTimestamps(productionChartData1, this.selectedLevel),
            yAxis: [
                {
                    type: 'value',
                    position: 'left',
                    minInterval: 1
                },
                {
                    type: 'value',
                    position: 'left',
                    minInterval: 1
                }
            ],
            series: [
                {
                    name: this.intl.t('bi.chartLegend.timePeriodPassedParts'),
                    data: productionChartData1.map((m1)=>m1.passCount),
                    type: 'bar',
                    stack: 'parts',
                    yAxisIndex: 1,
                    color: EchartsTheme.colorPalette.success
                },
                {
                    name: this.intl.t('bi.chartLegend.timePeriodRejects'),
                    data: productionChartData1.map((m1)=>m1.rejectCount),
                    type: 'bar',
                    stack: 'parts',
                    yAxisIndex: 1,
                    color: EchartsTheme.colorPalette.danger
                }
            ]
        };
    }
    static{
        template(`
    <div class="component-bi-production-time-bar-chart" ...attributes>
      {{#if this.productionData.isRunning}}
        <Spinner />
      {{else if (get this.productionData.value.chartData.series '0.data.length')}}
        <div class="d-flex flex-row-reverse">
          <BiLevelSelector
            class="pe-5"
            @selectedLevel={{this.selectedLevel}}
            @onSelectedLevel={{fn (mut this.selectedLevel)}}
          />
        </div>
        <div class="d-flex flex-row h-100 chart-row">
          <div class="col d-flex justify-content-between">
            <EChart
              @option={{this.productionData.value.chartData}}
              @exportData={{this.productionData.value.exportData}}
              class="h-100 w-100 time-production-chart" />
          </div>
        </div>
      {{else}}
        <BiNoDataPlaceholder />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
