import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { sortByProp, sortByProps } from 'ramda-adjunct';
import { t } from 'ember-intl';
import PowerSelect from 'eflex/components/power-select';
import { optional } from '@eflexsystems/ember-composable-helpers';
import { serviceHelper } from 'eflex/helpers';
import { fn } from '@ember/helper';
import { handleContextChange } from 'eflex/services/task-repo';
const getFilteredModels = (treeItem1, allowAll1, defaultTaskConfigContext1)=>{
    const options1 = sortByProp('code', treeItem1.area.models ?? []);
    if (allowAll1) {
        options1.unshift(defaultTaskConfigContext1.getContext(treeItem1));
    }
    return options1;
};
const getSelectedModel = (selectedContext1, filteredModels1)=>{
    if (selectedContext1.allSelected) {
        return filteredModels1[0];
    } else {
        return selectedContext1;
    }
};
export const ModelContextSelector = template(`
<div class="row form-row model-context-selector" ...attributes>
  <div class="col">
    <label class="form-label">
      {{t "model"}}
    </label>
    {{#let (getFilteredModels @treeItem @allowAll (serviceHelper 'defaultTaskConfigContext')) as |filteredModels|}}
      <PowerSelect
        @options={{filteredModels}}
        @selected={{getSelectedModel @selectedContext filteredModels}}
        @searchField="displayName"
        @renderInPlace={{@renderInPlace}}
        @onChange={{optional @contextWasSelected}}
        class="plant-model-select options-select"
      as |model|>
        {{model.displayName}}
      </PowerSelect>
    {{/let}}
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const getSortedAreaComponents = (components1)=>{
    return sortByProps([
        'isComplex',
        'priority'
    ], components1 ?? []);
};
const getComponentOptions = (treeItem1, allowAll1, defaultTaskConfigContext1)=>{
    let options1 = [];
    if (allowAll1) {
        options1 = [
            defaultTaskConfigContext1.getContext(treeItem1)
        ];
    }
    const component1 = treeItem1.component;
    if (component1) {
        options1 = options1.concat(sortByProps([
            'isComplex',
            'priority'
        ], component1.options));
    }
    return options1;
};
const getSelectedComponentOption = (selectedContext1, componentOptions1)=>{
    if (selectedContext1.allSelected) {
        return componentOptions1[0];
    } else {
        return selectedContext1;
    }
};
export class ComponentContextSelector extends Component {
    @service
    taskRepo;
    @service
    defaultTaskConfigContext;
    onSelectedComponent = (treeItem1, componentOptions1, component1)=>{
        if (component1 == null || component1 === treeItem1.component) {
            return;
        }
        treeItem1.taskConfigs.forEach((taskConfig1)=>{
            taskConfig1.deleteRecord();
        });
        this.taskRepo.createComponentOptionConfigs(treeItem1, component1);
        handleContextChange(treeItem1, component1);
        treeItem1.component = component1;
        this.args.contextWasSelected(componentOptions1[0]);
    };
    static{
        template(`
    <div class="component-context-selector row" ...attributes>
      {{#let (getComponentOptions @treeItem @allowAll this.defaultTaskConfigContext) as |componentOptions|}}
        <div class="col-6">
          <div class="component-select-wrapper">
            <label class="form-label">
              {{t "components"}}
            </label>
            <PowerSelect
              @options={{getSortedAreaComponents @treeItem.area.bomSource.components}}
              @selected={{@treeItem.component}}
              @searchEnabled={{true}}
              @searchField="displayName"
              @renderInPlace={{@renderInPlace}}
              @onChange={{fn this.onSelectedComponent @treeItem componentOptions}}
              @disabled={{@disableComponentSelect}}
              class="app-component-select"
            as |comp|>
              {{comp.displayName}}
            </PowerSelect>
          </div>
        </div>
        {{#unless @treeItem.component.isAlwaysRun}}
          <div class="col-6">
            <label class="form-label">
              {{t "options"}}
            </label>
            <PowerSelect
              @options={{componentOptions}}
              @selected={{getSelectedComponentOption @selectedContext componentOptions}}
              @searchEnabled={{true}}
              @searchField="name"
              @renderInPlace={{@renderInPlace}}
              @placeholder={{t "options"}}
              @onChange={{optional @contextWasSelected}}
              class="app-option-select options-select"
            as |opt|>
              {{opt.displayName}}
            </PowerSelect>
          </div>
        {{/unless}}
      {{/let}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default template(`
{{#if @treeItem.usesComponents}}
  <ComponentContextSelector
    @treeItem={{@treeItem}}
    @renderInPlace={{@renderInPlace}}
    @selectedContext={{@selectedContext}}
    @contextWasSelected={{@contextWasSelected}}
    @allowAll={{@allowAll}}
    @disableComponentSelect={{@disableComponentSelect}}
    ...attributes
  />
{{else}}
  <ModelContextSelector
    @treeItem={{@treeItem}}
    @renderInPlace={{@renderInPlace}}
    @selectedContext={{@selectedContext}}
    @contextWasSelected={{@contextWasSelected}}
    @allowAll={{@allowAll}}
    ...attributes
  />
{{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
