import { template } from "@ember/template-compiler";
import { intoArray } from '@eflexsystems/ramda-helpers';
import { prop, pluck, filter } from 'ramda';
import { mapIndexed } from 'ramda-adjunct';
import BsButton from 'eflex/components/bs-button';
import { t } from 'ember-intl';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { fn } from '@ember/helper';
const compressGaps = (tasks1, sequenceType1)=>{
    const usedGaps1 = [
        true
    ];
    tasks1.forEach((task1)=>{
        const size1 = sequenceType1 === 'row' ? task1.sizeY : 1;
        for(let i1 = task1[sequenceType1]; i1 < task1[sequenceType1] + size1; i1++){
            usedGaps1[i1] = true;
        }
    });
    const availableGaps1 = intoArray(mapIndexed((p1, index1)=>({
            available: p1 == null,
            position: index1
        })), filter(prop('available')), pluck('position'))(usedGaps1);
    tasks1.forEach((task1)=>{
        const availableBefore1 = filter((a1)=>task1[sequenceType1] > a1, availableGaps1);
        task1[sequenceType1] = task1[sequenceType1] - availableBefore1.length;
    });
};
const onClick = (tasks1)=>{
    compressGaps(tasks1, 'row');
    compressGaps(tasks1, 'column');
};
export default template(`
<BsButton
  class="clean-sequence"
  @onClick={{fn onClick @tasks}}
  @disabled={{@disabled}}
  ...attributes
>
  <FaIcon @icon="broom" @prefix="fas" class="me-2" />
  {{t "plant.station.cleanSequence"}}
</BsButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
