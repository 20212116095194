import { template } from "@ember/template-compiler";
import BsForm from 'eflex/components/bs-form';
import { get } from '@ember/helper';
import { filterByIds, callWithMapped, serviceHelper as service } from 'eflex/helpers';
import { t } from 'ember-intl';
export default template(`
{{#let (get (service "modelRepo") "models") as |models|}}
  <div class="model-select" ...attributes>
    <BsForm as |form|>
      <form.element
        class="model-select"
        @controlType="power-select-multiple"
        @label={{t "navigation.plant.editor.models.index"}}
        @value={{filterByIds models @modelIds}}
        @options={{models}}
        @optionLabelPath="code"
        @onChange={{callWithMapped @onChange "id"}}
      as |el|>
        <el.control
          @searchEnabled={{true}}
          @searchField="code"
          @placeholder={{t "allModels"}}
        />
      </form.element>
    </BsForm>
  </div>
{{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
