import { template } from "@ember/template-compiler";
import BsForm from 'eflex/components/bs-form';
export default template(`
<BsForm @model={{this}} @readonly={{true}} as |form|>
  <form.element
    class="jem-model-display mb-0"
    @controlType="text"
    @size="lg"
    @value={{if @productionScheduleEnabled @productionScheduleModelCode @buildStatusModelCode}}
  as |el|>
    <el.control
      class="fw-semibold"
      title={{el.value}}
    />
  </form.element>
</BsForm>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
