import { attr, belongsTo } from '@ember-data/model';
import SyncBase from 'eflex/models/kinetic-config/sync-base';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';

@buildValidations({
  companyId: [validator('presence', true)],
  siteId: [validator('presence', true)],
  restUrl: [
    validator('presence', true),
    validator('inline', {
      validate(value) {
        return URL.canParse(value);
      },
    }),
  ],
  kineticEnvironment: [validator('presence', true)],
  restApiKey: [validator('presence', true)],
  kineticUsername: [validator('presence', true)],
  kineticPassword: [validator('presence', true)],
})
class KineticConfig extends SyncBase {
  @attr('string') companyId;
  @attr('string') siteId;
  @attr('string') siteName;
  @attr('string') timezone;
  @attr('string') restUrl;
  @attr('string') kineticEnvironment;
  @attr('string') restApiKey;
  @attr('string') kineticUsername;
  @attr('string') kineticPassword;

  @belongsTo('kineticConfig/bom', { inverse: null, async: false, embedded: true }) bom;
}

export default KineticConfig;
