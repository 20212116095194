import { template } from "@ember/template-compiler";
const getDisplayVariableValue = (jemDisplayVariableId1, buildDatumVariables1)=>{
    return buildDatumVariables1?.find((item1)=>item1.id === jemDisplayVariableId1)?.rawValue;
};
export default template(`
<div ...attributes>
  {{#if @jemDisplayVariable}}
    <label class="form-label app-selected-variable-name">
      {{@jemDisplayVariable.name}}
    </label>
    <div class="form-control form-control-lg border-0 jem-bom-variables" >
      {{getDisplayVariableValue @jemDisplayVariable.id @buildDatumVariables}}
    </div>
  {{/if}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
