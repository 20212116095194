import { template } from "@ember/template-compiler";
import Link from 'eflex/components/link';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
const getProductionCountLabel = (productionSchedule1, stationIsRunning1)=>{
    if (!productionSchedule1) {
        return '--/--';
    }
    let passedDisplay1;
    if (productionSchedule1?.startTime == null) {
        passedDisplay1 = '--';
    } else if (productionSchedule1.passed == null) {
        passedDisplay1 = 1;
    } else if (stationIsRunning1 && !productionSchedule1.isCompleted) {
        passedDisplay1 = productionSchedule1.passed + 1;
    } else {
        passedDisplay1 = productionSchedule1.passed;
    }
    return `${passedDisplay1}/${productionSchedule1.target}`;
};
export default template(`
<h6 class="production-count text-gray-100 mb-0">
  {{getProductionCountLabel @productionSchedule @stationIsRunning}}
</h6>
<Link @route="productionScheduler" @disabled={{@disabled}}>
  <span class="btn btn-lg btn-peripheral-dark ms-2 production-link {{if @disabled "not-allowed"}}">
    <FaIcon @icon="gear"/>
  </span>
</Link>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
