import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import { trackedFunction } from 'reactiveweb/function';
import getUrlType from 'eflex/util/get-url-type';
import { eq } from 'ember-truth-helpers';
import PowerSelectWithCreate from 'eflex/components/power-select-with-create';
import { t } from 'ember-intl';
import { optional } from '@eflexsystems/ember-composable-helpers';
import { inputNum } from 'eflex/helpers';
import { on } from '@ember/modifier';
export default class HostedFilePicker extends Component {
    @service
    store;
    @service
    hostedFileRepo;
    fileType = trackedFunction(this, async ()=>{
        const computedImageUrl1 = this.computedImageUrl;
        if (!computedImageUrl1) {
            return null;
        }
        if (computedImageUrl1.mime != null) {
            return computedImageUrl1.mime;
        }
        if (computedImageUrl1.name == null) {
            return null;
        }
        return await getUrlType(computedImageUrl1.name);
    });
    get allowPageSelection() {
        return this.args.allowPageSelection ?? true;
    }
    get computedImageUrl() {
        if (this.args.externalImageUrl == null) {
            return null;
        }
        const recordId1 = this.args.externalImageUrl.split('/').at(-1).match(/^([\da-z]*)/)?.[1];
        let record1;
        if (!isBlank(recordId1)) {
            record1 = this.store.peekRecord('hostedFile', recordId1);
        }
        if (record1 != null) {
            return {
                id: record1.id,
                name: record1.name,
                mime: record1.mime,
                pdfPage: this.args.pdfPage
            };
        } else {
            return {
                id: null,
                name: this.args.externalImageUrl,
                pdfPage: this.args.pdfPage
            };
        }
    }
    get fileOptions() {
        let options1 = [];
        options1 = this.hostedFileRepo.hostedFiles.map(({ id: id1, name: name1, mime: mime1 })=>({
                id: id1,
                name: name1,
                mime: mime1
            }));
        if (this.computedImageUrl != null && this.computedImageUrl.id == null) {
            options1.push(this.computedImageUrl);
        }
        if (this.args.mimeWhitelist?.length > 0) {
            options1 = options1.filter((option1)=>{
                return this.args.mimeWhitelist.includes(option1.mime);
            });
        }
        return options1;
    }
    setExternalImageUrl = (url1)=>{
        this.args.onChange?.(url1, null, url1);
    };
    updateLink = (link1)=>{
        if (link1 != null) {
            const record1 = this.store.peekRecord('hostedFile', link1.id);
            this.args.onChange?.(record1.url, record1.id, record1.name);
        } else {
            this.args.onChange?.(null);
        }
    };
    static{
        template(`
    <div
      class="file-picker component-hosted-file-picker col"
      ...attributes
    >
      <div class="row">
        <div class="col">
          <PowerSelectWithCreate
            class="w-100"
            @triggerClass="form-control"
            @placeholder={{@placeholder}}
            @searchEnabled={{true}}
            @searchField="name"
            @disabled={{@disabled}}
            @options={{this.fileOptions}}
            @selected={{this.computedImageUrl}}
            @allowClear={{true}}
            @renderInPlace={{true}}
            @onChange={{this.updateLink}}
            @onCreate={{this.setExternalImageUrl}}
          as |hostedFile|>
            {{hostedFile.name}}
          </PowerSelectWithCreate>
        </div>
        {{#if this.allowPageSelection}}
          {{#if (eq "application/pdf" this.fileType.value)}}
            <div class="d-flex align-items-center col-2">
              <label class="me-1">
                {{t "page"}}:
              </label>
              <input
                type="Number"
                value={{this.computedImageUrl.pdfPage}}
                class="form-control pdf-page flex-fill"
                {{on "input" (inputNum (optional @onPdfPageInput))}}
              />
            </div>
          {{/if}}
        {{/if}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
