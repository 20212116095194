import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import EdhrMethodTypes from 'eflex/constants/tasks/edhr-method-types';
import EdhrProcessDataTypes from 'eflex/constants/edhr-process-data-types';
import { taskTypes } from 'eflex/constants/tasks/task-types';
import { concatRight, sortByProps, sortByProp } from 'ramda-adjunct';
import { range, pipe } from 'ramda';
import HardwareSelect from 'eflex/components/hardware-select';
import TrashCan from 'eflex/components/trash-can';
import QuantitySelect from 'eflex/components/quantity-select';
import { t } from 'ember-intl';
import { fn } from '@ember/helper';
import { notEq, eq } from 'ember-truth-helpers';
import { append } from '@eflexsystems/ember-composable-helpers';
const HARDWARE_TYPES = [
    'edhr'
];
const METHODS_WITH_REASON_CODES = new Set([
    EdhrMethodTypes.sendData,
    EdhrMethodTypes.completeOperation
]);
const IMAGE_TASK_TYPES = new Set([
    taskTypes.imageCapture,
    taskTypes.vision
]);
const edhrMethodTypeValues = Object.values(EdhrMethodTypes);
export default class EdhrDeviceConfig extends Component {
    @service
    store;
    @service
    intl;
    get previousTasks() {
        const allTasks1 = sortByProps([
            'row',
            'column'
        ], this.args.task.parent?.children ?? []);
        const index1 = allTasks1.indexOf(this.args.task);
        if (index1 === -1) {
            return [];
        }
        return allTasks1.slice(0, index1);
    }
    get showReasonCode() {
        return METHODS_WITH_REASON_CODES.has(this.args.task.edhrMethod);
    }
    get processDataOptions() {
        return pipe(concatRight(this.variableDefs ?? []), concatRight(this.images ?? []), concatRight(this.barcodes ?? []), concatRight(this.decisions ?? []), sortByProp('groupName'), concatRight([
            this._getMappingOption(EdhrProcessDataTypes.currentDateTime),
            this._getMappingOption(EdhrProcessDataTypes.userDefinedString)
        ]))(this.bolts ?? []);
    }
    get decimalOptions() {
        const keys1 = Array.from({
            length: 11
        }).keys();
        return [
            ...keys1
        ].map(String);
    }
    get bolts() {
        const torqueTasks1 = this.previousTasks.filter((item1)=>item1.taskType === taskTypes.torque);
        return torqueTasks1.map((task1)=>{
            const options1 = range(1, task1.maxBoltCount + 1).flatMap((i1)=>{
                return [
                    this._boltToProcessDatum(i1, EdhrProcessDataTypes.boltTorque, task1),
                    this._boltToProcessDatum(i1, EdhrProcessDataTypes.boltAngle, task1)
                ];
            });
            options1.unshift(this._getMappingOption(EdhrProcessDataTypes.hardwareName, task1));
            return {
                groupName: task1.name,
                options: options1
            };
        });
    }
    get variableDefs() {
        return this.previousTasks.filter((item1)=>item1.variableDefs?.length).map((task1)=>({
                groupName: task1.name,
                options: [
                    this._getMappingOption(EdhrProcessDataTypes.hardwareName, task1),
                    ...task1.variableDefs.filter((item1)=>item1.isFromDevice).map((variableDef1)=>({
                            id: variableDef1.id,
                            processDataType: EdhrProcessDataTypes.variableDef,
                            name: variableDef1.name,
                            variableDef: variableDef1,
                            dataTask: task1
                        }))
                ]
            }));
    }
    get images() {
        const imageTasks1 = this.previousTasks.filter((task1)=>IMAGE_TASK_TYPES.has(task1.taskType));
        return imageTasks1.map((task1)=>{
            return {
                groupName: task1.name,
                options: [
                    this._getMappingOption(EdhrProcessDataTypes.hardwareName, task1),
                    this._getMappingOption(EdhrProcessDataTypes.sendImage, task1)
                ]
            };
        });
    }
    get barcodes() {
        const barcodeTasks1 = this.previousTasks.filter((item1)=>item1.taskType === taskTypes.barcode);
        return barcodeTasks1.map((task1)=>{
            return {
                groupName: task1.name,
                options: [
                    this._getMappingOption(EdhrProcessDataTypes.hardwareName, task1),
                    this._getMappingOption(EdhrProcessDataTypes.scannedBarcode, task1)
                ]
            };
        });
    }
    get decisions() {
        const decisionTasks1 = this.previousTasks.filter((item1)=>item1.taskType === taskTypes.decision);
        return decisionTasks1.map((task1)=>{
            return {
                groupName: task1.name,
                options: [
                    this._getMappingOption(EdhrProcessDataTypes.selectedDecision, task1)
                ]
            };
        });
    }
    _getMappingOption(processDataType1, dataTask1) {
        return {
            id: processDataType1,
            processDataType: processDataType1,
            name: this.intl.t(processDataType1),
            dataTask: dataTask1
        };
    }
    _boltToProcessDatum(boltNumber1, processDataType1, task1) {
        return {
            id: this._getBoltId(boltNumber1, processDataType1),
            processDataType: processDataType1,
            name: `${this.intl.t('bolt')} ${boltNumber1} ${this.intl.t(processDataType1)}`,
            boltIndex: boltNumber1,
            dataTask: task1
        };
    }
    _getBoltId(boltNumber1, boltFieldType1) {
        return String(boltNumber1) + boltFieldType1;
    }
    getDataMappingReference = (mapping1)=>{
        const group1 = this.processDataOptions.find((option1)=>{
            return option1.options?.find((item1)=>item1.dataTask === mapping1.dataTask);
        });
        switch(mapping1.processDataType){
            case EdhrProcessDataTypes.variableDef:
                {
                    return group1?.options.find((item1)=>item1.id === mapping1.variableDef?.id);
                }
            case EdhrProcessDataTypes.boltTorque:
            case EdhrProcessDataTypes.boltAngle:
                {
                    return group1?.options.find((item1)=>item1.id === this._getBoltId(mapping1.boltIndex, mapping1.processDataType));
                }
            default:
                {
                    if (group1) {
                        return group1.options.find((item1)=>item1.id === mapping1.processDataType);
                    }
                    return this.processDataOptions.find((item1)=>item1.id === mapping1.processDataType);
                }
        }
    };
    addMapping = (count1)=>{
        for(let i1 = 1; i1 <= count1; i1++){
            this.store.createRecord('edhrMapping', {
                task: this.args.task
            });
        }
    };
    removeMapping = (mapping1)=>{
        mapping1.deleteRecord();
    };
    setProcessDatum = (mapping1, processDatum1)=>{
        Object.assign(mapping1, {
            boltIndex: processDatum1.boltIndex ?? null,
            processDataType: processDatum1.processDataType,
            variableDef: processDatum1.variableDef,
            dataTask: processDatum1.dataTask
        });
    };
    static{
        template(`
    <div class="row form-row">
      <div class="col-6">
        <HardwareSelect
          @types={{HARDWARE_TYPES}}
          @model={{@task}}
          @form={{@form}}
        />
      </div>
    </div>

    <hr/>

    <div class="row form-row">
      <div class="col-6">
        <@form.element
          class="edhr-operation"
          @controlType="text"
          @label={{t "operation"}}
          @property="operation"
        />
        {{#if @task.usesModels}}
          <div class='col-auto'>
            <@form.element
              @controlType='checkbox'
              @property='operationUsesModel'
              @label={{t 'useModelCode'}}
              class="use-model-code"
            />
          </div>
        {{/if}}
      </div>

      <div class="col-6">
        <@form.element
          @controlType="power-select"
          class="method-type-select"
          @property="edhrMethod"
          @label={{t "methodType"}}
          @options={{edhrMethodTypeValues}}
          @onChange={{fn (mut @task.edhrMethod)}}
        as |el|>
          <el.control
            class="edhr-method-select"
            @triggerClass="form-control"
          as |option|>
            {{t option}}
          </el.control>
        </@form.element>
      </div>
    </div>

    {{#if this.showReasonCode}}
      <div class="row form-row">
        <div class="col-6">
          <@form.element
            @controlType="text"
            class="reason-code"
            @label={{t "reasonCode"}}
            @property="reasonCode"
          />
        </div>
      </div>
    {{/if}}

    {{#if (eq EdhrMethodTypes.sendData @task.edhrMethod)}}
      <div class="bootstrap-table mt-2">
        <div class="row form-row header-row">
          <div class="col">
            {{t "processData"}}
          </div>
        </div>

        {{#each @task.nonDeletedEdhrMappings as |mapping|}}
          <div class="mapping-row row form-row align-items-start">
            <div class="col-4">
              <div class="nested-header">
                {{t "type"}}
              </div>
              <@form.element
                @controlType="power-select"
                class="process-data-type-select"
                @model={{mapping}}
                @options={{this.processDataOptions}}
                @optionLabelPath="name"
                @value={{this.getDataMappingReference mapping}}
                @onChange={{fn this.setProcessDatum mapping}}
              as |el|>
                <el.control
                  @triggerClass="form-control"
                  @searchEnabled={{true}}
                  @searchField="name"
                />
              </@form.element>
              {{#if mapping.dataTask}}
                <div class="nested-header text-truncate">
                  <span class="data-task-name" title={{mapping.dataTask.name}}>
                    {{mapping.dataTask.name}}
                  </span>
                </div>
              {{/if}}
            </div>

            <div class="col">
              {{#if (notEq mapping.processDataType EdhrProcessDataTypes.sendImage)}}
                <div class="nested-header">
                  {{t "fieldName"}}
                </div>
                <@form.element
                  class="field-name"
                  @controlType="text"
                  @model={{mapping}}
                  @property="fieldName"
                />
              {{/if}}
            </div>

            {{#if (eq mapping.processDataType EdhrProcessDataTypes.userDefinedString)}}
              <div class="col-4">
                <div class="nested-header">
                  {{t "userDefinedString"}}
                </div>
                <@form.element
                  @controlType="text"
                  class="user-string"
                  @model={{mapping}}
                  @property="customValue"
                />
              </div>
            {{/if}}

            {{#if mapping.canBeNumber}}
              <div class="col-2">
                <div class="nested-header">
                  {{t "dataType.decimal"}}
                </div>
                <@form.element
                  @controlType="power-select"
                  class="decimal-precision"
                  @options={{append null this.decimalOptions}}
                  @model={{mapping}}
                  @property="decimalPrecision"
                as |el|>
                  <el.control
                    @placeholder="--"
                    @allowClear={{true}}
                  />
                </@form.element>
              </div>
            {{/if}}

            <div class="col-auto">
              <div class='nested-header'></div>
              <div class="col-icon mt-3">
                <TrashCan
                  class='align-self-center'
                  disabled={{@disabled}}
                  @onDelete={{fn this.removeMapping mapping}}
                />
              </div>
            </div>
          </div>
        {{else}}
          <div class="row form-row">
            <div class="m-2 fst-italic">
              {{t 'noEdhrProcessData' }}
            </div>
          </div>
        {{/each}}
      </div>

      <div class="row form-row pt-0 add-process-data">
        <div class="col">
          <QuantitySelect
            class="add-mapping text-uppercase"
            @disabled={{@disabled}}
            @onSubmit={{this.addMapping}}
          >
            {{t "addProcessData"}}
          </QuantitySelect>
        </div>
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
