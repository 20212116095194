import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import SearchTag from 'eflex/components/criteria-visor/search-tag';
import FilterWrapper from 'eflex/components/criteria-visor/filter-wrapper';
import { findBy } from '@eflexsystems/ember-composable-helpers';
export default class CriteriaVisorPassFail extends Component {
    @service
    intl;
    @tracked
    isDisplayed = Boolean(this.args.passed);
    get options() {
        return [
            {
                label: this.intl.t('passed'),
                value: true
            },
            {
                label: this.intl.t('failed'),
                value: false
            }
        ];
    }
    get breadcrumb() {
        return this.options.find((opt1)=>opt1.value === this.args.passed)?.label;
    }
    constructor(){
        super(...arguments);
        this.args.initParam('passed', this.args.passed);
    }
    onDisplayedChanged = (isDisplayed1)=>{
        this.isDisplayed = isDisplayed1;
        this.args.updateParam('passed', null);
    };
    onSelected = (selected1)=>{
        this.args.updateParam('passed', selected1?.value);
    };
    static{
        template(`
    <SearchTag class='passed' @isDisplayed={{this.isDisplayed}} @text={{this.breadcrumb}} />

    <FilterWrapper
      class="eflex-pass-fail-criteria"
      @form={{@form}}
      @label="controls.criteria.passfail"
      @isDisplayed={{this.isDisplayed}}
      @onChecked={{this.onDisplayedChanged}}
      ...attributes
    >
      <@form.element
        @controlType="power-select"
        @options={{this.options}}
        @optionLabelPath="label"
        @value={{findBy "value" @passed this.options}}
        @onChange={{this.onSelected}}
      />
    </FilterWrapper>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
