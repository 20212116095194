import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
export default class CollapseToggle extends Component {
    @tracked
    isCollapsed = false;
    @service
    resizeListener;
    get collapsedClass() {
        const direction1 = this.args.direction ?? 'left';
        switch(true){
            case direction1 === 'left' && this.isCollapsed:
                {
                    return 'caret-right';
                }
            case direction1 === 'left' && !this.isCollapsed:
                {
                    return 'caret-left';
                }
            case direction1 === 'right' && this.isCollapsed:
                {
                    return 'caret-left';
                }
            default:
                {
                    return 'caret-right';
                }
        }
    }
    onClick = ()=>{
        this.isCollapsed = !this.isCollapsed;
        this.args.onToggle?.(this.isCollapsed);
        this.resizeListener.triggerResize();
    };
    static{
        template(`
    <div
      {{on "click" this.onClick}}
      class="px-1 mx-1"
      ...attributes
    >
      <FaIcon @icon={{this.collapsedClass}} @prefix="fas" />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
