import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { task } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';
import { PAGE_SIZE } from 'eflex/constants/pagination';
import JobStatuses from 'eflex/constants/kinetic-job-statuses';
import { not } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import { fn } from '@ember/helper';
import EmberGrid from 'eflex/components/ember-grid';
import { on } from '@ember/modifier';
import { modifier } from 'ember-modifier';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { join, sortBy } from '@eflexsystems/ember-composable-helpers';
import { isEmpty } from '@ember/utils';
export default class KineticWorkQueue extends Component {
    @service
    store;
    @service
    notifier;
    @service
    eventBus;
    @tracked
    assemblyOps = new TrackedArray();
    get verifyStation() {
        if (isEmpty(this.args.verifyStation)) {
            return true;
        } else {
            return this.args.verifyStation;
        }
    }
    get verifyTasks() {
        if (isEmpty(this.args.verifyTasks)) {
            return true;
        } else {
            return this.args.verifyTasks;
        }
    }
    onDidInsert = modifier(async ()=>{
        try {
            this.assemblyOps = await this._getData.perform();
            this.eventBus.trigger('resetPagination');
        } catch (e1) {
            console.error(e1);
            this.notifier.sendError('logs.searchFailed');
        }
    });
    fetch = task(waitFor(async (params1, pagination1)=>{
        const assemblyOps1 = await this._getData.perform({
            ...params1,
            ...pagination1
        });
        this.assemblyOps.push(...assemblyOps1);
    }));
    _getData = task(waitFor(async (params1 = {})=>{
        params1.jobStatus = JobStatuses.released;
        return new TrackedArray(await this.store.query('kineticAssemblyOperation', {
            skip: 0,
            take: PAGE_SIZE,
            ...params1
        }));
    }));
    onOperationSelected = (assemblyOperation1)=>{
        if (this.verifyStation && assemblyOperation1.stationNotAllowed(this.args.station)) {
            return;
        }
        this.args.onOperationSelected(assemblyOperation1);
    };
    operationClickable = (operation1)=>{
        if (isEmpty(this.args.onOperationSelected)) {
            return false;
        }
        return !((this.verifyStation && !operation1.stationIsAllowed(this.args.station)) || (this.verifyTasks && !operation1.hasTasks));
    };
    static{
        template(`
    <div {{this.onDidInsert}}></div>
    {{#if (not (isEmpty this.assemblyOps))}}
      <EmberGrid
        class="jobs-grid"
        @data={{this.assemblyOps}}
        @fetch={{this.fetch.perform}}
        ...attributes
      >
        <thead>
          <tr>
            <th></th>
            <th>
              {{t "quantity"}}
            </th>
            <th>
              {{t "job"}}
            </th>
            <th>
              {{t "kinetic.assembly"}}
            </th>
            <th>
              {{t "kinetic.operation"}}
            </th>
            <th>
              {{t "kinetic.part"}}
            </th>
            <th>
              {{t "kinetic.revision"}}
            </th>
          </tr>
        </thead>
        <tbody>
          {{#each (sortBy "job.jobNumber" "assembly.assemblySequence" "sequence" this.assemblyOps) as |operation|}}
            <tr
              class="{{if (this.operationClickable operation) 'ember-grid-action-row'}} operation-row"
              {{on "click" (fn this.onOperationSelected operation)}}
            >
              <td class="app-operation-icon d-flex align-items-center flex-column">
                {{#if (not (isEmpty (operation.warnings @station))) }}
                  <div class="icon-message help" title={{join ", " (operation.warnings @station)}}>
                    <FaIcon @icon="triangle-exclamation" @prefix="fas" class="fa"/>
                  </div>
                {{/if}}
              </td>
              <td class="app-operation-quantity">
                {{operation.quantityCount}}
              </td>
              <td class="app-operation-job">
                {{operation.job.jobNumber}}
              </td>
              <td class="app-operation-assembly">
                {{operation.assembly.assemblySequence}}
              </td>
              <td class="app-operation-name">
                {{operation.name}}
              </td>
              <td class="app-part-name">
                {{operation.part.name}}
              </td>
              <td class="app-part-rev-name {{if operation.partRev.isOtf 'fst-italic otf-part-rev'}}">
                {{operation.partRev.name}}
              </td>
            </tr>
          {{/each}}
        </tbody>
      </EmberGrid>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
