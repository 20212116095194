import { template } from "@ember/template-compiler";
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import TextControl from 'eflex/components/bs-form/element/control/text';
export default class NumberControl extends Component {
    onChange = (value1)=>{
        if (isPresent(value1)) {
            value1 = Number(value1);
        }
        this.args.onChange(value1);
    };
    static{
        template(`
    <TextControl
      @type='number'
      @id={{@id}}
      @disabled={{@disabled}}
      @readonly={{@readonly}}
      @ariaDescribedBy={{@ariaDescribedBy}}
      @value={{@value}}
      @size={{@size}}
      @validationType={{@validationType}}
      @onChange={{this.onChange}}
      ...attributes
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
