import { template } from "@ember/template-compiler";
import { isBlank, isEmpty, isPresent } from '@ember/utils';
import { task, taskGroup, timeout } from 'ember-concurrency';
import { service } from '@ember/service';
import getDelayTime from 'eflex/util/get-delay-time';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { TrackedArray } from 'tracked-built-ins';
import { addObjectsIfNotPresent } from 'eflex/util/array-helpers';
import { sortByProp } from 'ramda-adjunct';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import { t } from 'ember-intl';
import { on } from '@ember/modifier';
import BsButton from 'eflex/components/bs-button';
import Link from 'eflex/components/link';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { not, and, eq } from 'ember-truth-helpers';
import Spinner from 'eflex/components/spinner';
import { onResize } from 'eflex/modifiers';
import WorkInstructionEditorLibraryCard from 'eflex/components/work-instruction-editor/library-card';
import { fn } from '@ember/helper';
const TASK_CARD_WIDTH = 124;
export default class WorkInstructionEditorTaskLibrary extends Component {
    @service
    workInstructionRepo;
    @service
    workInstructionCache;
    @tracked
    pageNumber = 1;
    @tracked
    recordCount = 0;
    @tracked
    filteredFolders = new TrackedArray();
    @tracked
    filteredWorkInstructions = new TrackedArray();
    @tracked
    searchTerm;
    @tracked
    currentIndex = 0;
    @tracked
    maxCardsDisplayed = 5;
    @tracked
    selectedFolder = this.args.currentWorkInstruction?.folder;
    previousConfig = this.args.config;
    previousCurrentWorkInstruction = this.args.currentWorkInstruction;
    @taskGroup({
        restartable: true
    })
    loadTasks;
    get allCards() {
        return this._selectableFolders.concat(this._selectableWorkInstructions);
    }
    get visibleCards() {
        return this.allCards.slice(this.currentIndex, this.currentIndex + this.numCards);
    }
    get disableNext() {
        const { showLoadButton: showLoadButton1, allCards: allCards1, currentIndex: currentIndex1, numCards: numCards1 } = this;
        if (showLoadButton1) {
            return false;
        }
        return allCards1.length <= currentIndex1 + numCards1;
    }
    get showSpinner() {
        return this._search.isRunning || this.loadFolder.isRunning || this._resetList.isRunning || this._loadMore.isRunning;
    }
    get numCards() {
        const { selectedFolder: selectedFolder1, maxCardsDisplayed: maxCardsDisplayed1 } = this;
        if (selectedFolder1 != null) {
            return maxCardsDisplayed1 - 1;
        } else {
            return maxCardsDisplayed1;
        }
    }
    get _selectableFolders() {
        const { selectedFolder: selectedFolder1, filteredFolders: filteredFolders1 } = this;
        if (selectedFolder1 != null) {
            return [];
        }
        return sortByProp('name', filteredFolders1);
    }
    get _selectableWorkInstructions() {
        const { filteredWorkInstructions: filteredWorkInstructions1, selectedFolder: selectedFolder1, searchTerm: searchTerm1 } = this;
        let instructions1 = filteredWorkInstructions1.filter((workInstruction1)=>workInstruction1.deployed);
        if (selectedFolder1) {
            instructions1 = instructions1.filter((workInstruction1)=>isPresent(workInstruction1.folder));
        } else if (isBlank(searchTerm1)) {
            instructions1 = instructions1.filter((workInstruction1)=>workInstruction1.folder == null);
        }
        return sortByProp('name', instructions1);
    }
    get showLoadButton() {
        const { workInstructionCount: workInstructionCount1, recordCount: recordCount1 } = this;
        return workInstructionCount1 > 0 && workInstructionCount1 < recordCount1;
    }
    get workInstructionCount() {
        return this._selectableWorkInstructions.length + this._selectableFolders.length;
    }
    onDidInsert = task({
        group: 'loadTasks'
    }, waitFor(async (element1)=>{
        await this._resetList.perform();
        this.onResize(element1);
        const { allCards: allCards1, numCards: numCards1, args: { currentWorkInstruction: currentWorkInstruction1 } } = this;
        this._jumpCarousel(allCards1, numCards1, currentWorkInstruction1);
    }));
    onDidUpdate = task({
        group: 'loadTasks'
    }, waitFor(async (element1, [currentWorkInstruction1, config1])=>{
        const { previousConfig: previousConfig1, previousCurrentWorkInstruction: previousCurrentWorkInstruction1 } = this;
        const configChanged1 = previousConfig1 !== config1 || config1?.isDirty;
        const currentWorkInstructionChanged1 = currentWorkInstruction1 !== previousCurrentWorkInstruction1;
        Object.assign(this, {
            previousConfig: config1,
            previousCurrentWorkInstruction: currentWorkInstruction1,
            selectedFolder: currentWorkInstruction1?.folder
        });
        if (configChanged1) {
            await this._resetList.perform();
            const { allCards: allCards1, numCards: numCards1 } = this;
            this._jumpCarousel(allCards1, numCards1, currentWorkInstruction1);
        } else if (currentWorkInstruction1 && currentWorkInstructionChanged1) {
            this._addRecordToFilteredInstructions();
        }
    }));
    clickNext = task(waitFor(async ()=>{
        const { visibleCards: visibleCards1, numCards: numCards1, workInstructionCount: workInstructionCount1, showLoadButton: showLoadButton1 } = this;
        const cardLength1 = visibleCards1.length;
        let currentIndex1 = this.currentIndex;
        if (cardLength1 === numCards1) {
            this.currentIndex = currentIndex1 = currentIndex1 + numCards1;
        }
        if (currentIndex1 + cardLength1 >= workInstructionCount1 && showLoadButton1) {
            this.pageNumber += 1;
            await this._loadMore.perform();
        }
    }));
    onSearchKeyUp = task({
        restartable: true
    }, waitFor(async ({ target: { value: searchTerm1 } })=>{
        Object.assign(this, {
            searchTerm: searchTerm1,
            currentIndex: 0
        });
        if (isBlank(searchTerm1)) {
            await this._resetList.perform();
            return;
        }
        await timeout(getDelayTime(300));
        await this._search.perform();
    }));
    onClearSearch = task({
        restartable: true
    }, waitFor(async ()=>{
        this.currentIndex = 0;
        await this._resetList.perform();
    }));
    onSearchClicked = task(waitFor(async ()=>{
        this.currentIndex = 0;
        await this._search.perform();
    }));
    loadFolder = task({
        restartable: true
    }, waitFor(async (folder1)=>{
        const { selectedFolder: selectedFolder1 } = this;
        this.currentIndex = 0;
        if (folder1 == null && selectedFolder1.isInvalid) {
            // Reset the folder to have its previous (i.e. non-blank) name.
            selectedFolder1.rollbackAttributes();
        }
        Object.assign(this, {
            searchTerm: null,
            pageNumber: 1,
            selectedFolder: folder1
        });
        if (folder1 == null) {
            await this._resetList.perform();
            return;
        }
        const results1 = await this._query.perform();
        this.#setQueryResults(results1);
    }));
    _search = task({
        restartable: true
    }, waitFor(async ()=>{
        const { searchTerm: searchTerm1 } = this;
        this.pageNumber = 1;
        if (isBlank(searchTerm1)) {
            return;
        }
        const folders1 = this.workInstructionRepo.searchFolders(searchTerm1);
        const results1 = await this._query.perform();
        this.#setQueryResults({
            folders: folders1,
            ...results1
        });
    }));
    _loadMore = task(waitFor(async ()=>{
        const { searchTerm: searchTerm1, selectedFolder: selectedFolder1, filteredWorkInstructions: filteredWorkInstructions1, filteredFolders: filteredFolders1 } = this;
        const folders1 = selectedFolder1 ? [] : this.workInstructionRepo.searchFolders(searchTerm1);
        const { instructions: instructions1, count: count1 } = await this._query.perform();
        addObjectsIfNotPresent(filteredWorkInstructions1, instructions1);
        addObjectsIfNotPresent(filteredFolders1, folders1);
        this.recordCount = count1 + folders1.length;
    }));
    _resetList = task(waitFor(async ()=>{
        Object.assign(this, {
            searchTerm: null,
            pageNumber: 1
        });
        const folders1 = this.selectedFolder ? [] : this.workInstructionRepo.searchFolders();
        const results1 = await this._query.perform();
        this.#setQueryResults({
            folders: folders1,
            ...results1
        });
        this._addRecordToFilteredInstructions();
    }));
    _query = task(waitFor(async ()=>{
        return await this.workInstructionCache.queryInstructions.perform({
            searchTerm: this.searchTerm,
            pageNumber: this.pageNumber,
            folder: this.selectedFolder?.id,
            deployed: true
        });
    }));
    #setQueryResults({ count: count1, instructions: instructions1, folders: folders1 = [] }) {
        Object.assign(this, {
            recordCount: count1 + folders1.length,
            filteredWorkInstructions: new TrackedArray(instructions1),
            filteredFolders: new TrackedArray(folders1)
        });
    }
    onCardDoubleClick = (workInstruction1)=>{
        const { args: { isDisabled: isDisabled1, currentWorkInstruction: currentWorkInstruction1 } } = this;
        if (isDisabled1) {
            return;
        }
        if (workInstruction1.id === currentWorkInstruction1?.id) {
            return;
        }
        this.args.loadWorkInstruction?.(workInstruction1);
    };
    onResize = (element1)=>{
        const { maxCardsDisplayed: maxCardsDisplayed1 } = this;
        const { width: width1 } = element1.getBoundingClientRect();
        const numCardsToFit1 = Math.floor(width1 / TASK_CARD_WIDTH);
        if (numCardsToFit1 === maxCardsDisplayed1) {
            return;
        }
        this.maxCardsDisplayed = numCardsToFit1;
    };
    onClickPrevious = (currentIndex1, numCards1)=>{
        this.currentIndex = currentIndex1 - numCards1;
    };
    _addRecordToFilteredInstructions() {
        const { args: { currentWorkInstruction: currentWorkInstruction1 }, filteredWorkInstructions: filteredWorkInstructions1, showLoadButton: showLoadButton1 } = this;
        if (currentWorkInstruction1 == null || currentWorkInstruction1.isDestroyed || filteredWorkInstructions1.includes(currentWorkInstruction1)) {
            return;
        }
        this.filteredWorkInstructions.push(currentWorkInstruction1);
        if (showLoadButton1) {
            this.recordCount += 1;
        }
    }
    _jumpCarousel(allCards1, numCards1, currentWorkInstruction1) {
        if (currentWorkInstruction1 == null) {
            return;
        }
        let index1 = allCards1.indexOf(currentWorkInstruction1);
        if (index1 === -1) {
            return;
        }
        while(index1 % numCards1 !== 0){
            index1 -= 1;
        }
        this.currentIndex = index1;
    }
    static{
        template(`
    <div
      class="component-work-instruction-editor-library-task"
      {{didUpdate this.onDidUpdate.perform @currentWorkInstruction @config}}
      ...attributes
    >
      <div class="input-group flex-nowrap bg-white mb-2">
        <div class="d-flex position-relative w-100">
          <input
            value={{this.searchTerm}}
            placeholder={{t "searchFor"}}
            class="library-search form-control"
            {{on "keyup" this.onSearchKeyUp.perform}}
          />
          {{#if this.searchTerm.length}}
            <button
              class="clear form-control form-control-sm"
              type="button"
              {{on "click" this.onClearSearch.perform}}
            >
              <div class="icon icon-clear"></div>
            </button>
          {{/if}}
        </div>
        <BsButton
          class="input-group-append search"
          @type="secondary"
          @onClick={{this.onSearchClicked.perform}}
        >
          <FaIcon @icon="magnifying-glass" @prefix="fas" />
        </BsButton>
      </div>
      <div class="library-carousel-container d-flex rounded bg-white">
        <div class="carousel-arrow carousel-prev">
          <button
            disabled={{eq this.currentIndex 0}}
            class="btn btn-lg icon icon-carousel-prev"
            type="button"
            {{on "click" (fn this.onClickPrevious this.currentIndex this.numCards)}}
          ></button>
        </div>
        {{#if this.showSpinner}}
          <Spinner class="position-absolute" />
        {{/if}}
        <div
          class="library-carousel flex-fill d-flex"
          {{onResize this.onResize}}
          {{didInsert this.onDidInsert.perform}}
        >
          {{#if this.selectedFolder}}
            <div class="return-card d-flex flex-column">
              <div title={{this.selectedFolder.name}} class="folder-title">
                <div class="icon icon-folder me-1"></div>{{this.selectedFolder.name}}
              </div>
              <WorkInstructionEditorLibraryCard
                @workInstruction={{null}}
                @isReadOnly={{true}}
                {{on "click" (fn this.loadFolder.perform null)}}
              />
            </div>
          {{/if}}

          {{#if (and (isEmpty this.allCards) (not this.showSpinner))}}
            <div class="jem-library-empty p-2">
              {{#if this.selectedFolder}}
                {{t "workInstructions.folderIsEmpty"}}
              {{else if this.searchTerm.length}}
                {{t "workInstructions.noResults"}}
              {{else}}
                <div class="empty-intro">
                  {{t "plant.station.jem.emptyLibrary"}}
                </div>
                {{t "plant.station.jem.createInstruction-before"}}
                <Link @route="workInstructions">
                  {{t "plant.station.jem.createInstruction-link"}}
                </Link>
                {{t "plant.station.jem.createInstruction-after"}}
              {{/if}}
            </div>
          {{else}}
            <div class="library-carousel-inner d-flex">
              {{#each this.visibleCards as |card|}}
                {{#if card.isFolder}}
                  <WorkInstructionEditorLibraryCard
                    @workInstruction={{card}}
                    @isReadOnly={{true}}
                    {{on "dblclick" (fn this.loadFolder.perform card)}}
                  />
                {{else}}
                  <WorkInstructionEditorLibraryCard
                    @workInstruction={{card}}
                    @isActive={{eq card.id @currentWorkInstruction.id}}
                    @isReadOnly={{true}}
                    {{on "click" (fn this.onCardDoubleClick card)}}
                  />
                {{/if}}
              {{/each}}
            </div>
          {{/if}}
        </div>
        <div class="carousel-arrow carousel-next">
          <button
            disabled={{this.disableNext}}
            class="btn btn-lg icon icon-carousel-next"
            type="button"
            {{on "click" this.clickNext.perform}}
          ></button>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
