import { template } from "@ember/template-compiler";
import { fn } from '@ember/helper';
import { t } from 'ember-intl';
import { add } from 'ramda';
import { sortBy, findBy } from '@eflexsystems/ember-composable-helpers';
const setOutputOnly = (hardwareIo1, { value: value1 })=>{
    hardwareIo1.outputOnly = value1;
};
const typeOptions = [
    {
        label: 'input',
        value: false
    },
    {
        label: 'output',
        value: true
    }
];
export default template(`
<div class="row form-row">
  <div class="col-6">
    <@form.element
      @controlType="text"
      @label={{t "ipAddress"}}
      @property="ipAddress"
      class="hardware-ip-address is-required"
    />
  </div>
</div>
<div class="bootstrap-table border-0">
  <div class="row form-row no-hover-row fs-4 text-muted pt-2 pb-0">
    <div class="col-auto">
      <div class="width-rem-1"></div>
    </div>
    <div class="col">
      {{t "inputOutput"}}
    </div>
  </div>

  {{#each (sortBy "index" @hardware.hardwareIos) as |io|}}
    <div class="row form-row io-row">
      <div class="col-auto">
        <label class="width-rem-1 fs-4 text-muted">
          {{add 1 io.index}}
        </label>
      </div>

      <div class="col">
        <@form.element
          @controlType="text"
          @model={{io}}
          @property={{if io.outputOnly "outputName" "inputName"}}
          class="{{if io.outputOnly "io-output-name" "io-input-name"}} w-100"
        />
      </div>
      <div class="col-auto">
        <@form.element
          @controlType="radio-group"
          @options={{typeOptions}}
          @optionLabelPath="label"
          @value={{findBy "value" io.outputOnly typeOptions}}
          @onChange={{fn setOutputOnly io}}
        as |el|>
          <el.control @inline={{true}} as |opt|>
            {{t opt}}
          </el.control>
        </@form.element>
      </div>
    </div>
  {{/each}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
