import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { clone, pipe, reject } from 'ramda';
import { sortByProp } from 'ramda-adjunct';
import SidePanel from 'eflex/components/side-panel';
import BsForm from 'eflex/components/bs-form';
import { t } from 'ember-intl';
import { fn } from '@ember/helper';
import { peekRecord, caption } from 'eflex/helpers';
import { autofocus } from 'eflex/modifiers';
import { findBy } from '@eflexsystems/ember-composable-helpers';
import { and } from 'ember-truth-helpers';
export default class BomBuilderPanel extends Component {
    @service
    store;
    get buildDatumComponents() {
        return pipe(reject((component1)=>{
            if (component1.isAlwaysRun) {
                return true;
            }
            if (component1.id != null) {
                const componentRecord1 = this.store.peekRecord('component', component1.id);
                return componentRecord1?.isComplex ?? false;
            }
            return false;
        }), sortByProp('priority'))(this.args.buildDatum?.components ?? []);
    }
    willDestroy() {
        super.willDestroy(...arguments);
        this.args.onClose?.();
    }
    setOption = (componentId1, option1)=>{
        this.args.buildDatum.components = this.args.buildDatum.components.map((component1)=>{
            if (component1.id !== componentId1) {
                return clone(component1);
            }
            if (option1.id == null) {
                return {
                    ...clone(component1),
                    option: null,
                    rawValue: null
                };
            } else {
                return {
                    ...clone(component1),
                    option: {
                        id: option1.id,
                        captions: clone(option1.captions),
                        priority: option1.priority
                    },
                    rawValue: option1.value
                };
            }
        });
    };
    setVariableRawValue = (variableId1, rawValue1)=>{
        // firing on input causes rerender asserts
        if (event.type !== 'change') {
            return;
        }
        this.args.buildDatum.variables = this.args.buildDatum.variables.map((variable1)=>{
            if (variable1.id === variableId1) {
                return {
                    ...clone(variable1),
                    rawValue: rawValue1
                };
            }
            return clone(variable1);
        });
    };
    static{
        template(`
    <SidePanel
      @header="{{t "bomBuilder"}} - {{@buildDatum.serialNumber}}"
      @onClose={{@onClose}}
      class="sn-monitor-panel"
    >
      <div class="bom-builder-panel h-100 p-3">
        <BsForm
          class="h-100 d-flex flex-column"
          @model={{@buildDatum}}
          @disabled={{@disabled}}
          @showValidations={{@showValidations}}
        as |form|>
          <form.element
            @controlType="text"
            @property="serialNumber"
            @label={{t "bomBuilder.serialNumber"}}
            class="serial-number"
            {{autofocus}}
          as |el|>
            <el.control disabled={{@buildDatum.isFinished}} />
          </form.element>
          <div class="bootstrap-table sticky-top bg-white mt-3 overflow-auto">
            {{#if this.buildDatumComponents}}
              <div class="row form-row header-row app-component-header">
                <div class="col">
                  {{t "component"}}
                </div>
                <div class="col">
                  {{t "option"}}
                </div>
              </div>
              {{#each this.buildDatumComponents as |datumComponent|}}
                <div class="app-component-row row form-row align-items-center">
                  <div class="col component-name">
                    {{caption datumComponent.captions}}
                  </div>
                  <div class="col">
                    {{#let (findBy "id" datumComponent.id @bomSource.components) as |sourceComponent|}}
                      <form.element
                        @controlType="power-select"
                        @options={{sourceComponent.availableOptions}}
                        @value={{
                          findBy
                          "value"
                          datumComponent.rawValue
                          sourceComponent.availableOptions
                        }}
                        @onChange={{fn this.setOption datumComponent.id}}
                      as |el|>
                        <el.control
                          class="app-option-select"
                          @placeholder={{t "noConfiguration"}}
                          @searchField="name"
                          @searchEnabled={{true}}
                        as |option|>
                          <span class={{unless option.id "fst-italic"}}>
                            {{option.name}}
                          </span>
                        </el.control>
                      </form.element>
                    {{/let}}
                  </div>
                </div>
              {{/each}}
            {{/if}}
            {{#if @buildDatum.variables}}
              <hr>
              <div
                class="row form-row header-row app-variable-header"
              >
                <div class="col-6">
                  {{t "variable"}}
                </div>
                <div class="col">
                  {{t "value"}}
                </div>
                {{#if @bomSource.usesVariableValidation}}
                  <div class="col col-type">
                    {{t "type"}}
                  </div>
                {{/if}}
              </div>
              {{#each @buildDatum.variables as |datumVariable|}}
                {{#let
                  (peekRecord 'bomVariable' datumVariable.id)
                  @bomSource.usesVariableValidation
                as |bomVariable usesValidation|}}
                  <div class="app-variable-row row form-row align-items-baseline">
                    <div class="col-6">
                      {{caption datumVariable.captions}}
                    </div>
                    <div class="col">
                      <form.element
                        class="app-variable-value"
                        @controlType={{if (and usesValidation bomVariable.isNumeric) 'number' 'text'}}
                        @value={{datumVariable.rawValue}}
                        @onChange={{fn this.setVariableRawValue datumVariable.id}}
                      as |el|>
                        <el.control maxlength={{if usesValidation bomVariable.length}} />
                      </form.element>
                    </div>
                    {{#if usesValidation}}
                      <div class="col col-type">
                        {{#if bomVariable.dataType}}
                          {{t bomVariable.dataType}}
                        {{/if}}
                      </div>
                    {{/if}}
                  </div>
                {{/let}}
              {{/each}}
            {{/if}}
          </div>
        </BsForm>
      </div>
    </SidePanel>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
