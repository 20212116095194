import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import { ObjectId } from 'bson';
import { waitFor } from '@ember/test-waiters';
import { t } from 'ember-intl';
import BsForm from 'eflex/components/bs-form';
export default class BiPanelBoltData extends Component {
    @service
    intl;
    @service
    queryRunner;
    get params() {
        return {
            stationIds: this.args.stationIds,
            modelIds: this.args.modelIds,
            fasteners: this.args.fasteners
        };
    }
    get selectedFasteners() {
        return this.args.fasteners;
    }
    getFastenerData = task({
        drop: true
    }, waitFor(async (params1)=>{
        const boltDataQuery1 = [
            {
                $match: {
                    taskType: 'torque'
                }
            },
            this.getStationMatch(params1.stationIds),
            {
                $lookup: {
                    from: 'LocationBases',
                    localField: '_id',
                    foreignField: 'parent',
                    as: 'taskConfigs'
                }
            },
            {
                $unwind: {
                    path: '$taskConfigs'
                }
            },
            {
                $group: {
                    _id: '$_id',
                    taskName: {
                        $first: {
                            $first: '$captions.text'
                        }
                    },
                    maxBoltNumber: {
                        $max: '$taskConfigs.boltCount'
                    }
                }
            },
            {
                $sort: {
                    taskName: 1
                }
            }
        ];
        let fastenerData1 = await this.queryRunner.runQuery.perform('LocationBases', boltDataQuery1);
        fastenerData1 = fastenerData1.flatMap(({ taskName: taskName1, maxBoltNumber: maxBoltNumber1, _id: _id1 })=>{
            const fastenersArray1 = [];
            for(let i1 = 1; i1 <= maxBoltNumber1; i1++){
                fastenersArray1.push({
                    _id: _id1,
                    name: `${taskName1} ${this.intl.t('fastener')} ${i1}`,
                    idAndFastener: `${_id1}${i1}`
                });
            }
            fastenersArray1.toSorted((a1, b1)=>a1.name - b1.name);
            return fastenersArray1;
        });
        return fastenerData1;
    }));
    fastenerOptions = trackedTask(this, this.getFastenerData, ()=>[
            this.params
        ]);
    getStationMatch(stationIds1) {
        if (stationIds1.length > 0) {
            return {
                $match: {
                    parent: {
                        $in: stationIds1.map((stationId1)=>new ObjectId(stationId1))
                    }
                }
            };
        } else {
            return {
                $match: {}
            };
        }
    }
    static{
        template(`
    <BsForm as |form|>
      <form.element
        class="fastener-select"
        @controlType="power-select-multiple"
        @label={{t "fasteners"}}
        @value={{this.selectedFasteners}}
        @optionLabelPath="name"
        @options={{this.fastenerOptions.value}}
        @onChange={{@onChange}}
      as |el|>
        <el.control
            @searchEnabled={{true}}
         />
      </form.element>
    </BsForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
