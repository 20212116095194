import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isAncestorOf } from 'eflex/util/tree-helpers';
import SidePanel from 'eflex/components/side-panel';
import BsForm from 'eflex/components/bs-form';
import { t } from 'ember-intl';
import { notEq, and, not, or } from 'ember-truth-helpers';
import NoRouteTabs from 'eflex/components/no-route-tabs';
import { hash, fn } from '@ember/helper';
import { scrollIntoView } from 'eflex/modifiers';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import Spinner from 'eflex/components/spinner';
import { eventBus } from 'eflex/helpers';
import BsButton from 'eflex/components/bs-button';
import ContextSelector from 'eflex/components/context-selector';
import TaskDeviceConfig from 'eflex/components/task-device-config';
import TaskContextConfig from 'eflex/components/task-context-config';
import DecisionConfig from 'eflex/components/decision-config';
import TriggerConfigs from 'eflex/components/trigger-configs';
import JemInstructionsConfiguration from 'eflex/components/jem/instructions/configuration';
import VerticalCollection from '@html-next/vertical-collection/components/vertical-collection/component';
import { isTesting as isTestingMacro, macroCondition } from '@embroider/macros';
import { copyAllToAllContexts, copyEventToAllContexts, copyDecisionToAllContexts } from 'eflex/services/task-config-repo';
import { copyToAllContexts as copyWorkInstructionToAllContexts } from 'eflex/services/work-instruction-repo';
import { changeType } from 'eflex/services/task-repo';
import { dedupeTracked } from 'tracked-toolbox';
// eslint-disable-next-line no-unneeded-ternary
const isTesting = macroCondition(isTestingMacro()) ? true : false;
const TASK_CONFIGS_CHUNK_SIZE = 15;
const getTaskConfigsChunked = (taskConfigs1, taskConfigsCurrentChunks1)=>{
    return taskConfigs1.slice(0, taskConfigsCurrentChunks1);
};
const getAllTaskConfigsForWorkInstructions = (taskConfigs1, context1)=>{
    return taskConfigs1.filter((_taskConfig1)=>{
        return taskConfigIsDisplayed(_taskConfig1, context1);
    });
};
const taskConfigIsDisplayed = (taskConfig1, context1)=>{
    switch(true){
        case taskConfig1.isDeleted:
            {
                return false;
            }
        case taskConfig1.usesComponents && context1.allSelected:
            {
                return taskConfig1.component === taskConfig1.parentComponent;
            }
        case taskConfig1.usesComponents:
            {
                return taskConfig1.configOption === context1;
            }
        case taskConfig1.usesOperations:
        case context1.allSelected:
            {
                return true;
            }
        default:
            {
                return taskConfig1.configModel === context1;
            }
    }
};
const onCopyAllToAllContexts = (taskConfigs1)=>{
    const toCopy1 = taskConfigs1.find((_taskConfig1)=>{
        return taskConfigIsDisplayed(_taskConfig1, {
            allSelected: true
        });
    });
    copyAllToAllContexts(toCopy1);
};
export default class TaskPanel extends Component {
    @service
    workInstructionCache;
    @service
    defaultTaskConfigContext;
    @tracked
    taskConfigsCurrentChunks = 15;
    @tracked
    selectedContext = this.defaultTaskConfigContext.getContext(this.args.taskParent);
    @dedupeTracked
    scrollToTop = false;
    previousTask = this.args.task;
    constructor(){
        super(...arguments);
        this.workInstructionCache.setupAsync.perform();
    }
    onTreeItemDeleted = (treeItem1)=>{
        const treeTask1 = this.args.task;
        if (treeTask1 != null && (treeItem1 === treeTask1 || isAncestorOf(treeItem1, treeTask1))) {
            this.args.onCancel();
        }
    };
    onDidUpdate = (elem1, [treeTask1])=>{
        if (treeTask1 != null && treeTask1 !== this.previousTask) {
            this.scrollToTop = true;
        }
        this.previousTask = treeTask1;
    };
    contextWasSelected = (context1)=>{
        this.selectedContext = context1;
        if (context1.allSelected) {
            this.scrollToTop = true;
        }
    };
    tabBecameActive = (tabName1)=>{
        this.scrollToTop = true;
        this.args.tabBecameActive(tabName1);
    };
    loadMoreInstructions = (taskConfigsLength1, taskConfigsChunkedLength1)=>{
        if (taskConfigsChunkedLength1 < taskConfigsLength1) {
            this.taskConfigsCurrentChunks += TASK_CONFIGS_CHUNK_SIZE;
        }
    };
    static{
        template(`
    <SidePanel
      @header={{t "taskConfig"}}
      @subheader={{@taskParent.name}}
      @subheader2={{@task.name}}
      @onClose={{@onConfirm}}
      {{didUpdate this.onDidUpdate @task}}
    >
      {{eventBus deletedTreeItem=this.onTreeItemDeleted}}

      {{#if @isLoading}}
        <Spinner />
      {{else}}
        <div class="task-panel h-100 d-flex flex-column">
          <BsForm
            class="task-info bg-gray-100 p-3"
            @showValidations={{@showValidations}}
            @model={{@task}}
          >
            {{#if (and @task (not @task.usesOperations))}}
              <div class="row form-row align-items-end">
                <div class="col">
                  <ContextSelector
                    @treeItem={{@task}}
                    @renderInPlace={{true}}
                    @disableComponentSelect={{@disabled}}
                    @selectedContext={{this.selectedContext}}
                    @contextWasSelected={{this.contextWasSelected}}
                    @allowAll={{true}}
                  />
                </div>
                <div class="col-auto">
                  <label class="form-label"></label>
                  <div>
                    <BsButton
                      @type="secondary"
                      @onClick={{fn onCopyAllToAllContexts @task.taskConfigs}}
                      @disabled={{
                        or
                        this.selectedContext.allSelected
                        @task.component.isAlwaysRun
                        @disabled
                      }}
                      class="copy-to-all"
                    >
                      {{#if @task.usesComponents}}
                        {{t "copyToAllOptions"}}
                      {{else}}
                        {{t "copyToAllModels"}}
                      {{/if}}
                    </BsButton>
                  </div>
                </div>
              </div>
            {{/if}}
          </BsForm>
          {{#if @task}}
            <div class="task-panel-tabs d-flex flex-column">
              <NoRouteTabs
                @activeTab={{@activeTab}}
                @tabBecameActive={{this.tabBecameActive}}
              as |tabs|>
                <div class="task-tabs-wrapper bg-gray-100">
                  <tabs.navigation class="mb-0" as |nav|>
                    <nav.tab @href="task-device">
                      {{t "plant.task.device"}}
                    </nav.tab>
                    <nav.tab @href="task-context">
                      {{#if @task.usesComponents}}
                        {{t "plant.task.componentSpecificConfiguration"}}
                      {{else if @task.usesOperations}}
                        {{t "plant.task.operationSpecificConfiguration"}}
                      {{else}}
                        {{t "plant.task.modelSpecificConfiguration"}}
                      {{/if}}
                    </nav.tab>
                    {{#unless @task.isDecision}}
                      <nav.tab @href="task-decision">
                        {{t "decisions"}}
                      </nav.tab>
                    {{/unless}}
                    <nav.tab @href="task-event">
                      {{t "events"}}
                    </nav.tab>
                    <nav.tab @href="task-wie">
                      {{t "navigation.plant.editor.workInstructions"}}
                    </nav.tab>
                  </tabs.navigation>
                </div>
                <tabs.panes class="p-3 pt-4 overflow-y-auto overflow-x-hidden" as |panes|>
                  <panes.pane @id="task-device">
                    <div
                      class="eflex-scroll task-panes-wrapper flex-column"
                      {{scrollIntoView
                        shouldScroll=this.scrollToTop
                        options=(hash behavior="smooth" top=0)
                      }}
                    >
                      <TaskDeviceConfig
                        @task={{@task}}
                        @taskParent={{@taskParent}}
                        @showValidations={{@showValidations}}
                        @disabled={{@disabled}}
                        @selectedTaskType={{fn changeType @task}}
                      />
                    </div>
                  </panes.pane>
                  <panes.pane @id="task-context">
                    <div class="eflex-scroll task-panes-wrapper flex-column">
                      {{#each @task.taskConfigs as |taskConfig i|}}
                        {{#if (taskConfigIsDisplayed taskConfig this.selectedContext)}}
                          <div class="task-context-config">
                            {{#if (notEq i 0)}}
                              <hr />
                            {{/if}}

                            <TaskContextConfig
                              class='pb-2'
                              @task={{@task}}
                              @taskConfig={{taskConfig}}
                              @disabled={{@disabled}}
                              @showValidations={{@showValidations}}
                            />
                          </div>
                        {{/if}}
                      {{/each}}
                    </div>
                  </panes.pane>
                  {{#unless @task.isDecision}}
                    <panes.pane @id="task-decision">
                      <div class="eflex-scroll task-panes-wrapper flex-column">
                        {{#each @task.taskConfigs as |taskConfig i|}}
                          {{#if (taskConfigIsDisplayed taskConfig this.selectedContext)}}
                            <div class="task-decision-config">
                              {{#if (notEq i 0)}}
                                <hr />
                              {{/if}}
                              <div class="context-name model-specific label fw-semibold pb-3 fs-4">
                                {{taskConfig.configName}}
                              </div>
                              <DecisionConfig
                                @taskConfig={{taskConfig}}
                                @task={{@task}}
                                @disabled={{@disabled}}
                                @showValidations={{@showValidations}}
                              />
                              {{#unless @task.usesOperations}}
                                <div class="d-flex justify-content-end mt-3">
                                  <BsButton
                                    class="copy-tab"
                                    @type="secondary"
                                    @onClick={{fn copyDecisionToAllContexts @taskConfig}}
                                    @disabled={{@task.component.isAlwaysRun}}
                                  >
                                    {{#if @task.usesComponents}}
                                      {{t "copyOptionSpecific"}}
                                    {{else}}
                                      {{t "copyModelSpecific"}}
                                    {{/if}}
                                  </BsButton>
                                </div>
                              {{/unless}}
                            </div>
                          {{/if}}
                        {{/each}}
                      </div>
                    </panes.pane>
                  {{/unless}}
                  <panes.pane @id="task-event">
                    <div class="eflex-scroll task-panes-wrapper flex-column">
                      {{#each @task.taskConfigs as |taskConfig i|}}
                        {{#if (taskConfigIsDisplayed taskConfig this.selectedContext)}}
                          <div class="task-event-config">
                            {{#if (notEq i 0)}}
                              <hr />
                            {{/if}}
                            <div class="context-name model-specific label fw-semibold pb-3 fs-4">
                              {{taskConfig.configName}}
                            </div>
                            <TriggerConfigs
                              @location={{@task}}
                              @triggerConfigs={{taskConfig.triggerConfigs}}
                              @showValidations={{@showValidations}}
                              @disabled={{@disabled}}
                            />
                            {{#unless @task.usesOperations}}
                              <div class="d-flex justify-content-end mt-3">
                                <BsButton
                                  @type="secondary"
                                  @onClick={{fn copyEventToAllContexts taskConfig}}
                                  @disabled={{@task.component.isAlwaysRun}}
                                  class="copy-tab"
                                >
                                  {{#if @task.usesComponents}}
                                    {{t "copyOptionSpecific"}}
                                  {{else}}
                                    {{t "copyModelSpecific"}}
                                  {{/if}}
                                </BsButton>
                              </div>
                            {{/unless}}
                          </div>
                        {{/if}}
                      {{/each}}
                    </div>
                  </panes.pane>
                  <panes.pane @id="task-wie" class="mh-100">
                    {{#let
                      (getAllTaskConfigsForWorkInstructions @task.taskConfigs this.selectedContext)
                    as |allTaskConfigs|}}
                      {{#let (getTaskConfigsChunked allTaskConfigs) as |taskConfigsChunked|}}
                        <VerticalCollection
                          @items={{taskConfigsChunked}}
                          @estimateHeight="500px"
                          @lastReached={{
                            fn
                            this.loadMoreInstructions
                            allTaskConfigs.length
                            taskConfigsChunked.length
                          }}
                          @renderAll={{isTesting}}
                        as |taskConfig i|>
                          <div class="task-instruction-config">
                            {{#if (notEq i 0)}}
                              <hr />
                            {{/if}}
                            <div class="context-name model-specific label fw-semibold fs-4 pb-3">
                              {{taskConfig.configName}}
                            </div>
                            <JemInstructionsConfiguration
                              @taskConfig={{taskConfig}}
                              @usesComponents={{taskConfig.usesComponents}}
                              @disabled={{@disabled}}
                              @useEvent={{true}}
                              @showCopyToAll={{not @task.usesOperations}}
                              @copyToAll={{fn copyWorkInstructionToAllContexts @task}}
                            />
                          </div>
                        </VerticalCollection>
                      {{/let}}
                    {{/let}}
                  </panes.pane>
                </tabs.panes>
              </NoRouteTabs>
            </div>
          {{/if}}
        </div>
      {{/if}}
    </SidePanel>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
