import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import { all, task, timeout } from 'ember-concurrency';
import { taskTypes } from 'eflex/constants/tasks/task-types';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { sum } from 'ramda';
import { TrackedArray } from 'tracked-built-ins';
import Component from '@glimmer/component';
import Breadcrumbs from 'eflex/components/breadcrumbs';
import CheckboxInput from 'eflex/components/checkbox-input';
import { fn } from '@ember/helper';
import { inputNum } from 'eflex/helpers';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { isPresent } from '@ember/utils';
import SpinnerOverlay from 'eflex/components/spinner-overlay';
import RouteTemplate from 'ember-route-template';
import { isTesting, isDevelopingApp, macroCondition } from '@embroider/macros';
let DataGeneratorTemplate;
if (macroCondition(isDevelopingApp() && !isTesting())) {
    DataGeneratorTemplate = class extends Component {
        @service
        locationRepo;
        @service
        store;
        @service
        areaRepo;
        @service
        groupRepo;
        @service
        stationRepo;
        @service
        taskRepo;
        @tracked
        areaCount = 1;
        @tracked
        modelCount = 10;
        @tracked
        groupCount = 1;
        @tracked
        stationCount = 5;
        @tracked
        taskCount = 10;
        @tracked
        persist = true;
        @tracked
        profile = false;
        @tracked
        results = new TrackedArray();
        @tracked
        totalTimeToCreate;
        @tracked
        totalTimeToSave;
        @tracked
        current;
        get numRecords() {
            let numRecords1 = this.areaCount;
            numRecords1 += this.areaCount * this.modelCount;
            numRecords1 += this.areaCount * this.groupCount;
            numRecords1 += this.areaCount * this.groupCount * this.stationCount;
            numRecords1 += this.areaCount * this.groupCount * this.stationCount * this.taskCount;
            return numRecords1;
        }
        generate = task(waitFor(async ()=>{
            await timeout(0); // let animation start
            if (this.profile) {
                // eslint-disable-next-line no-console
                console.profile('create');
            }
            const times1 = {
                areaTimes: [],
                groupTimes: [],
                stationTimes: [],
                taskTimes: [],
                modelTimes: []
            };
            this.current = 1;
            const areas1 = [];
            const start1 = performance.now();
            for(let i1 = 0; i1 < this.areaCount; i1++){
                areas1.push(this._createArea(i1, times1));
            }
            if (this.profile) {
                // eslint-disable-next-line no-console
                console.profileEnd('create');
            }
            Object.assign(this, {
                results: new TrackedArray(),
                totalTimeToCreate: Math.round(performance.now() - start1)
            });
            for (const type1 of [
                'area',
                'model',
                'group',
                'station',
                'task'
            ]){
                const typeTimes1 = times1[`${type1}Times`];
                const total1 = Math.round(sum(typeTimes1));
                const average1 = Math.round(total1 / typeTimes1.length);
                this.results.push({
                    type: type1,
                    times: typeTimes1,
                    average: average1,
                    total: total1
                });
            }
            if (this.persist) {
                const allModels1 = areas1.flatMap((area1)=>area1.models);
                const groups1 = areas1.flatMap((area1)=>area1.groups);
                const stations1 = groups1.flatMap((group1)=>group1.stations);
                const tasks1 = stations1.flatMap((station1)=>station1.tasks);
                const taskConfigs1 = tasks1.flatMap((_task1)=>_task1.taskConfigs);
                const saveStart1 = performance.now();
                if (this.profile) {
                    // eslint-disable-next-line no-console
                    console.profile('save');
                }
                await all(areas1.map((area1)=>area1.save()));
                await all(allModels1.map((allModel1)=>allModel1.save()));
                await all(groups1.map((group1)=>group1.save()));
                await this.locationRepo.bulkSave.perform({
                    source: 'data generator',
                    stations: stations1,
                    tasks: tasks1,
                    taskConfigs: taskConfigs1
                });
                if (this.profile) {
                    // eslint-disable-next-line no-console
                    console.profileEnd('save');
                }
                this.totalTimeToSave = Math.round(performance.now() - saveStart1);
            }
        }));
        _createArea(areaId1, times1) {
            const areaStart1 = performance.now();
            const area1 = this.areaRepo.create({
                name: `Area ${areaId1}`
            });
            times1.areaTimes.push(performance.now() - areaStart1);
            this._incrementTotal();
            for(let i1 = 0; i1 < this.modelCount; i1++){
                const modelStart1 = performance.now();
                this.store.createRecord('model', {
                    code: i1,
                    area: area1
                });
                times1.modelTimes.push(performance.now() - modelStart1);
                this._incrementTotal();
            }
            for(let g1 = 0, end11 = this.groupCount; g1 < end11; g1++){
                this._createGroup(g1, area1, times1);
            }
            return area1;
        }
        _createGroup(locationNumber1, parent1, times1) {
            const start1 = performance.now();
            const group1 = this.groupRepo.create({
                parent: parent1,
                name: `Group ${locationNumber1}`
            });
            times1.groupTimes.push(performance.now() - start1);
            this._incrementTotal();
            for(let i1 = 0; i1 < this.stationCount; i1++){
                this._createStation(i1, group1, times1);
            }
        }
        _createStation(locationNumber1, parent1, times1) {
            const start1 = performance.now();
            const station1 = this.stationRepo.create({
                parent: parent1,
                name: `Station ${locationNumber1}`,
                jem: true
            });
            times1.stationTimes.push(performance.now() - start1);
            this._incrementTotal();
            for(let i1 = 0; i1 < this.taskCount; i1++){
                this._createTask(i1, station1, times1);
            }
        }
        _createTask(locationNumber1, parent1, times1) {
            const start1 = performance.now();
            this.taskRepo.create({
                parent: parent1,
                name: `Task ${locationNumber1}`,
                taskType: taskTypes.timer
            });
            times1.taskTimes.push(performance.now() - start1);
            this._incrementTotal();
        }
        _incrementTotal() {
            this.current += 1;
            if (this.current % 500 === 0 || this.current === this.numRecords) {
                // eslint-disable-next-line no-console
                console.log('Created', this.current, 'of', this.numRecords, 'total records.');
            }
        }
        static{
            template(`
      <Breadcrumbs @page="Data Generator" />

      <div class="row">
        <div class="col-sm-2">
          <label class="col-form-label">
            Num Areas
          </label>
          <input
            value={{this.areaCount}}
            type="number"
            class="form-control"
            {{on "input" (inputNum (fn (mut this.areaCount)))}}
          />
        </div>
        <div class="col-sm-2">
          <label class="col-form-label">
            Num Models
          </label>
          <input
            value={{this.modelCount}}
            type="number"
            class="form-control"
            {{on "input" (inputNum (fn (mut this.modelCount)))}}
          />
        </div>
        <div class="col-sm-2">
          <label class="col-form-label">
            Num Groups
          </label>
          <input
            value={{this.groupCount}}
            type="number"
            class="form-control"
            {{on "input" (inputNum (fn (mut this.groupCount)))}}
          />
        </div>
        <div class="col-sm-2">
          <label class="col-form-label">
            Num Stations
          </label>
          <input
            value={{this.stationCount}}
            type="number"
            class="form-control"
            {{on "input" (inputNum (fn (mut this.stationCount)))}}
          />
        </div>
        <div class="col-sm-2">
          <label class="col-form-label">
            Num Tasks
          </label>
          <input
            value={{this.taskCount}}
            type="number"
            class="form-control"
            {{on "input" (inputNum (fn (mut this.taskCount)))}}
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2">
          <label class="col-form-label">
            Persist Data?
          </label>
          <CheckboxInput
            @checked={{this.persist}}
            @onChange={{fn (mut this.persist)}}
          />
        </div>
        <div class="col-sm-2">
          <label class="col-form-label">
            Profile?
          </label>
          <CheckboxInput
            @checked={{this.profile}}
            @onChange={{fn (mut this.profile)}}
          />
        </div>
        <div class="col-sm-6"></div>
        <div class="col-sm-2">
          <button
            class="btn btn-secondary btn-sm"
            type="button"
            {{on "click" this.generate.perform}}
          >
            Create Locations
          </button>
        </div>
      </div>

      {{#if this.generate.isRunning}}
        <SpinnerOverlay @message={{t "loading.wait"}} />
      {{else if (isPresent this.results)}}
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>
                Type
              </th>
              <th>
                Number Created
              </th>
              <th>
                Average Time To Create (ms)
              </th>
              <th>
                Total Time (ms)
              </th>
            </tr>
          </thead>
          <tbody>
            {{#each this.results as |result|}}
              <tr>
                <td class="num-col">
                  {{result.type}}
                </td>
                <td class="num-col">
                  {{result.times.length}}
                </td>
                <td class="num-col">
                  {{result.average}}
                </td>
                <td class="num-col">
                  {{result.total}}
                </td>
              </tr>
            {{/each}}
          </tbody>
        </table>
        <h3>
          Total time to create {{this.numRecords}} records: {{this.totalTimeToCreate}}ms
        </h3>
      {{/if}}
      {{#if this.totalTimeToSave}}
        <h3>
          Total time to save: {{this.totalTimeToSave}} ms
        </h3>
      {{/if}}
    `, {
                component: this,
                eval () {
                    return eval(arguments[0]);
                }
            });
        }
    };
} else {
    DataGeneratorTemplate = template(``, {
        eval () {
            return eval(arguments[0]);
        }
    });
}
export default RouteTemplate(DataGeneratorTemplate);
