import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { or } from 'ember-truth-helpers';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { on } from '@ember/modifier';
export default class IconButton extends Component {
    onClick = task({
        drop: true
    }, waitFor(async ()=>{
        if (this.args.disabled) {
            return;
        }
        await this.args.onClick();
    }));
    static{
        template(`
    {{#let (or @disabled this.onClick.isRunning) as |disabled|}}
      {{#if @icon}}
        <FaIcon
          class='pointer {{if disabled 'disabled'}}'
          @icon={{@icon}}
          @prefix={{@prefix}}
          {{on 'click' this.onClick.perform}}
          ...attributes
        />
      {{else}}
        <i
          class='icon {{if disabled 'disabled'}}'
          {{on 'click' this.onClick.perform}}
          ...attributes
        ></i>
      {{/if}}
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
