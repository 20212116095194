import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import DecisionTypes from 'eflex/constants/tasks/decision-types';
import { chain, prop, reject, uniq } from 'ramda';
import { intoArray } from '@eflexsystems/ramda-helpers';
import BsForm from 'eflex/components/bs-form';
import { t } from 'ember-intl';
import TrashCan from 'eflex/components/trash-can';
import { fn } from '@ember/helper';
export default class DecisionConfig extends Component {
    @service
    taskRepo;
    get decisionDefConfigs() {
        return reject(prop('isDeleted'), this.args.taskConfig.decisionDefConfigs);
    }
    get showOptions() {
        return this.args.taskConfig.task.decisionType !== DecisionTypes.passFail;
    }
    get allDecisionTags() {
        const tags1 = intoArray(reject(prop('isDeleted')), chain(prop('decisionTags')), uniq)(this.taskRepo.tasks);
        return tags1.toSorted();
    }
    deleteDecisionDef = (decisionDef1)=>{
        decisionDef1.decisionDefConfigs.forEach((decisionDefConfig1)=>{
            decisionDefConfig1.deleteRecord();
        });
        decisionDef1.deleteRecord();
    };
    static{
        template(`
    <div class="bootstrap-table">
      <BsForm
        @showValidations={{@showValidations}}
        @model={{@taskConfig}}
      as |form|>
        <div class="row form-row header-row">
          {{#if this.showOptions}}
            <div class="col-icon border-0" />
          {{/if}}
          <div class="col">
            {{t "decisionName"}}
          </div>
          <div class="col">
            {{t "decisionTags"}}
          </div>
          {{#if this.showOptions}}
            <div class="col-icon border-0" />
          {{/if}}
        </div>
        {{#each this.decisionDefConfigs as |decisionDefConfig|}}
          <div class="decision-config-row row form-row">
            {{#if this.showOptions}}
              <div class="col-icon">
                <form.element
                  @controlType="checkbox"
                  @model={{decisionDefConfig}}
                  @property="enabled"
                  class="model-specific enable-decision"
                as |el|>
                  <el.control @disabled={{@disabled}} />
                </form.element>
              </div>
            {{/if}}
            <div class="col">
              {{#if this.showOptions}}
                <form.element
                  @controlType="text"
                  @model={{decisionDefConfig.decisionDef}}
                  @property="name"
                  class="decision-name"
                as |el|>
                  <el.control disabled={{@disabled}} />
                </form.element>
              {{else}}
                <div class="decision-name">{{decisionDefConfig.decisionDef.name}}</div>
              {{/if}}
            </div>
            <div class="col">
              <form.element
                @controlType="power-select-multiple"
                @model={{decisionDefConfig}}
                @property="decisionTags"
                @options={{this.allDecisionTags}}
                class="model-specific decision-tag-select"
              as |el|>
                <el.control
                  @disabled={{@disabled}}
                  @triggerClass="form-control"
                as |option|>
                  <span class="model-specific">
                    {{option}}
                  </span>
                </el.control>
              </form.element>
            </div>
            {{#if this.showOptions}}
              <div class="col-icon">
                <TrashCan
                  disabled={{@disabled}}
                  @onDelete={{fn this.deleteDecisionDef decisionDefConfig.decisionDef}}
                />
              </div>
            {{/if}}
          </div>
        {{/each}}
      </BsForm>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
