import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isBlank } from '@ember/utils';
import { fn, hash } from '@ember/helper';
import SearchInput from 'eflex/components/inputs/search';
import { or } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import PortalTarget from 'ember-stargate/components/portal-target';
import BsButton from 'eflex/components/bs-button';
import BsForm from 'eflex/components/bs-form';
import DateRangeCriteria from 'eflex/components/criteria-visor/date-range';
import ImageTypeCriteria from 'eflex/components/criteria-visor/image-type';
import LogLevelCriteria from 'eflex/components/criteria-visor/log-level';
import PassFailCriteria from 'eflex/components/criteria-visor/pass-fail';
import TextFieldCriteria from 'eflex/components/criteria-visor/text-field';
import TreeCriteria from 'eflex/components/criteria-visor/tree';
export default class CriteriaVisor extends Component {
    @tracked
    params = {};
    constructor(){
        super(...arguments);
        this.initParam('searchTerm', this.args.searchTerm);
    }
    initParam = (key1, value1)=>{
        if (isBlank(value1)) {
            return;
        }
        this.params[key1] = value1;
    };
    updateParam = (key1, value1)=>{
        if (isBlank(value1)) {
            delete this.params[key1];
        } else {
            this.params[key1] = value1;
        }
        this.args.onParamChange(key1, value1);
    };
    onSearch = ()=>{
        return this.args?.onSearch({
            ...this.params
        });
    };
    static{
        template(`
    <div
      class="component-criteria-visor"
      ...attributes
    >
      <div class="search-wrapper mb-3">
        <div class="row gx-2 align-items-center w-100">
          <div class={{or @searchWrapClass "col-7"}}>
            <div class="w-100">
              <SearchInput
                @inputClass={{@inputClass}}
                @searchTerm={{@searchTerm}}
                @placeholder={{@placeholder}}
                @onSubmit={{this.onSearch}}
                @onInput={{fn this.updateParam 'searchTerm'}}
              />
            </div>
          </div>

          <div class="col-auto">
            <BsButton
              class="advanced-criteria-btn fs-4"
              @size="sm"
              data-bs-toggle="offcanvas"
              data-bs-target="#criteria-visor-top"
            >
              {{t "controls.visor.advcrit"}}
            </BsButton>
          </div>

          {{yield to="rightOfSearch"}}

          {{yield this.params to="rightOfAdvancedCriteria"}}

        </div>

        <div class="breadcrumbs">
          <small class="results-label">
            {{t "controls.visor.resultsfor"}}:
          </small>

          <PortalTarget @name="search-tag" @multiple={{true}} />
        </div>
      </div>

      <div
        class="offcanvas offcanvas-top search-criteria oveflow-scroll-y eflex-criteria-visor p-4 pe-5"
        id="criteria-visor-top"
      >
        <button
            class="btn-close btn-close-white float-end"
            data-bs-toggle="offcanvas"
            data-bs-target="#criteria-visor-top"
            type="button"
          >
        </button>
        <div class="w-100 d-flex">
          <div class="ms-auto">
            <BsButton
              @type="success"
              @onClick={{this.onSearch}}
              class="visor-search"
              data-bs-toggle="offcanvas"
              data-bs-target="#criteria-visor-top"
            >
              {{t "search"}}
            </BsButton>
          </div>
        </div>
        <BsForm class="w-100 criteria-auto-grid" as |form|>
          {{yield
            (hash
              DateRange=(component
                DateRangeCriteria
                form=form
                updateParam=this.updateParam
                initParam=this.initParam
              )
              ImageType=(component
                ImageTypeCriteria
                form=form
                updateParam=this.updateParam
                initParam=this.initParam
              )
              LogLevel=(component
                LogLevelCriteria
                form=form
                updateParam=this.updateParam
                initParam=this.initParam
              )
              PassFail=(component
                PassFailCriteria
                form=form
                updateParam=this.updateParam
                initParam=this.initParam
              )
              TextField=(component
                TextFieldCriteria
                form=form
                updateParam=this.updateParam
                initParam=this.initParam
              )
              Tree=(component
                TreeCriteria
                form=form
                updateParam=this.updateParam
                initParam=this.initParam
              )
            )
          }}
        </BsForm>
      </div>
    </div>

    {{yield this.params to="belowSearch"}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
