import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import { usesBomSourceLookup, STATION_LOAD_OPTIONS } from 'eflex/constants/station-options';
import { waitFor } from '@ember/test-waiters';
import { waitForEvent, task } from 'ember-concurrency';
import { t } from 'ember-intl';
import BsButton from 'eflex/components/bs-button';
import { fn } from '@ember/helper';
import JemProductionScheduleLink from 'eflex/components/jem/production-schedule-link';
import JemModelDropdown from 'eflex/components/jem/model-dropdown';
import JemModelDisplay from 'eflex/components/jem/model-display';
import { and, or } from 'ember-truth-helpers';
import JemKineticJobOpSelector from 'eflex/components/jem/kinetic-job-op-selector';
import JemBomSourceLookupInput from 'eflex/components/jem/bom-source-lookup-input';
import JemSerialInput from 'eflex/components/jem/serial-input';
import JemSerialDisplay from 'eflex/components/jem/serial-display';
import JemBomVariables from 'eflex/components/jem/bom-variables';
import JemCustomIdentifierData from 'eflex/components/jem/custom-identifier-data';
import JemBuildStatus from 'eflex/components/jem/build-status';
import JemEndActivityModal from 'eflex/components/jem/end-activity-modal';
import { on } from '@ember/modifier';
import { set } from 'eflex/helpers';
import Modifier from 'ember-modifier';
let OnIframeMessageFocus = class OnIframeMessageFocus extends Modifier {
    #didSetup = false;
    modify(element1) {
        if (this.#didSetup) {
            return;
        }
        this.#didSetup = true;
        this._messageListener.perform(element1);
    }
    _messageListener = task({
        onState: null
    }, async (element1)=>{
        while(true){
            const event1 = await waitForEvent(globalThis, 'message');
            if (event1.data?.focusChildSelector) {
                element1.querySelector(event1.data?.focusChildSelector)?.focus();
            } else if (event1.data?.focusElement) {
                element1.focus();
            }
        }
    });
};
const getShowModelDropdown = (station1, stationIsRunning1)=>{
    if (stationIsRunning1) {
        return false;
    }
    return (station1?.loadOption === STATION_LOAD_OPTIONS.modelGenerateSerialNumber || station1?.loadOption === STATION_LOAD_OPTIONS.selectModel);
};
const getShowEndActivity = (station1, stationIsRunning1, notAuthorized1, kineticAssemblyOperation1)=>{
    return station1.usesOperations && !stationIsRunning1 && !notAuthorized1 && isPresent(kineticAssemblyOperation1);
};
const getSerialInputDisabled = (station1, kineticAssemblyOperation1, notAuthorized1)=>{
    return (station1.usesOperations && isEmpty(kineticAssemblyOperation1)) || notAuthorized1;
};
const getShowSerialInput = (station1, model1, stationIsRunning1, waitingForData1)=>{
    if (model1 == null && !station1.usesOperations && getShowModelDropdown(station1, stationIsRunning1)) {
        return false;
    }
    return !stationIsRunning1 && !waitingForData1;
};
let JemInfoBar = class JemInfoBar extends Component {
    @service
    currentUser;
    @tracked
    showEndActivityModal = false;
    onEndActivity = task(waitFor(async (activityNotes1, activityComplete1)=>{
        await this.args.onEndActivity(activityNotes1, activityComplete1);
        this.showEndActivityModal = false;
    }));
    static{
        template(`
    <div
      class="jem-info-bar row bg-gray-900 p-2 px-1 pt-0 pb-3"
      {{OnIframeMessageFocus}}
      ...attributes
    >
      {{#if @station.usesModels}}
        {{#if @station.productionScheduleEnabled}}
          <div class="col-auto d-flex align-items-center mt-4 pb-1">
            <JemProductionScheduleLink
              @disabled={{this.currentUser.isNotAdmin}}
              @productionSchedule={{@productionSchedule}}
              @stationIsRunning={{@stationIsRunning}}
            />
          </div>
        {{/if}}

        <div class="col-4">
          <label class="form-label">
            {{t "model"}}
          </label>

          {{#if (getShowModelDropdown @station @stationIsRunning)}}
            <JemModelDropdown
              @models={{@station.area.models}}
              @model={{@model}}
              @productionScheduleEnabled={{@station.productionScheduleEnabled}}
              @disabled={{or
                @stationIsRunning
                (or @notAuthorized @station.productionScheduleEnabled)
              }}
              @onChange={{@onModelSelected}}
              @onScan={{@onModelScanned}}
            />
          {{else}}
            <JemModelDisplay
              @productionScheduleEnabled={{@station.productionScheduleEnabled}}
              @productionScheduleModelCode={{@productionSchedule.model.code}}
              @buildStatusModelCode={{@buildStatus.modelName}}
            />
          {{/if}}
        </div>
      {{else if @station.usesOperations}}
        <JemKineticJobOpSelector
          @station={{@station}}
          @selectedJob={{@selectedJob}}
          @selectedAssembly={{@selectedAssembly}}
          @assemblyOperation={{@kineticAssemblyOperation}}
          @onJobSelected={{@onJobSelected}}
          @onAssemblySelected={{@onAssemblySelected}}
          @onOperationSelected={{@onOperationSelected}}
          @disabled={{or @stationIsRunning @notAuthorized}}
        />
      {{else if (usesBomSourceLookup @station)}}
        <JemBomSourceLookupInput
          disabled={{@notAuthorized}}
          @bomSourceLookupValue={{@bomSourceLookupValue}}
          @stationIsRunning={{@stationIsRunning}}
          @buildStatusBomSourceLookupValue={{@buildStatus.bomSourceLookupValue}}
          @tempSerialNumber={{@tempSerialNumber}}
          @onEnter={{fn @onSubmitSerialNumber @tempSerialNumber}}
          @onInput={{@onBomSourceLookupValueInput}}
          {{on "focusin" @onClearSelectedTask}}
        />
      {{/if}}
      <div class="col d-flex align-items-end">
        {{#if (getShowSerialInput @station @model @stationIsRunning @waitingForData)}}
          <JemSerialInput
            @disabled={{getSerialInputDisabled @station @kineticAssemblyOperation @notAuthorized}}
            @serialNumber={{@buildStatus.serialNumber}}
            @tempSerialNumber={{@tempSerialNumber}}
            @bomSourceLookupValue={{@bomSourceLookupValue}}
            @station={{@station}}
            @modelCode={{@model.code}}
            @kineticJobNumber={{@kineticAssemblyOperation.job.jobNumber}}
            @onSubmit={{@onSubmitSerialNumber}}
            @onInput={{@onSerialNumberInput}}
            @onScan={{@onSerialNumberScanned}}
            {{on "focusin" @onClearSelectedTask}}
          />
        {{else}}
          <JemSerialDisplay @buildStatus={{@buildStatus}} />
        {{/if}}
      </div>

      {{#if (getShowEndActivity @station @stationIsRunning @notAuthorized @kineticAssemblyOperation)}}
        <div class="col-auto d-flex align-items-end">
          <BsButton
            class="end-activity text-capitalize"
            @type="primary"
            @size="lg"
            @onClick={{set this 'showEndActivityModal' true}}
          >
            {{t "kinetic.endActivity"}}
          </BsButton>
        </div>
      {{/if}}

      {{#if (and @station.usesComponents @station.area.jemDisplayVariable @stationIsRunning)}}
        <JemBomVariables
          class='col-auto'
          @jemDisplayVariable={{@station.area.jemDisplayVariable}}
          @buildDatumVariables={{@buildStatus.buildDatum.variables}}
        />
      {{/if}}

      {{#if (isPresent @buildStatus.customIdentifierData)}}
        <div class='col'>
          <JemCustomIdentifierData
            @customIdentifierData={{@buildStatus.customIdentifierData}}
            @waitingForData={{@waitingForData}}
            @onSubmit={{@onSaveNewBuildStatus}}
          />
        </div>
      {{/if}}

      <JemBuildStatus
        class="col-auto align-self-end d-flex flex-column mb-0"
        @statusClass={{@buildStatus.statusClass}}
        @statusTranslation={{@buildStatus.statusTranslation}}
      />
    </div>

    {{#if this.showEndActivityModal}}
      <JemEndActivityModal
        @assemblyOperation={{@kineticAssemblyOperation}}
        @onConfirm={{this.onEndActivity.perform}}
        @onCancel={{set this 'showEndActivityModal' false}}
      />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default JemInfoBar;
