import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import { task, all } from 'ember-concurrency';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import StandardModal from 'eflex/components/standard-modal';
import { t } from 'ember-intl';
import BsForm from 'eflex/components/bs-form';
import BsButton from 'eflex/components/bs-button';
import { fn } from '@ember/helper';
import { autofocus } from 'eflex/modifiers';
import { isEmpty, or } from 'ember-truth-helpers';
export default class ConfigurationHistoryNoteModal extends Component {
    @service
    configurationHistoryRepo;
    @tracked
    notes;
    onConfirm = task(waitFor(async ()=>{
        const { locations: locations1 } = this.args;
        const { notes: notes1, configurationHistoryRepo: configurationHistoryRepo1 } = this;
        await all(locations1.map((treeItem1)=>configurationHistoryRepo1.createNewVersion.perform(treeItem1, notes1)));
        await this.args.onConfirm?.();
    }));
    static{
        template(`
    <StandardModal
      class="configuration-history-notes-modal"
      @onCancel={{@onConfirm}}
      @onConfirm={{this.onConfirm.perform}}
      ...attributes
    as |modal|>
      <modal.header @text={{t "configurationHistoryNotes"}} />
      <modal.body>
        <BsForm as |form|>
          <form.element
            @controlType="textarea"
            @label={{t "notes"}}
            @onChange={{fn (mut this.notes)}}
          as |el|>
            <el.control
              class="notes"
              rows={{9}}
              {{autofocus}}
            />
          </form.element>
        </BsForm>
      </modal.body>
      <modal.footer as |footer|>
        <footer.confirm
          @disabled={{
            or
              (isEmpty this.notes)
              this.onConfirm.isRunning
            }}
        >
          {{t "save"}}
        </footer.confirm>
        <BsButton
          @type="secondary"
          class="none-notes fs-3"
          @onClick={{this.onConfirm.perform}}
        >
          {{t "none"}}
        </BsButton>
      </modal.footer>
    </StandardModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
