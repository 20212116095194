import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { add, reject } from 'ramda';
import getJemTaskComponent from 'eflex/util/get-jem-task-component';
import * as JemTaskState from 'eflex/util/jem-task-state';
import TaskStatuses from 'eflex/constants/task-statuses';
import { lt, not, or, gt } from 'ember-truth-helpers';
import { isNull, formatCycleTime, getHardware } from 'eflex/helpers';
import { withDefault } from '@eflexsystems/ember-composable-helpers';
import { t } from 'ember-intl';
import { fn } from '@ember/helper';
import JemTaskComponentOption from 'eflex/components/jem/task/component-option';
import JemTaskFault from 'eflex/components/jem/task-fault';
import JemTaskButton from 'eflex/components/jem/task-button';
import JemTaskRejectButton from 'eflex/components/jem/task-reject-button';
import onKey from 'ember-keyboard/modifiers/on-key';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import BsButton from 'eflex/components/bs-button';
export default class JemStv extends Component {
    @service
    taskRepo;
    get taskComponent() {
        return getJemTaskComponent(this.task.taskType);
    }
    get task() {
        return this.args.currentTask ?? this.args.tasks[0];
    }
    get viewingIndex() {
        let index1 = this.displayedTasks.indexOf(this.task);
        if (index1 < 0) {
            index1 = 0;
        } else if (index1 >= this.displayedTasks.length) {
            index1 = this.displayedTasks.length - 1;
        }
        return index1;
    }
    get taskConfirmButtonIsShown() {
        return JemTaskState.taskConfirmButtonIsShown({
            childStatus: this.childStatus,
            task: this.task,
            taskConfig: this.args.currentTaskConfig
        });
    }
    get taskConfirmIsDisabled() {
        return JemTaskState.taskConfirmIsDisabled({
            childStatus: this.childStatus,
            task: this.task,
            taskConfig: this.args.currentTaskConfig,
            notAuthorized: this.args.notAuthorized
        });
    }
    get taskRejectButtonIsShown() {
        return JemTaskState.taskRejectButtonIsShown({
            childStatus: this.childStatus,
            task: this.task,
            taskConfig: this.args.currentTaskConfig
        });
    }
    get taskRejectIsDisabled() {
        return JemTaskState.taskRejectIsDisabled({
            childStatus: this.childStatus,
            task: this.task,
            taskConfig: this.args.currentTaskConfig,
            notAuthorized: this.args.notAuthorized
        });
    }
    @cached
    get displayedTasks() {
        return reject((task1)=>{
            const taskConfig1 = this._getTaskConfig(task1);
            return !taskConfig1 || taskConfig1.disabled;
        }, this.args.tasks);
    }
    @cached
    get childStatus() {
        return this.args.buildStatus?.getChildStatusForTask(this.task);
    }
    get nextTaskDisabled() {
        return !this.args.currentTask || this.viewingIndex + 1 >= this.displayedTasks.length;
    }
    _getTaskConfig(task1) {
        return this.taskRepo.getConfigForModelOrBuildDatum(task1, this.args.model, this.args.buildStatus?.buildDatum);
    }
    nextTask = ()=>{
        const nextIndex1 = this.viewingIndex + 1;
        if (nextIndex1 >= this.displayedTasks.length) {
            return;
        }
        const task1 = this.displayedTasks[nextIndex1];
        if (!task1) {
            return;
        }
        this.args.onSelectTask?.(task1);
    };
    prevTask = ()=>{
        if (this.viewingIndex < 0) {
            return;
        }
        const previousIndex1 = this.viewingIndex - 1;
        const task1 = this.displayedTasks[previousIndex1];
        if (!task1) {
            return;
        }
        this.args.onSelectTask?.(task1);
    };
    onTaskComplete = (status1)=>{
        return this.args.onTaskComplete(status1, this.childStatus);
    };
    static{
        template(`
    <div class="component-jem-stv me-1" ...attributes>
      <div class="jem-stv h-100 pt-2 pe-2 d-flex flex-column">
        {{#if (gt @tasks.length 0)}}
          {{#let
            (getHardware @station this.task)
            this.task
          as |hardware task|}}
            <this.taskComponent
              @childStatus={{this.childStatus}}
              @taskConfig={{@currentTaskConfig}}
              @disabled={{or (isNull @currentTaskConfig) (not this.childStatus.isRunning)}}
              @notAuthorized={{@notAuthorized}}
              @task={{task}}
              @station={{@station}}
              @hardware={{hardware}}
              @onTaskComplete={{this.onTaskComplete}}
              @onRepair={{@onRepair}}
            as |TaskDisplay|>
              <div class="steps-wrp mb-3 d-flex flex-column">
                <div class="d-flex align-items-center">
                  <div class="steps">
                    <span class="h4 fw-semibold current-step status mb-0 {{this.childStatus.taskStatusClass}}">
                      {{add this.viewingIndex 1}}
                    </span>
                    <span class="slash text-gray-300">
                      /
                    </span>
                    <span class="last-step text-gray-300">
                      {{this.displayedTasks.length}}
                    </span>
                  </div>
                  <div class="steps-nav d-flex ms-auto">
                    <BsButton
                      @type="secondary-dark"
                      @onClick={{this.prevTask}}
                      @disabled={{lt this.viewingIndex 1}}
                      class="prev-step pointer fs-3 px-3 py-2 me-2"
                    >
                      <FaIcon @icon="chevron-left" @prefix="fas" />
                    </BsButton>
                    <BsButton
                      @type="secondary-dark"
                      @onClick={{this.nextTask}}
                      @disabled={{this.nextTaskDisabled}}
                      class="next-step pointer fs-3 px-3 py-2"
                    >
                      <FaIcon @icon="chevron-right" @prefix="fas" />
                    </BsButton>
                  </div>
                </div>
                {{#if this.childStatus.isFinishedPassed}}
                  <div class="h4 time d-inline me-3 status success">
                    {{formatCycleTime this.childStatus.cycleTime}}
                  </div>
                {{/if}}
              </div>

              <div class="task-data-container eflex-scroll h-100">
                <div class="text-gray-300 fs-4 mb-2">
                  {{t "plant.station.jem.description"}}
                </div>
                <div class="h5 description d-flex align-items-center fw-semibold mb-3">
                  <i class="eflex-icon light align-middle m-0 me-2 {{task.iconClass}}"></i>
                  <span class="display-name d-inline-block">
                    {{task.displayName}}
                  </span>
                </div>
                <TaskDisplay.taskDetails class="adjust-line-height task-details p-0" />
              </div>

              <JemTaskComponentOption
                @task={{task}}
                @childStatus={{this.childStatus}}
                @taskConfig={{@currentTaskConfig}}
              />

              <JemTaskFault
                @task={{task}}
                @station={{@station}}
                @hardware={{hardware}}
              />

              {{#let
                this.taskConfirmIsDisabled
                this.taskRejectIsDisabled
                (withDefault TaskDisplay.onTaskComplete this.onTaskComplete)
              as |taskConfirmIsDisabled taskRejectIsDisabled onTaskComplete|}}
                <div
                  class="task-buttons d-flex flex-column"
                  {{onKey
                    'Enter'
                    (fn onTaskComplete TaskStatuses.GOOD)
                    priority=1
                    activated=(not taskConfirmIsDisabled)
                  }}
                  {{onKey
                    'ctrl+alt+KeyO'
                    (fn onTaskComplete TaskStatuses.GOOD)
                    priority=1
                    activated=(not taskConfirmIsDisabled)
                  }}
                  {{onKey
                    'ctrl+alt+KeyR'
                    (fn onTaskComplete TaskStatuses.GOOD)
                    priority=1
                    activated=(not taskRejectIsDisabled)
                  }}
                >
                  {{#if this.taskConfirmButtonIsShown}}
                    <JemTaskButton
                      class="flex-fill mt-3"
                      @task={{task}}
                      @childStatus={{this.childStatus}}
                      @notAuthorized={{@notAuthorized}}
                      @size="lg"
                      @type="success"
                      @okButtonOverride={{TaskDisplay.okButtonOverride}}
                      @retryButtonOverride={{TaskDisplay.retryButtonOverride}}
                      @disabled={{or taskConfirmIsDisabled TaskDisplay.okDisabled}}
                      @onTaskComplete={{onTaskComplete}}
                    />
                  {{/if}}

                  {{#if this.taskRejectButtonIsShown}}
                    <JemTaskRejectButton
                      class="flex-fill mt-3"
                      @notAuthorized={{@notAuthorized}}
                      @disabled={{taskRejectIsDisabled}}
                      @size="lg"
                      @type="danger"
                      @onTaskComplete={{onTaskComplete}}
                    />
                  {{/if}}
                </div>
              {{/let}}
            </this.taskComponent>
          {{/let}}
        {{/if}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
