import { template } from "@ember/template-compiler";
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { service } from '@ember/service';
import { stationWebsocketListener, transitionTo } from 'eflex/helpers';
import { fn, concat, array } from '@ember/helper';
import { on } from '@ember/modifier';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { t } from 'ember-intl';
const getOeeActiveState = (stationId1, oeeStateRepo1)=>{
    return oeeStateRepo1.oeeStates.find((oeeState1)=>oeeState1.stationId === stationId1 && oeeState1.endDate == null && !oeeState1.isCustom)?.state;
};
const onOeeStateUpdate = (store1, state1)=>{
    store1.push(store1.normalize('oeeState', state1));
};
let JemOeeState = class JemOeeState extends Component {
    @service
    store;
    @service
    oeeStateRepo;
    loadOeeStates = task(waitFor(async (elem1, [station1])=>{
        this.store.unloadAll('oeeState');
        await this.store.query('oeeState', {
            stationId: station1.id
        });
    }));
    onShiftUpdate = task(waitFor(async ()=>{
        this.store.unloadAll('oeeState');
        await this.store.query('oeeState', {
            stationId: this.args.station.id
        });
    }));
    static{
        template(`
    {{#let
      (stationWebsocketListener
        station=@station
        roomPrefixes=(array 'oee')
        oeeStateUpdate=(fn onOeeStateUpdate this.store)
        oeeShiftUpdate=this.onShiftUpdate.perform
      )
      (getOeeActiveState @station.id this.oeeStateRepo)
    as |webSocketListener state|}}
      {{#unless webSocketListener.isLoading}}
        <button
          class="app-jem-oee-button btn btn-secondary-dark"
          type="button"
          {{on "click" (transitionTo 'oee.station' @station.id)}}
          {{didInsert this.loadOeeStates.perform @station}}
          {{didUpdate this.loadOeeStates.perform @station}}
        >
          <FaIcon @icon="chart-simple" @prefix="fas" class="me-1"/> {{t "oee"}}
          {{#if state}}
            <div class="status {{state}} badge rounded-pill fs-5 ms-2">
              {{t (concat "oee." state)}}
            </div>
          {{/if}}
        </button>
      {{/unless}}
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default JemOeeState;
