import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import BsForm from 'eflex/components/bs-form';
import { get } from '@ember/helper';
import { filterByIds, callWithMapped, serviceHelper as service } from 'eflex/helpers';
export default template(`
{{#let (get (service "userRepo") "users") as |users|}}
  <div class="user-select" ...attributes>
    <BsForm as |form|>
      <form.element
        class="user-select"
        @controlType="power-select-multiple"
        @label={{t "users"}}
        @value={{filterByIds users @userIds}}
        @options={{users}}
        @optionLabelPath="userName"
        @onChange={{callWithMapped @onChange "id"}}
      as |el|>
        <el.control
          @searchEnabled={{true}}
          @searchField="userName"
          @placeholder={{t "biPanel.users.placeholder"}}
        />
      </form.element>
    </BsForm>
  </div>
{{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
