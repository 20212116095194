import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import RerunStrategies from 'eflex/constants/rerun-strategies';
import RejectStrategies from 'eflex/constants/tasks/reject-strategies';
import RetryStrategies from 'eflex/constants/retry-strategies';
import TaskDeviceConfigBarcode from 'eflex/components/task-device-config/barcode';
import TaskDeviceConfigButton from 'eflex/components/task-device-config/button';
import TaskDeviceConfigCobot from 'eflex/components/task-device-config/cobot';
import TaskDeviceConfigEdhr from 'eflex/components/task-device-config/edhr';
import TaskDeviceConfigErrorProofing from 'eflex/components/task-device-config/errorproofing';
import TaskDeviceConfigImageCapture from 'eflex/components/task-device-config/imagecapture';
import TaskDeviceConfigLightGuide from 'eflex/components/task-device-config/lightguide';
import TaskDeviceConfigMultispindle from 'eflex/components/task-device-config/multispindle';
import TaskDeviceConfigNodeRed from 'eflex/components/task-device-config/nodered';
import TaskDeviceConfigPick from 'eflex/components/task-device-config/pick';
import TaskDeviceConfigPrint from 'eflex/components/task-device-config/print';
import TaskDeviceConfigSerialNumberTransfer from 'eflex/components/task-device-config/serialnumbertransfer';
import TaskDeviceConfigTimer from 'eflex/components/task-device-config/timer';
import TaskDeviceConfigTorque from 'eflex/components/task-device-config/torque';
import TaskDeviceConfigVision from 'eflex/components/task-device-config/vision';
import TaskDeviceConfigPlc from 'eflex/components/task-device-config/plc';
import TaskDeviceConfigOpcUa from 'eflex/components/task-device-config/opc-ua';
import { cached } from '@glimmer/tracking';
import { toTree } from 'eflex/util/tree-helpers';
import BsForm from 'eflex/components/bs-form';
import TaskTypeSelect from 'eflex/components/task-type-select';
import { t } from 'ember-intl';
import { autofocus } from 'eflex/modifiers';
import { mapBy, findBy } from '@eflexsystems/ember-composable-helpers';
import TagsSelect from 'eflex/components/tags-select';
import TreeSelect from 'eflex/components/tree-select';
import { fn } from '@ember/helper';
const RejectStrategyValues = Object.values(RejectStrategies);
const getTaskDeviceConfigComponent = (taskType1)=>{
    switch(taskType1){
        case 'barcode':
            {
                return TaskDeviceConfigBarcode;
            }
        case 'button':
            {
                return TaskDeviceConfigButton;
            }
        case 'cobot':
            {
                return TaskDeviceConfigCobot;
            }
        case 'edhr':
            {
                return TaskDeviceConfigEdhr;
            }
        case 'errorProofing':
            {
                return TaskDeviceConfigErrorProofing;
            }
        case 'opcUa':
            {
                return TaskDeviceConfigOpcUa;
            }
        case 'imageCapture':
            {
                return TaskDeviceConfigImageCapture;
            }
        case 'lightGuide':
            {
                return TaskDeviceConfigLightGuide;
            }
        case 'multispindle':
            {
                return TaskDeviceConfigMultispindle;
            }
        case 'nodeRed':
            {
                return TaskDeviceConfigNodeRed;
            }
        case 'pick':
            {
                return TaskDeviceConfigPick;
            }
        case 'plc':
            {
                return TaskDeviceConfigPlc;
            }
        case 'print':
            {
                return TaskDeviceConfigPrint;
            }
        case 'serialNumberTransfer':
            {
                return TaskDeviceConfigSerialNumberTransfer;
            }
        case 'timer':
            {
                return TaskDeviceConfigTimer;
            }
        case 'torque':
            {
                return TaskDeviceConfigTorque;
            }
        case 'vision':
            {
                return TaskDeviceConfigVision;
            }
        default:
            {
                return null;
            }
    }
};
const setRerunStrategy = (task1, { value: value1 })=>{
    task1.rerunStrategy = value1;
};
const setRetryStrategy = (task1, { value: value1 })=>{
    task1.retryStrategy = value1;
};
const getRepairTaskTree = (task1, taskParent1)=>{
    let parent1;
    if (task1.usesOperations) {
        parent1 = task1.parent;
    } else {
        parent1 = task1.area;
    }
    return [
        toTree(parent1, {
            lastModel: 'task',
            filter: (_task1)=>{
                if (_task1.type === 'station' || _task1.parent !== taskParent1) {
                    return true;
                }
                return false;
            }
        })
    ];
};
let TaskDeviceConfig = class TaskDeviceConfig extends Component {
    @service
    taskRepo;
    @service
    userRepo;
    @service
    store;
    @cached
    get userVisibilityOptions() {
        return [
            ...new Set(this.userRepo.users.flatMap((user1)=>user1.visibilityTags))
        ].toSorted();
    }
    @cached
    get userAuthorizationOptions() {
        return [
            ...new Set(this.userRepo.users.flatMap((user1)=>user1.authorizationTags))
        ].toSorted();
    }
    updateTasksToRepair = (tasksToRepair1)=>{
        const store1 = this.store;
        this.args.task.tasksToRepair = tasksToRepair1.map((task1)=>store1.peekRecord('task', task1.id));
    };
    static{
        template(`
    <BsForm
      @model={{@task}}
      @showValidations={{@showValidations}}
      @disabled={{@disabled}}
    as |form|>
      <div class="row form-row">
        <form.element
          class='col'
          @label={{t "plant.task.taskType"}}
        >
          <TaskTypeSelect
            @selected={{@task.taskType}}
            @onChange={{@selectedTaskType}}
            @disabled={{@disabled}}
          />
        </form.element>
        <form.element
          class='col'
          @label={{t "plant.task.taskName"}}
          @property="name"
        as |el|>
          <el.control
            class="new-task-name"
            disabled={{@disabled}}
            {{autofocus}}
          />
        </form.element>
      </div>
      <div class="row form-row">
        <form.element
          class='col'
          @controlType="power-select"
          @label={{t "plant.task.rerunStrategy"}}
          @options={{RerunStrategies}}
          @value={{
            findBy
            "value"
            @task.rerunStrategy
            RerunStrategies
          }}
          @onChange={{fn setRerunStrategy @task}}
        as |el|>
          <el.control @disabled={{@disabled}} as |option|>
            {{t option.label}}
          </el.control>
        </form.element>
        <form.element
          class='col'
          @controlType="power-select"
          @label={{t "plant.task.retryStrategy"}}
          @options={{RetryStrategies}}
          @value={{
            findBy
            "value"
            @task.retryStrategy
            RetryStrategies
          }}
          @onChange={{fn setRetryStrategy @task}}
        as |el|>
          <el.control @disabled={{@disabled}} as |option|>
            {{t option.label}}
          </el.control>
        </form.element>
      </div>
      <div class="row form-row">
        <form.element
          class='col-6'
          @controlType="power-select"
          @label={{t "plant.task.rejectStrategy"}}
          @property="rejectStrategy"
          @options={{RejectStrategyValues}}
        as |el|>
          <el.control @disabled={{@disabled}} as |option|>
            {{t option}}
          </el.control>
        </form.element>
      </div>
      <div class="row form-row">
        <form.element
          class='col'
          @label={{t "reportTags"}}
        >
          <TagsSelect
            @siblings={{this.taskRepo.tasks}}
            @taggedModel={{@task}}
            @disabled={{@disabled}}
          />
        </form.element>
        <form.element
          class='col'
          @label={{t "decisionTags"}}
        >
          <TagsSelect
            @siblings={{this.taskRepo.tasks}}
            @taggedModel={{@task}}
            @fieldName="decisionTags"
            @disabled={{@disabled}}
          />
        </form.element>
      </div>
      <div class="row form-row">
        <form.element
          @controlType="power-select-multiple"
          class="col jem-visibility-tags-select"
          @label={{t "settings.users.visibilityTags"}}
          @options={{this.userVisibilityOptions}}
          @property="jemVisibilityTags"
        as |el|>
          <el.control
            @triggerClass="form-control"
            @searchEnabled={{true}}
            @placeholder={{t "selectOneOrMore"}}
            @disabled={{@disabled}}
          />
        </form.element>
        <form.element
          @controlType="power-select-multiple"
          class="col jem-authorized-tags-select"
          @label={{t "settings.users.authorizationTags"}}
          @options={{this.userAuthorizationOptions}}
          @property="jemAuthorizedTags"
        as |el|>
          <el.control
            @triggerClass="form-control"
            @searchEnabled={{true}}
            @placeholder={{t "selectOneOrMore"}}
            @disabled={{@disabled}}
          />
        </form.element>
      </div>
      <div class="row form-row">
        <div class="col-6">
          <label class="form-label">{{t "plant.task.repairTask"}}</label>
          <TreeSelect
            @sourceTree={{getRepairTaskTree @task @taskParent}}
            @checkedIds={{mapBy "id" @task.tasksToRepair}}
            @fullWidth={{true}}
            @checkable={{true}}
            @minDepth={{3}}
            @disabled={{@disabled}}
            @placeholder={{t "plant.task.notRepair"}}
            @checkAllText={{t "allTasks"}}
            @onCheckedChanged={{this.updateTasksToRepair}}
          />
          {{#if @task.tasksToRepair}}
            <form.element
              @controlType="checkbox"
              @property="requireRepairCodes"
              @label={{ t "plant.task.requireRepairCodes" }}
              class="my-0 ms-auto"
            as |el|>
              <el.control @disabled={{@disabled}} />
            </form.element>
          {{/if}}
        </div>
      </div>

      {{#let (getTaskDeviceConfigComponent @task.taskType) as |TaskDeviceConfigComponent|}}
        {{#if TaskDeviceConfigComponent}}
          <TaskDeviceConfigComponent
            @task={{@task}}
            @disabled={{@disabled}}
            @form={{form}}
          />
        {{/if}}
      {{/let}}
    </BsForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default TaskDeviceConfig;
