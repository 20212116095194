import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import { isBlank } from '@ember/utils';
import { ObjectId } from 'bson';
import echartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';
import Spinner from 'eflex/components/spinner';
import EChart from 'eflex/components/e-chart';
import NoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import { fn, get } from '@ember/helper';
const getProcessDatumMatch = (processData1)=>{
    if (isBlank(processData1)) {
        return {
            $match: {}
        };
    }
    return {
        $match: {
            'children.processData.variableDef': {
                $in: processData1.map((variableDef1)=>new ObjectId(variableDef1))
            }
        }
    };
};
const getEchartOptions = (chartData1, unitMapping1, intl1)=>{
    return {
        title: {
            text: intl1.t('bi.chartLabel.processDataAvgBarChart')
        },
        legend: {
            show: true,
            bottom: 5
        },
        tooltip: {
            trigger: 'axis',
            formatter (params1) {
                // the key is the processDataName only
                const axisLabelPartsArray1 = params1[0].name.split(':');
                const mapKey1 = axisLabelPartsArray1.at(-1).trim();
                const tooltipItems1 = params1.map(({ marker: marker1, value: value1 })=>`<div style="text-align:center">${unitMapping1.get(mapKey1).stationName}</div><br>${marker1} ${unitMapping1.get(mapKey1).processDataName}: <span style="float:right;font-weight:bold;">${value1} <span style="font-weight:normal;">${unitMapping1.get(mapKey1).unit}</span></span>`).join('<br>');
                return `<div style="min-width:200px;">${tooltipItems1}</div>`;
            }
        },
        yAxis: {
            type: 'category',
            boundaryGap: true,
            data: chartData1.map((data1)=>`${data1.stationName}: ${data1.taskName}: ${data1.processDataName}`)
        },
        xAxis: [
            {
                type: 'value',
                position: 'bottom',
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],
        series: [
            {
                name: intl1.t('bi.chartLegend.avgProcessData'),
                data: chartData1.map((m1)=>m1?.avg?.toFixed(2)),
                type: 'bar',
                xAxisIndex: 0,
                color: echartsTheme.colorPalette.biPurple
            }
        ],
        variableDefIds: chartData1.map((item1)=>item1._id)
    };
};
export default class ProcessDataAvgBarChart extends Component {
    @service
    queryRunner;
    @service
    intl;
    @service
    router;
    get params() {
        return {
            stationIds: this.args.stationIds,
            modelIds: this.args.modelIds,
            userIds: this.args.userIds,
            tags: this.args.tags,
            startDate: this.args.startDate,
            endDate: this.args.endDate,
            shiftNames: this.args.shiftNames,
            processData: this.args.processData
        };
    }
    getData = task({
        drop: true
    }, waitFor(async (params1)=>{
        const averageProcessDataAggregation1 = [
            {
                $match: {
                    'children.processData.0': {
                        $exists: true
                    }
                }
            },
            {
                $unwind: {
                    path: '$children'
                }
            },
            {
                $unwind: {
                    path: '$children.processData'
                }
            },
            {
                $match: {
                    'children.processData.value': {
                        $type: 'number'
                    }
                }
            },
            getProcessDatumMatch(params1.processData),
            {
                $addFields: {
                    processDataName: '$children.processData.captions.text',
                    taskName: '$children.location.captions.text',
                    unit: '$children.processData.unitCaptions.text'
                }
            },
            {
                $group: {
                    _id: '$children.processData.variableDef',
                    station: {
                        $first: '$location'
                    },
                    processDataName: {
                        $first: '$processDataName'
                    },
                    taskName: {
                        $first: '$taskName'
                    },
                    unit: {
                        $first: '$unit'
                    },
                    avg: {
                        $avg: '$children.processData.value'
                    }
                }
            },
            {
                $project: {
                    stationName: {
                        $first: '$station.captions.text'
                    },
                    processDataName: {
                        $first: '$processDataName'
                    },
                    taskName: {
                        $first: '$taskName'
                    },
                    avg: true,
                    unit: {
                        $first: '$unit'
                    }
                }
            },
            {
                $sort: {
                    avg: 1
                }
            }
        ];
        const processDataQuery1 = await this.queryRunner.queryWithParams.perform(params1, averageProcessDataAggregation1);
        const chartData1 = await this.queryRunner.runQuery.perform('BuildStatuses', processDataQuery1);
        const unitMapping1 = new Map();
        chartData1.forEach((data1)=>{
            unitMapping1.set(data1.processDataName, {
                processDataName: data1.processDataName ?? '',
                unit: data1.unit ?? '',
                stationName: data1.stationName ?? ''
            });
        });
        return {
            chartData: getEchartOptions(chartData1, unitMapping1, this.intl),
            exportData: ()=>chartData1.map(({ _id: _id1, ...relevantData1 })=>relevantData1)
        };
    }));
    processData = trackedTask(this, this.getData, ()=>[
            this.params,
            this.args.refresher
        ]);
    processDatumDrillDown = (processData1, dataIndex1)=>{
        this.router.transitionTo('bi.processData.processDatum', {
            queryParams: {
                variableDefIds: [
                    processData1.chartData.variableDefIds[dataIndex1]
                ],
                stationIds: this.args.stationIds,
                modelIds: this.args.modelIds,
                userIds: this.args.userIds,
                tags: this.args.tags,
                beginDate: this.args.startDate,
                endDate: this.args.endDate,
                shiftNames: this.args.shiftNames
            }
        });
    };
    static{
        template(`
    <div ...attributes>
      {{#let
        this.processData.isRunning
        this.processData.value
      as |isRunning processData|}}
        {{#if isRunning}}
          <Spinner />
        {{else if (get processData.chartData.series '0.data.length')}}
          <div class="row h-100">
            <div class="col d-flex justify-content-between">
              <EChart
                class="h-100 w-100 process-data-avg-bar-chart"
                @option={{processData.chartData}}
                @exportData={{processData.exportData}}
                @onClick={{fn this.processDatumDrillDown processData}}
              />
            </div>
          </div>
        {{else}}
          <NoDataPlaceholder />
        {{/if}}
      {{/let}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
