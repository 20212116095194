import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import moment from 'moment-timezone';
import TaskAndFastenerMatcher from 'eflex/util/bi-task-fastener-matcher';
import BiFormatTaskAndBoltAxis from 'eflex/util/bi-task-anb-bolt-axis-formatter';
import EchartsTheme from 'eflex/echarts-theme';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import Spinner from 'eflex/components/spinner';
import EChart from 'eflex/components/e-chart';
import { fn, get } from '@ember/helper';
export default class BoltDataChart extends Component {
    @service
    queryRunner;
    @service
    intl;
    @service
    router;
    get params() {
        return {
            stationIds: this.args.stationIds,
            fasteners: this.args.fasteners,
            modelIds: this.args.modelIds,
            userIds: this.args.userIds,
            tags: this.args.tags,
            startDate: this.args.startDate,
            endDate: this.args.endDate,
            shiftNames: this.args.shiftNames
        };
    }
    getTorqueData = task({
        restartable: true
    }, async ()=>{
        const query1 = await this.queryRunner.queryWithParams.perform(this.params, [
            {
                $limit: 100_000
            },
            {
                $match: {
                    'children.boltProcessData.0': {
                        $exists: true
                    }
                }
            },
            {
                $unwind: {
                    path: '$children'
                }
            },
            {
                $unwind: {
                    path: '$children.boltProcessData'
                }
            },
            {
                $addFields: {
                    idAndFastener: {
                        $concat: [
                            {
                                $toString: '$children.location._id'
                            },
                            {
                                $toString: '$children.boltProcessData.boltNumber'
                            }
                        ]
                    }
                }
            },
            TaskAndFastenerMatcher(this.params.fasteners),
            {
                $project: {
                    serialNumber: 1,
                    taskAndBolt: {
                        $concat: [
                            {
                                $first: '$children.location.captions.text'
                            },
                            ' ',
                            this.intl.t('fastener'),
                            ' ',
                            {
                                $toString: '$children.boltProcessData.boltNumber'
                            }
                        ]
                    },
                    idAndFastener: {
                        $concat: [
                            {
                                $toString: '$children.location._id'
                            },
                            {
                                $toString: '$children.boltProcessData.boltNumber'
                            }
                        ]
                    },
                    boltFinishTime: '$children.boltProcessData.timestamp',
                    torque: '$children.boltProcessData.torque',
                    angle: '$children.boltProcessData.angle',
                    boltTimeStamp: '$children.boltProcessData.timestamp',
                    taskId: '$children.location._id',
                    timestamp: 1
                }
            },
            {
                $sort: {
                    boltTimeStamp: -1
                }
            }
        ]);
        const individualTorqueData1 = await this.queryRunner.runQuery.perform('BuildStatuses', query1);
        return {
            chartData: this.getEchartOptions(individualTorqueData1),
            exportData: ()=>this.formatExportData(individualTorqueData1)
        };
    });
    individualTorqueData = trackedTask(this, this.getTorqueData, ()=>[
            this.params,
            this.args.refresher
        ]);
    getEchartOptions(torqueData1) {
        return {
            title: {
                text: this.intl.t('bi.chartLabel.individualAngleTorque')
            },
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                show: true,
                bottom: 5
            },
            yAxis: BiFormatTaskAndBoltAxis(torqueData1),
            xAxis: [
                {
                    type: 'value',
                    data: torqueData1.map((item1)=>item1.torque),
                    name: this.intl.t('torque'),
                    nameTextStyle: {
                        padding: 10
                    },
                    nameLocation: 'center'
                },
                {
                    type: 'value',
                    data: torqueData1.map((item1)=>item1.angle),
                    name: this.intl.t('angle'),
                    nameTextStyle: {
                        padding: 10
                    },
                    nameLocation: 'center',
                    axisLabel: {
                        formatter: '{value}°'
                    }
                }
            ],
            series: [
                {
                    name: this.intl.t('torque'),
                    type: 'bar',
                    xAxisIndex: 0,
                    data: torqueData1.map((m1)=>{
                        return m1.torque?.toFixed(2).replace(/\.00/, '') ?? 0;
                    }),
                    color: EchartsTheme.colorPalette.warning
                },
                {
                    name: this.intl.t('angle'),
                    type: 'line',
                    xAxisIndex: 1,
                    data: torqueData1.map((m1)=>{
                        return m1.angle?.toFixed(2).replace(/\.00/, '') ?? 0;
                    }),
                    color: EchartsTheme.colorPalette.success
                }
            ],
            taskIds: torqueData1.map((item1)=>item1.taskId),
            names: torqueData1.map((item1)=>item1.taskAndBolt),
            idAndFasteners: torqueData1.map((item1)=>item1.idAndFastener),
            timestamps: torqueData1.map((item1)=>item1.timestamp)
        };
    }
    formatExportData(data1) {
        return data1?.map(({ _id: _id1, timestamp: timestamp1, idAndFastener: idAndFastener1, boltFinishTime: boltFinishTime1, ...boltData1 })=>({
                ...boltData1,
                torque: boltData1.torque?.toFixed(2).replace(/\.00/, ''),
                angle: boltData1.angle?.toFixed(2).replace(/\.00/, ''),
                boltTimeStamp: moment(boltData1.boltTimeStamp).format('YYYY-MM-DD HH:mm:ss')
            })) ?? [];
    }
    singleFastenerDrillDown = (individualTorqueData1, dataIndex1)=>{
        const timestamp1 = new Date(individualTorqueData1.timestamps[dataIndex1]);
        this.router.transitionTo('bi.torqueData.bolts', {
            queryParams: {
                stationIds: this.args.stationIds,
                fasteners: [
                    {
                        _id: individualTorqueData1.taskIds[dataIndex1],
                        name: individualTorqueData1.names[dataIndex1],
                        idAndFastener: individualTorqueData1.idAndFasteners[dataIndex1]
                    }
                ],
                modelIds: this.args.modelIds,
                userIds: this.args.userIds,
                tags: this.args.tags,
                beginDate: moment(timestamp1),
                endDate: moment(timestamp1).add(1, 'seconds'),
                shiftNames: this.args.shiftNames
            }
        });
    };
    static{
        template(`
    {{!-- template-lint-disable no-passed-in-event-handlers --}}
    <div class="h-100 w-100">
      {{#if this.individualTorqueData.isRunning}}
        <Spinner />
      {{else if (get this.individualTorqueData.value.chartData.series '0.data.length')}}
        <EChart
          class="h-100 w-100 individual-torque-angle-chart"
          @option={{this.individualTorqueData.value.chartData}}
          @exportData={{this.individualTorqueData.value.exportData}}
          @onClick={{fn this.singleFastenerDrillDown this.individualTorqueData.value.chartData}}
        />
      {{else}}
        <BiNoDataPlaceholder />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
