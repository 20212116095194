import { template } from "@ember/template-compiler";
import { hash } from '@ember/helper';
import Link from 'eflex/components/link';
const Tab = template(`
<li class="eflex-tab nav-item" ...attributes>
  <Link
    @route={{@route}}
    class="nav-link {{if @disabled "disabled"}}"
  >
    {{yield}}
  </Link>
</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template(`
<ul class="nav eflex-tabs eflex-navigate-tabs px-3 pt-2" ...attributes>
  {{yield (hash tab=Tab)}}
</ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
