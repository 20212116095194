import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import { taskTypes } from 'eflex/constants/tasks/task-types';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import NoRouteTabs from 'eflex/components/no-route-tabs';
import { t } from 'ember-intl';
import BsButton from 'eflex/components/bs-button';
import { not, and, notEq, or, eq } from 'ember-truth-helpers';
import { fn } from '@ember/helper';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import JemAuthPanel from 'eflex/components/jem/auth-panel';
import JemWebCam from 'eflex/components/jem/web-cam';
import StandardModal from 'eflex/components/standard-modal';
import UrlViewer from 'eflex/components/url-viewer';
import JemDynamicInstruction from 'eflex/components/jem/dynamic-instruction';
import JemNoData from 'eflex/components/jem/no-data';
import { getAnimationsToRun } from 'eflex/services/work-instruction-repo';
import { set } from 'eflex/helpers';
import { getJemConfig } from 'eflex/services/location-repo';
const InstructionsPane = template(`
{{#if @displayedConfig.hasExternalUrl}}
  <UrlViewer @url={{@displayedConfig.fullExternalUrl}} />
{{else if @displayedConfig.workInstruction.deployed}}
  <JemDynamicInstruction
    @workInstruction={{@displayedConfig.workInstruction}}
    @animations={{getAnimationsToRun @childStatus @displayedConfig.animations}}
  />
{{else if (not @isRunning)}}
  <JemNoData @fullScreen={{@showFullScreenImage}} />
{{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
// try not to change the ordering in here
// its very sensitive to ember data caching
// hance the belongsTo.value call
const getCurrentInstructionTriggerConfig = (station1, type1, model1, stationIsRunning1, currentTaskIsRunning1, triggerConfigs1, store1)=>{
    if (!stationIsRunning1 && (type1 === 'task' || type1 === 'station') && station1.loadJemConfiguration != null) {
        return station1.loadJemConfiguration;
    }
    if (type1 === 'station') {
        return getJemConfig(station1.jemConfigurations, model1);
    }
    if (type1 === 'task') {
        if (currentTaskIsRunning1) {
            return station1.currentInstructionTriggerConfig;
        }
        const triggerConfig1 = triggerConfigs1?.find((_triggerConfig1)=>_triggerConfig1.constructor.modelName === 'work-instruction-hardware-trigger-config' && _triggerConfig1.event === 'start');
        if (triggerConfig1) {
            return triggerConfig1;
        }
        if (station1.belongsTo('currentInstructionTriggerConfig').value()) {
            return station1.currentInstructionTriggerConfig;
        }
        return null;
    }
    return store1.peekRecord('customTabJemConfiguration', type1);
};
export default class JemInstructions extends Component {
    @tracked
    showFullScreenImage = false;
    @tracked
    showDeleteTabModal = false;
    @service
    resizeListener;
    @service
    store;
    onDeleteTab = task({
        drop: true
    }, waitFor(async (workInstructionTriggerConfig1)=>{
        await this.args.onDeleteTab(workInstructionTriggerConfig1);
        this.showDeleteTabModal = false;
    }));
    enterFullScreen = ()=>{
        this.showFullScreenImage = true;
        this.resizeListener.triggerResize();
    };
    exitFullScreen = ()=>{
        this.showFullScreenImage = false;
        this.resizeListener.triggerResize();
    };
    static{
        template(`
    <div class="work-instruction" ...attributes>
      {{#let
        (getCurrentInstructionTriggerConfig
          @station
          @type
          @model
          @stationIsRunning
          @currentTaskIsRunning
          @currentTaskConfig.triggerConfigs
          this.store
        )
      as |workInstructionTriggerConfig|}}
        <div class="jem-image-panel-online component-jem-instructions d-flex flex-column">
          <NoRouteTabs
            @activeTab={{@type}}
            @tabBecameActive={{@changeTab}}
          as |tabs|>
            {{#let (and @station.isWebCamScan (not @stationIsRunning)) as |isWebCamLoad|}}
              <tabs.navigation class="jem-pane-header d-flex align-items-center pe-0" as |nav|>
                <nav.tab @href="task" class="app-jem-task-tab">
                  {{t "task"}}
                </nav.tab>
                {{#unless (or @station.usesComponents @station.usesOperations)}}
                  <nav.tab @href="station" class="app-jem-station-tab">
                    {{t "station"}}
                  </nav.tab>
                {{/unless}}
                {{#if (or @task.usesWebCam isWebCamLoad)}}
                  <nav.tab @href="imageCapture" class="image-capture-tab">
                    {{t "image"}}
                  </nav.tab>
                {{/if}}
                {{#if (eq @childStatus.taskType taskTypes.vision)}}
                  <nav.tab @href="vision" class="vision-image-tab">
                    {{t "image"}}
                  </nav.tab>
                {{/if}}
                {{#if @childStatus.needsAuth}}
                  <nav.tab @href="authorize" class="app-jem-auth-tab">
                    {{t "authorize"}}
                  </nav.tab>
                {{/if}}
                {{#each @station.configuredJemTabs as |tab|}}
                  <nav.tab @href={{tab.id}} class="app-jem-configured-tab">
                    {{tab.title}}
                  </nav.tab>
                {{/each}}

                <div class="ms-auto"></div>

                {{#if @canEdit}}
                  <div class="tab-editing row g-1">
                    <div class="col-auto">
                      <BsButton
                        @onClick={{@onAddTab}}
                        @size="sm"
                        @type="secondary-dark"
                        class="tab-add-icon text-white d-flex p-2"
                      >
                        <FaIcon @icon="plus" @prefix="fas" />
                      </BsButton>
                    </div>
                    {{#if (and
                      (notEq @type "task")
                      (notEq @type "station")
                      workInstructionTriggerConfig
                    )}}
                      <div class="col-auto">
                        <BsButton
                          @onClick={{fn @onConfigureTab workInstructionTriggerConfig}}
                          @size="sm"
                          @type="secondary-dark"
                          class="tab-edit-icon text-white d-flex p-2"
                        >
                          <FaIcon @icon="gear" @prefix="fas" />
                        </BsButton>
                      </div>

                      <div class="col-auto">
                        <BsButton
                          @onClick={{set this 'showDeleteTabModal' true}}
                          @size="sm"
                          @type="secondary-dark"
                          class="tab-delete-icon text-white d-flex p-2"
                        >
                          <FaIcon @icon="trash" @prefix="fas" />
                        </BsButton>
                      </div>
                    {{/if}}
                  </div>
                {{/if}}

                <BsButton
                  class="full-screen-toggle text-white d-flex p-2 ms-1
                  {{if (notEq @type "imageCapture") "visible" "d-none"}}"
                  @onClick={{this.enterFullScreen}}
                  @size="sm"
                  @type="secondary-dark"
                >
                  <FaIcon @icon="expand" @prefix="fas" />
                </BsButton>
              </tabs.navigation>

              <tabs.panes as |panes|>
                <div class="jem-image-container h-100 {{if this.showFullScreenImage "full-screen"}}">
                  <panes.pane @id="authorize" class="position-relative">
                    <JemAuthPanel
                      @jemAuthorizedTags={{@task.jemAuthorizedTags}}
                      @childStatus={{@childStatus}}
                      @onTaskComplete={{@onTaskComplete}}
                      @onAuthSuccess={{fn @changeTab "task"}}
                    />
                  </panes.pane>

                  <panes.pane @id="imageCapture" class="position-relative">
                    <JemWebCam
                      @childStatus={{@childStatus}}
                      @station={{@station}}
                      @task={{@task}}
                      @isWebCamLoad={{isWebCamLoad}}
                      @onScan={{@onScan}}
                    />
                  </panes.pane>

                  <panes.pane @id="vision" class="position-relative">
                    <div class="h-100 w-100 position-relative">
                      {{#if @childStatus.visionUrl}}
                        <img src={{@childStatus.fullVisionUrl}} class="img-fluid vision-image" />
                      {{else}}
                        <JemNoData
                          @message={{t "plant.station.jem.waitingForImage"}}
                          @fullScreen={{this.showFullScreenImage}}
                        />
                      {{/if}}
                    </div>
                  </panes.pane>

                  <panes.pane @id="task" class="position-relative">
                    <InstructionsPane
                      @displayedConfig={{workInstructionTriggerConfig}}
                      @isRunning={{@stationIsRunning}}
                      @showFullScreenImage={{this.showFullScreenImage}}
                      @childStatus={{@childStatus}}
                    />
                  </panes.pane>

                  <panes.pane @id="station" class="position-relative">
                    <InstructionsPane
                      @displayedConfig={{workInstructionTriggerConfig}}
                      @isRunning={{@stationIsRunning}}
                      @showFullScreenImage={{this.showFullScreenImage}}
                      @childStatus={{@childStatus}}
                    />
                  </panes.pane>

                  {{#each @station.configuredJemTabs as |tab|}}
                    <panes.pane @id={{tab.id}} class="position-relative">
                      <InstructionsPane
                        @displayedConfig={{workInstructionTriggerConfig}}
                        @isRunning={{@isRunning}}
                        @showFullScreenImage={{this.showFullScreenImage}}
                        @childStatus={{@childStatus}}
                      />
                    </panes.pane>
                  {{/each}}
                  {{#if this.showFullScreenImage}}
                    <BsButton
                      @onClick={{this.exitFullScreen}}
                      @size="md"
                      @type="secondary-dark"
                      class="full-screen-toggle text-white d-flex p-2"
                    >
                      <FaIcon @icon="compress" @prefix="fas" />
                    </BsButton>
                  {{/if}}
                </div>
              </tabs.panes>
            {{/let}}
          </NoRouteTabs>
        </div>

        {{#if this.showDeleteTabModal}}
          <StandardModal
            @size="sm"
            @header={{t "areyousure"}}
            @text="{{t "jem.deleteTab.message"}} {{workInstructionTriggerConfig.title}}"
            @onCancel={{set this 'showDeleteTabModal' false}}
            @onConfirm={{fn this.onDeleteTab.perform workInstructionTriggerConfig}}
          />
        {{/if}}
      {{/let}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
