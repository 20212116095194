import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import { fn } from '@ember/helper';
import EmberFlatpickr from 'ember-flatpickr/components/ember-flatpickr';
const onExpirationDateChange = (hardware1, [date1])=>{
    hardware1.calibrationExpirationDate = date1;
};
const onCalibrationDateChange = (hardware1, [date1])=>{
    hardware1.calibrationDate = date1;
};
const VERSIONS = [
    1,
    2
];
export default template(`
<div class="row form-row">
  <div class="col-4">
    <@form.element
      @controlType="text"
      @property="ipAddress"
      @label={{t "ipAddress"}}
      class="hardware-ip is-required"
    />
  </div>
  <div class="col-2">
    <@form.element
      @controlType="number"
      @property="port"
      @label={{t "port"}}
      class="hardware-port is-required"
    />
  </div>
  <div class="col-3">
    <@form.element
      @controlType="power-select"
      @label={{t "version"}}
      @options={{VERSIONS}}
      @property="version"
      class="hardware-openprotocol-version is-required"
    as |el|>
      <el.control @triggerClass="form-control" />
    </@form.element>
  </div>
  <div class="col-3">
    <@form.element
      @controlType="text"
      @property="revision"
      @label={{t "revision"}}
      class="hardware-revision is-required"
    />
  </div>
</div>
<hr>
<div class="row form-row">
  <div class="col-6">
    <@form.element
      @controlType="text"
      @property="hardwareSerialNumber"
      @label={{t "hardware.atlascopco.serialNumber"}}
      class="hardware-serial-number"
    />
  </div>
</div>
<div class="row form-row">
  <div class="col-4">
    <label class="form-label">
      {{t "hardware.atlascopco.calibrationDate"}}
    </label>
    <div class="d-flex">
      <EmberFlatpickr
        class="calibration-date"
        @allowInput={{true}}
        @altInput={{true}}
        @date={{if
          @hardware.calibrationDate
          @hardware.calibrationDate
          null
        }}
        @onChange={{fn onCalibrationDateChange @hardware}}
      />
    </div>
  </div>
  <div class="col-4 d-flex flex-column">
    <div>
      <label class="form-label">
        {{t "hardware.atlascopco.expirationDate"}}
      </label>
      <div class="d-flex">
        <EmberFlatpickr
          class="expiration-date"
          @allowInput={{true}}
          @altInput={{true}}
          @date={{if
            @hardware.calibrationExpirationDate
            @hardware.calibrationExpirationDate
            null
          }}
          @onChange={{fn onExpirationDateChange @hardware}}
        />
      </div>
    </div>
  </div>
  <div class="col-4">
    <@form.element
      @controlType="number"
      @property="warningThresholdDays"
      @label={{t "hardware.atlascopco.warningThreshold"}}
      class="warning-threshold"
    />
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
