import { template } from "@ember/template-compiler";
import NavBarMap from 'eflex/util/nav-bar-map';
import Link from 'eflex/components/link';
import { service } from '@ember/service';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { t } from 'ember-intl';
import { fn, concat } from '@ember/helper';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import { and, or } from 'ember-truth-helpers';
import BsTooltip from 'eflex/components/bs-tooltip';
import { isPresent, isBlank } from '@ember/utils';
import { replaceAll, compact } from 'ramda-adjunct';
import InputsLiveSearch from 'eflex/components/inputs/search-live';
import { map } from 'ramda';
import { intoArray } from '@eflexsystems/ramda-helpers';
const getCanNav = (item1, licensing1)=>{
    if (licensing1.noProductsLicensed) {
        return item1.key === 'settings';
    }
    if (item1.lic != null) {
        return licensing1.license?.[`${item1.lic}Enabled`] ?? false;
    } else {
        return true;
    }
};
let NavItem = class NavItem extends Component {
    // eslint-disable-next-line ember/no-tracked-properties-from-args
    @tracked
    expanded = this.args.isExpanded;
    @service
    router;
    @service
    licensing;
    toggleExpanded = (hasChildren1)=>{
        if (!hasChildren1) {
            return;
        }
        this.expanded = !this.expanded;
    };
    static{
        template(`
    {{#let
      (t (concat 'navigation.' @item.label))
      (isPresent @item.children)
    as |label hasChildren|}}
      <li
        class='
          {{if @item.key (replaceAll '.' '-' @item.key)}}
          {{if hasChildren 'has-children'}}
          {{if (and hasChildren this.expanded) 'expanded'}}
        '
      >
        <Link
          class="d-flex align-items-center text-decoration-none fs-4 rounded"
          @route={{unless hasChildren @item.key}}
          @href={{if hasChildren "#" (if @item.href @item.href)}}
          target={{if @item.href "_blank"}}
          rel={{if @item.href "noopener noreferrer"}}
          {{on 'click' (fn this.toggleExpanded hasChildren)}}
        >
          {{#if hasChildren}}
            <FaIcon @icon="caret-right" @prefix="fas" class="sub-menu-arrow me-2" />
          {{/if}}
          {{#if @navCompressed}}
            <BsTooltip @title={{label}} @placement='right' @triggerEvents="hover" />
          {{else}}
            <span class="nav-item-label">{{label}}</span>
          {{/if}}
        </Link>

        {{#if (and hasChildren this.expanded)}}
          <ul class="nav-children mb-2">
            {{#each @item.children as |child|}}
              {{#if (getCanNav child this.licensing)}}
                <NavItem
                  @item={{child}}
                  @navCompressed={{@navCompressed}}
                />
              {{/if}}
            {{/each}}
          </ul>
        {{/if}}
      </li>
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
const getIsExpanded = (item1, router1)=>{
    return item1.children?.some((child1)=>router1.isActive(child1.key)) ?? false;
};
export default class NavBar extends Component {
    @service
    router;
    @service
    currentUser;
    @service
    resizeListener;
    @service
    licensing;
    @service
    intl;
    @tracked
    searchTerm;
    @cached
    get filteredChildren() {
        const sortedChildren1 = NavBarMap;
        const searchTerm1 = this.searchTerm;
        if (isBlank(searchTerm1)) {
            return sortedChildren1.map((child1)=>({
                    ...child1,
                    isExpanded: false
                }));
        }
        const searchRegex1 = new RegExp(`${searchTerm1}`, 'i');
        return intoArray(map((parent1)=>{
            let isExpanded1 = false;
            if (searchRegex1.test(this.intl.t(`navigation.${parent1.label}`))) {
                isExpanded1 = true;
                return {
                    ...parent1,
                    isExpanded: isExpanded1
                };
            }
            const filteredChild1 = {
                ...parent1
            };
            filteredChild1.children = (Array.isArray(filteredChild1.children) ? filteredChild1.children : []).filter((_child1)=>{
                if (searchRegex1.test(this.intl.t(`navigation.${_child1.label}`))) {
                    isExpanded1 = true;
                    return true;
                }
                return false;
            });
            if (filteredChild1.children?.length > 0) {
                return {
                    ...filteredChild1,
                    isExpanded: isExpanded1
                };
            }
        }), compact)(sortedChildren1);
    }
    constructor(){
        super(...arguments);
        this.router._router.setupRouter();
    }
    static{
        template(`
    <div
      class="nav-bar hidden-print h-100 overflow-y-auto"
      ...attributes
    >
      <nav
        class="
          eflex-scroll
          bg-white
          border-end
          border-gray-200
          p-3
          position-relative
          h-100
        "
      >
        <ul class="top-links list-unstyled d-flex flex-column h-100 mb-0">
          <img src="/images/epicor-logo-blue.png" width="100" class="menu-logo py-2 mb-3" />
          {{#if this.currentUser.user}}
            <InputsLiveSearch
              class="mb-3"
              @inputClass="navbar-search"
              @searchValue={{this.searchTerm}}
              @onInput={{fn (mut this.searchTerm)}}
            />
            {{#each this.filteredChildren as |nav|}}
              {{#if (getCanNav nav this.licensing)}}
                <NavItem
                  @item={{nav}}
                  @navCompressed={{this.resizeListener.navCompressed}}
                  @isExpanded={{or (getIsExpanded nav this.router) nav.isExpanded}}
                  @searchTerm={{@searchTerm}}
                />
              {{/if}}
            {{/each}}
          {{/if}}
        </ul>
      </nav>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
