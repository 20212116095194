import { template } from "@ember/template-compiler";
import PowerSelect from 'eflex/components/power-select';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
const Placeholder = template(`
<span class="ember-power-select-placeholder" ...attributes>
  {{#unless @noIcon}}
    <FaIcon @icon="plus" @prefix="fas" @size="sm" class="me-2"/>
  {{/unless}}
  <span class="ember-power-select-placeholder-text">
    {{@placeholder}}
  </span>
</span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template(`
<div class="dropdown">
  <PowerSelect
    class="btn btn-outline-primary component-button-power-select w-100 h-100 p-0 {{if @disabled 'disabled'}}"
    @triggerClass={{@triggerClass}}
    @disabled={{@disabled}}
    @dropdownClass="{{this.dropdownClass}} button-power-select"
    @placeholderComponent={{Placeholder}}
    @placeholder={{@placeholder}}
    @extra={{@extra}}
    @options={{@options}}
    @searchEnabled={{@searchEnabled}}
    @searchField={{@searchField}}
    @onChange={{@onChange}}
    ...attributes
  as |option select|>
    {{yield option select}}
  </PowerSelect>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
