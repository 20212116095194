import { template } from "@ember/template-compiler";
import { StrokeOptions } from 'eflex/constants/work-instructions/tool-props';
import { generateStrokeDashArray } from 'eflex/util/stroke-helper';
import PowerSelect from 'eflex/components/power-select';
import { inputNum } from 'eflex/helpers';
import { on } from '@ember/modifier';
import { withDefault } from '@eflexsystems/ember-composable-helpers';
import { fn } from '@ember/helper';
const getStrokeLabelClass = (strokeWidth1)=>{
    strokeWidth1 = Number.parseInt(strokeWidth1);
    if (strokeWidth1 < 10) {
        return 'ones';
    } else if (strokeWidth1 < 100) {
        return 'tens';
    } else {
        return null;
    }
};
const setStrokeStyle = (setStrokeAction1, strokeWidth1, style1)=>{
    setStrokeAction1(generateStrokeDashArray(style1, strokeWidth1), style1, strokeWidth1);
};
const setStrokeWidth = (setStrokeAction1, minimum1, maximum1, strokeStyle1, width1)=>{
    if (width1 < minimum1 || isNaN(width1)) {
        width1 = minimum1;
    } else if (width1 > maximum1) {
        width1 = maximum1;
    }
    setStrokeAction1(generateStrokeDashArray(strokeStyle1, width1), strokeStyle1, width1);
};
export default template(`
<div class="component-inputs-stroke-select" ...attributes>
  {{#let
    (withDefault @minimum 1)
    (withDefault @maximum 50)
  as |minimum maximum|}}
    <PowerSelect
      @dropdownClass="stroke-style-options"
      @options={{StrokeOptions.options}}
      @onChange={{fn setStrokeStyle @setStroke @strokeWidth}}
      @selected={{@strokeStyle}}
      @disabled={{@disabled}}
      @matchTriggerWidth={{true}}
      class="stroke-style-select"
    as |style|>
      <label data-style={{style}} class="stroke-option {{style}}"></label>
    </PowerSelect>

    <div class="stroke-width">
      <input
        type="number"
        value={{@strokeWidth}}
        min={{minimum}}
        max={{maximum}}
        disabled={{@disabled}}
        class="stroke-width-input form-control"
        {{on "input" (inputNum (fn setStrokeWidth @setStroke minimum maximum @strokeStyle))}}
      />
      {{#if @strokeWidth}}
        <label class="{{getStrokeLabelClass @strokeWidth}} {{if @disabled "disabled"}}">
          pt
        </label>
      {{/if}}
    </div>
  {{/let}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
