import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { buildValidations, validator } from '@eflexsystems/ember-tracked-validations';
import { t } from 'ember-intl';
import { fn } from '@ember/helper';
import StandardModal from 'eflex/components/standard-modal';
import BsForm from 'eflex/components/bs-form';
import onKey from 'ember-keyboard/modifiers/on-key';
import BsButton from 'eflex/components/bs-button';
import { or, not } from 'ember-truth-helpers';
import { isPresent } from '@ember/utils';
let KineticEmployeeModal = @buildValidations({
    employeeId: [
        validator('inline', {
            validate (employee1, options1, modal1) {
                if (!modal1.employee) {
                    return modal1.intl.t('kinetic.invalidEmployee');
                }
                return true;
            }
        })
    ]
})
class KineticEmployeeModal extends Component {
    @service
    kineticShiftRepo;
    @service
    kineticEmployeeRepo;
    @service
    intl;
    @tracked
    employeeId;
    @tracked
    employee;
    @tracked
    shift;
    setEmployee = task(waitFor(async ()=>{
        this.employee = await this.kineticEmployeeRepo.getEmployee.perform(this.employeeId);
        if (!this.employee) {
            this.shift = null;
            return;
        }
        this.shift = this.employee.currentShift ?? this.employee.defaultShift;
    }));
    get isInvalid() {
        return this.validations?.isInvalid ?? false;
    }
    static{
        template(`
    <StandardModal
      @onCancel={{@onCancel}}
      @onConfirm={{fn @onConfirm this.employee this.shift}}
      @size="sm"
      class="employee-login-modal"

    as |modal|>
      <modal.header @text={{t "controls.login.login"}} />
      <modal.body>
        <BsForm @model={{this}} as |form|>
          <div class="row">
            <div class="col">
              <form.element
                @controlType="text"
                @property="employeeId"
                @label={{t "kinetic.employeeId"}}
                class="employee-id"
                {{onKey "Enter" this.setEmployee.perform}}
              />
            </div>
            <div class="col-auto align-self-end">
              <BsButton
                @onClick={{this.setEmployee.perform}}
                @disabled={{not this.employeeId}}
                class="submit-id"
              >
                {{t "submit"}}
              </BsButton>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <form.element
                @controlType="text"
                @property="name"
                @label={{t "name"}}
                @model={{this.employee}}
                class="employee-name"
              as |el|>
                <el.control @disabled={{true}}/>
              </form.element>
            </div>
            <div class="col">
              <form.element
                @controlType="power-select"
                @label={{t "kinetic.shiftId"}}
                @options={{this.kineticShiftRepo.kineticShifts}}
                @optionLabelPath="displayName"
                @property='shift'
                class="shift-select"
              as |el|>
                <el.control
                  @disabled={{or (not this.employee) (isPresent this.employee.currentShift)}}
                  @placeholder={{t "kinetic.selectShift"}}
                />
              </form.element>
            </div>
          </div>
        </BsForm>

      </modal.body>
      <modal.footer as |footer|>
        <footer.confirm
          @disabled={{or (not this.employee) (not this.shift)}}
        >
          {{t "ok"}}
        </footer.confirm>
        <footer.close />
      </modal.footer>
    </StandardModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default KineticEmployeeModal;
