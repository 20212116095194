import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
// eslint-disable-next-line no-restricted-imports
import $ from 'jquery';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { without, pluck, zipObj } from 'ramda';
import { registerDestructor } from '@ember/destroyable';
import StandardModal from 'eflex/components/standard-modal';
import { t } from 'ember-intl';
import BsForm from 'eflex/components/bs-form';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
let loadedJqueryQueryBuilder = false;
const createRecordFromRule = (rule1, parent1, options1, store1)=>{
    const componentOption1 = options1.find((item1)=>item1.id === rule1.value);
    const newRule1 = store1.createRecord('complexComponentOption', {
        parent: parent1,
        componentOption: componentOption1,
        comparedComponent: componentOption1?.component
    });
    if (rule1.condition) {
        newRule1.condition = rule1.condition;
    }
    if (rule1.operator) {
        newRule1.operatorName = rule1.operator;
    }
    rule1.rules?.forEach((nestedRule1)=>{
        createRecordFromRule(nestedRule1, newRule1, options1, store1);
    });
};
export default class ComponentComplexOptionBuilder extends Component {
    @service
    store;
    @service
    notifier;
    #queryBuilder;
    loadQueryBuilder = task(waitFor(async (element1)=>{
        if (!loadedJqueryQueryBuilder) {
            await import('jQuery-QueryBuilder');
            loadedJqueryQueryBuilder = true;
        }
        const rules1 = this.args.selectedOption.toRulesObject();
        rules1.rules ??= []; // ensure top level has rules
        // eslint-disable-next-line ember/no-jquery
        $(element1).queryBuilder({
            rules: rules1,
            default_condition: this.args.selectedOption.condition,
            allow_empty: true,
            allow_invalid: true,
            filters: this.args.components.map((component1)=>{
                const options1 = without([
                    this.args.selectedOption
                ], component1.options);
                return {
                    id: component1.id,
                    label: component1.name,
                    type: 'string',
                    input: 'select',
                    operators: [
                        'equal',
                        'not_equal'
                    ],
                    values: zipObj(pluck('id', options1), pluck('name', options1))
                };
            })
        });
        registerDestructor(this, ()=>{
            element1.queryBuilder.destroy();
        });
        this.#queryBuilder = element1.queryBuilder;
    }));
    updateComplexOption = ()=>{
        const queryBuilder1 = this.#queryBuilder;
        if (!queryBuilder1.validate()) {
            this.notifier.sendError('components.options.invalidComplexOption');
            return;
        }
        const updated1 = queryBuilder1.getRules({
            allow_invalid: true
        });
        const allOptions1 = this.args.components.flatMap((component1)=>component1.options);
        const selectedOption1 = this.args.selectedOption;
        selectedOption1.condition = updated1.condition;
        selectedOption1.rules.forEach((rule1)=>{
            rule1.deleteRecord();
        });
        updated1.rules.forEach((rule1)=>{
            createRecordFromRule(rule1, selectedOption1, allOptions1, this.store);
        });
        this.args.onConfirm?.(selectedOption1);
    };
    static{
        template(`
    <StandardModal
      class="complex-option-modal"
      @onConfirm={{this.updateComplexOption}}
      @onCancel={{@onCancel}}
      @size="lg"
    as |modal|>
      <modal.header @text={{t "plant.areas.components.rulesBuilder"}} />
      <modal.body class="complex-option-builder">
        <BsForm @model={{@selectedOption}} class="mb-4" as |form|>
          <form.element
            @controlType="text"
            @label={{t "description"}}
            @property="name"
          as |el|>
            <el.control @disabled={{@disabled}} class="builder-complex-option-name" />
          </form.element>
        </BsForm>
        <div
          class="query-builder {{if @disabled "pe-none"}}"
          {{didInsert this.loadQueryBuilder.perform}}
          {{didUpdate this.updateQueryBuilder}}
        >
        </div>
      </modal.body>
      <modal.footer/>
    </StandardModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
