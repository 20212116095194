import { template } from "@ember/template-compiler";
import moment from 'moment-timezone';
import BsTooltip from 'eflex/components/bs-tooltip';
import { t } from 'ember-intl';
import PortalTarget from 'ember-stargate/components/portal-target';
import getProductVersion from 'eflex/util/get-product-version';
import config from 'eflex/config/environment';
const year = moment().get('year');
export default template(`
<div
  class="main-footer d-flex align-items-center bg-gray-100 text-gray-500 fs-5 fw-semibold hidden-print px-3 py-2"
  ...attributes
>
  <div class="version-wrapper d-flex justify-content-center flex-fill">
    <BsTooltip @triggerEvents="hover">
      {{config.APP.version}}
    </BsTooltip>
    <div class="me-3">
      {{t "footer"}} © {{year}}.
    </div>
    <div class="version-text">
      {{t "version"}} {{getProductVersion @version}}
    </div>
  </div>
  <PortalTarget class="footer-portal ms-auto" @name='footer-portal' />
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
