import { template } from "@ember/template-compiler";
import { decOpacityToHex } from 'eflex/util/opacity-helper';
import { isBlank } from '@ember/utils';
import OpacityInput from 'eflex/components/inputs/opacity';
import { inputVal } from 'eflex/helpers';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
const DEFAULT_OPACITY = '100';
const DEFAULT_COLOR = '#000000';
const updateColor = (onUpdateColor1, opacity1, color1)=>{
    color1 ??= DEFAULT_COLOR;
    const opacityHex1 = decOpacityToHex(opacity1);
    onUpdateColor1(color1, opacity1, color1.concat(opacityHex1));
};
const updateOpacity = (onUpdateColor1, color1, opacity1)=>{
    opacity1 ??= DEFAULT_OPACITY;
    const opacityHex1 = decOpacityToHex(opacity1);
    onUpdateColor1(color1, opacity1, color1.concat(opacityHex1));
};
export default template(`
<div class="component-inputs-color-opacity d-flex align-items-center" ...attributes>
  {{#let
    (if (isBlank @selectedOpacity) DEFAULT_OPACITY @selectedOpacity)
    (if (isBlank @selectedColor) DEFAULT_COLOR @selectedColor)
  as |opacity color|}}
    {{#unless @hideOpacity}}
      <OpacityInput
        class="opacity flex-fill me-2"
        @opacity={{opacity}}
        @disabled={{@disabled}}
        @update={{fn updateOpacity @updateColor color}}
      />
    {{/unless}}

    <input
      type="color"
      value={{color}}
      disabled={{@disabled}}
      class="square-color-picker"
      {{on "input" (inputVal (fn updateColor @updateColor opacity))}}
    />
  {{/let}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
