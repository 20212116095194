import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import BsForm from 'eflex/components/bs-form';
export default class BiPanelTaskTags extends Component {
    @service
    taskRepo;
    get tags() {
        return [
            ...new Set(this.taskRepo.tasks.flatMap((_task1)=>_task1.tags))
        ].toSorted();
    }
    static{
        template(`
    <div class="tag-select" ...attributes>
      <BsForm as |form|>
        <form.element
          class="tag-select"
          @controlType="power-select-multiple"
          @label={{t "plant.task.tags"}}
          @value={{@tags}}
          @options={{this.tags}}
          @onChange={{@onChange}}
        as |el|>
          <el.control
            @searchEnabled={{true}}
            @placeholder={{t "biPanel.tags.placeholder"}}
          />
        </form.element>
      </BsForm>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
