import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import BARCODE_SYMBOLOGIES from 'eflex/constants/barcode-symbologies';
import Component from '@glimmer/component';
import { addObjectIfNotPresent } from 'eflex/util/array-helpers';
import { without } from 'ramda';
import { t } from 'ember-intl';
import { fn } from '@ember/helper';
import AndOr from 'eflex/components/and-or';
import HardwareSelect from 'eflex/components/hardware-select';
import { eq, not, or } from 'ember-truth-helpers';
import { selectVal, getHardwareType } from 'eflex/helpers';
import { findBy } from '@eflexsystems/ember-composable-helpers';
const BARCODE_HARDWARE_TYPES = [
    'cognex-camera',
    'datalogic',
    'keyence',
    'node-red',
    'plc-hardware',
    'web-cam'
];
export default class TaskDeviceConfigBarcode extends Component {
    @service
    store;
    @service
    stationRepo;
    @service
    licensing;
    get stations() {
        return without([
            this.args.task.parent
        ], this.stationRepo.stations);
    }
    get partMarriage1() {
        return this.args.task.jemPartMarriages.find((item1)=>item1.order === 1)?.stations;
    }
    get partMarriage2() {
        return this.args.task.jemPartMarriages.find((item1)=>item1.order === 2)?.stations;
    }
    updatePartMarriage = (current1, order1, stations1)=>{
        if (current1 == null) {
            const jemPartMarriage1 = this.store.createRecord('jemPartMarriage', {
                order: order1,
                stations: stations1
            });
            addObjectIfNotPresent(this.args.task.jemPartMarriages, jemPartMarriage1);
        } else {
            this.args.task.jemPartMarriages.find((item1)=>item1.order === order1).stations = stations1;
        }
    };
    static{
        template(`
    <div class='row form-row'>
      <div class='col-6'>
        <HardwareSelect
          @form={{@form}}
          @model={{@task}}
          @default={{t "hardware.usbScanner"}}
          @types={{BARCODE_HARDWARE_TYPES}}
        />
      </div>
      <div class='col-6'>
        {{#if (eq (getHardwareType @task) 'web-cam')}}
          <@form.element
            @controlType='power-select'
            @label={{t 'symbology'}}
            @options={{BARCODE_SYMBOLOGIES}}
            @value={{findBy 'value' @task.barcodeSymbology BARCODE_SYMBOLOGIES}}
            @onChange={{selectVal (fn (mut @task.barcodeSymbology))}}
          as |el|>
            <el.control class='select-barcode-symbology' @placeholder={{t 'symbology.any'}} as |symbology|>
              {{t symbology.name}}
            </el.control>
          </@form.element>
        {{else if (eq (getHardwareType @task) 'node-red')}}
          <@form.element
            @controlType="text"
            @label={{t "mqttRequestTopic"}}
            @property="mqttTopic"
            class="task-mqtt-topic"
          />
        {{/if}}
      </div>
    </div>
    {{#if this.licensing.license.partMarriageEnabled}}
      <div class='row form-row mt-3 part-marriage'>
        <div class='col-auto'>
          <@form.element
            @controlType='checkbox'
            @property='marryPart'
            @label={{t 'marryPart'}}
            class="marry-part"
          />
        </div>
        <div class='col'>
          <div>
            <@form.element
              @controlType='text'
              @label={{t 'plant.task.barcode.marriageLabel'}}
              @property='marriageLabel'
            as |el|>
              <el.control disabled={{or @disabled (not @task.marryPart)}} />
            </@form.element>
          </div>
          <div>
            <AndOr
              @label={{t 'plant.task.barcode.previousStations'}}
              @or1Options={{this.stations}}
              @or1Selected={{this.partMarriage1}}
              @or1Changed={{fn this.updatePartMarriage this.partMarriage1 1}}
              @or2Options={{this.stations}}
              @or2Selected={{this.partMarriage2}}
              @or2Changed={{fn this.updatePartMarriage this.partMarriage2 2}}
              @disabled={{or @disabled (not @task.marryPart)}}
            />
          </div>
          <div>
            <@form.element
              @controlType='checkbox'
              @property='uniqueMarriageSerial'
              @label={{t 'uniqueMarriageSerial'}}
              class='unique-marriage'
            as |el|>
              <el.control
                @disabled={{or @disabled (not @task.marryPart)}}
              />
            </@form.element>
          </div>
        </div>
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
