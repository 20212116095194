import { template } from "@ember/template-compiler";
import BsForm from 'eflex/components/bs-form';
import { anyInvalid } from 'eflex/helpers';
import { conditionalAutofocus } from 'eflex/modifiers';
import { t } from 'ember-intl';
import { eq, not } from 'ember-truth-helpers';
export default template(`
<BsForm
  @model={{this}}
  @novalidate={{true}}
  @readonly={{not @waitingForData}}
  @submitOnEnter={{true}}
  @onSubmit={{@onSubmit}}
  @disabled={{not @waitingForData}}
  ...attributes
as |form|>
  <div class='row mb-0'>
    {{#each @customIdentifierData as |datum i|}}
      <form.element
        class="custom-identifier col is-required"
        @controlType="text"
        @model={{datum}}
        @size="lg"
        @property="value"
        @label={{datum.name}}
        {{conditionalAutofocus (eq i 0)}}
      />
    {{/each}}

    {{#if @waitingForData}}
      <div class="align-self-end col">
        <form.submitButton
          class="custom-identifier-submit text-uppercase"
          @disabled={{anyInvalid @customIdentifierData}}
          @type="primary"
          @size="lg"
        >
          {{t "submit"}}
        </form.submitButton>
      </div>
    {{/if}}
  </div>
</BsForm>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
