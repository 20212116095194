import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import moment from 'moment-timezone';
import echartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';
import { clone } from 'ramda';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import Spinner from 'eflex/components/spinner';
import EChart from 'eflex/components/e-chart';
import { get, fn } from '@ember/helper';
export default class CycleTimeStation extends Component {
    @service
    queryRunner;
    @service
    intl;
    @service
    router;
    get params() {
        return {
            stationIds: this.args.stationIds,
            modelIds: this.args.modelIds,
            userIds: this.args.userIds,
            tags: this.args.tags,
            startDate: this.args.startDate,
            endDate: this.args.endDate,
            shiftNames: this.args.shiftNames
        };
    }
    getData = task({
        drop: true
    }, waitFor(async (params1)=>{
        const cycleTimeQuery1 = await this.queryRunner.queryWithParams.perform(params1, [
            {
                $group: {
                    _id: '$location._id',
                    name: {
                        $first: {
                            $first: '$location.captions.text'
                        }
                    },
                    stationTargetCycleTime: {
                        $last: '$totalTargetCycleTime'
                    },
                    numberOfCycles: {
                        $sum: 1
                    },
                    numberOfCyclesOverTarget: {
                        $sum: {
                            $cond: {
                                if: {
                                    $and: [
                                        {
                                            $gte: [
                                                '$totalTargetCycleTime',
                                                1
                                            ]
                                        },
                                        {
                                            $gt: [
                                                '$cycleTime',
                                                {
                                                    $multiply: [
                                                        '$totalTargetCycleTime',
                                                        1000
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                then: 1,
                                else: 0
                            }
                        }
                    },
                    avgStationCycleTime: {
                        $avg: '$cycleTime'
                    }
                }
            },
            {
                $project: {
                    _id: true,
                    name: true,
                    avgStationCycleTime: true,
                    stationTargetCycleTime: true,
                    percentageOfCyclesOverTarget: {
                        $divide: [
                            '$numberOfCyclesOverTarget',
                            '$numberOfCycles'
                        ]
                    }
                }
            },
            {
                $sort: {
                    avgStationCycleTime: 1
                }
            }
        ]);
        const cycleTimeData1 = await this.queryRunner.runQuery.perform('BuildStatuses', cycleTimeQuery1);
        return {
            chartData: this.getEchartOptions(cycleTimeData1),
            exportData: ()=>this.formatCycleTimeExportData(clone(cycleTimeData1))
        };
    }));
    cycleTimeData = trackedTask(this, this.getData, ()=>[
            this.params,
            this.args.refresher
        ]);
    getEchartOptions(cycleTimeData1) {
        return {
            title: {
                text: this.intl.t('bi.chartLabel.cycleTimeByStation')
            },
            legend: {
                show: true,
                bottom: 5
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params1)=>{
                    const tooltipItems1 = [];
                    params1.forEach(({ marker: marker1, seriesName: seriesName1, value: value1, name: name1 }, index1)=>{
                        switch(index1){
                            case 0:
                                {
                                    tooltipItems1.push(`<div>${name1}</div>${marker1} ${seriesName1}:<span style="float:right;font-weight:bold;">${moment.duration(value1, 'seconds').format('d[d] h[h] m[m] s[s]', {
                                        precision: 1,
                                        trim: 'both mid'
                                    })}</span>`);
                                    break;
                                }
                            case 1:
                                {
                                    tooltipItems1.push(`${marker1} ${seriesName1}:<span style="float:right;font-weight:bold;">${value1}%</span>`);
                                    break;
                                }
                        }
                    });
                    return `<div style="min-width:285px;">${tooltipItems1.join('<br/>')}</div>`;
                }
            },
            yAxis: {
                type: 'category',
                boundaryGap: true,
                data: cycleTimeData1.map((item1)=>item1.name)
            },
            xAxis: [
                {
                    type: 'value',
                    show: false
                },
                {
                    type: 'value',
                    max: 100,
                    interval: 10,
                    position: 'bottom',
                    axisLabel: {
                        formatter: '{value}%'
                    }
                }
            ],
            series: [
                {
                    name: this.intl.t('bi.chartLegend.avgCycleTime'),
                    data: cycleTimeData1.map((m1)=>{
                        const avgStationCycleTime1 = Number((m1.avgStationCycleTime / 1000).toFixed(1));
                        const targetCycleTime1 = m1.stationTargetCycleTime;
                        return {
                            value: avgStationCycleTime1,
                            itemStyle: {
                                color: avgStationCycleTime1 > targetCycleTime1 && targetCycleTime1 && targetCycleTime1 > 0 ? echartsTheme.colorPalette.danger : echartsTheme.colorPalette.success
                            }
                        };
                    }),
                    label: {
                        show: true,
                        position: 'right',
                        color: echartsTheme.colorPalette.biGrey,
                        formatter: (params1)=>moment.duration(params1.value, 'seconds').format('d[d] h[h] m[m] s[s]', {
                                precision: 1,
                                trim: 'both mid'
                            })
                    },
                    type: 'bar',
                    xAxisIndex: 0,
                    markLine: {
                        data: [
                            {
                                type: 'average',
                                name: 'Avg'
                            }
                        ],
                        label: {
                            show: true,
                            position: 'end',
                            formatter: (params1)=>`${this.intl.t('bi.chartLegend.avg')}: ${moment.duration(params1.value, 'seconds').format('d[d] h[h] m[m] s[s]', {
                                    precision: 1,
                                    trim: 'both mid'
                                })}`
                        },
                        symbol: 'none'
                    }
                },
                {
                    name: this.intl.t('bi.chartLegend.pctOverCycle'),
                    data: cycleTimeData1.map((m1)=>Math.round(m1.percentageOfCyclesOverTarget * 100)),
                    type: 'line',
                    xAxisIndex: 1,
                    color: echartsTheme.colorPalette.taskRowRed
                }
            ],
            stationIds: cycleTimeData1.map((item1)=>item1._id)
        };
    }
    formatCycleTimeExportData(exportData1) {
        exportData1.forEach((station1)=>{
            station1.avgStationCycleTime = moment.duration(station1.avgStationCycleTime, 'seconds').format('d[d] h[h] m[m] s[s]', {
                precision: 1,
                trim: 'both mid'
            });
            station1.percentageOfCyclesOverTarget = `${Math.round(station1.percentageOfCyclesOverTarget * 100)}%`;
            delete station1._id;
        });
        return exportData1;
    }
    stationDrillDown = (stationCycleTimeData1, dataIndex1)=>{
        this.router.transitionTo('bi.cycleTime.stations', {
            queryParams: {
                stationIds: [
                    stationCycleTimeData1.stationIds[dataIndex1]
                ],
                modelIds: this.args.modelIds,
                userIds: this.args.userIds,
                tags: this.args.tags,
                beginDate: this.args.startDate,
                endDate: this.args.endDate,
                shiftNames: this.args.shiftNames
            }
        });
    };
    static{
        template(`
    {{! template-lint-disable no-passed-in-event-handlers }}
    <div ...attributes>
      {{#if this.cycleTimeData.isRunning}}
        <Spinner />
      {{else if (get this.cycleTimeData.value.chartData.series '0.data.length')}}
        <div class="row h-100">
          <div class="col d-flex justify-content-between">
            <EChart
              @option={{this.cycleTimeData.value.chartData}}
              @exportData={{this.cycleTimeData.value.exportData}}
              class="h-100 w-100 cycle-time-station"
              @onClick={{fn this.stationDrillDown this.cycleTimeData.value.chartData}}
            />
          </div>
        </div>
      {{else}}
        <BiNoDataPlaceholder />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
