import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import PowerSelectMultipleWithCreate from 'eflex/components/power-select-multiple-with-create';
import { fn, get } from '@ember/helper';
import { t } from 'ember-intl';
import { withDefault } from '@eflexsystems/ember-composable-helpers';
import { set } from 'eflex/helpers';
const getOptions = (siblings1, fieldName1, additionalOptions1)=>{
    let options1 = siblings1.filter((item1)=>!item1.isDeleted).map((item1)=>item1[fieldName1]);
    options1.forEach((element1, index1, array1)=>{
        if (element1?.content) {
            array1[index1] = element1.content;
        }
    });
    options1 = options1.flat();
    if (additionalOptions1) {
        options1 = options1.concat(additionalOptions1);
    }
    return [
        ...new Set(options1)
    ].toSorted();
};
const showCreateWhen = (tag1, options1)=>{
    return !options1.includes(tag1);
};
const onCreateTag = (fieldName1, taggedModel1, tag1)=>{
    taggedModel1[fieldName1] = [
        ...taggedModel1[fieldName1],
        tag1
    ];
};
export default class TagsSelect extends Component {
    @service
    intl;
    suggestAddTag = (tag1)=>{
        return this.intl.t('plant.task.addNewTag', {
            tag: tag1
        });
    };
    static{
        template(`
    {{#let (withDefault @fieldName 'tags') as |fieldName|}}
      <PowerSelectMultipleWithCreate
        @triggerClass="h-auto"
        @disabled={{@disabled}}
        @options={{getOptions @siblings fieldName @additionalOptions}}
        @selected={{get @taggedModel fieldName}}
        @searchEnabled={{true}}
        @searchMessage={{t "plant.task.addTagPrompt"}}
        @onCreate={{fn onCreateTag fieldName @taggedModel}}
        @onChange={{set @taggedModel fieldName}}
        @showCreateWhen={{showCreateWhen}}
        @renderInPlace={{true}}
        @placeholder={{t "plant.task.tagsPlaceholder"}}
        @buildSuggestion={{this.suggestAddTag}}
        class="standard-select tags-select w-100"
        ...attributes
      as |tag|>
        {{tag}}
      </PowerSelectMultipleWithCreate>
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
