import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { t } from 'ember-intl';
import StandardModal from 'eflex/components/standard-modal';
import { gt } from 'ember-truth-helpers';
import { on } from '@ember/modifier';
import { formatDate } from 'eflex/helpers';
export default class CarouselModal extends Component {
    @tracked
    index = this.args.images.indexOf(this.args.currentImage);
    get currentImage() {
        return this.args.images[this.index];
    }
    nextImage = ()=>{
        if (this.index === this.args.images?.length - 1) {
            this.index = 0;
        } else {
            this.index += 1;
        }
    };
    previousImage = ()=>{
        if (this.index === 0) {
            this.index = this.args.images?.length - 1;
        } else {
            this.index -= 1;
        }
    };
    static{
        template(`
    <StandardModal
      class="carousel-modal"
      @size="lg"
      @onCancel={{@onCancel}}
      ...attributes
    as |modal|>
      <modal.header as |header|>
        <div class="station-name">
          {{this.currentImage.stationName}}
        </div>
        <header.close />
      </modal.header>
      <modal.body>
        <div id="carousel-modal">
          <div class="first-column">
            {{#if (gt @images.length 1)}}
              <nav>
                <div
                  class="left-arrow"
                  {{on "click" this.previousImage}}
                ></div>
                <div
                  class="right-arrow"
                  {{on "click" this.nextImage}}
                ></div>
              </nav>
            {{/if}}
            <div class="status {{if this.currentImage.pass "pass" "fail"}}">
              {{this.currentImage.statusText}}
            </div>
            <div class="info">
              <div class="station-path">
                {{this.currentImage.stationPath}}
              </div>
              <div class="task">
                {{this.currentImage.taskName}}
              </div>
              <div class="timestamp">
                {{formatDate this.currentImage.dateCreated}}
              </div>
              <div class="serial">
                {{this.currentImage.serialNumber}}
              </div>
            </div>
            <a
              href={{this.currentImage.downloadUrl}}
              download={{this.currentImage.fileName}}
            >
              <div class="download-button"></div>
            </a>
          </div>
          <div class="second-column">
            <div class="img-contain">
              <div class="carousel-image">
                {{#if this.currentImage.isPdf}}
                  <FaIcon @icon="file-pdf" @prefix="fas" class="fa-file-pdf-o pdf-icon" />
                {{else}}
                  <img src={{this.currentImage.downloadUrl}} />
                {{/if}}
              </div>
              <div class="img-caption mt-1">
                {{#unless this.currentImage.hasImage}}
                  {{t "controls.evision.imagecarouselmodal.thumbonly"}}
                {{/unless}}
              </div>
            </div>
          </div>
        </div>
      </modal.body>
      <modal.footer as |footer|>
        <footer.close>
          {{t "close"}}
        </footer.close>
      </modal.footer>
    </StandardModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
