import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { all, task } from 'ember-concurrency';
import { getCaptionText } from 'eflex/util/translation-helper';
import { waitFor } from '@ember/test-waiters';
import { removeObject } from 'eflex/util/array-helpers';
import { isEmpty } from '@ember/utils';
import style from 'ember-style-modifier';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import Spinner from 'eflex/components/spinner';
import { isPresent } from '@eflexsystems/ember-composable-helpers';
import { t } from 'ember-intl';
const NODE_DEPTH_COLOR = [
    '#daf1fd',
    '#8fd6f6',
    '#56a3d9',
    '#426070',
    '#000000'
];
const KLAY_NODE_LIMIT = 25;
const COSE_LAYOUT = {
    name: 'cose',
    animate: true,
    nodeOverlap: 85,
    componentSpacing: 10,
    directed: true,
    fit: true,
    nodeDimensionsIncludeLabels: true
};
const KLAY_LAYOUT = {
    name: 'klay',
    animate: true,
    directed: true,
    fit: true,
    nodeDimensionsIncludeLabels: true
};
export default class GenealogyDiagram extends Component {
    @service
    intl;
    #currentPoppers = [];
    #hasLoadedCytoscape = false;
    #cy;
    @tracked
    cursorType;
    @tracked
    legend = [];
    renderGraph = task({
        enqueue: true
    }, waitFor(async (element1, [marriageData1])=>{
        this.#cleanup();
        if (!marriageData1 || isEmpty(marriageData1.buildStatuses)) {
            return;
        }
        const { default: cytoscape1 } = await import('cytoscape');
        if (!this.#hasLoadedCytoscape) {
            this.#hasLoadedCytoscape = true;
            const [{ default: klay1 }, { default: cytoscapePopper1 }, { default: panzoom1 }, { createPopper: createPopper1 }] = await all([
                import('cytoscape-klay'),
                import('cytoscape-popper'),
                import('cytoscape-panzoom'),
                import('@popperjs/core')
            ]);
            cytoscape1.use(klay1);
            cytoscape1.use(cytoscapePopper1(createPopper1));
            panzoom1(cytoscape1);
        }
        const graphData1 = this.#getGraphData(marriageData1);
        let layout1 = KLAY_LAYOUT;
        if (graphData1.nodes.length > KLAY_NODE_LIMIT) {
            layout1 = COSE_LAYOUT;
        }
        layout1.ready = ()=>{
            this.#renderLegend(marriageData1.maxDepth);
        };
        this.#cy = cytoscape1({
            container: element1.querySelector('.cytoscape-graph'),
            elements: graphData1,
            style: [
                {
                    selector: 'node',
                    style: {
                        'background-color': '#77778c',
                        label: 'data(id)',
                        height: 30,
                        width: 30,
                        'min-zoomed-font-size': 10,
                        'text-valign': 'center',
                        'line-color': '#a8eae5',
                        'font-size': '8px',
                        'font-weight': 'bold',
                        'text-outline-width': 2,
                        'text-outline-color': '#fff',
                        shape: 'ellipse'
                    }
                },
                {
                    selector: 'edge',
                    style: {
                        width: 3,
                        'curve-style': 'bezier',
                        'line-color': '#dedede',
                        'target-arrow-color': '#aaa',
                        'source-arrow-color': '#aaa',
                        'target-arrow-shape': 'triangle'
                    }
                },
                {
                    selector: 'node.not-married',
                    style: {
                        'border-width': '2px',
                        'border-style': 'solid',
                        'border-color': '#666666',
                        shape: 'rectangle'
                    }
                },
                {
                    selector: 'node.depth-0',
                    style: {
                        height: 80,
                        width: 80
                    }
                },
                {
                    selector: 'node.depth-1',
                    style: {
                        'background-color': NODE_DEPTH_COLOR[0],
                        height: 50,
                        width: 50
                    }
                },
                {
                    selector: 'node.depth-2',
                    style: {
                        'background-color': NODE_DEPTH_COLOR[1]
                    }
                },
                {
                    selector: 'node.depth-3',
                    style: {
                        'background-color': NODE_DEPTH_COLOR[2]
                    }
                },
                {
                    selector: 'node.depth-4',
                    style: {
                        'background-color': NODE_DEPTH_COLOR[3]
                    }
                },
                {
                    selector: 'node.depth-5',
                    style: {
                        'background-color': NODE_DEPTH_COLOR[4]
                    }
                }
            ],
            maxZoom: 3,
            minZoom: 0.25,
            layout: layout1
        });
        this.#cy.panzoom({
            sliderHandleIcon: 'icon icon-fa-minus',
            zoomInIcon: 'icon icon-fa-plus',
            zoomOutIcon: 'icon icon-fa-minus',
            resetIcon: 'icon icon-fa-up-right-and-down-left-from-center'
        });
        this.#cy.on('click', ()=>{
            this.#currentPoppers.forEach((tooltipId1)=>{
                this.#removePopper(element1, tooltipId1);
            });
        });
        this.#cy.on('mouseover mouseup', 'node', (e1)=>{
            this.cursorType = 'pointer';
            this.#createPopper(element1, e1.target);
        });
        this.#cy.on('mouseout', 'node', (e1)=>{
            this.cursorType = 'default';
            this.#removePopperByTarget(element1, e1.target);
        });
        this.#cy.on('mousedown', 'node', (e1)=>{
            this.cursorType = 'grabbing';
            this.#removePopperByTarget(element1, e1.target);
        });
        this.#cy.on('click', 'node', (e1)=>{
            this.args.nodeClicked?.(e1.target);
        });
        await this.#cy.promiseOn('layoutstop');
    }));
    #renderLegend(maxDepth1) {
        const legendRows1 = [
            {
                label: this.intl.t('reporting.parts.partGenealogy.legendMain'),
                class: 'depth-0'
            }
        ];
        for(let i1 = 1; i1 <= maxDepth1; i1++){
            legendRows1.push({
                label: this.intl.t('reporting.parts.partGenealogy.legendDepth', {
                    depth: i1
                }),
                class: `depth-${i1}`
            });
        }
        legendRows1.push({
            label: this.intl.t('reporting.parts.partGenealogy.legendEnd'),
            class: 'end'
        });
        this.legend = legendRows1;
    }
    #getTargetTooltipId(target1) {
        const validCharacters1 = /[\w.:-]/g;
        const id1 = target1.id().match(validCharacters1)?.join('') ?? '';
        return `popper-target-${id1}`;
    }
    #getPopperElement(target1, tooltipId1) {
        const tooltip1 = document.createElement('div');
        tooltip1.id = tooltipId1;
        tooltip1.classList.add('target-popper', 'border');
        const table1 = document.createElement('table');
        tooltip1.append(table1);
        const targetData1 = target1.data();
        const popperContent1 = new Map([
            [
                this.intl.t('serialNumber'),
                targetData1.id
            ],
            [
                this.intl.t('marriageTask'),
                getCaptionText(targetData1.locationCaptions)
            ],
            [
                this.intl.t('plant.task.barcode.marriageLabel'),
                getCaptionText(targetData1.marriageCaptions)
            ],
            [
                this.intl.t('timestamp'),
                targetData1.timestamp
            ],
            [
                this.intl.t('depth'),
                targetData1.depth
            ]
        ]);
        for (const [label1, value1] of popperContent1){
            if (value1) {
                this.#addPopperTableRow(table1, label1, value1);
            }
        }
        return tooltip1;
    }
    #addPopperTableRow(table1, title1, value1) {
        const tr1 = table1.insertRow();
        const tdTitle1 = tr1.insertCell();
        tdTitle1.classList.add('popper-title');
        tdTitle1.textContent = title1;
        const tdValue1 = tr1.insertCell();
        tdValue1.classList.add('popper-value');
        tdValue1.textContent = value1;
    }
    #createPopper(wrapperElement1, target1) {
        const tooltipId1 = this.#getTargetTooltipId(target1);
        this.#currentPoppers.push(tooltipId1);
        target1.popper({
            content: ()=>{
                const tooltip1 = this.#getPopperElement(target1, tooltipId1);
                wrapperElement1.append(tooltip1);
                return tooltip1;
            }
        });
    }
    #removePopperByTarget(wrapperElement1, target1) {
        if (!target1 || !target1.id()) {
            return;
        }
        const tooltipId1 = this.#getTargetTooltipId(target1);
        this.#removePopper(wrapperElement1, tooltipId1);
    }
    #removePopper(wrapperElement1, tooltipId1) {
        const existingTarget1 = wrapperElement1.querySelector(`#${tooltipId1}`);
        if (existingTarget1) {
            existingTarget1.remove();
            removeObject(this.#currentPoppers, tooltipId1);
        }
    }
    #getGraphData(marriageData1) {
        const { nodes: nodes1, seenSerials: seenSerials1 } = this.#buildNodes(marriageData1.buildStatuses);
        const edges1 = this.#buildEdges(marriageData1.children, seenSerials1);
        const hashMap1 = new Map();
        marriageData1.children.forEach((child1)=>{
            if (child1.scannedBarcode) {
                hashMap1.set(child1.scannedBarcode, child1);
                if (!seenSerials1.has(child1.scannedBarcode) && seenSerials1.has(child1.serialNumber)) {
                    seenSerials1.add(child1.scannedBarcode);
                    nodes1.push({
                        data: {
                            id: child1.scannedBarcode
                        }
                    });
                    edges1.push({
                        data: {
                            id: `${child1.scannedBarcode}_${child1.serialNumber}`,
                            source: child1.scannedBarcode,
                            target: child1.serialNumber
                        }
                    });
                }
            }
        });
        nodes1.forEach((node1)=>{
            const details1 = hashMap1.get(node1.data.id);
            node1.classes = [
                `depth-${node1.data.depth}`
            ];
            if (details1) {
                Object.assign(node1.data, details1);
                node1.classes.push('is-married');
            } else {
                node1.classes.push('not-married');
            }
        });
        return {
            nodes: nodes1,
            edges: edges1
        };
    }
    #buildNodes(buildStatuses1) {
        const nodes1 = [];
        const seenSerials1 = new Set();
        buildStatuses1.forEach((buildStatus1)=>{
            const serialNumber1 = buildStatus1.serialNumber;
            if (!seenSerials1.has(serialNumber1)) {
                seenSerials1.add(serialNumber1);
                nodes1.push({
                    data: {
                        id: serialNumber1,
                        depth: buildStatus1.depth
                    }
                });
            }
        });
        return {
            nodes: nodes1,
            seenSerials: seenSerials1
        };
    }
    #buildEdges(children1, seenSerials1) {
        const edges1 = [];
        children1.forEach((child1)=>{
            // only create an edge when both the source and target exist
            if (seenSerials1.has(child1.scannedBarcode) && seenSerials1.has(child1.serialNumber)) {
                edges1.push({
                    data: {
                        id: `${child1.scannedBarcode}_${child1.serialNumber}`,
                        source: child1.scannedBarcode,
                        target: child1.serialNumber
                    }
                });
            }
        });
        return edges1;
    }
    #cleanup() {
        if (!this.#cy) {
            return;
        }
        this.#cy.removeAllListeners();
        this.#cy.destroy();
        this.#cy = null;
    }
    willDestroy() {
        super.willDestroy(...arguments);
        this.#cleanup();
    }
    static{
        template(`
    <div
      class="component-genealogy-diagram"
      {{style cursor=this.cursorType}}
      {{didInsert this.renderGraph.perform @marriageData}}
      {{didUpdate this.renderGraph.perform @marriageData}}
      ...attributes
    >
      {{#if this.renderGraph.isRunning}}
        <Spinner class="position-absolute" />
      {{/if}}

      {{#if (isPresent this.legend)}}
        <div class="graph-legend">
          {{#each this.legend as |row|}}
            <div class="legend-row">
              {{row.label}}
              <div class="item {{row.class}}"></div>
            </div>
          {{/each}}
          <div class="legend-row total">
            {{t "reporting.parts.partGenealogy.legendTotal" total=@totalNodes}}
          </div>
        </div>
      {{/if}}

      <div class="cytoscape-graph h-100"/>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
