import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
export default template(`
<div class="component-jem-no-data h-100" ...attributes>
  <div
    class="
      jem-no-data
      d-flex
      flex-column
      justify-content-center
      align-items-center
      h-100
      {{if @fullScreen "full-screen"}}"
    >
    <FaIcon @icon="hourglass-start" @prefix="fas" @size="3x" class="text-gray-500 mb-3"/>
    <div class="text-gray-500 fs-2">
      {{#if @message}}
        {{@message}}
      {{else}}
        {{t "plant.station.jem.noDataFromPlc"}}
      {{/if}}
    </div>
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
